import React from 'react';
import Board from 'react-ui-kanban';

const data = {
  lanes: [
    {
      id: 'lane1',
      title: 'Registration Team',
      label: '02',
      cards: [
        { id: 'Card1', title: 'Sports Day', description: 'Setup registration booth', label: '30 mins ago', draggable: true },
        { id: 'Card2', title: 'Event Funds', description: 'Transfer funds to club account', label: '1 day ago', draggable: true },
      ]
    },
    {
      id: 'lane2',
      title: 'Social Media Team',
      label: '0',
      cards: []
    },
    {
      id: 'lane3',
      title: 'Core Team',
      label: '03',
      cards: [
        { id: 'Card3', title: 'Plan Event', description: 'List activities', label: '30 mins ago', draggable: true },
        { id: 'Card4', title: 'Supervise', description: 'Overlook planning', label: '1 day ago', draggable: true },
        { id: 'Card5', title: 'Post Event', description: 'Track post event activities', label: '1 day ago', draggable: true },
      ]
    }
  ]
};

export default class KanBan extends React.Component {
  render() {
    return (
      <div className='p-4'>
        <Board data={data} />
      </div>
    );
  }
}
