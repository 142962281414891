import React, { useEffect,useState ,useRef, } from "react";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import SideInnerSM from "../components/SideInnerSM";
import Sidebar from "../components/Sidebar";
import { useLanguage } from "../components/language";
import Navbar from "../components/Navbar";
import "react-toggle/style.css" 
import { createGlobalStyle } from "styled-components";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import toast from "react-hot-toast";
import * as XLSX from "xlsx";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Modal from "react-modal";
import Select from "react-select";
import SampleFile from "./SampleFile";
import axios from "axios";
import { Line } from 'rc-progress';
import UsersUpload from "./UsersUpload";
import AttendancePage from "./AttendancePage";
// import cloud from "../assets/images/cloud_upload.svg"


const AttendanceWrapper = () => {
const [isSidebarVisible, setIsSidebarVisible] = useState(false);
const { t, changeLanguage, lanArabic } = useLanguage();
const [isEditModalOpen, setIsEditModalOpen] = useState(false);
const roleOptions = [
  { value: "admin", label: "Admin" },
  { value: "teacher", label: "Teacher" },
  // Add more role options as needed
];

const permissionOptions = [
  { value: "read", label: "Read" },
  { value: "write", label: "Write" },
  // Add more permission options as needed
];



const toggleSidebar = () => {
    setIsSidebarVisible(prevState => !prevState);
  }

  return (
    <div>


    <div>
      <>
    
    <div class="hidden md:block absolute w-full bg-lilac h-96  min-h-75"></div>
    
    
    <Sidebar />
    
    {isSidebarVisible && <SideInnerSM/>}
    
        <main className={`relative h-full max-h-screen transition-all duration-200 ease-in-out ${lanArabic ? 'xl:mr-68' : 'xl:ml-68'} rounded-xl`}>
          {/* Navbar */}
          <Navbar toggleSidebar={toggleSidebar} />
    
          {/* end Navbar */}
          {/* cards */}
          <div className="w-full lg:p-6 p-3 mx-auto">

    
                <AttendancePage/>
    
          </div>
        </main>
      </>
      </div>
    
     
        </div>
  )
}

export default AttendanceWrapper