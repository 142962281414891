import React, { createContext, useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";
import { GoogleLogin } from "@react-oauth/google";
import jwtDecode from "jwt-decode";
const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
  let authTokenInitState = localStorage.getItem("authTokens")
    ? JSON.parse(localStorage.getItem("authTokens"))
    : null;

  let userState = localStorage.getItem("authTokens")
    ? jwt_decode(localStorage.getItem("authTokens"))
    : null;

  let [user, setUser] = useState(() => userState);
  let [authTokens, setAuthTokens] = useState(() => authTokenInitState);

  const navigate = useNavigate();
  const location = useLocation();

  // useEffect(() => {
  //   const token = localStorage.getItem("authTokens");

  //   if (!token) {
  //     // const redirectTimer = setTimeout(() => {
  //     //   navigate("/login");
  //     // }, 100);

  //     // return () => clearTimeout(redirectTimer);

  //     window.location.href = '/login';
  //   }
  // }, [navigate]);

  // useEffect(() => {
  //   const token = localStorage.getItem("authTokens");

  //   if (!token && location.pathname !== "/login") {
  //     navigate("/login");
  //   }
  // }, [location.pathname, navigate]);

  useEffect(() => {
    const oneDayInMilliseconds = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

    const deleteAuthTokenTimer = setTimeout(() => {
      localStorage.removeItem("authTokens");
      setAuthTokens(null); // Ensure state reflects removal
    }, oneDayInMilliseconds);

    // Cleanup function to clear the timer when component unmounts or token changes
    return () => clearTimeout(deleteAuthTokenTimer);
  }, [authTokens]); // Add authTokens as a dependency

  let googleLoginUser = async (responseData) => {
    try {
      console.log("Response Data:", responseData);

      const tokenId = jwtDecode(responseData.credential);
      console.log("Decoded Token:", tokenId);
      console.warn(tokenId);

      const apiUrl = `${process.env.REACT_APP_API_KEY}/api/google-login/`;

      const apiResponse = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ tokenId }),
      });

      if (!apiResponse.ok) {
        throw new Error("Login failed. Server error.");
      }

      const data = await apiResponse.json();
      console.log("API Response Data:", data);

      try {
        const decodedToken = jwt_decode(data.token);
        console.log("Decoded Access Token:", decodedToken);

        // Update user state with decoded token
        setUser(decodedToken);
        setAuthTokens(data);
        localStorage.setItem("authTokens", JSON.stringify(data));
        toast.success("Login Successful!");
        navigate("/home");
      } catch (error) {
        console.error("Error:", error.message);
        toast.error("Login failed. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error.message);
      toast.error(
        "This account does not exists. Contact your school administration."
      );
    }
  };

  // New Google login error handler
  let googleLoginError = (error) => {
    console.error("Google Login Failed:", error);
    toast.error("Google Login Failed. Please try again.");
  };

  let loginUser = async (e) => {
    e.preventDefault();
    const credentials = new FormData(e.currentTarget);
    const apiUrl = `${process.env.REACT_APP_API_KEY}/api/token/`;

    let response;
    try {
      response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: credentials.get("username"),
          password: credentials.get("password"),
        }),
      });

      if (response.status === 401) {
        toast.error("Invalid credentials. Please try again.", {
          icon: "😓",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
        return;
      }

      if (!response.ok) {
        throw new Error("Login failed. Server error.");
      }

      let data = await response.json();
      let token = jwt_decode(data.access);

      if (response.status === 200) {
        setAuthTokens(data);
        setUser(jwt_decode(data.access));
        localStorage.setItem("authTokens", JSON.stringify(data));
        navigate("/home/");
      } else {
        toast.error("Oh No! Something went wrong", {
          icon: "😓",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
      }
    } catch (error) {
      toast.error("An error occurred during login. Please try again.", {
        icon: "😓",
        style: {
          borderRadius: "55px",
          marginBottom: "15px",
        },
      });
    }
  };

  let registerUser = async (e) => {
    e.preventDefault();
    const credentials = new FormData(e.currentTarget);

    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/api/register/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: credentials.get("username"),
          email: credentials.get("email"),
          password: credentials.get("password"),
          password2: credentials.get("password2"),
          role: "Student",
        }),
      }
    );

    let data = await response.json();

    if (response.status === 201) {
      navigate("/login");
    } else {
      alert("Something went wrong!");
    }
  };

  let logoutUser = () => {
    setAuthTokens(null);
    setUser(null);
    localStorage.removeItem("authTokens");
    navigate("/login");
  };

  let contextData = {
    user: user,
    loginUser: loginUser,
    logoutUser: logoutUser,
    registerUser: registerUser,
    googleLoginUser: googleLoginUser,
  };

  return (
    <div>
      <AuthContext.Provider value={contextData}>
        {children}
        <div style={{ zIndex: 999999999999999 }}>
          <Toaster position="top-center" />
        </div>
      </AuthContext.Provider>
    </div>
  );
};
