// src/components/BlogList.js
import React from 'react';
import ClubCard from './ClubCard';

const ClubListList = ({ blogs }) => {
  return (
    <div>
    {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-4"> */}
      


      <>
  {/* Card Blog */}
  <div className="max-w-[85rem] p-6 rounded-xl bg-white sm:px-6 mx-auto">
    {/* Grid */}
    <div className="grid lg:grid-cols-3 lg:gap-y-8 gap-10">

    {blogs.map((blog) => (
        <ClubCard key={blog.id} {...blog} />
      ))}
      {/* Card */}
     
      {/* End Card */}
      {/* Card */}
      
      {/* End Card */}
    </div>
    {/* End Grid */}
  </div>
  {/* End Card Blog */}
</>

    </div>
  );
};

export default ClubListList;
