import React, { useEffect, useState } from "react";
import axios from "axios";
import SSMS_logo_white from "../media/SSMS_white.png";
import { toast, Toaster } from "react-hot-toast";
import { useLanguage } from "../components/language";
function PilotForm() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a delay to mimic content loading
    setTimeout(() => {
      setLoading(false); // Set loading to false when content is loaded
    }, 2000); // Adjust the delay as needed
  }, []);

  const [loader, setloader] = useState(false);

  const handleClick = () => {
    setloader(true);

    // Simulate a delay of 4 seconds before hiding the spinner
    setTimeout(() => {
      setloader(false);
    }, 4000);
  };

    const { t, changeLanguage, lanArabic } = useLanguage();
  const [formData, setFormData] = useState({
    name: "",
    organization: "",
    email: "",
    contact: "",
    inquiry: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Send the form data to your Django backend
    axios
      .post(`${process.env.REACT_APP_API_KEY}/api/get_in_touch/`, formData)
      .then((response) => {
        toast.success("Your inquiry has been received 👏", {
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
      })
      .catch((error) => {
        toast.error("Network error 🤕", {
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
      });
  };

  return (
    <div>
      <div>
        {loading ? (
          <div className="flex items-center gap-4 justify-center h-screen">
            <div className="w-4 h-4 rounded-full animate-pulse bg-primary"></div>
            <div className="w-4 h-4 rounded-full animate-pulse bg-primary"></div>
            <div className="w-4 h-4 rounded-full animate-pulse bg-primary"></div>
          </div>
        ) : (
          // Your page content goes here

          <div>
            <main class="mt-0 transition-all duration-200 ease-in-out">
              <section>
                <div class="relative flex items-center min-h-screen p-0 overflow-hidden bg-center bg-cover">
                  <div class="container z-1">
                    <div class="flex flex-wrap -mx-3">
                      <div class="flex flex-col w-full max-w-full px-3 mx-auto lg:mx-0 shrink-0 md:flex-0 md:w-7/12 lg:w-5/12 xl:w-4/12">
                        <div class="relative flex flex-col min-w-0 break-words bg-transparent border-0 shadow-none lg:py4 rounded-2xl bg-clip-border">
                          

                          {lanArabic ? (
                            <div class="p-6 pb-0 flex justify-start md:justify-end flex-col mb-0 text-center lg:text-end">
                            <img
                              src="https://res.cloudinary.com/ahbranding/image/upload/v1682359716/AHbranding/Start_Up_Early_Logo_vu73y8.png"
                              className="w-20 mb-8 flex justify-end mx-auto lg:mr-0"
                            />
                            
                            <div>
                            <h4 class="font-bold">{t('Book a Demo')}</h4>
                            <p class="mb-0">{t(`Fill in the details and we'll reach out to you`)}</p>
                            </div>
                          </div>
                            ) : (
                              <div class="p-6 pb-0 mb-0 text-center lg:text-start">
                            <img
                              src="https://res.cloudinary.com/ahbranding/image/upload/v1682359716/AHbranding/Start_Up_Early_Logo_vu73y8.png"
                              className="w-20 mb-8 mx-auto lg:mx-0"
                            />
                            
                            <div>
                            <h4 class="font-bold">{t('Enroll in free pilot')}</h4>
                            <p class="mb-0">{t(`Fill in the details and we'll reach out to you`)}</p>
                            </div>
                          </div>
                            )}
                          <div class="flex-auto p-6">
                            <form role="form" onSubmit={handleSubmit}>
                              <div class="mb-4">
                                <input
                                  type="text"
                                  name="name"
                                  placeholder={t("Your Name")}
                                  onChange={handleChange}
                                  value={formData.name}
                                  class="focus:shadow-primary-outline text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding p-3 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none"
                                />
                              </div>

                              <div class="mb-4">
                                <input
                                  type="email"
                                  name="email"
                                  placeholder={t("Your email address")}
                                  onChange={handleChange}
                                  value={formData.email}
                                  class="focus:shadow-primary-outline text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding p-3 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none"
                                />
                              </div>

                              <div class="mb-4">
                                <input
                                  type="text"
                                  name="contact"
                                  placeholder={t("Your Contact")}
                                  onChange={handleChange}
                                  value={formData.contact}
                                  class="focus:shadow-primary-outline text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding p-3 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none"
                                />
                              </div>

                              <div class="mb-4">
                                <input
                                  type="text"
                                  name="organization"
                                  placeholder={t("Organization / Institute")}
                                  value={formData.organization}
                                  onChange={handleChange}
                                  class="focus:shadow-primary-outline text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding p-3 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none"
                                />
                              </div>

                              <div class="mb-4">
                                <textarea
                                  type="text"
                                  name="inquiry"
                                  placeholder={t("More Details. eg: number of students etc.")}
                                  value={formData.inquiry}
                                  onChange={handleChange}
                                  class="focus:shadow-primary-outline text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding p-3 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none"
                                ></textarea>
                              </div>

                              <div class="text-center">
                                <button
                                  type="submit"
                                  onClick={handleClick}
                                  disabled={loading}
                                  class="inline-block w-full px-16 py-3.5 mt-6 mb-0 font-bold leading-normal text-center text-white align-middle transition-all bg-primary border-0 rounded-lg cursor-pointer hover:-translate-y-px active:opacity-85 hover:shadow-xs text-sm ease-in tracking-tight-rem shadow-md bg-150 bg-x-25"
                                >
                                  {loader ? (
                                    <div
                                      className="spinner-border text-light"
                                      role="status"
                                    >
                                      <span className="visually-hidden">
                                        Loading...
                                      </span>
                                    </div>
                                  ) : (
                                    t("Enroll Now")
                                  )}
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div class="absolute top-0 right-0 flex-col justify-center hidden w-6/12 h-full max-w-full px-3 pr-0 my-auto text-center flex-0 lg:flex">
                        <div class="relative flex flex-col justify-center h-full bg-cover px-24 m-4 overflow-hidden bg-[url('https://res.cloudinary.com/ahbranding/image/upload/v1697657957/AHbranding/Group_34784350_xgb8gv.png')] rounded-xl ">
                          <span class="absolute top-0 left-0 w-full h-full bg-center bg-cover bg-gradient-to-tl from-blue-500 to-violet-500 opacity-60"></span>
                          {/* <img src='https://res.cloudinary.com/ahbranding/image/upload/v1682446626/AHbranding/Group_34784215_yphk4j.png' className='w-1/2 mb-4 mx-auto ' /> */}

                          <img
                            src={SSMS_logo_white}
                            className="w-1/2 mb-4 mx-auto "
                          />
                          <h4 class="z-20 mt-12 font-bold text-white">
                            Welcome to Student Success Management System
                          </h4>
                          <p class="z-20 text-white ">
                            Revolutionizing education with innovative tools that
                            capture every achievement, highlighting students’
                            contributions beyond the classroom, to help them
                            unleash their full potential.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </main>
          </div>
        )}
      </div>



    </div>
  );
}

export default PilotForm;
