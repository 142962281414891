import React, { useEffect, useState, useContext, useRef } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import ProfileEditForm from "./ProfileEditForm";
import { axiosInstance } from "../Axios";
import axios from "axios";
import { FaSignal,FaTrophy, FaRegCheckCircle, FaSchool, FaUsers, FaAsterisk, FaCalendarWeek } from 'react-icons/fa';
import { SlGraph } from "react-icons/sl";
import { useLanguage } from "./language";


const NetworkStats = () => {
  const { t, changeLanguage, lanArabic } = useLanguage();

  let { user } = useContext(AuthContext);
  let UserID = user['user_id'];
  let { logoutUser } = useContext(AuthContext);
  let [totalCompletions, setTotalCompletions] = useState(0);
  let [totalExperiencePoints, setTotalExperiencePoints] = useState(0);
  let [currentLevel, setCurrentLevel] = useState('Level 1');
  let [profile, setProfile] = useState();


  useEffect(() => {
    const getProfile = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_KEY}/api/profile/${UserID}`);
        // parse the data in json
        let data = await response.json();
        setProfile(data);
      } catch (err) {
        // console.log('The requested profile does not exist.');
      }
    };
    getProfile();

  }, [UserID]);


  const [allClubs, setAllClubs] = useState([]);
  const [clubCount, setClubCount] = useState(0);
  useEffect(() => {
    // Fetch all clubs data from the backend for all users (no authentication required)
    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/clubs/`)
      .then((response) => {
        // Ensure that the creator field is compared as a number (if it's a string in the response)
        const clubsWithCreator2 = response.data.filter((club) => parseInt(club.creator, 10) === UserID);
        setAllClubs(clubsWithCreator2);
        console.log('Club resp:', response.data);

        // Update the clubCount state with the count of clubs
        setClubCount(clubsWithCreator2.length);
      })
      .catch((error) => {
        console.error('Error fetching clubs data:', error);
      });
  }, []);

  



useEffect(() => {
  const getProfile = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_KEY}/api/network_profile/${UserID}`);
      const data = await response.json();
      setProfile(data);

      // Set the currentNetwork state once the profile data is fetched
      setCurrentNetwork(data?.network_id); // Ensure data is not null before accessing the property
    } catch (err) {
      console.log("The requested profile does not exist.");
    }
  };

  getProfile();
}, [UserID]);

const [currentNetwork, setCurrentNetwork] = useState(null);

const [error, setError] = useState(null);
const [schools, setSchools] = useState(0);
const [numberOfSchools, setNumberOfSchools] = useState(0); // Initialize the numberOfSchools state to 0

const [totalStudents, setTotalStudents] = useState(0);

useEffect(() => {
  if (currentNetwork) {
    // Fetch schools from the Django API
    fetch(`${process.env.REACT_APP_API_KEY}/api/schools/`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        // Filter schools with network matching the currentNetwork
        const filteredSchools = data.filter(school => school.network === currentNetwork);
        setSchools(filteredSchools);

        // Calculate the total number of students
        const studentsCount = filteredSchools.reduce((total, school) => total + school.number_of_students, 0);
        setTotalStudents(studentsCount);
      })
      .catch(error => {
        console.error('Error fetching schools:', error);
        setError(error.message);
      });
  }
}, [currentNetwork]);


//console.log("ID"+profile?.network_id)
useEffect(() => {
  if (currentNetwork) {
    // Fetch schools from the Django API
    fetch(`${process.env.REACT_APP_API_KEY}/api/student_count/`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        // Filter schools with network matching the currentNetwork
        const filteredStudents = data.filter(students => parseInt(students.network_in) === parseInt(profile?.network_id));


        // Calculate the total number of students
        const totalCount = filteredStudents.length;
        setTotalStudents(totalCount);
      })
      .catch(error => {
        console.error('Error fetching schools:', error);
        setError(error.message);
      });
  }
}, [currentNetwork]);

const [events, setEvents] = useState(0);

useEffect(() => {
  // Fetch data from the '/get_events/' endpoint
  fetch(`${process.env.REACT_APP_API_KEY}/api/get_events/`)
    .then((response) => response.json())
    .then((data) => {
      // Filter events where campus matches the username
      const filteredEvents = data.filter((event) => event.campus === user.username);
      const totalEventCount = filteredEvents.length;
      setEvents(totalEventCount);

    })
    .catch((error) => {
      console.error('Error fetching data:', error);
    });
}, [user.username]); // Include username as a dependency to re-fetch when it changes

  // console.log("EXP: " + totalExperiencePoints);

 // let currentLevel = 'Level 1';
        if (totalExperiencePoints >= 200) currentLevel = 'Level 2';
        if (totalExperiencePoints >= 400) currentLevel = 'Level 3';
        if (totalExperiencePoints >= 700) currentLevel = 'Level 4';
        if (totalExperiencePoints >= 1000) currentLevel = 'Level 5';



  //// console.log(role);

  const scrollContainerRef = useRef(null);
    // Function to scroll the content left
    const scrollLeft = () => {
      if (scrollContainerRef.current) {
        const scrollAmount = 250; // Adjust the scroll amount as needed
        scrollContainerRef.current.scrollLeft -= scrollAmount;
      }
    };
    
    const scrollRight = () => {
      if (scrollContainerRef.current) {
        const scrollAmount = 250; // Adjust the scroll amount as needed
        scrollContainerRef.current.scrollLeft += scrollAmount;
      }
    };
  return (

    <div>
{/* <div className="flex pb-4" style={{overflowX:'scroll'}}> */}

<div className={`pb-4 ${user.role === 'ccaAdmin' ? 'flex items-center' : ''}`}>


{(() => {
        if (user['role']==='ccaAdmin') {
          return (
            <button className="btn btn-circle" onClick={scrollLeft}>
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
</svg>
</button>
          )
        }
      })()}


          {/* card1 */}
          {/* <div className="flex" style={{ overflowX: 'scroll' }} ref={scrollContainerRef}> */}
          <div className="flex scrollable-container pb-4" ref={scrollContainerRef}>


          <div className="w-full max-w-full px-3 sm:w-1/2 sm:flex-none xl:w-1/4">
            <div className="relative flex flex-col min-w-0 break-words bg-white shadow-xl   rounded-2xl bg-clip-border border-b-3 ">
              <div className="flex-auto p-4">
                <div className="flex flex-row -mx-3">
                  <div className="flex-none w-2/3 max-w-full px-3">
                    <div>
                      <p className="mb-0 font-sans text-sm font-semibold leading-normal uppercase  ">
                       Campus(s)
                      </p>
                      <h5 className="mb-2 font-bold ">{schools.length < 10 ? `0${schools.length}` : schools.length}</h5>
                      <p className="mb-0  hidden">
                        <span className="text-sm font-bold leading-normal text-emerald-500">
                          +5%
                        </span>
                        than last month
                      </p>
                    </div>
                  </div>
                  <div className="px-3 text-right basis-1/3 flex justify-center">
                    <div className="flex justify-center items-center w-12 h-12 text-center rounded-circle bg-amber-400">
                      <FaSchool size={25} className="text-white "/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="w-full max-w-full px-3 sm:w-1/2 sm:flex-none xl:w-1/4">
            <div className="relative flex flex-col min-w-0 break-words bg-white shadow-xl   rounded-2xl bg-clip-border border-b-3 ">
              <div className="flex-auto p-4">
                <div className="flex flex-row -mx-3">
                  <div className="flex-none w-2/3 max-w-full px-3">
                    <div>
                      <p className="mb-0 font-sans text-sm font-semibold leading-normal uppercase  ">
                        Students
                      </p>
                      <h5 className="mb-2 font-bold ">{totalStudents < 10 ? `0${totalStudents}` : totalStudents}</h5>
                      <p className="mb-0  hidden">
                        <span className="text-sm font-bold leading-normal text-emerald-500">
                          +5%
                        </span>
                        than last month
                      </p>
                    </div>
                  </div>
                  <div className="px-3 text-right basis-1/3 flex justify-center">
                    <div className="flex justify-center items-center w-12 h-12 text-center rounded-circle bg-primary">
                      <FaUsers size={25} className="text-white "/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>



          <div className="w-full max-w-full px-3 sm:w-1/2 sm:flex-none xl:w-1/4">
            <div className="relative flex flex-col min-w-0 break-words bg-white shadow-xl   rounded-2xl bg-clip-border border-b-3 ">
              <div className="flex-auto p-4">
                <div className="flex flex-row -mx-3">
                  <div className="flex-none w-2/3 max-w-full px-3">
                    <div>
                      <p className="mb-0 font-sans text-sm font-semibold leading-normal uppercase  ">
                        Events
                      </p>
                      <h5 className="mb-2 font-bold ">{events}</h5>
                      <p className="mb-0  hidden">
                        <span className="text-sm font-bold leading-normal text-emerald-500">
                          +5%
                        </span>
                        than last month
                      </p>
                    </div>
                  </div>
                  <div className="px-3 text-right basis-1/3 flex justify-center">
                    <div className="flex justify-center items-center w-12 h-12 text-center rounded-circle bg-teal">
                      <FaCalendarWeek size={25} className="text-white "/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>



          <div className="w-full max-w-full px-3 sm:w-1/2 sm:flex-none xl:w-1/4">
            <div className="relative flex flex-col min-w-0 break-words bg-white shadow-xl   rounded-2xl bg-clip-border border-b-3 ">
              <div className="flex-auto p-4">
                <div className="flex flex-row -mx-3">
                  <div className="flex-none w-2/3 max-w-full px-3">
                    <div>
                      <p className="mb-0 font-sans text-sm font-semibold leading-normal uppercase  ">
                        Student Clubs
                      </p>
                      <h5 className="mb-2 font-bold ">{clubCount < 10 ? `0${clubCount}` : clubCount}</h5>
                      <p className="mb-0  hidden">
                        <span className="text-sm font-bold leading-normal text-emerald-500">
                          +5%
                        </span>
                        than last month
                      </p>
                    </div>
                  </div>
                  <div className="px-3 text-right basis-1/3 flex justify-center">
                    <div className="flex justify-center items-center w-12 h-12 text-center rounded-circle bg-purple-400">
                      <FaAsterisk size={25} className="text-white "/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
         
         
         
</div>




{(() => {
        if (user['role']==='ccaAdmin') {
          return (
            <button className="btn btn-circle" onClick={scrollRight}>

<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
</svg>

</button>
          )
        }
      })()}
        </div>

    </div>
    
  );
};

export default NetworkStats;
