import React, { useState } from "react";
import { axiosInstance } from "../Axios";

import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import { useEffect } from "react";
import SideInnerSM from "../components/SideInnerSM";
import Sidebar from "../components/Sidebar";
import MyCalendar from "../components/EventCalendar";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Stats from "../components/Stats";
import ApexChart from "../components/AreaChart";

import Navbar from "../components/Navbar";
import BarChart from '../components/Barchart';
import RadarChart from '../components/RadarChart';
import BarStacked from '../components/BarStacked'
import CoCurricularEventsTable from '../components/CoCurricularEventsTable'
import NetworkStats from "../components/NetworkStats";
import Network_StudentList from "../components/Network_StudentList";
import Calendar from "react-calendar";
import EventCalendar from "../components/EventCalendar";
import BarchartSchoolTrend from "./BarchartSchoolTrend";
import NetworkStudents from "../components/NetworkStudents";
import { useLanguage } from "../components/language";
import SidebarPilot from "../components/SidebarPilot";
import SideInnerSMPilot from "../components/SideInnerSMPilot";
import RoleDropdown from "./RoleDropdown";
import { Link } from "react-router-dom";

export default function MyClubs({ selectedRole, onRoleChange }) {
 
  const { t, changeLanguage, lanArabic } = useLanguage();
const [isSidebarVisible, setIsSidebarVisible] = useState(false);
const toggleSidebar = () => {
  setIsSidebarVisible(prevState => !prevState);
}

let {user} = useContext(AuthContext);
let profileID = user['user_id'];
const [profile, setProfile] = useState();
const [loading, setLoading] = useState(true);
useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_KEY}/api/network_profile/${profileID}`);
      let data = await response.json();
      setProfile(data);
      setLoading(false);
    } catch (err) {
      //console.log("The requested profile does not exist.");
      setLoading(false); // Set loading to false even on error to stop showing the loader
    }
  };

  // Simulate the loader by setting a timer for 3 seconds
  const timer = setTimeout(() => {
    fetchData();
  }, 900);

  // Clean up the timer when the component unmounts
  return () => clearTimeout(timer);
}, [profileID]);


const [date, setDate] = useState(new Date());
  const [selectedEvent, setSelectedEvent] = useState(null);

  const [events, setEvents] = useState([]);

  useEffect(() => {
    // Fetch data from the '/get_events/' endpoint
    fetch(`${process.env.REACT_APP_API_KEY}/api/get_events/`)
      .then((response) => response.json())
      .then((data) => {
        // Filter events where campus matches the username
        const filteredEvents = data.filter((event) => event.campus === user.username);
        setEvents(filteredEvents);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [user.username]); // Include username as a dependency to re-fetch when it changes


  
  return (
    <div>


<div><>

<div class="hidden md:block absolute w-full bg-lilac h-96  min-h-75"></div>

{(() => {
        if (profileID===263) {
          return (
            <SidebarPilot />
          )
        } else {
          return (
            <Sidebar />
          )
        }
      })()}



      {isSidebarVisible && (
  (() => {
    if (profileID === 263) {
      return <SideInnerSMPilot />;
    } else {
      return <SideInnerSM />;
    }
  })()
)}





    <main className={`relative h-full max-h-screen transition-all duration-200 ease-in-out ${lanArabic ? 'xl:mr-68' : 'xl:ml-68'} rounded-xl`}>
      {/* Navbar */}
      <Navbar toggleSidebar={toggleSidebar} />

      {/* end Navbar */}
      {/* cards */}
      <div className="w-full px-6 py-6 mx-auto">

      


      <>
  {/* Card Blog */}
  <div className="px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
  <div className="flex items-center mb-4 justify-end gap-4">
  <Link to={'/addclub'} className="btn btn-primary"> + Add Club </Link>

  <RoleDropdown/>
  </div>
  
    {/* Title */}
    {/* End Title */}
    {/* Grid */}
    <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
      {/* Card */}
      <Link
        className="bg-white group flex flex-col h-content border border-gray-200 hover:border-transparent hover:shadow-lg transition-all duration-300 rounded-xl p-5 darki:border-gray-700 darki:hover:border-transparent darki:hover:shadow-black/[.4] darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
        to={'/club_details'}
      >
        <div className="aspect-w-16 aspect-h-11">
          <img
            className="w-full object-cover rounded-xl"
            src="https://res.cloudinary.com/ahbranding/image/upload/v1705663616/Group_34784500_avny0v.png"
            alt="Image Description"
          />
        </div>
        <div className="my-6">
          <h3 className="text-xl font-semibold text-gray-800 darki:text-gray-300 darki:group-hover:text-white">
            Strikers FC Club
          </h3>
          <p className="mt-5 text-gray-600 darki:text-gray-400">
            At Wake, our mission has always been focused on bringing openness.
          </p>
        </div>
        <div className="flex items-center gap-x-3">
          <img
            className="w-8 h-8 rounded-full"
            src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
            alt="Image Description"
          />
          <div>
            <h5 className="text-sm text-gray-800 darki:text-gray-200">
              Lauren Waller
            </h5>
          </div>
        </div>
      </Link>
      {/* End Card */}
      {/* Card */}
      <Link
        className="bg-white  group flex flex-col h-content border border-gray-200 hover:border-transparent hover:shadow-lg transition-all duration-300 rounded-xl p-5 darki:border-gray-700 darki:hover:border-transparent darki:hover:shadow-black/[.4] darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
        to={'/club_details'}
      >
        <div className="aspect-w-16 aspect-h-11">
          <img
            className="w-full object-cover rounded-xl"
            src="https://res.cloudinary.com/ahbranding/image/upload/v1705663616/Group_34784499_gei8qi.png"
            alt="Image Description"
          />
        </div>
        <div className="my-6">
          <h3 className="text-xl font-semibold text-gray-800 darki:text-gray-300 darki:group-hover:text-white">
            Science Rethink
          </h3>
          <p className="mt-5 text-gray-600 darki:text-gray-400">
            Google is constantly updating its consumer AI, Google Assistant,
            with new features.
          </p>
        </div>
        <div className="flex items-center gap-x-3">
          <img
            className="w-8 h-8 rounded-full"
            src="https://images.unsplash.com/photo-1492562080023-ab3db95bfbce?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
            alt="Image Description"
          />
          <div>
            <h5 className="text-sm text-gray-800 darki:text-gray-200">
              Aaron Larsson
            </h5>
          </div>
        </div>
      </Link>
      {/* End Card */}
      {/* Card */}
      <Link
        className="bg-white  group flex flex-col h-content border border-gray-200 hover:border-transparent hover:shadow-lg transition-all duration-300 rounded-xl p-5 darki:border-gray-700 darki:hover:border-transparent darki:hover:shadow-black/[.4] darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
        to={'/club_details'}
      >
        <div className="aspect-w-16 aspect-h-11">
          <img
            className="w-full object-cover rounded-xl"
            src="https://res.cloudinary.com/ahbranding/image/upload/v1705663615/Group_34784501_hughbl.png"
            alt="Image Description"
          />
        </div>
        <div className="my-6">
          <h3 className="text-xl font-semibold text-gray-800 darki:text-gray-300 darki:group-hover:text-white">
            Media Club
          </h3>
          <p className="mt-5 text-gray-600 darki:text-gray-400">
            Are you an accountant? Are you a company formation advisor?
          </p>
        </div>
        <div className="flex items-center gap-x-3">
          <img
            className="w-8 h-8 rounded-full"
            src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=320&h=320&q=80"
            alt="Image Description"
          />
          <div>
            <h5 className="text-sm text-gray-800 darki:text-gray-200">
              Lauren Waller
            </h5>
          </div>
        </div>
      </Link>
      {/* End Card */}
    </div>
    {/* End Grid */}
    {/* Card */}

    {/* End Card */}
  </div>
  {/* End Card Blog */}
</>



      </div>
    </main>
  </>
  </div>

    
    </div>
  );
}
