import React from "react";
import { Link } from "react-router-dom";
import { useLanguage } from "./language";

function Network_StudentTable({
  schools,
  selectActivity,
  activityPresent,
  activityNot,
  activityCount,
  filterStudent,
}) {
  const { t, changeLanguage, lanArabic } = useLanguage();

  
  return (
    <div className="overflow-x-auto">

<div className="student-count">

</div>
      <table className="table w-full">
        <thead>
          <tr>
            <th>Name</th>
            {/* <th>Grade</th> */}
            <th>Activities Performed</th>
            <th></th>
          </tr>
        </thead>
        <tbody>

          {selectActivity === "Activity Present" &&
          activityPresent
    .slice()
    .sort((a, b) => (activityCount[b.user] || 0) - (activityCount[a.user] || 0))
    .map((student) => (
              <tr key={student.id}>
              {/* <p className="text-lg font-bold mb-3">Total Students: {activityPresent.length}</p> */}

                <td>
                  <div className="flex items-center space-x-3">
                  {/* <td>{student.user} </td> */}
                  
                    <div className="avatar">
                      <div className="mask mask-squircle w-12 h-12">
                        <img src={student.avatar} alt="Avatar" />
                      </div>
                    </div>
                    <div>
                      <div className="font-bold">
                        {student.firstname} {student.lastname}
                      </div>
                      <div className="text-sm opacity-50">@{student.name}</div>
                    </div>
                  </div>
                </td>
                {/* <td>{student.grade} </td> */}

                <td>{activityCount[student.user] || 0}</td>

                <th>
                  <Link to={`/student_nw/${student.user}`}>
                    <button className="btn btn-ghost btn-xs">details</button>
                  </Link>
                </th>
              </tr>
            ))}

          {selectActivity === "Activity Not Present" &&
            activityNot.map((student) => (
              <tr key={student.id}>
                <td>
                  <div className="flex items-center space-x-3">
                    <div className="avatar">
                      <div className="mask mask-squircle w-12 h-12">
                        <img src={student.avatar} alt="Avatar" />
                      </div>
                    </div>
                    <div>
                      <div className="font-bold">
                        {student.firstname} {student.lastname}
                      </div>
                      <div className="text-sm opacity-50">@{student.name}</div>
                    </div>
                  </div>
                </td>
                {/* <td>{student.grade} </td> */}
                <td>{activityCount[student.user] || 0}</td>

                <th>
                  <Link to={`/student_nw/${student.user}`}>
                    <button className="btn btn-ghost btn-xs">details</button>
                  </Link>
                </th>
              </tr>
            ))}

          {selectActivity === "All" &&
            schools.map((student) => (
              <tr key={student.id}>
                <td>
                  <div className="flex items-center space-x-3">
                    <div className="avatar">
                      <div className="mask mask-squircle w-12 h-12">
                        <img src={student.avatar} alt="Avatar" />
                      </div>
                    </div>
                    <div>
                      <div className="font-bold">
                        {student.firstname} {student.lastname}
                      </div>
                      <div className="text-sm opacity-50">@{student.name}</div>
                    </div>
                  </div>
                </td>
                {/* <td>{student.grade} </td> */}
                <td>{activityCount[student.user] || 0}</td>

                <th>
                  <Link to={`/student_nw/${student.user}`}>
                    <button className="btn btn-ghost btn-xs">details</button>
                  </Link>
                </th>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}

export default Network_StudentTable;
