import React, { useState, useEffect } from 'react';

function CaseStudyQuote( {text,name}  ) {


  return (
<div
  className="bg-[#fcfaff] border-s-4 border-purple-700 p-4 darki:bg-red-800/30 my-6"
  role="alert"
>
  <div className="flex">
    <div className="flex-shrink-0">
    </div>
    <div className="ms-3">
      <p className="m-0 text-gray-700 darki:text-gray-400">
        "{text}" - <span className='font-semibold'>{name}.</span>
      </p>
    </div>
  </div>
</div>

  )
}

export default CaseStudyQuote;