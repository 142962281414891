import React, { useEffect, useState } from "react";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import SideInnerSM from "../components/SideInnerSM";
import Sidebar from "../components/Sidebar";
import { useLanguage } from "../components/language";
import Navbar from "../components/Navbar";
import { MultiSelect } from "react-multi-select-component";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import ReturnNav from "./ReturnNav";
import axios from "axios";
import toast from "react-hot-toast";
import ReactModal from "react-modal";
import AddClubCategory from "./AddClubCategory";
import { Link } from "react-router-dom";
import { Calendar, DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const AddClub = () => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [selected, setSelected] = useState([]);
  // const [startDate, setStartDate] = useState(new Date());

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);
  
  const { t, changeLanguage, lanArabic } = useLanguage();
  const [toggle, setToggle] = useState(false);

  let { user } = useContext(AuthContext);
  let profileID = user["user_id"];
  const [profile, setProfile] = useState();
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    category: 0,
    name: "",
    description: "",
    faculty_advisors: [],
    goals: "",
    logo: "hello",
    cover: "hello",
    schedule: "",
    max_student: "",
    created_by: profileID,
    status: "",
    fees: "",
  });

  const [facultyAdvisors, setFacultyAdvisors] = useState([]);
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/faculty/${1}/`)
      .then((res) => {
        setFacultyAdvisors(res.data);
        setLoading(false);
      });

    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/get_category/`)
      .then((res) => {
        setCategories(res.data);
      });
  }, []);

  const options = [
    {
      label: "Sarah J.",
      value: "grapes",
      avatar:
        "https://images.unsplash.com/photo-1541101767792-f9b2b1c4f127?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&&auto=format&fit=facearea&facepad=3&w=300&h=300&q=80",
    },
    {
      label: "Adam M.",
      value: "mango",
      avatar:
        "https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80",
    },
    {
      label: "Mike K.",
      value: "strawberry",
      avatar:
        "https://images.unsplash.com/photo-1531927557220-a9e23c1e4794?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80",
    },
  ];
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // const handleSubmit = () => {
  //   const selectedIds = selected.map((item) => item.value);
  //   setFormData({
  //     ...formData,
  //     faculty_advisors: selectedIds,
  //     schedule: "ok",
  //   });
  //   axios.post(`${process.env.REACT_APP_API_KEY}/api/clubs/`, formData);
  //   //
  // };
  // const handleSubmit = () => {
  //   const selectedIds = selected.map((item) => item.value);
  //   if (selectedIds.length > 0) {
  //     setFormData({
  //       ...formData,
  //       faculty_advisors: selectedIds,
  //       schedule: "ok",
  //     });
  //     axios
  //       .post(`${process.env.REACT_APP_API_KEY}/api/clubs/`, formData)
  //       .then((res) => {
  //         console.log(res.data);
  //         toast.success("Club Created Successfully");
  //         setFormData({
  //           category: "",
  //           name: "",
  //           description: "",
  //           faculty_advisors: [],
  //           goals: "",
  //           logo: "hello",
  //           cover: "hello",
  //           schedule: "ok",
  //           max_student: "",
  //           created_by: profileID,
  //           status: "",
  //           fees: "",
  //         });
  //       });
  //   } else {
  //     toast.error("Please select at least one faculty advisor.");
  //   }
  // };

  const handleSubmit = async () => {
    const selectedIds = selected.map((item) => item.value);

    if (selectedIds.length > 0) {
      setFormData({
        ...formData,
        faculty_advisors: selectedIds,
        schedule: "ok",
      });
    } else {
      toast.error("Please select at least one faculty advisor.");
      return;
    }
  };

  useEffect(() => {
    const submitClubData = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_KEY}/api/clubs/`,
          formData
        );

        console.log(response.data);
        toast.success("Club created successfully");
        setFormData({
          category: "",
          name: "",
          description: "",
          faculty_advisors: [],
          goals: "",
          logo: "hello",
          cover: "hello",
          schedule: "ok",
          max_student: "",
          created_by: profileID,
          status: "",
          fees: "",
        });
      } catch (error) {
        console.error("Error creating club:", error);

        toast.error("Error creating club");
      }
    };

    if (formData.faculty_advisors.length > 0) {
      submitClubData();
    }
  }, [formData]);

  const toggleSidebar = () => {
    setIsSidebarVisible((prevState) => !prevState);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/network_profile/${profileID}`
        );
        let data = await response.json();
        setProfile(data);
        setLoading(false);
      } catch (err) {
        //console.log("The requested profile does not exist.");
        setLoading(false); // Set loading to false even on error to stop showing the loader
      }
    };

    // Simulate the loader by setting a timer for 3 seconds
    const timer = setTimeout(() => {
      fetchData();
    }, 900);

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [profileID]);



  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };


  return (
    <div>
      <div>
        <>
          <div class="hidden md:block absolute w-full bg-lilac h-96  min-h-75"></div>

          <Sidebar />

          {isSidebarVisible && <SideInnerSM />}

          <main
            className={`relative h-full max-h-screen transition-all duration-200 ease-in-out ${
              lanArabic ? "xl:mr-68" : "xl:ml-68"
            } rounded-xl`}
          >
            {/* Navbar */}
            <Navbar toggleSidebar={toggleSidebar} />

            {/* end Navbar */}
            {/* cards */}
            <ReturnNav />
            <div className="w-full px-6 py-6 mx-auto">
              <div className="bg-white rounded-lg p-14 flex flex-col ">
                <div className="flex flex-col w-11/12 mx-auto bg-white px-8 py-7 rounded-lg ">
                  <h4>Add Club</h4>
                  <div className="flex items-center gap-10">
                    <div className="flex flex-col w-2/6 ">
                      <label className="inline-block text-sm font-medium text-gray-800 mt-2.5 darki:text-gray-200">
                        Club name
                      </label>

                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className="input input-bordered"
                        placeholder="Enter project name"
                      />
                    </div>
                    <div className="flex flex-col w-2/6">
                      <label className="flex justify-between text-sm font-medium text-gray-800 mt-2.5 darki:text-gray-200">
                         <span>Category</span> <Link to={'/add_club_category'} className="link text-primary" >Add new category</Link>
                      </label>
                      
                      <select
                        className="input input-bordered"
                        name="category"
                        value={formData.category}
                        onChange={handleChange}
                      >
                        <option value="" disabled>
                          Select a category
                        </option>
                        {categories.map((category) => (
                          <option key={category.id} value={category.id}>
                            {category.title}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="flex flex-col w-2/6 ">
                      <label className="inline-block text-sm font-medium text-gray-800 mt-2.5 darki:text-gray-200">
                        Faculty Advisor(s)
                      </label>

                      <MultiSelect
                        className=""
                        options={facultyAdvisors.map((faculty) => ({
                          value: faculty.id,
                          label: faculty.first_name + " " + faculty.last_name,
                        }))}
                        value={selected}
                        onChange={setSelected}
                        labelledBy="Select"
                        renderOption={({ label, value, avatar }) => (
                          <div>
                            {avatar && (
                              <img
                                src={avatar}
                                alt={`${label}'s Avatar`}
                                style={{
                                  width: "24px",
                                  height: "24px",
                                  borderRadius: "50%",
                                  marginRight: "8px",
                                }}
                                crossOrigin="anonymous"
                              />
                            )}
                            {label}
                          </div>
                        )}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col w-full gap-1 ">
                    <label className="inline-block text-sm font-medium text-gray-800 mt-2.5 darki:text-gray-200">
                      Description
                    </label>
                    <textarea
                      rows={7}
                      className="w-full p-4 textarea textarea-bordered"
                      name="description"
                      value={formData.description}
                      onChange={handleChange}
                      placeholder="Write something about club."
                    ></textarea>
                  </div>

                  <div className="flex flex-col gap-1 w-full pt-4 ">
                    <label className="inline-block text-sm font-medium text-gray-800 mt-2.5 darki:text-gray-200">
                      Mission and Goals
                    </label>
                    <textarea
                      rows={8}
                      className="w-full p-4 textarea textarea-bordered"
                      name="goals"
                      value={formData.goals}
                      onChange={handleChange}
                      placeholder="Write goals, mission and purpose of the club"
                    ></textarea>
                  </div>
                  <div className="flex items-center gap-10">
                    <div className="flex flex-col gap-1  w-2/4 pt-4 ">
                      <label className="inline-block text-sm font-medium text-gray-800 mt-2.5 darki:text-gray-200">
                        Club Logo / Icon
                      </label>

                      <input
                        type="file"
                        name="file-input"
                        id="file-input"
                        class="block w-full border border-gray-200 shadow-sm rounded-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none darki:bg-slate-900 darki:border-gray-700 darki:text-gray-400 darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600
    file:bg-gray-50 file:border-0
    file:bg-gray-100 file:me-4
    file:py-3 file:px-4
    darki:file:bg-gray-700 darki:file:text-gray-400"
                      />
                    </div>
                    <div className="flex flex-col gap-1  w-2/4 pt-4 ">
                      <label className="inline-block text-sm font-medium text-gray-800 mt-2.5 darki:text-gray-200">
                        Club Cover Photo
                      </label>
                      <input
                        type="file"
                        name="file-input"
                        id="file-input"
                        class="block w-full border border-gray-200 shadow-sm rounded-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none darki:bg-slate-900 darki:border-gray-700 darki:text-gray-400 darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600
    file:bg-gray-50 file:border-0
    file:bg-gray-100 file:me-4
    file:py-3 file:px-4
    darki:file:bg-gray-700 darki:file:text-gray-400"
                      />
                    </div>
                  </div>
                  <div className="py-6 flex items-center text-sm text-gray-800 before:flex-[1_1_0%] before:border-t before:border-gray-200 before:me-6 after:flex-[1_1_0%] after:border-t after:border-gray-200 after:ms-6 darki:text-white darki:before:border-gray-600 darki:after:border-gray-600">
                    Schedule
                  </div>

                  <div className="flex ">
                    <div className="flex pt-4 w-full">

                      {/* <DatePicker
                        style={{ width: "100%" }}
                        className="input input-bordered"
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        name="schedule"
                        value={formData.schedule}
                      /> */}
                      <DateRange
        editableDateInputs={true}
        onChange={(item) => setDateRange([item.selection])}
        moveRangeOnFirstSelection={false}
        ranges={dateRange}
        className="w-full"
      />
                    </div>
                    
                  </div>

                  <div className="py-6 flex items-center text-sm text-gray-800 before:flex-[1_1_0%] before:border-t before:border-gray-200 before:me-6 after:flex-[1_1_0%] after:border-t after:border-gray-200 after:ms-6 darki:text-white darki:before:border-gray-600 darki:after:border-gray-600">
                    Constraints and Criterias
                  </div>

                  <div className="flex flex-col gap-4">
                    <div className="pt-8 flex items-center gap-2">
                      <span>Add Constraints</span>
                      <Toggle
                        color="red"
                        defaultChecked={toggle}
                        // onChange={(e) => setToggle(e.target.value)}
                        onChange={() => setToggle(!toggle)}
                      />
                    </div>

                    {toggle && (
                      <div className="bg-purple-50 p-6 rounded-xl">
                        <span className="text-grey">
                          Only students matching following attributes will be
                          eligible to join / apply for this club
                        </span>


                        <div className="flex flex-col gap-1  w-2/4 pt-4 ">
                      <label className="inline-block text-sm font-medium text-gray-800 mt-2.5 darki:text-gray-200">
                        Max Students Allowed
                      </label>
                      <input
                        type="text"
                        className="input input-bordered"
                        placeholder="Enter project name"
                        name="max_student"
                        value={formData.max_student}
                        onChange={handleChange}
                      />
                    </div>

                        <div className="w-full flex items-center gap-10 pt-4">
                          <div className="flex flex-col gap-4 w-1/6">
                            <label className="inline-block text-sm font-medium text-gray-500 mt-2.5 darki:text-gray-200">
                              Type
                            </label>

                            <select class="py-2 px-3 pe-9 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none darki:bg-slate-900 darki:border-gray-700 darki:text-gray-400 darki:focus:ring-gray-600">
                              <option selected>By Grade</option>
                              <option>1</option>
                              <option>2</option>
                              <option>3</option>
                            </select>

                            <select class="py-2 px-3 pe-9 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none darki:bg-slate-900 darki:border-gray-700 darki:text-gray-400 darki:focus:ring-gray-600">
                              <option selected>By Age</option>
                              <option>1</option>
                              <option>2</option>
                              <option>3</option>
                            </select>
                          </div>
                          <div className="flex flex-col w-4/6">
                            <div className="flex flex-col gap-4 w-2/4 ">
                              <label className="inline-block text-sm font-medium text-gray-500 mt-2.5 darki:text-gray-200">
                                Value
                              </label>

                              <input
                                type="text"
                                className="input input-bordered"
                                placeholder="Enter project name"
                              />
                              <input
                                type="text"
                                className="input input-bordered"
                                placeholder="Enter project name"
                              />
                            </div>
                          </div>
                        </div>
                        {/* <div className="flex items-center pt-5">
                          <button className="px-4 py-3 rounded-lg bg-[#DBEAFE]">
                            {" "}
                            + Add
                          </button>
                        </div> */}
                      </div>
                    )}
                  </div>

                  <div className="py-6 flex items-center text-sm text-gray-800 before:flex-[1_1_0%] before:border-t before:border-gray-200 before:me-6 after:flex-[1_1_0%] after:border-t after:border-gray-200 after:ms-6 darki:text-white darki:before:border-gray-600 darki:after:border-gray-600">
                    Budget
                  </div>

                  <div className="flex items-center gap-10">
                    <div className="flex flex-col w-2/4 ">
                      <label className="inline-block text-sm font-medium text-gray-800 mt-2.5 darki:text-gray-200">
                        Membership Fee
                      </label>

                      <input
                        type="text"
                        className="input input-bordered"
                        placeholder="Enter project name"
                        name="fees"
                        value={formData.fees}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="flex flex-col w-2/4 ">
                      <label className="inline-block text-sm font-medium text-gray-800 mt-2.5 darki:text-gray-200">
                        Total Budget Allocated
                      </label>
                      <input
                        type="text"
                        className="input input-bordered"
                        placeholder="Enter project name"
                      />
                    </div>
                  </div>

                  <div className="w-full flex items-center pt-12 gap-4">
                    <button
                      className="px-4 py-3 text-white rounded-lg bg-[#4F0696]"
                      onClick={handleSubmit}
                    >
                      Save & Create
                    </button>
                    <button className="px-4 py-3 text-black rounded-lg bg-white border-2 border-black">
                      Save as Draft
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </>
      </div>


    </div>
  );
};

export default AddClub;
