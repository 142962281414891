import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import AuthContext from "../context/AuthContext";
import toast from "react-hot-toast";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import SideInnerSM from "./SideInnerSM";
import { useParams } from "react-router-dom";
import TimelineStack from "./TimelineStack";
import Stats_sm from "./Stats_sm";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Barchart from "./Barchart";
import RadarChart from "./RadarChart";
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
function NetworkStudentDetails() {
  const { user } = useContext(AuthContext);
  const {t} = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  
  const lanArabic = i18n.language === 'ar'
  let params = useParams();
  let profileID = params.id;
  const userID = user["user_id"];

  let isMyself = true;
  if (user !== null && user["user_id"] === parseInt(profileID)) {
    isMyself = true;
  }

  const [profile, setProfile] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/profile/${profileID}`)
      .then((response) => {
        setProfile(response.data);
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  }, []);

  const [profileData, setProfileData] = useState({
    profileID: profileID,
    name: "",
    lastname: "",
    lastname: "",
    bio: "",
    status: "",
    contact: "",
    city: "",
    grade: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    // Update the database with new data
    const dataToSend = {
      ...profile,
      name: profile.name,
      firstname: profile.firstname,
      lastname: profile.lastname,
      bio: profile.bio,
      status: profile.status,
      contact: profile.contact,
      city: profile.city,
      grade: profile.grade,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_KEY}/api/update_profile/${profileID}/`,
        dataToSend
      )
      .then((res) => {
        toast.success("Profile Updated Successfully");
      })
      .catch((err) => {
        toast.error("Failed To Update Profile");
      });
  };

  const handlefileChange = (e) => {
    setfile(e.target.files[0]);
  };
  const [file, setfile] = useState("");

  const handleFileSubmit = (e) => {
    e.preventDefault();
    if (file) {
      const form = new FormData();
      form.append("profile_picture", file);
      form.append("name", profileData.profileID);
      axios
        .post(
          `${process.env.REACT_APP_API_KEY}/api/upload_profile_pic/${profileID}/`,
          form
        )
        .then((response) => {
          console.log(response.data);
        })
        .then((res) => {
          toast.success("Profile Pic Updated Successfully");
          window.location.reload();
        })
        .catch((err) => {
          toast.error("Failed To Update Profile Picture");
        });
    }
  };
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const toggleSidebar = () => {
    setIsSidebarVisible(prevState => !prevState);
  }
  return (
    <div>
      <div>
        <>
          <div class="hidden md:block absolute w-full bg-lilac h-96  min-h-75"></div>

          <Sidebar />

          {isSidebarVisible && <SideInnerSM />}

          <main className={`relative h-full max-h-screen transition-all duration-200 ease-in-out ${lanArabic ? 'xl:mr-68' : 'xl:ml-68'} rounded-xl`}>
            {/* Navbar */}
            <Navbar toggleSidebar={toggleSidebar} /> 

            {/* end Navbar */}
            {/* cards */}
            <div className="w-full px-6 py-6 mx-auto">
              <div class="relative w-full mx-auto  ">
                <div class="relative flex flex-col flex-auto min-w-0 p-4 mx-6 overflow-hidden break-words bg-white border-0  shadow-3xl rounded-2xl bg-clip-border">
                  <div class="flex flex-wrap -mx-3 items-center">
                    <div class="flex-none w-auto max-w-full px-3 pl-6">
                      <div class="relative inline-flex items-center justify-center text-white transition-all duration-200 ease-in-out text-base h-19 w-19 rounded-xl">
                        <img
                          src={profile.avatar}
                          alt="profile_image"
                          class="w-full shadow-2xl rounded-xl"
                        />
                      </div>
                    </div>
                    <div class="flex-none w-auto max-w-full px-3 my-auto">
                      <div class="h-full">
                        <h5 class="mb-1 ">
                          {profile.firstname} {profile.lastname}
                        </h5>
                        <p class="mb-0 font-semibold leading-normal   text-sm">
                          @{profile?.name}
                        </p>
                      </div>
                    </div>
                    <div class="w-full  px-3 mx-auto mt-4 sm:my-auto sm:mr-0 md:w-1/2 md:flex-none lg:w-6/12">
                      
                      <Stats_sm source="Param" />

                    </div>
                  </div>
                </div>
              </div>






              <div class="w-full p-6 mx-auto">
                <div class="flex flex-wrap -mx-3 ">
                  <div class="w-full max-w-full px-3 shrink-0 md:w-7/12 md:flex-0">
                    <div class="relative flex flex-col min-w-0 break-words bg-white border-0 shadow-xl  rounded-2xl bg-clip-border">


                    <Tabs className="mt-4">
    <TabList className="tab-list">
    <Tab className="tab">Student Information</Tab>
        <Tab className="tab">Activity Map</Tab>
        <Tab className="tab">Competency Map</Tab>
    </TabList>

    <TabPanel className="tab-panel">
    <div class="flex-auto p-6">
                        <p class="leading-normal uppercase   text-sm">
                          User Information
                        </p>



                        <div class="flex flex-wrap -mx-3">
                          <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0 hidden">
                            <div class="mb-4">
                              <label
                                for="username"
                                class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 /80"
                              >
                                Username
                              </label>
                              <input
                                type="text"
                                name="name"
                                disabled
                                value={profileData.name}
                                onChange={handleChange}
                                class="focus:shadow-primary-outline  text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                              />
                            </div>
                          </div>

                          <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                            <div class="mb-4">
                              <label
                                for="firstname"
                                class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 /80"
                              >
                                Student Name
                              </label>
                              <p>{profile.firstname} {profile.lastname}</p>
                            </div>
                          </div>

                          <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                            <div class="mb-4">
                              <label
                                for="firstname"
                                class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 /80"
                              >
                                Contact
                              </label>
                              <p>{profile.contact}</p>
                            </div>
                          </div>

                          <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                            <div class="mb-4">
                              <label
                                for="firstname"
                                class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 /80"
                              >
                                Grade
                              </label>
                              <p>{profile.grade}</p>
                            </div>
                          </div>

                          <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                            <div class="mb-4">
                              <label
                                for="firstname"
                                class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 /80"
                              >
                                City
                              </label>
                              <p>{profile.city}</p>
                            </div>
                          </div>

                          <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                            <div class="mb-4">
                              <label
                                for="firstname"
                                class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 /80"
                              >
                                School
                              </label>
                              <p>{profile.school}</p>
                            </div>
                          </div>

                          <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                            <div class="mb-4">
                              <label
                                for="firstname"
                                class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 /80"
                              >
                                Branch
                              </label>
                              <p>{profile.branch}</p>
                            </div>
                          </div>





                          <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                            <div class="mb-4">
                              <label
                                for="firstname"
                                class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 /80"
                              >
                                Bio
                              </label>
                              <p>{profile.bio}</p>
                            </div>
                          </div>
                          

                          <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0 opacity-0">
                            <div class="mb-4">
                              <label
                                for="firstname"
                                class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 /80"
                              >
                                Bio
                              </label>
                              <p>{profile.bio}</p>
                            </div>
                          </div>


                          <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0 ">
                            <div class="mb-4">
                              <label
                                for="firstname"
                                class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 /80"
                              >
                                Roadmap
                              </label>
                              <p>{profile.roadmap}</p>
                            </div>
                          </div>


                        </div>
                        <hr class="h-px mx-0 my-4 bg-transparent border-0 opacity-25 bg-gradient-to-r from-transparent via-black/40 to-transparent  " />



                      </div>
    </TabPanel>

    <TabPanel className="tab-panel">
        <Barchart source="Param" />
    </TabPanel>

    <TabPanel className="tab-panel">
        <RadarChart source="Param" />
    </TabPanel>


</Tabs>

                    
                    </div>
                  </div>
                  <div class="w-full max-w-full px-3 mt-6 shrink-0 md:w-5/12 md:flex-0 md:mt-0">
                    <div class="relative flex flex-col min-w-0 break-words bg-white border-0 shadow-xl  rounded-2xl bg-clip-border">

                       <TimelineStack source="Param" /> 

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </>
      </div>
    </div>
  );
}

export default NetworkStudentDetails;
