import React, { useState } from "react";
import { axiosInstance } from "../Axios";
import "react-circular-progressbar/dist/styles.css";
import ReactApexChart from 'react-apexcharts';

import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import { useEffect } from "react";
import SideInnerSM from "../components/SideInnerSM";
import Sidebar from "../components/Sidebar";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

import MyCalendar from "../components/EventCalendar";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Stats from "../components/Stats";


import Navbar from "../components/Navbar";
import BarChart from '../components/Barchart';
import RadarChart from '../components/RadarChart';
import BarStacked from '../components/BarStacked'
import CoCurricularEventsTable from '../components/CoCurricularEventsTable'
import NetworkStats from "../components/NetworkStats";
import Network_StudentList from "../components/Network_StudentList";
import Calendar from "react-calendar";
import EventCalendar from "../components/EventCalendar";
import BarchartSchoolTrend from "./BarchartSchoolTrend";
import CreateClubForm from "../components/CreateClubForm";
import { useLanguage } from "../components/language";
import domain from "../assets/images/domain.svg"
import face from "../assets/images/face.svg"
import extra from "../assets/images/extra.svg"
import school from "../assets/images/school.svg"

export default function NetworkClubsTalha() {
 
  const { t, changeLanguage, lanArabic } = useLanguage();
const [isSidebarVisible, setIsSidebarVisible] = useState(false);
const toggleSidebar = () => {
  setIsSidebarVisible(prevState => !prevState);
}

let {user} = useContext(AuthContext);
let profileID = user['user_id'];
const [profile, setProfile] = useState();
const [loading, setLoading] = useState(true);
useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_KEY}/api/network_profile/${profileID}`);
      let data = await response.json();
      setProfile(data);
      setLoading(false);
    } catch (err) {
      //console.log("The requested profile does not exist.");
      setLoading(false); // Set loading to false even on error to stop showing the loader
    }
  };

  // Simulate the loader by setting a timer for 3 seconds
  const timer = setTimeout(() => {
    fetchData();
  }, 900);

  // Clean up the timer when the component unmounts
  return () => clearTimeout(timer);
}, [profileID]);

const styles = buildStyles({
  // Rotation of path and trail, in number of turns (0-1)
  rotation: 0.25,

  
 
  // How long animation takes to go from one percentage to another, in seconds
  pathTransitionDuration: 0.5,

  // Colors
  pathColor: `#0FBB9B`,
  textColor: "#4F0696",
  trailColor: "#bfdcd7",
  backgroundColor: "#F2F2F2",
});

const mockData = {
  monthDataSeries1: {
    prices: [30, 40, 45, 50, 48, 52, 50, 42, 38, 30],
    dates: ['2022-01-01', '2022-01-02', '2022-01-03', '2022-01-04', '2022-01-05', '2022-01-06', '2022-01-07', '2022-01-08', '2022-01-09', '2022-01-10'],
  },
};

const chartState = {
  series: [44, 55 ,13],
  options: {
    chart: {
      width: 380,
      type: 'pie',
    },
    // labels: ['Team A', 'Team B'],
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200,
        },
        legend: {
          position: 'bottom',
        },
      },
    }],
  },
}

const areaState = {
  series: [{
    name: "STOCK ABC",
    data:  mockData.monthDataSeries1.prices
  }],
  options: {
    chart: {
      type: 'area',
      height: 350,
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'straight'
    },
    title: {
      text: 'Fundamental Analysis of Stocks',
      align: 'left'
    },
    subtitle: {
      text: 'Price Movements',
      align: 'left'
    },
    labels: mockData.monthDataSeries1.dates,
    xaxis: {
      type: 'datetime',
    },
    yaxis: {
      opposite: true
    },
    legend: {
      horizontalAlign: 'left'
    }
  },
};

  return (
    <div>


<div><>

<div class="hidden md:block absolute w-full bg-lilac h-96  min-h-75"></div>


<Sidebar />

{isSidebarVisible && <SideInnerSM/>}

    <main className={`relative h-full max-h-screen transition-all duration-200 ease-in-out ${lanArabic ? 'xl:mr-68' : 'xl:ml-68'} rounded-xl`}>
      {/* Navbar */}
      <Navbar toggleSidebar={toggleSidebar} />

      {/* end Navbar */}
      {/* cards */}
      <div className="w-full px-6 py-6 mx-auto">



           <div className="bg-[#f9f3ff] rounded-lg p-4 flex flex-col gap-6">
           {/* <CreateClubForm/> */}

           <div className="w-full flex gap-4 items-center ">
             <NewCard img={domain} title="Total Departments" number='05' />
             <NewCard img={face} title="Total Faculty Advisors" number='55' />
             <NewCard img={school} title="Total Faculty Students" number='126' />
             <NewCard img={extra} title="Extra curricular budget" number='$4.5K' />
           </div>
           <div className="w-full flex gap-6">
           <div className="w-2/6 flex flex-col bg-white gap-2 rounded-lg p-5">
            <h3>Participation</h3>
             <div className="flex justify-center items-center flex-col gap-4">
             <div className="w-3/5 relative ">
              <CircularProgressbar
                      strokeWidth={7}
                      styles={styles}
                      value={"60"}
                      
                    />

                    <h2
                     style={{left: '4.5rem',
                     top: '5rem',
                     position: 'absolute',
                     background: 'rgb(15, 187, 155)',
                     borderRadius: '100%',
                     padding: '30px',
                     height: '50%',
                     width: '50%',
                     margin: '0px auto',
                     alignItems: 'center',
                     justifyContent: 'center',
                     display: 'flex',
                     color: 'white',}}
                      >60%</h2>
              </div>
              <div className="flex items-center gap-3">
                  <div className="flex items-center gap-1">
                  <div className="w-3 h-3 rounded-lg bg-[#0FBB9B]"></div>
                  <span>Active</span>
                  </div>

                  <div className="flex items-center gap-1">
                  <div className="w-3 h-3 rounded-lg bg-[#bfdcd7]"></div>
                  <span>Non Active</span>
                  </div>

              </div>
             </div>
           </div>
           <div className="w-2/6 flex flex-col bg-white gap-2 rounded-lg p-5">
            <h3>Budget</h3>
             <div className="flex justify-center items-center flex-col gap-4">
             <div  id="chart" className="w-full ">
             
            <ReactApexChart options={chartState.options} series={chartState.series} type="pie" width={440} />
      
              </div>
            
             </div>
           </div>
           <div className="w-2/6 flex flex-col bg-white gap-2 rounded-lg p-5">
            <h3>Text</h3>
             <div className="w-full flex justify-center items-center flex-col gap-4">
             <div  id="chart" className="w-full ">
             
           
            <ReactApexChart options={areaState.options} series={areaState.series} type="area" width={470} height={340} />
      
      
              </div>
            
             </div>
           </div>
           </div>

           <div>
              <div></div>
           </div>
           
           </div> 


      </div>
    </main>
  </>
  </div>

    
    </div>
  );
}

export const NewCard = (props) => {
  return (
    <div className="w-1/4 flex flex-col gap-4 px-8 py-8 bg-white rounded-lg">
    <img className="w-10 " src={props.img} alt={props.img} />
    <h5 className="m-0 text-xl">{props.title}</h5>
    <h6 className="m-0 text-5xl text-[#4F0696]">{props.number}</h6>
  </div>
  )
}