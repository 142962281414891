import React, { useState, useEffect } from 'react';

function ClubEnrolments(props) {

  const [checkedCheckboxes, setCheckedCheckboxes] = useState([]);

  const handleCheckboxChange = (id) => {
    if (checkedCheckboxes.includes(id)) {
      setCheckedCheckboxes(checkedCheckboxes.filter((item) => item !== id));
    } else {
      setCheckedCheckboxes([...checkedCheckboxes, id]);
    }
  };


    return (
<div>
<>
  {/* Table Section */}
  <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
    {/* Card */}
    <div className="flex flex-col">
      <div className="-m-1.5 overflow-x-auto">
        <div className="p-1.5 min-w-full inline-block align-middle">
          <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden darki:bg-slate-900 darki:border-gray-700">
            {/* Header */}
            <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-b border-gray-200 darki:border-gray-700">
              <div>
                <h2 className="text-xl font-semibold text-gray-800 darki:text-gray-200">
                  Enrollments
                </h2>
              </div>
              <div>
                <div className="inline-flex gap-x-2">
                  <a
                    className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none darki:bg-slate-900 darki:border-gray-700 darki:text-white darki:hover:bg-gray-800 darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
                    href="#"
                  >
                    Export Data
                  </a>
                  <a
                    className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
                    href="#"
                  >
                    <svg
                      className="flex-shrink-0 w-3 h-3"
                      xmlns="http://www.w3.org/2000/svg"
                      width={16}
                      height={16}
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M2.63452 7.50001L13.6345 7.5M8.13452 13V2"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                      />
                    </svg>
                    Add user
                  </a>

                  {checkedCheckboxes.length > 0 && (
                    <a
                    className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-amber-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
                    href="#"
                  >

                    Nominate for Club Leaders
                  </a>
      )}
                </div>
              </div>
            </div>
            {/* End Header */}
            {/* Table */}
            <table className="min-w-full divide-y divide-gray-200 darki:divide-gray-700">
              <thead className="bg-gray-50 darki:bg-slate-800">
                <tr>
                  <th scope="col" className="ps-6 py-3 text-start">
                    <label
                      htmlFor="hs-at-with-checkboxes-main"
                      className="flex"
                    >
                      <input
                        type="checkbox"
                        className="shrink-0 border-gray-300 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none darki:bg-slate-900 darki:border-gray-600 darki:checked:bg-blue-500 darki:checked:border-blue-500 darki:focus:ring-offset-gray-800"
                        id="hs-at-with-checkboxes-main"
                      />
                      <span className="sr-only">Checkbox</span>
                    </label>
                  </th>
                  <th
                    scope="col"
                    className="ps-6 lg:ps-3 xl:ps-0 pe-6 py-3 text-start"
                  >
                    <div className="flex items-center gap-x-2">
                      <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200">
                        Name
                      </span>
                    </div>
                  </th>
                  <th scope="col" className="px-6 py-3 text-start">
                    <div className="flex items-center gap-x-2">
                      <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200">
                        Grade
                      </span>
                    </div>
                  </th>
                  <th scope="col" className="px-6 py-3 text-start">
                    <div className="flex items-center gap-x-2">
                      <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200">
                        Clubs Joined
                      </span>
                    </div>
                  </th>

                  <th scope="col" className="px-6 py-3 text-start">
                    <div className="flex items-center gap-x-2">
                      <span className="text-xs font-semibold uppercase tracking-wide text-gray-800 darki:text-gray-200">
                        Applied
                      </span>
                    </div>
                  </th>
                  <th scope="col" className="px-6 py-3 text-end" />
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 darki:divide-gray-700">
                <tr>
                  <td className="h-px w-px whitespace-nowrap">
                    <div className="ps-6 py-3">
                      <label htmlFor="hs-at-with-checkboxes-1" className="flex">
                        <input
                          type="checkbox"
onChange={() => handleCheckboxChange("hs-at-with-checkboxes-1")}
                          className="shrink-0 border-gray-300 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none darki:bg-slate-900 darki:border-gray-600 darki:checked:bg-blue-500 darki:checked:border-blue-500 darki:focus:ring-offset-gray-800"
                          id="hs-at-with-checkboxes-1"
                        />
                        <span className="sr-only">Checkbox</span>
                      </label>
                    </div>
                  </td>
                  <td className="h-px w-px whitespace-nowrap">
                    <div className="ps-6 lg:ps-3 xl:ps-0 pe-6 py-3">
                      <div className="flex items-center gap-x-3">
                        <img
                          className="inline-block h-[2.375rem] w-[2.375rem] rounded-full"
                          src="https://images.unsplash.com/photo-1531927557220-a9e23c1e4794?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80"
                          alt="Image Description"
                        />
                        <div className="grow">
                          <span className="block text-sm font-semibold text-gray-800 darki:text-gray-200">
                            Christina Bersh
                          </span>
                          <span className="block text-sm text-gray-500">
                            christina@site.com
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="h-px w-px whitespace-nowrap">
                    <div className="px-6 py-3">
                      <span className="block text-sm font-semibold text-gray-800 darki:text-gray-200">
                        Grade 9
                      </span>
                    </div>
                  </td>
                  <td className="h-px w-px whitespace-nowrap">
                    <div className="px-6 py-3">
                      <span className="">
                        1
                      </span>
                    </div>
                  </td>

                  <td className="h-px w-px whitespace-nowrap">
                    <div className="px-6 py-3">
                      <span className="text-sm text-gray-500">
                        28 Dec, 12:12
                      </span>
                    </div>
                  </td>
                  <td className="h-px w-px whitespace-nowrap">
                    <div className="px-6 py-1.5">
                                          <div className="flex flex-col sm:inline-flex sm:flex-row rounded-sm">
  <button
    type="button"
    className="btn mx-2 btn-success"
  >
    Approve
  </button>
  <button
    type="button"
    className="btn mx-2 btn-error"
  >
    Reject
  </button>

</div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="h-px w-px whitespace-nowrap">
                    <div className="ps-6 py-3">
                      <label htmlFor="hs-at-with-checkboxes-2" className="flex">
                        <input
                          type="checkbox"
onChange={() => handleCheckboxChange("hs-at-with-checkboxes-1")}
                          className="shrink-0 border-gray-300 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none darki:bg-slate-900 darki:border-gray-600 darki:checked:bg-blue-500 darki:checked:border-blue-500 darki:focus:ring-offset-gray-800"
                          id="hs-at-with-checkboxes-2"
                        />
                        <span className="sr-only">Checkbox</span>
                      </label>
                    </div>
                  </td>
                  <td className="h-px w-px whitespace-nowrap">
                    <div className="ps-6 lg:ps-3 xl:ps-0 pe-6 py-3">
                      <div className="flex items-center gap-x-3">
                        <img
                          className="inline-block h-[2.375rem] w-[2.375rem] rounded-full"
                          src="https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80"
                          alt="Image Description"
                        />
                        <div className="grow">
                          <span className="block text-sm font-semibold text-gray-800 darki:text-gray-200">
                            David Harrison
                          </span>
                          <span className="block text-sm text-gray-500">
                            david@site.com
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="h-px w-px whitespace-nowrap">
                    <div className="px-6 py-3">
                      <span className="block text-sm font-semibold text-gray-800 darki:text-gray-200">
                        Grade 5
                      </span>

                    </div>
                  </td>
                  <td className="h-px w-px whitespace-nowrap">
                    <div className="px-6 py-3">
                      <span className="">
                        0
                      </span>
                    </div>
                  </td>

                  <td className="h-px w-px whitespace-nowrap">
                    <div className="px-6 py-3">
                      <span className="text-sm text-gray-500">
                        20 Dec, 09:27
                      </span>
                    </div>
                  </td>
                  <td className="h-px w-px whitespace-nowrap">
                    <div className="px-6 py-1.5">
                                          <div className="flex flex-col sm:inline-flex sm:flex-row rounded-sm">
  <button
    type="button"
    className="btn mx-2 btn-success"
  >
    Approve
  </button>
  <button
    type="button"
    className="btn mx-2 btn-error"
  >
    Reject
  </button>

</div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="h-px w-px whitespace-nowrap">
                    <div className="ps-6 py-3">
                      <label htmlFor="hs-at-with-checkboxes-3" className="flex">
                        <input
                          type="checkbox"
onChange={() => handleCheckboxChange("hs-at-with-checkboxes-1")}
                          className="shrink-0 border-gray-300 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none darki:bg-slate-900 darki:border-gray-600 darki:checked:bg-blue-500 darki:checked:border-blue-500 darki:focus:ring-offset-gray-800"
                          id="hs-at-with-checkboxes-3"
                        />
                        <span className="sr-only">Checkbox</span>
                      </label>
                    </div>
                  </td>
                  <td className="h-px w-px whitespace-nowrap">
                    <div className="ps-6 lg:ps-3 xl:ps-0 pe-6 py-3">
                      <div className="flex items-center gap-x-3">
                        <span className="inline-flex items-center justify-center h-[2.375rem] w-[2.375rem] rounded-full bg-gray-300 darki:bg-gray-700">
                          <span className="font-medium text-gray-800 leading-none darki:text-gray-200">
                            A
                          </span>
                        </span>
                        <div className="grow">
                          <span className="block text-sm font-semibold text-gray-800 darki:text-gray-200">
                            Anne Richard
                          </span>
                          <span className="block text-sm text-gray-500">
                            anne@site.com
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="h-px w-px whitespace-nowrap">
                    <div className="px-6 py-3">
                      <span className="block text-sm font-semibold text-gray-800 darki:text-gray-200">
                      Grade 10
                      </span>
                     
                    </div>
                  </td>
                  <td className="h-px w-px whitespace-nowrap">
                    <div className="px-6 py-3">
                      <span className="">
                        1
                      </span>
                    </div>
                  </td>

                  <td className="h-px w-px whitespace-nowrap">
                    <div className="px-6 py-3">
                      <span className="text-sm text-gray-500">
                        18 Dec, 15:20
                      </span>
                    </div>
                  </td>
                  <td className="h-px w-px whitespace-nowrap">
                    <div className="px-6 py-1.5">
                                          <div className="flex flex-col sm:inline-flex sm:flex-row rounded-sm">
  <button
    type="button"
    className="btn mx-2 btn-success"
  >
    Approve
  </button>
  <button
    type="button"
    className="btn mx-2 btn-error"
  >
    Reject
  </button>

</div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="h-px w-px whitespace-nowrap">
                    <div className="ps-6 py-3">
                      <label htmlFor="hs-at-with-checkboxes-4" className="flex">
                        <input
                          type="checkbox"
onChange={() => handleCheckboxChange("hs-at-with-checkboxes-1")}
                          className="shrink-0 border-gray-300 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none darki:bg-slate-900 darki:border-gray-600 darki:checked:bg-blue-500 darki:checked:border-blue-500 darki:focus:ring-offset-gray-800"
                          id="hs-at-with-checkboxes-4"
                        />
                        <span className="sr-only">Checkbox</span>
                      </label>
                    </div>
                  </td>
                  <td className="h-px w-px whitespace-nowrap">
                    <div className="ps-6 lg:ps-3 xl:ps-0 pe-6 py-3">
                      <div className="flex items-center gap-x-3">
                        <img
                          className="inline-block h-[2.375rem] w-[2.375rem] rounded-full"
                          src="https://images.unsplash.com/photo-1541101767792-f9b2b1c4f127?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&&auto=format&fit=facearea&facepad=3&w=300&h=300&q=80"
                          alt="Image Description"
                        />
                        <div className="grow">
                          <span className="block text-sm font-semibold text-gray-800 darki:text-gray-200">
                            Samia Kartoon
                          </span>
                          <span className="block text-sm text-gray-500">
                            samia@site.com
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="h-px w-px whitespace-nowrap">
                    <div className="px-6 py-3">
                      <span className="block text-sm font-semibold text-gray-800 darki:text-gray-200">
                      Grade 6
                      </span>

                    </div>
                  </td>
                  <td className="h-px w-px whitespace-nowrap">
                    <div className="px-6 py-3">
                      <span className="">
                        1
                      </span>
                    </div>
                  </td>

                  <td className="h-px w-px whitespace-nowrap">
                    <div className="px-6 py-3">
                      <span className="text-sm text-gray-500">
                        18 Dec, 15:20
                      </span>
                    </div>
                  </td>
                  <td className="h-px w-px whitespace-nowrap">
                    <div className="px-6 py-1.5">
                                          <div className="flex flex-col sm:inline-flex sm:flex-row rounded-sm">
  <button
    type="button"
    className="btn mx-2 btn-success"
  >
    Approve
  </button>
  <button
    type="button"
    className="btn mx-2 btn-error"
  >
    Reject
  </button>

</div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="h-px w-px whitespace-nowrap">
                    <div className="ps-6 py-3">
                      <label htmlFor="hs-at-with-checkboxes-5" className="flex">
                        <input
                          type="checkbox"
onChange={() => handleCheckboxChange("hs-at-with-checkboxes-1")}
                          className="shrink-0 border-gray-300 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none darki:bg-slate-900 darki:border-gray-600 darki:checked:bg-blue-500 darki:checked:border-blue-500 darki:focus:ring-offset-gray-800"
                          id="hs-at-with-checkboxes-5"
                        />
                        <span className="sr-only">Checkbox</span>
                      </label>
                    </div>
                  </td>
                  <td className="h-px w-px whitespace-nowrap">
                    <div className="ps-6 lg:ps-3 xl:ps-0 pe-6 py-3">
                      <div className="flex items-center gap-x-3">
                        <span className="inline-flex items-center justify-center h-[2.375rem] w-[2.375rem] rounded-full bg-gray-300 darki:bg-gray-700">
                          <span className="font-medium text-gray-800 leading-none darki:text-gray-200">
                            D
                          </span>
                        </span>
                        <div className="grow">
                          <span className="block text-sm font-semibold text-gray-800 darki:text-gray-200">
                            David Harrison
                          </span>
                          <span className="block text-sm text-gray-500">
                            david@site.com
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="h-px w-px whitespace-nowrap">
                    <div className="px-6 py-3">
                      <span className="block text-sm font-semibold text-gray-800 darki:text-gray-200">
                      Grade 6
                      </span>

                    </div>
                  </td>
                  <td className="h-px w-px whitespace-nowrap">
                    <div className="px-6 py-3">
                      <span className="">
                        
                        3
                      </span>
                    </div>
                  </td>

                  <td className="h-px w-px whitespace-nowrap">
                    <div className="px-6 py-3">
                      <span className="text-sm text-gray-500">
                        15 Dec, 14:41
                      </span>
                    </div>
                  </td>
                  <td className="h-px w-px whitespace-nowrap">
                    <div className="px-6 py-1.5">
                                          <div className="flex flex-col sm:inline-flex sm:flex-row rounded-sm">
  <button
    type="button"
    className="btn mx-2 btn-success"
  >
    Approve
  </button>
  <button
    type="button"
    className="btn mx-2 btn-error"
  >
    Reject
  </button>

</div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="h-px w-px whitespace-nowrap">
                    <div className="ps-6 py-3">
                      <label htmlFor="hs-at-with-checkboxes-6" className="flex">
                        <input
                          type="checkbox"
onChange={() => handleCheckboxChange("hs-at-with-checkboxes-1")}
                          className="shrink-0 border-gray-300 rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none darki:bg-slate-900 darki:border-gray-600 darki:checked:bg-blue-500 darki:checked:border-blue-500 darki:focus:ring-offset-gray-800"
                          id="hs-at-with-checkboxes-6"
                        />
                        <span className="sr-only">Checkbox</span>
                      </label>
                    </div>
                  </td>
                  <td className="h-px w-px whitespace-nowrap">
                    <div className="ps-6 lg:ps-3 xl:ps-0 pe-6 py-3">
                      <div className="flex items-center gap-x-3">
                        <img
                          className="inline-block h-[2.375rem] w-[2.375rem] rounded-full"
                          src="https://images.unsplash.com/photo-1492562080023-ab3db95bfbce?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=facearea&facepad=2&w=300&h=300&q=80"
                          alt="Image Description"
                        />
                        <div className="grow">
                          <span className="block text-sm font-semibold text-gray-800 darki:text-gray-200">
                            Brian Halligan
                          </span>
                          <span className="block text-sm text-gray-500">
                            brian@site.com
                          </span>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="h-px w-px whitespace-nowrap">
                    <div className="px-6 py-3">
                      <span className="block text-sm font-semibold text-gray-800 darki:text-gray-200">
                      Grade 7
                      </span>

                    </div>
                  </td>
                  <td className="h-px w-px whitespace-nowrap">
                    <div className="px-6 py-3">
                      <span className="">
                        1
                      </span>
                    </div>
                  </td>

                  <td className="h-px w-px whitespace-nowrap">
                    <div className="px-6 py-3">
                      <span className="text-sm text-gray-500">
                        11 Dec, 18:51
                      </span>
                    </div>
                  </td>
                  <td className="h-px w-px whitespace-nowrap">
                    <div className="px-6 py-1.5">
                    <div className="flex flex-col sm:inline-flex sm:flex-row rounded-sm">
  <button
    type="button"
    className="btn mx-2 btn-success"
  >
    Approve
  </button>
  <button
    type="button"
    className="btn mx-2 btn-error"
  >
    Reject
  </button>

</div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            {/* End Table */}
            {/* Footer */}
            <div className="px-6 py-4 grid gap-3 md:flex md:justify-between md:items-center border-t border-gray-200 darki:border-gray-700">
              <div>
                <p className="text-sm text-gray-600 darki:text-gray-400">
                  <span className="font-semibold text-gray-800 darki:text-gray-200">
                    6
                  </span>{" "}
                  results
                </p>
              </div>
              <div>
                <div className="inline-flex gap-x-2">
                  <button
                    type="button"
                    className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none darki:bg-slate-900 darki:border-gray-700 darki:text-white darki:hover:bg-gray-800 darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
                  >
                    <svg
                      className="flex-shrink-0 w-4 h-4"
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="m15 18-6-6 6-6" />
                    </svg>
                    Prev
                  </button>
                  <button
                    type="button"
                    className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none darki:bg-slate-900 darki:border-gray-700 darki:text-white darki:hover:bg-gray-800 darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
                  >
                    Next
                    <svg
                      className="flex-shrink-0 w-4 h-4"
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="m9 18 6-6-6-6" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            {/* End Footer */}
          </div>
        </div>
      </div>
    </div>
    {/* End Card */}
  </div>
  {/* End Table Section */}
</>

       </div>
    )
}

export default ClubEnrolments;