import React, { useState, useEffect } from 'react';

const NominationsPrompt = ({ targetDate }) => {
  const [comparisonResult, setComparisonResult] = useState('');

  useEffect(() => {
    const currentDate = new Date();
    const parsedTargetDate = new Date(targetDate);

    if (currentDate > parsedTargetDate) {
      setComparisonResult('Current date is greater than target date.');
    } else if (currentDate < parsedTargetDate) {
      setComparisonResult('Current date is lesser than target date.');
    } else {
      setComparisonResult('Current date is equal to target date.');
    }
  }, [targetDate]);

  return (
    <div className='max-w-[85rem] px-6 py-2 mx-auto'>
<div class="bg-white border border-gray-200 rounded-lg shadow-lg p-4 darki:bg-gray-800 darki:border-gray-700" role="alert">
  <div class="flex">
    <div class="flex-shrink-0">
      <svg class="flex-shrink-0 h-4 w-4 text-blue-600 mt-1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="10"/><path d="M12 16v-4"/><path d="M12 8h.01"/></svg>
    </div>
    <div class="ms-3">
      <h3 class="text-gray-800 mb-2 text-xl font-semibold darki:text-white">
        Start Nominations for Student Leader
      </h3>
      <p class="m-0 text-sm text-gray-700 darki:text-gray-400">
        Club enrollments are complete and due date has passed. 
      </p>
    </div>
  </div>
</div>
    </div>
  );
};

export default NominationsPrompt;
