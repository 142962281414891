const Sports_Data = [
  {
    "Main Categories": "Sports event",
    "Sub Categories": "Football",
    Technology: "",
    Leadership: "1",
    "Interpersonal Skills": "3",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "3",
  },
  {
    "Main Categories": "Sports event",
    "Sub Categories": "Basketball",
    Technology: "",
    Leadership: "1",
    "Interpersonal Skills": "3",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "3",
  },
  {
    "Main Categories": "Sports event",
    "Sub Categories": "Cricket",
    Technology: "",
    Leadership: "1",
    "Interpersonal Skills": "3",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "3",
  },
  {
    "Main Categories": "Sports event",
    "Sub Categories": "Hockey",
    Technology: "",
    Leadership: "1",
    "Interpersonal Skills": "3",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "3",
  },
  {
    "Main Categories": "Sports event",
    "Sub Categories": "Badminton",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "3",
  },
  {
    "Main Categories": "Sports event",
    "Sub Categories": "Tennis",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "3",
  },
  {
    "Main Categories": "Sports event",
    "Sub Categories": "Gymnastics",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "3",
  },
  {
    "Main Categories": "Sports event",
    "Sub Categories": "Throwball",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "2",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "3",
  },
  {
    "Main Categories": "Sports event",
    "Sub Categories": "Futsal",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "2",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "3",
  },
  {
    "Main Categories": "Sports event",
    "Sub Categories": "Archery",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "3",
  },
  {
    "Main Categories": "Sports event",
    "Sub Categories": "Cycling",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "3",
  },
  {
    "Main Categories": "Sports event",
    "Sub Categories": "Sprint",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "3",
  },
  {
    "Main Categories": "Sports event",
    "Sub Categories": "Relay race",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "3",
  },
  {
    "Main Categories": "Sports event",
    "Sub Categories": "Baseball",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "2",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "3",
  },
  {
    "Main Categories": "Sports event",
    "Sub Categories": "Fencing",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "3",
    "Physical Fitness": "2",
  },
  {
    "Main Categories": "Sports event",
    "Sub Categories": "Golf",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "3",
  },
  {
    "Main Categories": "Sports event",
    "Sub Categories": "Handball",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "2",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "3",
  },
  {
    "Main Categories": "Sports event",
    "Sub Categories": "Netball",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "2",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "3",
  },
  {
    "Main Categories": "Sports event",
    "Sub Categories": "Rowing",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "3",
    "Social Responsibility": "",
    "Critical Thinking": "1",
    "Physical Fitness": "3",
  },
  {
    "Main Categories": "Sports event",
    "Sub Categories": "Skateboarding",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "3",
  },
  {
    "Main Categories": "Sports event",
    "Sub Categories": "Road bicycle racing",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "3",
  },
  {
    "Main Categories": "Sports event",
    "Sub Categories": "Ballet",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "3",
    "Physical Fitness": "3",
  },
  {
    "Main Categories": "Sports event",
    "Sub Categories": "Kabaddi",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "3",
  },
  {
    "Main Categories": "Sports event",
    "Sub Categories": "Kickboxing",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "3",
  },
  {
    "Main Categories": "Sports event",
    "Sub Categories": "Boxing",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "3",
  },
  {
    "Main Categories": "Sports event",
    "Sub Categories": "Strongman",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "",
    "Physical Fitness": "3",
  },
  {
    "Main Categories": "Sports event",
    "Sub Categories": "Lacrosse",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "3",
  },
  {
    "Main Categories": "Sports event",
    "Sub Categories": "Polo",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "3",
  },
  {
    "Main Categories": "Sports event",
    "Sub Categories": "Water Polo",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "3",
  },
  {
    "Main Categories": "Sports event",
    "Sub Categories": "Long Jump",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "3",
  },
  {
    "Main Categories": "Sports event",
    "Sub Categories": "High Jump",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "3",
  },
  {
    "Main Categories": "Sports event",
    "Sub Categories": "Shot put",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "3",
  },
  {
    "Main Categories": "Sports event",
    "Sub Categories": "Pole Vault",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "3",
  },
  {
    "Main Categories": "Sports event",
    "Sub Categories": "Pickleball",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "3",
  },
  {
    "Main Categories": "Sports event",
    "Sub Categories": "Chess",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "3",
    "Physical Fitness": "",
  },
  {
    "Main Categories": "Sports event",
    "Sub Categories": "Track & field",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "3",
  },
  {
    "Main Categories": "Sports event",
    "Sub Categories": "Curling",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "3",
  },
  {
    "Main Categories": "Sports event",
    "Sub Categories": "Endurance running",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "",
    "Physical Fitness": "3",
  },
  {
    "Main Categories": "Sports event",
    "Sub Categories": "Hurdle races",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "3",
  },
  {
    "Main Categories": "Sports event",
    "Sub Categories": "Ice skating",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "3",
    "Physical Fitness": "3",
  },
  {
    "Main Categories": "Sports event",
    "Sub Categories": "Surfing",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "3",
  },
  {
    "Main Categories": "Sports event",
    "Sub Categories": "Skiing",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "3",
    "Physical Fitness": "3",
  },
  {
    "Main Categories": "Sports event",
    "Sub Categories": "Snowboarding",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "3",
  },
  {
    "Main Categories": "Sports event",
    "Sub Categories": "Dodgeball",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "3",
    "Physical Fitness": "3",
  },
  {
    "Main Categories": "Sports event",
    "Sub Categories": "Climbing",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "3",
    "Physical Fitness": "3",
  },
  {
    "Main Categories": "Sports event",
    "Sub Categories": "Snooker",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "3",
    "Physical Fitness": "2",
  },
  {
    "Main Categories": "Sports event",
    "Sub Categories": "Squash",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "3",
  },
  {
    "Main Categories": "Sports event",
    "Sub Categories": "Swimming",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "",
    "Physical Fitness": "3",
  },
  {
    "Main Categories": "Sports event",
    "Sub Categories": "Table Tennis",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "3",
  },
  {
    "Main Categories": "Sports event",
    "Sub Categories": "Volleyball",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "3",
  },
  {
    "Main Categories": "Sports event",
    "Sub Categories": "Weightlifting",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "3",
  },
  {
    "Main Categories": "Sports event",
    "Sub Categories": "Rugby",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "3",
  },
];

export default Sports_Data;
