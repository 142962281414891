import React, { useEffect, useState, useContext } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import AuthContext from '../context/AuthContext'

const MobileBottomPilot = () => {
  let { user } = useContext(AuthContext)
  let location = useLocation()

  return (
    <div className="bg-gray-100 mobileTab flex md:hidden">
      <div className="fixed bottom-0 left-0 right-0 bg-white border-t shadow bg-primary mobileBottom text-white pb-4">
        <div className="flex justify-around p-2 mobileBottom-inner">


<NavLink
            to={`/bisr`}
            href="#"
            className={`flex flex-col w-1/5 text-center py-2 ${
              location.pathname === `/bisr`
                ? 'mobBtm_active'
                : 'text-white hover:mobBtm_active'
            }`}
          >


          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-8 h-8 mx-auto mb-1">
  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
</svg>



            <span className='text-sm'>Form</span>
          </NavLink>

          <NavLink
            to={`/preview`}
            href="#"
            className={`flex flex-col w-1/5 text-center py-2 ${
              location.pathname === `/preview`
                ? 'mobBtm_active'
                : 'text-white hover:mobBtm_active'
            }`}
          >

<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-8 h-8 mx-auto mb-1">
  <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 002.25-2.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v2.25A2.25 2.25 0 006 10.5zm0 9.75h2.25A2.25 2.25 0 0010.5 18v-2.25a2.25 2.25 0 00-2.25-2.25H6a2.25 2.25 0 00-2.25 2.25V18A2.25 2.25 0 006 20.25zm9.75-9.75H18a2.25 2.25 0 002.25-2.25V6A2.25 2.25 0 0018 3.75h-2.25A2.25 2.25 0 0013.5 6v2.25a2.25 2.25 0 002.25 2.25z" />
</svg>


            <span className='text-sm'>Dashboard</span>
          </NavLink>

        </div>
      </div>
    </div>
  )
}

export default MobileBottomPilot
