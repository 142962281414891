// App.js
import React from 'react';
import ReturnNav from './ReturnNav';
import { createGlobalStyle } from 'styled-components';
import PublicNav from './PublicNav';
const Tweet = () => {

  const profile = {
    "name": "John Doe",
    "age": 25,
    "gender": "male",
    "location": {
      "city": "Randomville",
      "country": "Wonderland"
    },
    "interests": ["coding", "gaming", "reading"],
    "isStudent": true,
    "skills": {
      "programming": ["JavaScript", "React", "Node.js"],
      "languages": ["English", "Spanish"]
    }
  };

  const GlobalStyle = createGlobalStyle`
  html {
    background: #F9F3FF !important;
    /* Add other global styles here if needed */
  }
`;
  return (
    <div>
    <PublicNav/>
    <div className='mx-32'>


    <ReturnNav/>
    <GlobalStyle/>
<>
  {/* component */}
  <div className="">


  <div class="w-full md:block max-w-full px-3 mt-6 shrink-0  md:flex-0 md:mt-0 ">
                    <div class="relative flex flex-col min-w-0 break-words bg-white border-0 shadow-xl  rounded-2xl bg-clip-border">
                      <img
                        class="w-full rounded-t-2xl timelineCover"
                        src="https://lhsroar.com/wp-content/uploads/2015/12/10373593623_feaf5a6ac1_h-900x599.jpg"
                        alt="profile cover image"
                      />

                      <div class="">
                        <div class="w-4/12 max-w-full px-3 flex-0 ">
                          <div class="mb-6 px-4 -mt-6 lg:mb-0 lg:-mt-16">
                            <div className='flex flex-col justify-center '>
                              <img
                                class="h-36 w-36 border-2 border-gray-400 border-solid img-fit rounded-circle"
                                src={'https://res.cloudinary.com/ahbranding/image/upload/v1705663616/Group_34784500_avny0v.png'}
                                alt="profile image"
                              />

                              <h4 className='ml-2 text-xl mt-4 text-start text-black font-semibold'> Striker FC </h4>
                              <p className='ml-2 text-gray-500 text-start m-0 '>Academic Club</p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="flex-auto p-6 pt-0">
                        <div class="mt-6 text-center">
                          <div class="/80 text-start ml-2 ">
                          The Stikers Football Club is a dynamic and passionate community dedicated to promoting the spirit of teamwork, sportsmanship, and a love for the beautiful game. Whether you're a seasoned player or just starting, our club provides a welcoming environment for everyone to enjoy the thrill of football.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

    <div className="flex">


    
    
      <div className="w-4/5 border-r border-gray-400 h-auto  border-t-0">
        {/*middle wall*/}
    
        <hr className="border-gray-600" />
        {/*middle creat tweet*/}
       
        <div className='bg-white p-4 rounded-xl mx-4 shadow-xl'>
        <div className="flex">
          <div className="m-2 w-10 py-1">
            <img
              className="inline-block h-10 w-10 rounded-full  img-fit"
              src="https://res.cloudinary.com/ahbranding/image/upload/v1705663616/Group_34784500_avny0v.png"
              alt=""
            />
          </div>
          <div className="flex-1 px-2 pt-2 mt-2">
            <textarea
              className="textarea textarea-bordered bg-transparent text-gray-400 font-medium text-lg w-full"
              rows={2}
              cols={50}
              placeholder="What's happening?"
              defaultValue={""}
            />
          </div>
        </div>
        {/*middle creat tweet below icons*/}
        <div className="flex justify-end mr-4">
        <button className="btn btn-primary mt-4">
              Add new post
            </button>
        </div>

        <div></div>

</div>


        {/*first tweet start*/}

        <div className='bg-white p-4 rounded-xl mx-4 shadow-xl my-4'>
        <div className="flex flex-shrink-0 p-4 pb-0">
          <a href="#" className="flex-shrink-0 group block">
            <div className="flex items-center">
              <div>
                <img
                  className="inline-block h-10 w-10 rounded-full  img-fit"
                  src="https://res.cloudinary.com/ahbranding/image/upload/v1705663616/Group_34784500_avny0v.png"
                  alt=""
                />
              </div>
              <div className="ml-3">
                <div className="flex justify-between text-base leading-6 font-medium text-black">
                  <span className='block'>Striker FC</span>
                  <span className="ml-4  block text-sm leading-5 font-medium text-gray-400 group-hover:text-gray-300 transition ease-in-out duration-150">
                    • 16 April
                  </span>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div className="pl-16">
          <p className="text-normal width-auto font-normal text-black flex-shrink">
            🔓 Registrations for the Football Club are now OPEN! ⚽️ Whether you're a seasoned pro or just starting your football journey, join our vibrant community and experience the thrill of the game! 🎉✨
          </p>
          <div className="md:flex-shrink pr-6 pt-3">
            <img
              className="rounded-lg w-full h-64 img-fit"
              src="https://res.cloudinary.com/dzcx399ln/image/upload/f_auto,c_fill,w_1600,h_534/ncsa/wp-content/uploads/2022/07/list-of-division-1-football-colleges.jpg"
              alt="Woman paying for a purchase"
            />
          </div>
          <div className="flex">
            <div className="w-full">
              <div className="flex items-center">
              <div className="flex w-content text-center py-2 m-2">
                  <a
                    href="#"
                    className="w-12 mt-1 group flex items-center text-gray-500 px-3 py-2 text-base leading-6 font-medium rounded-full hover:bg-blue-800 hover:text-blue-300"
                  >
                    <svg
                      className="text-center h-7 w-6"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                    </svg>
                  </a>
                </div>
                <div className="flex  w-content text-center">
                  <a
                    href="#"
                    className="w-12 mt-1 group flex items-center text-gray-500 px-3 py-2 text-base leading-6 font-medium rounded-full hover:bg-blue-800 hover:text-blue-300"
                  >
                    <svg
                      className="text-center h-6 w-6"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

        <div className='bg-white p-4 rounded-xl mx-4 shadow-xl my-4'>
        <div className="flex flex-shrink-0 p-4 pb-0">
          <a href="#" className="flex-shrink-0 group block">
            <div className="flex items-center">
              <div>
                <img
                  className="inline-block h-10 w-10 rounded-full  img-fit"
                  src="https://res.cloudinary.com/ahbranding/image/upload/v1705663616/Group_34784500_avny0v.png"
                  alt=""
                />
              </div>
              <div className="ml-3">
                <p className="flex justify-between text-base leading-6 font-medium text-black">
                  <span>Striker FC</span>
                  <span className="text-sm leading-5 font-medium text-gray-400 group-hover:text-gray-300 transition ease-in-out duration-150 ml-4 m-0">• 16 April</span>
                </p>
              </div>
            </div>
          </a>
        </div>
        <div className="pl-16">
          <p className="text-base width-auto font-medium text-black flex-shrink">
            Day 07 of the challenge{" "}
            <span className="text-blue-400">#100DaysOfCode</span>I was wondering
            what I can do with{" "}
            <span className="text-blue-400">#tailwindcss</span>, so just started
            building Twitter UI using Tailwind and so far it looks so promising.
            I will post my code after completion. [07/100]
            <span className="text-blue-400"> #WomenWhoCode #CodeNewbie</span>
          </p>
          <div className="flex">
            <div className="w-full">
              <div className="flex items-center">



              <div className="flex w-content text-center py-2 m-2">
                  <a
                    href="#"
                    className="w-12 mt-1 group flex items-center text-gray-500 px-3 py-2 text-base leading-6 font-medium rounded-full hover:bg-blue-800 hover:text-blue-300"
                  >
                    <svg
                      className="text-center h-7 w-6"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" />
                    </svg>
                  </a>
                </div>
                <div className="flex  w-content text-center">
                  <a
                    href="#"
                    className="w-12 mt-1 group flex items-center text-gray-500 px-3 py-2 text-base leading-6 font-medium rounded-full hover:bg-blue-800 hover:text-blue-300"
                  >
                    <svg
                      className="text-center h-6 w-6"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                    </svg>
                  </a>
                </div>


               
                
               
                
              
              </div>
            </div>
          </div>
        </div>
</div>





        {/*second tweet*/}
        
</div>





      <div className="w-2/5 h-12 mt-4">

        <div className="max-w-sm rounded-lg bg-white shadow-xl overflow-hidden m-4 mr-20">
          <div className="flex">
            <div className="flex-1 m-2">
              <h2 className="px-4 py-2 text-xl w-full font-semibold text-black">
                Upcoming Events
              </h2>
            </div>
          </div>
          {/*first person who to follow*/}
          <div className="flex flex-shrink-0 px-4 my-2 mx-2 rounded-xl border-2 border-gray-200">
            <div className="flex-1 ">
              <div className="flex items-center w-48">
                <div className="ml-3 mt-3">
                  <p className="text-base leading-6 font-medium text-black">
                    School Tournament
                  </p>
                  <p className="text-sm leading-5 font-medium text-gray-400 group-hover:text-gray-300 transition ease-in-out duration-150">
                    Jan 28 • 2024
                  </p>
                </div>
              </div>
            </div>
            <button className="btn  mt-2 btn-sm btn-ghost">
                   Details
                </button>
          </div>
          {/*second person who to follow*/}
          <div className="flex flex-shrink-0 px-4 my-2 mx-2 rounded-xl border-2 border-gray-200">
            <div className="flex-1 ">
              <div className="flex items-center w-48">
                <div className="ml-3 mt-3">
                  <p className="text-base leading-6 font-medium text-black">
                    Country League
                  </p>
                  <p className="text-sm leading-5 font-medium text-gray-400 group-hover:text-gray-300 transition ease-in-out duration-150">
                    Mar 25 • 2024
                  </p>
                </div>
              </div>
            </div>
            <button className="btn mt-2 btn-sm btn-ghost">
                   Details
                </button>
          </div>

        </div>
      </div>

      <div></div>
    </div>
  </div>
</>

    </div></div>
  );
};

export default Tweet;
