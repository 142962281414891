import React, { useEffect, useState, useContext } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import Sidebar from "../components/Sidebar";
import Joyride, { STATUS } from "react-joyride";
import axios from "axios";
import { toast } from "react-hot-toast";
import SideInnerSM from "../components/SideInnerSM";
import Navbar from "../components/Navbar";
import TimelineStack from "../components/TimelineStack";
import Category from "./Category";
import PillCat from "./PillCat";
import { useLanguage } from "../components/language";


import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '25px'
  },
};

const Cats = () => {
  const [profile, setProfile] = useState();



  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }


  function closeModal() {
    setIsOpen(false);
  }

  const { t, changeLanguage, lanArabic } = useLanguage();

  const navigate = useNavigate();
  let { user } = useContext(AuthContext);
  let profileID = user["user_id"];
  let isMyself = true;

  if (user !== null && user["user_id"] === parseInt(profileID)) {
    isMyself = true;
  }

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/profile/${profileID}`
        );
        let data = await response.json();
        setProfile(data);
        setLoading(false);
      } catch (err) {
        // console.log("The requested profile does not exist.");
        setLoading(false); // Set loading to false even on error to stop showing the loader
      }
    };

    // Simulate the loader by setting a timer for 3 seconds
    const timer = setTimeout(() => {
      fetchData();
    }, 900);

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [profileID]);


  /*
  const handleJoyrideCallback = (data) => {
    const { status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setIsTourRunning(false);
    }
  };*/

  let lead_count = 0;
  let society_count = 0;
  let social_count = 0;
  let award_count = 0;
  let sports_count = 0;
  let intern_count = 0;
  let cert_count = 0;
  let talent_count = 0;
  let lang_count = 0;
  let event_count = 0;
  let sum_lead = 0;
  if (profile?.lead_activity !== "[]") {
    for (let i = 0; i < profile?.lead_activity?.length; i++) {
      let obj = profile?.lead_activity[i];
      let lead_activity_val = parseFloat(obj.name) || 0;
      let lead_startdate_val = obj.startdate ? new Date(obj.startdate) : null;
      let lead_enddate_val = obj.enddate ? new Date(obj.enddate) : null;
      let lead_duration_val = 0;
      let lead_scope_val = parseFloat(obj.scope) || 0;
      let lead_service_val = parseFloat(obj.service) || 0;

      if (lead_startdate_val && lead_enddate_val) {
        let diffInMonths =
          (lead_enddate_val.getFullYear() - lead_startdate_val.getFullYear()) *
          12;
        diffInMonths +=
          lead_enddate_val.getMonth() - lead_startdate_val.getMonth();
        lead_duration_val = diffInMonths / 10;
      }

      sum_lead +=
        lead_activity_val *
        lead_duration_val *
        lead_scope_val *
        lead_service_val;

      // console.log("Data: "+lead_activity_val+','+lead_duration_val+','+lead_scope_val+','+lead_service_val);

      lead_count++;
    }
  }
  let sum_society = 0;

  if (profile?.society_activity !== "[]") {
    for (let i = 0; i < profile?.society_activity?.length; i++) {
      let obj = profile?.society_activity[i];
      let society_name_val = parseFloat(obj.name) || 0;
      let society_startdate_val = obj.startdate
        ? new Date(obj.startdate)
        : null;
      let society_enddate_val = obj.enddate ? new Date(obj.enddate) : null;
      let society_duration_val = 0;
      let society_service_val = parseFloat(obj.service) || 0;
      let society_role_val = parseFloat(obj.role) || 0;

      if (society_startdate_val && society_enddate_val) {
        let diffInMonths =
          (society_enddate_val.getFullYear() -
            society_startdate_val.getFullYear()) *
          12;
        diffInMonths +=
          society_enddate_val.getMonth() - society_startdate_val.getMonth();
        society_duration_val = diffInMonths / 10;
      }

      sum_society +=
        society_name_val *
        society_duration_val *
        society_service_val *
        society_role_val;

      society_count++;
    }
  }

  let sum_social = 0;
  if (profile?.social_activity !== "[]") {
    for (let i = 0; i < profile?.social_activity?.length; i++) {
      let obj = profile?.social_activity[i];
      let social_name = parseFloat(obj.name) || 0;
      let social_startdate_val = obj.startdate ? new Date(obj.startdate) : null;
      let social_enddate_val = obj.enddate ? new Date(obj.enddate) : null;
      let social_duration = 0;
      let social_scope = parseFloat(obj.scope) || 0;
      let social_service = parseFloat(obj.service) || 0;
      let social_role = parseFloat(obj.role) || 0;

      if (social_startdate_val && social_enddate_val) {
        let diffInMonths =
          (social_enddate_val.getFullYear() -
            social_startdate_val.getFullYear()) *
          12;
        diffInMonths +=
          social_enddate_val.getMonth() - social_startdate_val.getMonth();
        social_duration = diffInMonths / 10;
      }

      sum_social +=
        social_name *
        social_duration *
        social_scope *
        social_service *
        social_role;
      social_count++;
    }
  }

  let sum_award = 0;

  if (profile?.award_activity !== "[]") {
    for (let i = 0; i < profile?.award_activity?.length; i++) {
      let obj = profile?.award_activity[i];
      let award_name = parseFloat(obj.name) || 0;
      let award_scope = parseFloat(obj.scope) || 0;
      let award_role = parseFloat(obj.rank) || 0;

      sum_award += award_name * award_scope * award_role;
      award_count++;
    }
  }

  let sum_sports = 0;
  if (profile?.sports_activity !== "[]") {
    for (let i = 0; i < profile?.sports_activity?.length; i++) {
      let obj = profile?.sports_activity[i];
      let sports_name = parseFloat(obj.name) || 0;
      let sports_scope = parseFloat(obj.scope) || 0;
      let sports_role = parseFloat(obj.role) || 0;
      let sports_startdate_val = obj.startdate ? new Date(obj.startdate) : null;
      let sports_enddate_val = obj.enddate ? new Date(obj.enddate) : null;
      let sports_duration = 0;
      let sports_service = parseFloat(obj.service) || 0;

      if (sports_startdate_val && sports_enddate_val) {
        let diffInMonths =
          (sports_enddate_val.getFullYear() -
            sports_startdate_val.getFullYear()) *
          12;
        diffInMonths +=
          sports_enddate_val.getMonth() - sports_startdate_val.getMonth();
        sports_duration = diffInMonths / 10;
      }

      sum_sports +=
        sports_name *
        sports_scope *
        sports_role *
        sports_duration *
        sports_service;
      sports_count++;
    }
  }

  let sum_intern = 0;
  if (profile?.intern_activity !== "[]") {
    for (let i = 0; i < profile?.intern_activity?.length; i++) {
      let obj = profile?.intern_activity[i];
      let intern_name = parseFloat(obj.name) || 0;
      let intern_startdate_val = obj.startdate ? new Date(obj.startdate) : null;
      let intern_enddate_val = obj.enddate ? new Date(obj.enddate) : null;
      let intern_duration = 0;
      let intern_role = parseFloat(obj.role) || 0;
      let intern_scope = parseFloat(obj.scope) || 0;

      if (intern_startdate_val && intern_enddate_val) {
        let diffInMonths =
          (intern_enddate_val.getFullYear() -
            intern_startdate_val.getFullYear()) *
          12;
        diffInMonths +=
          intern_enddate_val.getMonth() - intern_startdate_val.getMonth();
        intern_duration = diffInMonths / 10;
      }

      sum_intern += intern_name * intern_duration * intern_role * intern_scope;

      intern_count++;
    }
  }

  let sum_cert = 0;
  if (profile?.cert_activity !== "[]") {
    for (let i = 0; i < profile?.cert_activity?.length; i++) {
      let obj = profile?.cert_activity[i];
      let cert_type = parseFloat(obj.type) || 0;
      sum_cert += cert_type;

      cert_count++;
    }
  }

  let sum_talent = 0;
  if (profile?.talent_activity !== "[]") {
    for (let i = 0; i < profile?.talent_activity?.length; i++) {
      let obj = profile?.talent_activity[i];
      let talent_name = parseFloat(obj.name) || 0;
      let talent_service = parseFloat(obj.service) || 0;
      sum_talent += talent_name * talent_service;

      talent_count++;
    }
  }

  let sum_lang = 0;
  if (profile?.lang_activity !== "[]") {
    for (let i = 0; i < profile?.lang_activity?.length; i++) {
      let obj = profile?.lang_activity[i];
      let lang_prof = parseFloat(obj.prof) || 0;
      sum_lang += 7 * lang_prof;

      lang_count++;
    }
  }

  let events_count = 0;
  if (profile?.event_activity !== "[]") {
    for (let i = 0; i < profile?.event_activity?.length; i++) {
      events_count++;
    }
  }

  if (
    profile?.hobbies !== "[]" ||
    profile?.hobbies !== 0 ||
    profile?.hobbies !== "" ||
    profile?.hobbies !== null
  ) {
    let count_hobbies = 0;

    count_hobbies++;
  }

  const round_lead_result = Math.floor(sum_lead);
  const round_society_result = Math.floor(sum_society);
  const round_social_result = Math.floor(sum_social);
  const round_award_result = Math.floor(sum_award);
  const round_sports_result = Math.floor(sum_sports);
  const round_intern_result = Math.floor(sum_intern);
  const round_cert_result = Math.floor(sum_cert);

  const round_lead_duration = profile?.lead_duration * 10;
  const round_lead_duration2 = profile?.lead_duration2 * 10;
  const round_lead_duration3 = profile?.lead_duration3 * 10;

  const round_society_duration = profile?.society_duration * 10;
  const round_society_duration2 = profile?.society_duration2 * 10;
  const round_society_duration3 = profile?.society_duration3 * 10;

  const round_social_duration = profile?.social_duration * 10;
  const round_social_duration2 = profile?.social_duration2 * 10;
  const round_social_duration3 = profile?.social_duration3 * 10;

  const totalScore =
    (round_lead_result +
      round_society_result +
      round_social_result +
      round_award_result +
      round_sports_result +
      round_cert_result +
      round_intern_result) *
    15;

  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScroll(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  let { logoutUser } = useContext(AuthContext);

  const [leads, setLeads] = useState();
  const [society, setsociety] = useState();
  const [social, setsocial] = useState();
  const [awards, setawards] = useState();
  const [sports, setsports] = useState();
  const [intern, setintern] = useState();
  const [cert, setcert] = useState();
  const [talents, settalents] = useState();
  const [lang, setlang] = useState();
  const [events, setevents] = useState();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/get_lead/`)
      .then((response) => {
        setLeads(response.data);
      })
      .catch((error) => {
        toast.error("Oh No! Something went wrong: " + error, {
          icon: "😓",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
      });

    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/get_society/`)
      .then((response) => {
        setsociety(response.data);
      })
      .catch((error) => {
        toast.error("Oh No! Something went wrong: " + error, {
          icon: "😓",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
      });

    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/get_social/`)
      .then((response) => {
        setsocial(response.data);
      })
      .catch((error) => {
        toast.error("Oh No! Something went wrong: " + error, {
          icon: "😓",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
      });

    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/get_awards/`)
      .then((response) => {
        setawards(response.data);
      })
      .catch((error) => {
        toast.error("Oh No! Something went wrong: " + error, {
          icon: "😓",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
      });

    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/get_sports/`)
      .then((response) => {
        setsports(response.data);
      })
      .catch((error) => {
        toast.error("Oh No! Something went wrong: " + error, {
          icon: "😓",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
      });

    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/get_intern/`)
      .then((response) => {
        setintern(response.data);
      })
      .catch((error) => {
        toast.error("Oh No! Something went wrong: " + error, {
          icon: "😓",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
      });

    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/get_cert/`)
      .then((response) => {
        setcert(response.data);
      })
      .catch((error) => {
        toast.error("Oh No! Something went wrong: " + error, {
          icon: "😓",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
      });

    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/get_talent/`)
      .then((response) => {
        settalents(response.data);
      })
      .catch((error) => {
        toast.error("Oh No! Something went wrong: " + error, {
          icon: "😓",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
      });

    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/get_eventStd/`)
      .then((response) => {
        setevents(response.data);
      })
      .catch((error) => {
        toast.error("Oh No! Something went wrong: " + error, {
          icon: "😓",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
      });

    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/get_lang/`)
      .then((response) => {
        setlang(response.data);
      })
      .catch((error) => {
        toast.error("Oh No! Something went wrong: " + error, {
          icon: "😓",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
      });
  }, [profileID]);

  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarVisible((prevState) => !prevState);
  };
  const [showSection, setShowSection] = useState(false); // State to track whether to show the section

  const toggleSection = () => {
    setShowSection(!showSection); // Toggle the state
  };




  const [tourSteps, setTourSteps] = useState([]);
  const [runTour, setRunTour] = useState(false);
  const [joyrideKey, setJoyrideKey] = useState(0); // Add a key to force remount


  useEffect(() => {

      // Define the tour steps here

      if(!lanArabic){
        const newTourSteps = [
          {
            target: '[data-tour="step1"]',
            content: `Click on this button to open categories. Selecting each category opens its respective form.`,
          },
  
          {
            target: '[data-tour="step2"]',
            content: `You can click on it to  toggle timeline `,
          },
  
          {
            target: '[data-tour="step3"]',
            content: `Timeline shows complete history of extracurricular activities`,
          },
  
          // Add more steps as needed
        ];
        setTourSteps(newTourSteps);
      }

      else{
        const newTourStepsArabic = [
          {
            target: '[data-tour="step1"]',
            content: `انقر على هذا الزر لفتح الفئات. اختيار كل فئة يفتح النموذج الخاص بها.`,
          },
        
          {
            target: '[data-tour="step2"]',
            content: `يمكنك النقر عليه لتبديل الجدول الزمني`,
          },
        
          {
            target: '[data-tour="step3"]',
            content: `الجدول الزمني يظهر تاريخ كامل للأنشطة اللاصفية`,
        },
        
          // Add more steps as needed
        ];
        setTourSteps(newTourStepsArabic);
        
      }
      

  }, [profile]);
  
  const startTour = () => {
    setRunTour(true);
    setJoyrideKey((prevKey) => prevKey + 1); // Increment the key to force remount
  };


  return (
    <div>
      <div>
        <div>
          <>
            <div class="hidden md:block absolute w-full bg-lilac h-96  min-h-75"></div>

            {/* <Sidebar />

            {isSidebarVisible && <SideInnerSM />} */}
{/* 
            <main
              className={`relative h-full max-h-screen transition-all duration-200 ease-in-out 
              ${
                lanArabic ? "xl:mr-68" : "xl:ml-68"
              } 
              rounded-xl`}
            > */}

<main
              className={`relative h-full max-h-screen transition-all duration-200 ease-in-out 
    
              rounded-xl`}
            >
              {/* Navbar */}
              <Navbar toggleSidebar={toggleSidebar} />

              {/* end Navbar */}
              {/* cards */}
              <div className="w-full px-6 py-6 mx-auto">
                <div className=" md:flex flex-wrap md:mt-6 -mx-3">
                  {/* <div className="w-full max-w-full px-3 mt-0 mb-6 lg:mb-0 lg:w-8/12 lg:flex-none"> */}
                  <div
                    className={`w-full max-w-full px-3 mt-0 lg:w-${
                      showSection ? "8/12" : "full"
                    } lg:flex-none`}
                  >


<div className="flex justify-end mb-2">
<button className="flex md:hidden  btn btn-sm capitalize" onClick={openModal}>
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
  <path stroke-linecap="round" stroke-linejoin="round" d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" />
</svg>



My Timeline</button>
</div>

                    <div className="relative flex flex-col min-w-0 break-words bg-white border-0 border-solid shadow-xl    border-black-125 rounded-2xl bg-clip-border">
                      <div className="p-4 pb-0 mb-0 rounded-t-4">
                        <div
                          class={`flex flex-col-reverse md:flex-row items-center justify-between ${
                            lanArabic ? "items-end" : ""
                          }`}
                        >
                          <div className="mb-4" >
                            <h6 className="mb-2 ">{t("Activity Category")}</h6>
                            <p className="text-sm capitalize leading-normal ">
                              {" "}
                              {t("Choose the category that best fits your extracurricular activity.")}
                            </p>
                          </div>




                          {showSection && (
                            <div className="tooltip tooltip-left" data-tip="Close" onClick={toggleSection} >
  <button className="hidden md:block  btn btn-sm">
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
  <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
</svg>


Timeline</button>
</div>
                          )}

                          {!showSection && (
                            <div className="tooltip tooltip-left" data-tip="Open" onClick={toggleSection} >
  <button className="hidden md:block btn btn-sm">
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
</svg>


Timeline</button>



</div>
                          )}
                        </div>
                      </div>
                      <div data-tour="step1"></div>
                      <div>
                          <PillCat />
                        </div>
                    </div>
                  </div>

                  {showSection && (
                    <div className="w-full max-w-full px-3 mt-0 lg:w-4/12 lg:flex-none">
                      <div className="border-black/12.5 shadow-xl   relative flex min-w-0 flex-col break-words rounded-2xl border-0 border-solid bg-white bg-clip-border">
                        {/* <div className={`p-4 pb-0 rounded-t-4 ${lanArabic ? 'text-end' : ''}`}>
              <h6 className="mb-2 ">{t('Competency Map')}</h6>
                  <p className="text-sm capitalize leading-normal "> {t('A comprehensive visualization of your skill set')}</p>
              </div> */}
                        <div className="flex-auto p-4" data-tour="step3">
                          <TimelineStack source="Self" />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </main>
          </>
        </div>
      </div>

      {/* <button onClick={startTour} style={{zIndex:'9999999999999999999'}} className={`btn btn-primary rounded-full fixed bottom-4 ${
    lanArabic ? 'left-4' : 'right-4'
  } bg-blue-500 text-white py-2 px-4 rounded-full shadow-md hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue active:bg-blue-800`}>{t('Need Help')} 🌟</button>
 */}

      {runTour && (
        <Joyride
          key={joyrideKey}
          steps={tourSteps}
          run={runTour}
          continuous
          scrollToFirstStep
          showProgress
          disableBeacon
          styles={{
            options: {
              arrowColor: '#4F0696',
              backgroundColor: '#fff',
              overlayColor: 'rgba(19, 10, 32, 0.46)',
              primaryColor: '#4F0696',
              textColor: '#000',
              width: 900,
              zIndex: 1000,
            },
            beacon: {
              backgroundColor: '#fff', // Change to the desired gold color
              borderRadius: '100px',
              inner: '#4F0696', // Use the original color for the inner part of the beacon
            },
          }}
        />
      )}




      <Modal
        isOpen={modalIsOpen}

        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
       <TimelineStack/>
      </Modal>
    </div>
  );
};

export default Cats;
