const Languages_Data = [
  {
    "Main Categories": "Language",
    "Sub Categories": "Urdu",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "2",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "",
  },
  {
    "Main Categories": "Language",
    "Sub Categories": "English",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "2",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "",
  },
  {
    "Main Categories": "Language",
    "Sub Categories": "German",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "2",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "",
  },
  {
    "Main Categories": "Language",
    "Sub Categories": "Japanese",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "2",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "",
  },
  {
    "Main Categories": "Language",
    "Sub Categories": "Mandarin",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "2",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "",
  },
  {
    "Main Categories": "Language",
    "Sub Categories": "Spanish",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "2",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "",
  },
  {
    "Main Categories": "Language",
    "Sub Categories": "French",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "2",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "",
  },
  {
    "Main Categories": "Language",
    "Sub Categories": "Russian",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "2",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "",
  },
  {
    "Main Categories": "Language",
    "Sub Categories": "Korean",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "2",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "",
  },
  {
    "Main Categories": "Language",
    "Sub Categories": "Punjabi",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "2",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "",
  },
  {
    "Main Categories": "Language",
    "Sub Categories": "Pashto",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "2",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "",
  },
  {
    "Main Categories": "Language",
    "Sub Categories": "Siraiki",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "2",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "",
  },
  {
    "Main Categories": "Language",
    "Sub Categories": "Arabic",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "2",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "",
  },
  {
    "Main Categories": "Language",
    "Sub Categories": "Portuguese",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "2",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "",
  },
  {
    "Main Categories": "Language",
    "Sub Categories": "Vietnamese",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "2",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "",
  },
  {
    "Main Categories": "Language",
    "Sub Categories": "Italian",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "2",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "",
  },
  {
    "Main Categories": "Language",
    "Sub Categories": "Turkish",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "2",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "",
  },
  {
    "Main Categories": "Language",
    "Sub Categories": "Hindi",
    Technology: "",
    Leadership: "",
    "Interpersonal Skills": "2",
    "Social Responsibility": "",
    "Critical Thinking": "2",
    "Physical Fitness": "",
  },
];

export default Languages_Data;
