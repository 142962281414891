import React, { useState, useEffect, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import { FaSortAlphaDown, FaSortAlphaUp, FaSearch } from "react-icons/fa"; // Icons

import AuthContext from "../context/AuthContext";
import StudentTable from "../components/Network_StudentTable";
import SelectDropdown from "../components/SelectDropdown";
import { useLanguage } from "./language";
import ApexChart from "./AreaChart";

function Network_StudentList() {
  const { t, changeLanguage, lanArabic } = useLanguage();
  const [schools, setSchools] = useState([]);
  const { user } = useContext(AuthContext);
  const userID = user["user_id"];
  const params = useParams();
  const profileID = userID;

  const [loadingStudents, setLoadingStudents] = useState(true);
  const [studentActivityCount, setStudentActivityCount] = useState({});

  const [profile, setProfile] = useState();
  const [error, setError] = useState();
  const [currentNetwork, setCurrentNetwork] = useState(null);
  const [filterStudent, setFilterStudent] = useState([]);
  const [activityNot, setActivityNot] = useState([]);
  const [activityPresent, setActivityPresent] = useState([]);
  const [selectedOption, setSelectedOption] = useState("All");

  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [studentsPerPage, setStudentsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState("name-asc");

  const sortedStudents = [...schools].sort((a, b) => {
    if (sortOrder === "name-asc") {
      return a.firstname.localeCompare(b.firstname);
    } else if (sortOrder === "name-desc") {
      return b.firstname.localeCompare(a.firstname);
    }
    return 0;
  });

  const filteredStudents = sortedStudents.filter((student) =>
    student.firstname.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const displayedStudents = filteredStudents.slice(
    (currentPage - 1) * studentsPerPage,
    currentPage * studentsPerPage
  );

  useEffect(() => {
    const getProfile = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/network_profile/${profileID}`
        );
        const data = await response.json();
        setProfile(data);
        setCurrentNetwork(data?.network_id);
      } catch (err) {
        console.log("The requested profile does not exist.");
      }
    };

    getProfile();
  }, [profileID]);

  useEffect(() => {
    if (currentNetwork) {
      fetch(`${process.env.REACT_APP_API_KEY}/api/student_count/`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          const allStudents = data.filter(
            (student) =>
              parseInt(student.network_in) === parseInt(currentNetwork)
          );

          // Filtering students who are in the activity list
          const filteredSchools = allStudents.filter((student) =>
            filterStudent.some(
              (activity) =>
                parseInt(activity.posted_by) === parseInt(student.user)
            )
          );
          

          // Filtering students who are NOT in the activity list
          const filterActivityNot = allStudents.filter((student) =>
            filterStudent.every(
              (activity) =>
                parseInt(activity.posted_by) !== parseInt(student.user)
            )
          );

          setActivityNot(filterActivityNot);
          setSchools(allStudents);
          setActivityPresent(filteredSchools);
        })
        .catch((error) => {
          console.error("Error fetching schools:", error);
          setError(error.message);
        });
    }
  }, [currentNetwork, filterStudent]);

  const [totalStudents, setTotalStudents] = useState(0); // Step 1

  useEffect(() => {
    const getActivities = async () => {
      fetch(`${process.env.REACT_APP_API_KEY}/api/get_all_activities/`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Student Activites was not ok");
          }
          return response.json();
        })
        .then((data) => {
          setFilterStudent(data);

          // Create an object to store activity count for each student
          const activityCount = {};

          // For each student, count their activities
          schools.forEach((student) => {
            activityCount[student.user] = data.filter(
              (activity) =>
                parseInt(activity.posted_by) === parseInt(student.user) && activity.status === "1"
            ).length;
          });

          // Update the state with the activity count
          setStudentActivityCount(activityCount);

          
        });
    };

    getActivities();
  }, [profileID, schools]);

  useEffect(() => {
    // ... (existing code)

    // Step 1: Update the total number of students based on the selected option
    let studentsToShow;
    if (selectedOption === "Activity Present") {
      studentsToShow = activityPresent.length;
    } else if (selectedOption === "Activity Not Present") {
      studentsToShow = activityNot.length;
    } else {
      studentsToShow = filteredStudents.length;
    }
    setTotalStudents(studentsToShow);
  }, [selectedOption, activityPresent, activityNot, filteredStudents]);



  if (error) {
    return <div>Error: {error}</div>;
  }

  const handleSelect = (selected) => {
    setSelectedOption(selected);
  };

  const itemsPerPage = 5; // Number of items to display per page
  const totalPages = Math.ceil(filteredStudents.length / itemsPerPage);

  // Calculate the start and end page numbers for the current group
  const groupSize = 5; // Number of pages per group
  const currentGroup = Math.ceil(currentPage / groupSize);
  const startPage = (currentGroup - 1) * groupSize + 1;
  const endPage = Math.min(startPage + groupSize - 1, totalPages);




  return (
    <div className=" p-4">
      <div className="join w-full justify-center">
        <div>
          <div>
            <input
              className="input input-bordered join-item w-full"
              placeholder="Search by name..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>
        <SelectDropdown
          selectedOption={selectedOption}
          onSelect={handleSelect}
        />
      </div>

      
      <div className="total-students">
        Total Students: {totalStudents} 
      </div>
      <StudentTable
        schools={displayedStudents}
        selectActivity={selectedOption}
        activityPresent={activityPresent}
        activityNot={activityNot}
        filterStudent={filterStudent}
        activityCount={studentActivityCount} // Passing the count to StudentTable
      />

      <div className="pagination">
        {startPage > 1 && (
          <button
            onClick={() => setCurrentPage(startPage - groupSize)}
            className="pagination-button"
          >
            Previous
          </button>
        )}
        {[...Array(endPage - startPage + 1)].map((_, idx) => {
          const page = startPage + idx;
          return (
            <button
              key={idx}
              onClick={() => setCurrentPage(page)}
              className={`pagination-button ${
                page === currentPage ? "active" : ""
              }`}
            >
              {page}
            </button>
          );
        })}
        {endPage < totalPages && (
          <button
            onClick={() => setCurrentPage(endPage + 1)}
            className="pagination-button"
          >
            Next
          </button>
        )}
      </div>
{/* 
      <ApexChart/> */}
    </div>
  );
}

export default Network_StudentList;
