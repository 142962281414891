import React, { useState, useEffect } from 'react';
import LogoCloud from './LogoCloud';
import { Link } from 'react-router-dom';

function BottomCTA() {


    return (
       

<>
    
<div className="bg-[#FCF9FF]">
<div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">

      <div className="max-w-screen-lg gap-8 p-6 rounded-xl bg-white row-gap-10 mx-auto lg:grid-cols-2">

        <h2 className="font-bold text-3xl text-center text-primary darki:text-gray-200">
        Revolutionizing education with innovative tools that capture every achievement, highlighting students’ contributions beyond the classroom, to help them unleash their full potential.
          </h2>


        <div className="flex flex-col justify-center items-center gap-2 mt-8">
<p className="text-gray-500 text-sm text-center ">Take the Next Step with Start Up Early</p>

<Link to='/book_demo' className="btn btn-primary">Book a demo</Link>

</div>
      </div>




      
    </div>
</div>

</>


    )
}

export default BottomCTA;