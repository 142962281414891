import React, { useState } from "react";
import { axiosInstance } from "../Axios";

import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import { useEffect } from "react";
import Navbar from "../components/Navbar";
import SideInnerSM from "../components/SideInnerSM";
import Sidebar from "../components/Sidebar";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Stats from "../components/Stats";
import toast from "react-hot-toast";
import Network_StudentList from "../components/Network_StudentList";
import { useLanguage } from "../components/language";
import axios from "axios";
export default function AddSchool() {
  const { t, changeLanguage, lanArabic } = useLanguage();
  const [schools, setSchools] = useState([]);
  const [students, setStudents] = useState([]);
  const [error, setError] = useState(null);
  const { user } = useContext(AuthContext);
  const userID = user["user_id"];
  const profileID = userID;

  const [loadingStudents, setLoadingStudents] = useState(true);
  const [campuses, setCampuses] = useState([{ name: "", email: "" }]);

  const [profile, setProfile] = useState();
  const [currentNetwork, setCurrentNetwork] = useState(null); // Initialize the currentNetwork state

  useEffect(() => {
    const getProfile = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/network_profile/${profileID}`
        );
        const data = await response.json();
        setProfile(data);

        // Set the currentNetwork state once the profile data is fetched
        setCurrentNetwork(data?.network_id); // Ensure data is not null before accessing the property
      } catch (err) {
        console.log("The requested profile does not exist.");
      }
    };

    getProfile();
  }, [profileID]);

  useEffect(() => {
    if (currentNetwork) {
      // Fetch schools from the Django API
      fetch(`${process.env.REACT_APP_API_KEY}/api/schools/`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          // Filter schools with network matching the currentNetwork
          const filteredSchools = data.filter(
            (school) => school.network === currentNetwork
          );
          setSchools(filteredSchools);
        })
        .catch((error) => {
          console.error("Error fetching schools:", error);
          setError(error.message);
        });
    }
  }, [currentNetwork]);

  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const addMoreCampus = () => {
    setCampuses((prevCampuses) => [...prevCampuses, { name: "", email: "" }]);
  };

  const removeCampus = (indexToRemove) => {
    setCampuses((prevCampuses) =>
      prevCampuses.filter((campus, index) => index !== indexToRemove)
    );
  };

  const handleChange = (index, field, value) => {
    const newCampuses = [...campuses];
    newCampuses[index][field] = value;
    setCampuses(newCampuses);
  };

  const handleSubmit = () => {
    const campuse = campuses.filter((campus) => campus.name && campus.email);
    if (campuse.length === 0) {
      // alert("Please add at least one campus");
      toast.error('Add at least one campus');
      return;
    }
    const campusData = campuse.map((campus) => {
      return {
        username: campus.name,
        email: campus.email,
        password: "admin@admin.",
        password2: "admin@admin",
        role: "School Admin",
        school_network: currentNetwork,
      };
    });
    axios
      .post(`${process.env.REACT_APP_API_KEY}/api/create_school/`, campusData)
      .then((response) => {
        console.log(response);
        toast.success('Campus Added Successfully!')
      })
      .catch((error) => {
        console.log(error);
        toast.error('Error: '+error)
      });
  };

  const toggleSidebar = () => {
    setIsSidebarVisible((prevState) => !prevState);
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
       <div className="bg-white rounded-lg p-6">
            <div>
              <>
                {/* Card Section */}

                {/* Card */}
                {/* <div className="bg-white rounded-xl shadow p-4 sm:p-7 darki:bg-slate-900"> */}
                <div className="mb-8">
                  <h2 className="text-xl font-bold text-gray-800 darki:text-gray-200">
                    Add Campus{" "}
                    <span className="text-gray-300 font-normal text-base ml-4">
                      {/* {step} of {totalsteps} */}
                    </span>
                  </h2>
                  <p className="text-sm text-gray-600 darki:text-gray-400">
                    Add a campus to your school network.
                  </p>
                </div>
                {campuses.map((campus, index) => (
                  <div
                    key={index}
                    className="grid my-4 sm:grid-cols-12 gap-2 sm:gap-6 bg-[#f4effb52] p-4 rounded-xl"
                  >
                    <div className="sm:col-span-3">
                      <label
                        htmlFor={`campus-name-${index}`}
                        className="inline-block text-sm text-gray-800 mt-2.5 darki:text-gray-200"
                      >
                        Campus {index+1}
                      </label>
                    </div>
                    <div className="sm:col-span-9">
                      <div className="flex gap-2 items-center flex-row">
                        <input
                          id={`campus-name-${index}`}
                          type="text"
                          className="input input-bordered w-full"
                          placeholder="eg: Northville Central Campus"
                          value={campus.name}
                          onChange={(e) =>
                            handleChange(index, "name", e.target.value)
                          }
                        />
                        <input
                          id={`campus-email-${index}`}
                          type="email"
                          className="input input-bordered w-full"
                          placeholder="eg: central@northville.com"
                          value={campus.email}
                          onChange={(e) =>
                            handleChange(index, "email", e.target.value)
                          }
                        />
{index > 0 && ( // Only render the button if index is greater than 0
  <button onClick={() => removeCampus(index)} className="btn text-red-500 bg-red-100 btn-circle">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
      <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
    </svg>
  </button>
)}

                      </div>
                    </div>

                    
                  </div>
                ))}

                <button
                  className="btn bg-secondary text-primary btn-sm mt-4"
                  onClick={addMoreCampus}
                >
                  {" "}
                  + Add more{" "}
                </button>


                
                {/* </div> */}
                {/* End Card */}

                {/* End Card Section */}
              </>
            </div>
            <button className="btn btn-primary mt-8" onClick={handleSubmit}>
            Confirm Campus(s)
          </button>
          </div>

    </div>
  );
}
