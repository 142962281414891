import React, { useState } from "react";
import { axiosInstance } from "../Axios";
import "react-circular-progressbar/dist/styles.css";
import ReactApexChart from 'react-apexcharts';
import { Line } from 'rc-progress';
import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import { useEffect } from "react";
import SideInnerSM from "../components/SideInnerSM";
import Sidebar from "../components/Sidebar";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

import MyCalendar from "../components/EventCalendar";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Stats from "../components/Stats";

import { createGlobalStyle } from "styled-components";
import Navbar from "../components/Navbar";
import BarChart from '../components/Barchart';
import RadarChart from '../components/RadarChart';
import BarStacked from '../components/BarStacked'
import CoCurricularEventsTable from '../components/CoCurricularEventsTable'
import NetworkStats from "../components/NetworkStats";
import Network_StudentList from "../components/Network_StudentList";
import Calendar from "react-calendar";
import EventCalendar from "../components/EventCalendar";
import BarchartSchoolTrend from "./BarchartSchoolTrend";
import CreateClubForm from "../components/CreateClubForm";
import { useLanguage } from "../components/language";
import domain from "../assets/images/domain.svg"
import face from "../assets/images/face.svg"
import extra from "../assets/images/extra.svg"
import school from "../assets/images/school.svg"
import StackedAreaChart from "./StackedAreaChart";
import LineChart from "./LineChart";
import PieRadialChart from "./PieRadialChart";
import BarChartClub from "./BarChartClub";

export default function NetworkClubs() {
 
  const { t, changeLanguage, lanArabic } = useLanguage();
const [isSidebarVisible, setIsSidebarVisible] = useState(false);
const toggleSidebar = () => {
  setIsSidebarVisible(prevState => !prevState);
}

let {user} = useContext(AuthContext);
let profileID = user['user_id'];
const [profile, setProfile] = useState();
const [loading, setLoading] = useState(true);
useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_KEY}/api/network_profile/${profileID}`);
      let data = await response.json();
      setProfile(data);
      setLoading(false);
    } catch (err) {
      //console.log("The requested profile does not exist.");
      setLoading(false); // Set loading to false even on error to stop showing the loader
    }
  };

  // Simulate the loader by setting a timer for 3 seconds
  const timer = setTimeout(() => {
    fetchData();
  }, 900);

  // Clean up the timer when the component unmounts
  return () => clearTimeout(timer);
}, [profileID]);

const styles = buildStyles({
  // Rotation of path and trail, in number of turns (0-1)
  rotation: 0.25,

  
 
  // How long animation takes to go from one percentage to another, in seconds
  pathTransitionDuration: 0.5,

  // Colors
  pathColor: `#0FBB9B`,
  textColor: "#4F0696",
  trailColor: "#bfdcd7",
  backgroundColor: "#F2F2F2",
});

const mockData = {
  monthDataSeries1: {
    prices: [30, 40, 45, 50, 48, 52, 50, 42, 38, 30],
    dates: ['2022-01-01', '2022-01-02', '2022-01-03', '2022-01-04', '2022-01-05', '2022-01-06', '2022-01-07', '2022-01-08', '2022-01-09', '2022-01-10'],
  },
};

const chartState = {
  series: [44, 55 ,13],
  options: {
    chart: {
      width: 380,
      type: 'pie',
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      show: false
    },
    // labels: ['Team A', 'Team B'],
    responsive: [{
      breakpoint: 500,
      options: {
        chart: {
          width: 250,
        },
        legend: {
          position: 'bottom',
        },
      },
    }],
  },
}

const areaState = {
  series: [{
    name: "Club Membership",
    data:  mockData.monthDataSeries1.prices
  }],
  options: {
    chart: {
      type: 'area',
      height: 900,
      zoom: {
        enabled: false
      },

      toolbar: {
        show: true,
        tools:{
          download:false // <== line to add
        }
      },

      
    },
    
    dataLabels: {
      show: false,
      enabled: false
    },
    stroke: {
      curve: 'straight'
    },

    labels: {
      show: false,
      enabled: false
    },
    xaxis: {
      show: false,
      enabled: false
    },
    yaxis: {
      show: false,
      enabled: false
    },
    legend: {
      show: false,
      enabled: false
    }
  },
};


  const splineChart = {
    series: [
      {
        name: 'series1',
        data: [31, 40, 28, 51, 42, 109, 100],
      },
      {
        name: 'series2',
        data: [11, 32, 45, 32, 34, 52, 41],
      },
      {
        name: 'series3',
        data: [11, 32, 45, 32, 50, 52, 41],
      },
    ],
    options: {



      chart: {
        height: 350,
        width: '100%',
        type: 'area',
        zoom: {
          enabled: false
        },
  
        toolbar: {
          show: true,
          tools:{
            download:false // <== line to add
          }
        },
  
        
      },
      
      fill: {
        type: 'solid',
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        show: false
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        type: 'datetime',
        categories: [
          '2018-09-19T00:00:00.000Z',
          '2018-09-19T01:30:00.000Z',
          '2018-09-19T02:30:00.000Z',
          '2018-09-19T03:30:00.000Z',
          '2018-09-19T04:30:00.000Z',
          '2018-09-19T05:30:00.000Z',
          '2018-09-19T06:30:00.000Z',
        ],
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm',
        },
      },
    },
  }

  const GlobalStyle = createGlobalStyle`
  html {
    background: #F9F3FF !important;
    /* Add other global styles here if needed */
  }
`;

  return (
    <div>
<GlobalStyle/>

<div>
  <>




<Sidebar />

{isSidebarVisible && <SideInnerSM/>}

    <main className={`relative h-full max-h-screen transition-all duration-200 ease-in-out rounded-xl`}>
      {/* Navbar */}


      {/* end Navbar */}
      {/* cards */}
      <div className="w-full px-6 py-6 mx-auto">



           <div className="rounded-lg p-4 flex flex-col gap-6">
           {/* <CreateClubForm/> */}

           <div className="w-full flex gap-4 items-center md:flex-row flex-col">
             <NewCard img={domain} title="Departments" number='05' />
             <NewCard img={face} title="Faculty Advisors" number='55' />
             <NewCard img={school} title="Club Members" number='126' />
             <NewCard img={extra} title="Club budget" number='$4.5K' />
           </div>
           <div className="w-full  flex  gap-6 md:flex-row flex-col">
           <div className="md:w-2/6  w-full flex flex-col bg-white gap-2 rounded-lg p-5">
            <h3 className="text-lg font-semibold">Participation</h3>
             <div className="flex justify-center items-center flex-col gap-4">
             <div className="">
              {/* <CircularProgressbar
                      strokeWidth={7}
                      styles={styles}
                      value={"60"}
                      text={"60%"}
                      
                    /> */}


                    <PieRadialChart/>
                   
              </div>
              {/* <div className="flex items-center gap-3">
                  <div className="flex items-center gap-1">
                  <div className="w-3 h-3 rounded-lg bg-[#0FBB9B]"></div>
                  <span>Active</span>
                  </div>

                  <div className="flex items-center gap-1">
                  <div className="w-3 h-3 rounded-lg bg-[#bfdcd7]"></div>
                  <span>Non Active</span>
                  </div>

              </div> */}
             </div>
           </div>
           <div className="md:w-2/6  w-full flex flex-col bg-white gap-2 rounded-lg p-5">
            <h3 className="text-lg font-semibold">Budget</h3>
             <div className="flex justify-center items-center flex-col gap-4">
             <div  id="chart" className="w-full flex items-center justify-center ">
             
            {/* <ReactApexChart options={chartState.options} series={chartState.series} type="pie" /> */}
               <BarChartClub/>

            
      
              </div>
              {/* <div className="flex items-center gap-3">
                  <div className="flex items-center gap-1">
                  <div className="w-3 h-3 rounded-lg bg-[#0FBB9B]"></div>
                  <span>Logistics</span>
                  </div>

                  <div className="flex items-center gap-1">
                  <div className="w-3 h-3 rounded-lg bg-[#bfdcd7]"></div>
                  <span>Printing</span>
                  </div>

                  <div className="flex items-center gap-1">
                  <div className="w-3 h-3 rounded-lg bg-[#bfdcd7]"></div>
                  <span>Acquisition</span>
                  </div>

              </div> */}
            
             </div>
           </div>
           <div className="md:w-2/6  w-full flex flex-col bg-white gap-2 rounded-lg p-5">
            <h3 className="text-lg font-semibold">Club Membership Count</h3>
             <div className="w-full flex justify-center items-center flex-col gap-4">
             <div  id="chart" className="w-full ">
             
           
            {/* <ReactApexChart options={areaState.options} series={areaState.series} type="area"  /> */}

            <LineChart/>
      
      
              </div>
            
             </div>
           </div>
           </div>

           <div className="w-full flex gap-6">
              <div className="w-9/12 flex justify-center flex-col text-start items-center bg-white rounded-lg p-5">
              {/* <ReactApexChart options={splineChart.options} series={splineChart.series} type="area"   width= {1100} height={350} /> */}
              
                <h3 className="text-lg text-start font-semibold">Forum Engagement</h3>
              <StackedAreaChart/>
              </div>
              <div className="w-3/12 flex flex-col bg-white rounded-lg p-5 gap-6">
                <div className="flex flex-col gap-2">
                  <h5 className="m-0">Tasks</h5>
                  <p className="m-0"></p>
                </div>
                <div className="w-full flex flex-col gap-1">
                  <div className="w-full flex items-center justify-between ">
                    <span>Marketing Team</span>
                    <span className="text-[#FFB200]"> 32 </span>
                  </div>
                  <div>
                  <Line percent={60} strokeWidth={3} trailWidth={4} trailColor="#FFF5CC" strokeColor="#FFB200" />
                  </div>
                  <div className="w-full flex flex-col gap-1">
                  <div className="w-full flex items-center justify-between ">
                    <span>Logistics Team</span>
                    <span className="text-[#4339F2]">65</span>
                  </div>
                  <div>
                  <Line percent={90} strokeWidth={3}  trailWidth={4} trailColor="#DAD7FE" strokeColor="#4339F2" />
                  </div>
                </div>
                <div className="w-full flex flex-col gap-1">
                  <div className="w-full flex items-center justify-between ">
                    <span>Registration Team</span>
                    <span className="text-[#02A0FC]">10</span>
                  </div>
                  <div>
                  <Line percent={68} strokeWidth={3} trailWidth={4} trailColor="#CCF8FE" strokeColor="#02A0FC" />
                  </div>
                </div>
                <div className="w-full flex flex-col gap-1">
                  <div className="w-full flex items-center justify-between ">
                    <span>Core Team</span>
                    <span className="text-[#FF3A29]">65</span>
                  </div>
                  <div>
                  <Line percent={80} strokeWidth={3} strokeColor="#FF3A29" trailWidth={4} trailColor="#FFE5D3" />
                  </div>
                </div>
                </div>
              </div>
           </div>
           
           </div> 


      </div>
    </main>
  </>
  </div>

    
    </div>
  );
}

export const NewCard = (props) => {
  return (
    <div className="md:w-1/4 w-full flex flex-col gap-4 px-8 py-8 bg-white rounded-lg">
    <img className="w-10 " src={props.img} alt={props.img} />
    <h5 className="m-0 text-base font-medium text-gray-500">{props.title}</h5>
    <h6 className="m-0 text-4xl text-[#4F0696]">{props.number}</h6>
  </div>
  )
}