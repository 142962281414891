import React, {useEffect, useState, useContext} from 'react'
import { NavLink,Link } from 'react-router-dom'
import AuthContext from '../context/AuthContext'
import { FaHouseChimney,FaPlus,FaTrophy, FaStopwatch,FaBell, FaCheckToSlot   } from "react-icons/fa6";
import {FaRegCheckCircle, FaCompass , FaSchool, FaUsers, FaStarOfLife, FaAsterisk, FaCalendarWeek } from 'react-icons/fa';
import { RiAdminFill,RiTeamFill  } from "react-icons/ri";
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { tr } from 'date-fns/locale';
const SideInner = () => {

    let {user} = useContext(AuthContext)
    let userID = user['user_id']
    ////console.log("role: "+user['role'])

    const [shouldRenderQuizzesLink, setShouldRenderQuizzesLink] = useState(false);
const [shouldRenderNotificationsLink, setShouldRenderNotificationsLink] = useState(false);

let profileID = userID

const [profile, setProfile] = useState();
const [loading, setLoading] = useState(true);

let url = '';

if(user['role']==='Student'){
  url = 'profile'
} else if(user['role']==='Network Admin'){
  url = 'network_profile'
}

const {t} = useTranslation();
const changeLanguage = (lng) => {
  i18n.changeLanguage(lng);
};

const lanArabic = i18n.language === 'ar'

const [clubAccess, setclubAccess] = useState(false);

useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_KEY}/api/${url}/${profileID}`);
      let data = await response.json();
      setProfile(data);
      setLoading(false);

      if(data?.club_access===1){
        setclubAccess(true);
      }

      // Check if the school name is not matching exactly
      if (data.school.trim() === "Northville Public School") {
        setShouldRenderQuizzesLink(false);
        setShouldRenderNotificationsLink(false);
      } else {
        setShouldRenderQuizzesLink(true);
        setShouldRenderNotificationsLink(true);
      }

    } catch (err) {
      // Handle errors appropriately, e.g., log the error
      console.error("Error fetching profile data:", err);
      setLoading(false); // Set loading to false even on error to stop showing the loader
    }
  };

  // Simulate the loader by setting a timer for 900 milliseconds
  const timer = setTimeout(() => {
    fetchData();
  }, 900);

  // Clean up the timer when the component unmounts
  return () => clearTimeout(timer);
}, [profileID]);






    return (


      <div>
<aside className={`fixed inset-y-0 flex-wrap items-center justify-between block w-full p-0 my-4 overflow-y-auto antialiased transition-transform duration-200 ${lanArabic ? 'xl:right-0 xl:mr-6' : 'xl:left-0 xl:ml-6'} -translate-x-full bg-white border-0 shadow-xl max-w-64 ease-nav-brand z-990 rounded-2xl xl:translate-x-0`}>

      <div class="h-19">
        <i class="absolute top-0 right-0 p-4 opacity-50 cursor-pointer fas fa-times text-slate-400 xl:hidden" ></i>
        <a class="block px-8 py-6 m-0 text-sm whitespace-nowrap  text-slate-700"  target="_blank">
        {(() => {
        if (user['role']==="Network Admin") {
          return (
            <img src={profile?.avatar} class="block mx-auto h-full max-w-full transition-all duration-200  ease-nav-brand max-h-16" alt="main_logo" />
          )
        } else if (user['role']==="Student") {
          return (
            <img src="https://res.cloudinary.com/ahbranding/image/upload/v1682359716/AHbranding/Start_Up_Early_Logo_vu73y8.png" class="block mx-auto h-full max-w-full transition-all duration-200  ease-nav-brand max-h-16" alt="main_logo" />
          )
        }
      })()}
          
        </a>
      </div>

 

      <hr class="h-px mt-8 bg-transparent bg-gradient-to-r from-transparent via-black/40 to-transparent " />

      <div class="items-center block w-auto  overflow-auto h-auto grow basis-full">
        <ul class="flex flex-col pl-0 mb-0">
        







        {(() => {
        if (user['role']==='Student') {
          return (
      
            <NavLink to={`/categories`} className={({ isActive, isPending }) => isPending ? "pending-link" : isActive ? "active" : "mb-6" }>
            <li className="mt-0.5 w-full">
            <a className={`py-2.7 text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap rounded-lg px-4 font-semibold text-slate-700 transition-colors gradient-animation ${lanArabic ? 'flex-row-reverse' : ''}`}>
            <div className="mr-2 flex 8 items-center justify-center rounded-lg bg-center stroke-0 text-white text-center xl:p-2.5">
                <FaPlus size={20} />
            </div>
            <span className="ml-1 duration-300 opacity-100 text-white pointer-events-none ease">{t('Add Activity')}</span>
        </a>
    </li>

              </NavLink>
   
          )
        }
      })()}

      {(() => {
        if (user['role']==='Student') {
          return (
      
            <NavLink to={`/student`} className={({ isActive, isPending }) => isPending ? "pending-link" : isActive ? "active-link" : "" }>
                <li className="mt-0.5 w-full">
                  <a className={`gap-2 py-2.7 text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap rounded-lg px-4 font-semibold text-slate-700 transition-colors ${lanArabic ? 'flex-row-reverse' : ''}`}>

                    <div className="mr-2 flex 8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                    <FaHouseChimney size={20}/>
                    </div>
                    <span className="ml-1 duration-300 opacity-100 pointer-events-none ease">{t('Dashboard')}</span>
                  </a>
                </li>
              </NavLink>
   
          )
        }
      })()}


      
      {(() => {
        if (user['role']==='Network Admin') {
          return (
      
            <NavLink to={`/network`} className={({ isActive, isPending }) => isPending ? "pending-link" : isActive ? "active-link" : "" }>
                <li className="mt-0.5 w-full">
                  <a className={`gap-2 py-2.7 text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap rounded-lg px-4 font-semibold text-slate-700 transition-colors ${lanArabic ? 'flex-row-reverse' : ''}`}>

                    <div className="mr-2 flex 8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                    <FaHouseChimney size={20}/>
                    </div>
                    <span className="ml-1 duration-300 opacity-100 pointer-events-none ease">{t('Dashboard')}</span>
                  </a>
                </li>
              </NavLink>
   
          )
        }
      })()}


      {(() => {
        if (user['role']==='Network Admin' && clubAccess) {
          return (
      
            <NavLink to={`/network_schools`} className={({ isActive, isPending }) => isPending ? "pending-link" : isActive ? "active-link" : "" }>
                <li className="mt-0.5 w-full">
                  <a className={`gap-2 py-2.7 text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap rounded-lg px-4 font-semibold text-slate-700 transition-colors ${lanArabic ? 'flex-row-reverse' : ''}`}>

                    <div className="mr-2 flex 8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                    <FaSchool size={20}/>
                    </div>
                    <span className="ml-1 duration-300 opacity-100 pointer-events-none ease">{t('Schools')}</span>
                  </a>
                </li>
              </NavLink>
   
          )
        }
      })()}


      {(() => {
        if (user['role']==='Network Admin' && clubAccess) {
          return (
      
            <NavLink to={`/network_staff`} className={({ isActive, isPending }) => isPending ? "pending-link" : isActive ? "active-link" : "" }>
                <li className="mt-0.5 w-full">
                  <a className={`gap-2 py-2.7 text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap rounded-lg px-4 font-semibold text-slate-700 transition-colors ${lanArabic ? 'flex-row-reverse' : ''}`}>

                    <div className="mr-2 flex 8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                    <RiAdminFill  size={20}/>
                    </div>
                    <span className="ml-1 duration-300 opacity-100 pointer-events-none ease">{t('Staff Members')}</span>
                  </a>
                </li>
              </NavLink>
   
          )
        }
      })()}

      {(() => {
        if (user['role']==='Network Admin') {
          return (
      
            <NavLink to={`/moderate_network`} className={({ isActive, isPending }) => isPending ? "pending-link" : isActive ? "active-link" : "" }>
                <li className="mt-0.5 w-full">
                  <a className={`gap-2 py-2.7 text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap rounded-lg px-4 font-semibold text-slate-700 transition-colors ${lanArabic ? 'flex-row-reverse' : ''}`}>

                    <div className="mr-2 flex 8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                    <FaCheckToSlot   size={20}/>
                    </div>
                    <span className="ml-1 duration-300 opacity-100 pointer-events-none ease">{t('Activities')}</span>
                  </a>
                </li>
              </NavLink>
   
          )
        }
      })()}


      


      {(() => {
        if (user['role']==='Network Admin') {
          return (
      
            <NavLink to={`/network_students`} className={({ isActive, isPending }) => isPending ? "pending-link" : isActive ? "active-link" : "" }>
                <li className="mt-0.5 w-full">
                  <a className={`gap-2 py-2.7 text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap rounded-lg px-4 font-semibold text-slate-700 transition-colors ${lanArabic ? 'flex-row-reverse' : ''}`}>

                    <div className="mr-2 flex 8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                    <FaUsers size={20}/>
                    </div>
                    <span className="ml-1 duration-300 opacity-100 pointer-events-none ease">{t('Students')}</span>
                  </a>
                </li>
              </NavLink>
   
          )
        }
      })()}




      {(() => {
        if (user['role']==='Network Admin' && clubAccess) {
          return (
      
            <NavLink to={`/clubs`} className={({ isActive, isPending }) => isPending ? "pending-link" : isActive ? "active-link" : "" }>
                <li className="mt-0.5 w-full">
                  <a className={`gap-2 py-2.7 text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap rounded-lg px-4 font-semibold text-slate-700 transition-colors ${lanArabic ? 'flex-row-reverse' : ''}`}>

                    <div className="mr-2 flex 8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                    <FaStarOfLife size={20}/>
                    </div>
                    <span className="ml-1 duration-300 opacity-100 pointer-events-none ease">{t('Student Clubs')}</span>
                  </a>
                </li>
              </NavLink>
   
          )
        }
      })()}


      {/* {(() => {
        if (user['role']==='Student') {
          return (
      
            <NavLink to={`/student_clubs`} className={({ isActive, isPending }) => isPending ? "pending-link" : isActive ? "active-link" : "" }>
                <li className="mt-0.5 w-full">
                  <a className={`gap-2 py-2.7 text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap rounded-lg px-4 font-semibold text-slate-700 transition-colors ${lanArabic ? 'flex-row-reverse' : ''}`}>

                    <div className="mr-2 flex 8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                    <RiTeamFill  size={20}/>
                    </div>
                    <span className="ml-1 duration-300 opacity-100 pointer-events-none ease">{t('Student Clubs')}</span>
                  </a>
                </li>
              </NavLink>
   
          )
        }
      })()} */}


      {(() => {
        if (user['role']==='Student') {
          return (
      
            <NavLink to={`/badges`} className={({ isActive, isPending }) => isPending ? "pending-link" : isActive ? "active-link" : "" }>
                <li className="mt-0.5 w-full">
                  <a className={`gap-2 py-2.7 text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap rounded-lg px-4 font-semibold text-slate-700 transition-colors ${lanArabic ? 'flex-row-reverse' : ''}`}>

                    <div className="mr-2 flex 8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                    <FaTrophy size={20}/>
                    </div>
                    <span className="ml-1 duration-300 opacity-100 pointer-events-none ease">{t('Badges')}</span>
                  </a>
                </li>
              </NavLink>
   
          )
        }
      })()}


      {shouldRenderQuizzesLink && (
        <NavLink to={`/challenges`} className={({ isActive, isPending }) => isPending ? "pending-link" : isActive ? "active-link" : "" }>
                <li className="mt-0.5 w-full">
                  <a className={`gap-2 py-2.7 text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap rounded-lg px-4 font-semibold text-slate-700 transition-colors ${lanArabic ? 'flex-row-reverse' : ''}`}>

                    <div className="mr-2 flex 8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                    <FaStopwatch size={20}/>
                    </div>
                    <span className="ml-1 duration-300 opacity-100 pointer-events-none ease">{t('Quizzes')}</span>
                  </a>
                </li>
              </NavLink>
      )}

      {shouldRenderNotificationsLink && (
        <NavLink to={`/notifications`} className={({ isActive, isPending }) => isPending ? "pending-link" : isActive ? "active-link" : "" }>
                <li className="mt-0.5 w-full">
                  <a className={`gap-2 py-2.7 text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap rounded-lg px-4 font-semibold text-slate-700 transition-colors ${lanArabic ? 'flex-row-reverse' : ''}`}>

                    <div className="mr-2 flex 8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                    <FaBell size={20}/>
                    </div>
                    <span className="ml-1 duration-300 opacity-100 pointer-events-none ease">{t('Content Library')}</span>
                  </a>
                </li>
              </NavLink>
      )}



      {(() => {
        if (profile?.restricted_to==='1') {
          return (
      
            <NavLink to={`/pathway`} className={({ isActive, isPending }) => isPending ? "pending-link" : isActive ? "active-link" : "" }>
                <li className="mt-0.5 w-full">
                  <a className={`gap-2 py-2.7 text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap rounded-lg px-4 font-semibold text-slate-700 transition-colors ${lanArabic ? 'flex-row-reverse' : ''}`}>

                    <div className="mr-2 flex  items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                    <FaBell size={20}/>
                    </div>
                    <span className="ml-1 duration-300 opacity-100 pointer-events-none ease">{t('Guided Pathway')}</span>
                  </a>
                </li>
              </NavLink>
   
          )
        }
      })()}


        </ul>
      </div>


      {/* {(() => {
        if (profile?.restricted_to==='0') {
          return (
      
            <NavLink to={`/badges`} className={({ isActive, isPending }) => isPending ? "pending-link" : isActive ? "active-link" : "" }>
                <li className="mt-0.5 w-full">
                  <a className={`py-2.7 text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap rounded-lg px-4 font-semibold text-slate-700 transition-colors ${lanArabic ? 'flex-row-reverse' : ''}`}>

                    <div className="mr-2 flex  items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                    <FaTrophy size={20}/>
                    </div>
                    <span className="ml-1 duration-300 opacity-100 pointer-events-none ease">Guided Pathways</span>
                  </a>
                </li>
              </NavLink>
   
          )
        }
      })()} */}


      {/* {(() => {
        if (user['role']==='Student') {
          return (
            <div class="mx-4 ">

<p class="invisible hidden text-gray-800 text-red-500 text-red-600 text-blue-500  bg-slate-500 bg-gray-500/30 bg-cyan-500/30 bg-emerald-500/30 bg-orange-500/30 bg-red-500/30 after:bg-gradient-to-tl after:from-zinc-800 after:to-zinc-700  after:from-blue-700 after:to-cyan-500 after:from-orange-500 after:to-yellow-500 after:from-green-600 after:to-lime-400 after:from-red-600 after:to-orange-600 after:from-slate-600 after:to-slate-300 text-emerald-500 text-cyan-500 text-slate-400"></p>
<div class="relative flex flex-col min-w-0 break-words bg-transparent border-0 shadow-none rounded-2xl bg-clip-border" sidenav-card>
  <img class="w-1/2 mx-auto" src="https://res.cloudinary.com/ahbranding/image/upload/v1698491940/AHbranding/icon-documentation_knmtut.svg" alt="sidebar illustrations" />
  <div class="flex-auto w-full p-4 pt-0 text-center">
    <div class="transition-all duration-200 ease-nav-brand">
      <h6 class="mb-0  text-slate-700">{t('Need help?')}</h6>
      <p class="mb-0 text-xs font-semibold leading-tight  ">{t('Please check our tutorials')}</p>
    </div>
  </div>
</div>

<a class="inline-block w-full px-8 py-2 text-xs font-bold leading-normal text-center text-white align-middle transition-all ease-in bg-blue-500 border-0 rounded-lg shadow-md select-none bg-150 bg-x-25 hover:shadow-xs hover:-translate-y-px" href="https://www.youtube.com/watch?v=KToW597rSvI&t=13s" target="_blank">{t('Video Tutorials')}</a>
</div>
          )
        } 
      })()} */}


    </aside>
      </div>


)
}

export default SideInner