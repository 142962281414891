import React, { useState } from "react";
import coCurricularEvents from "./coCurricularEvents";
import { useLanguage } from "./language";

const CoCurricularEventsAccordion = () => {
  const [openIndex, setOpenIndex] = useState(null);
  const { t, lanArabic } = useLanguage(); // Use the useLanguage hook to access translations and lanArabic

  return (
    <div className="grid grid-cols-2 md:grid-cols-3 gap-6">
      {coCurricularEvents.map((event, index) => (
        <div key={index} className="border rounded-lg overflow-hidden">
          {/* Accordion Header */}
          <div
            className="text-primary cursor-pointer p-4"
            onClick={() => setOpenIndex(openIndex === index ? null : index)}
          >
            {t(event.category)}
          </div>

          {/* Accordion Content */}
          {openIndex === index && (
            <div className="bg-fuchsia-200 p-4 space-y-2">
              <p>
                <strong>Description:</strong> {event.description}
              </p>
              <p>
                <strong>Timeline:</strong> {event.timeline}
              </p>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default CoCurricularEventsAccordion;
