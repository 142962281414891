import React, { useState, useEffect } from "react";
import Navbar from "../Static/Navbar";
import Hero from "./Hero";
import LandingStats from "./LandingStats";
import FeaturedNavs from "./FeaturedNavs";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import LogoCloud from "./LogoCloud";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import BlogFeaturesLanding from "./BlogFeaturesLanding";
import BottomCTA from "./BottomCTA";
import { useTranslation } from 'react-i18next';

function LandingPage() {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => setIsOpen(!isOpen);

  const { t } = useTranslation();
  const { i18n } = useTranslation();

  return (
    <div>
      <Navbar />
      <Hero/>
      <LandingStats/>
      <FeaturedNavs/>


<div className="bg-[#FCF9FF]">
<div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-16">
      <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">

        <h2 className="font-bold mb-3 text-3xl text-center lg:text-4xl text-primary darki:text-gray-200">
        {t('Why you need Start Up Early')}
        </h2>

        <p className="m-0 text-base  tracking-wide text-gray-500">{t('Schools face a myriad of challenges in extracurricular management our EMS tackles these head-on, transforming complexities into streamlined processes.')}</p>
      </div>



      <Tabs className={'justify-center'}>

<div className="mb-2">
  <div className="border-b border-gray-200 mx-auto">
    <TabList className="-mb-px flex gap-6 justify-center " aria-label="Tabs">
      <Tab className="shrink-0  border-b-2 border-transparent px-1 pb-4 text-sm  cursor-pointer font-semibold text-gray-500 hover:border-gray-300 hover:text-gray-700">
      {t('For School & Staff')}
      </Tab>
      <Tab className="shrink-0 border-b-2 border-transparent px-1 pb-4 text-sm font-semibold cursor-pointer text-gray-500 hover:border-gray-300 hover:text-gray-700">
      {t('For Student & Parents')}
      </Tab>
    </TabList>

  </div>
</div>

<TabPanel>
<div className="grid rounded-xl bg-white row-gap-12 mx-auto lg:grid-cols-4" style={{padding:'3.5rem 3rem 3rem', gap:'3rem'}}>
        <div className={`flex flex-col max-w-md sm:mx-auto border-r pe-12 ${i18n.language==='en'? 'items-start':'items-end'}`}>
          <div className="mr-4">
            <div className="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-indigo-50">
           

    <svg
      viewBox="0 0 38 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="w-6 h-6 text-deep-purple-accent-400"
    >
      <path
        d="M17 25.146L27.005 15.14l-2.523-2.524L17 20.1l-3.763-3.763-2.524 2.523L17 25.146zm1.86 9.65c-2.214 0-4.287-.42-6.22-1.26-1.934-.842-3.616-1.978-5.048-3.41-1.431-1.431-2.567-3.113-3.408-5.047-.842-1.933-1.262-4.006-1.262-6.22 0-2.213.42-4.287 1.262-6.22.84-1.933 1.977-3.615 3.408-5.047 1.432-1.431 3.114-2.567 5.047-3.408 1.934-.842 4.007-1.262 6.22-1.262 2.214 0 4.287.42 6.22 1.262 1.934.84 3.616 1.977 5.047 3.408 1.432 1.432 2.568 3.114 3.41 5.047.84 1.934 1.26 4.007 1.26 6.22 0 2.214-.42 4.287-1.26 6.22-.842 1.934-1.978 3.616-3.41 5.047-1.431 1.432-3.113 2.568-5.047 3.41-1.933.84-4.006 1.26-6.22 1.26zM7.525 0l2.48 2.48-7.527 7.525L0 7.526 7.526 0zm22.667 0l7.526 7.526-2.48 2.48-7.525-7.527L30.192 0z"
        fill="#4F0696"
      />
    </svg>
            </div>
          </div>
          <div className={`${i18n.language==='en'? 'text-start':'text-end'}`}>
            <h6 className="mb-3 text-xl font-bold leading-5">{t('Simplify with Automation')}</h6>
            <p className="mb-3 text-sm text-gray-900">
            {t('Automate and simplify the administration of extracurricular activities, freeing up valuable resources.')}
            </p>
            
          </div>
        </div>
        <div className={`flex flex-col max-w-md sm:mx-auto border-r pe-12 ${i18n.language==='en'? 'items-start':'items-end'}`}>
          <div className="mr-4">
            <div className="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-indigo-50">
            <svg
      className="w-6 h-6 text-deep-purple-accent-400"
      viewBox="0 0 34 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.05 28.9L17 24.438l5.844 4.462-2.232-7.225L26.563 17h-7.225L17 9.775 14.662 17H7.438l5.844 4.675L11.05 28.9zM17 42.5c-4.923-1.24-8.987-4.064-12.192-8.473C1.603 29.617 0 24.72 0 19.337V6.375L17 0l17 6.375v12.962c0 5.384-1.603 10.28-4.808 14.69C25.987 38.436 21.922 41.26 17 42.5z"
        fill="#4F0696"
      />
    </svg>
            </div>
          </div>
          <div className={`${i18n.language==='en'? 'text-start':'text-end'}`}>
            <h6 className="mb-3 text-xl font-bold leading-5">
            {t('Accurate Data-Driven Decisions')}
            </h6>
            <p className="mb-3 text-sm text-gray-900">
            {t('Access to analytics and reporting for informed decision-making and tailored extracurricular offerings.')} 
            </p>
            
          </div>
        </div>
        <div className={`flex flex-col max-w-md sm:mx-auto border-r pe-12 ${i18n.language==='en'? 'items-start':'items-end'}`}>
          <div className="mr-4">
            <div className="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-indigo-50">
      
    <svg
      viewBox="0 0 38 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="w-6 h-6 text-deep-purple-accent-400"
    >
      <path
        d="M27.182 31.875l-6.242-6.287 2.48-2.479 3.762 3.763 7.526-7.526 2.48 2.524-10.006 10.005zM0 35.417V3.542c0-.974.347-1.808 1.04-2.502C1.734.347 2.568 0 3.542 0h28.333c.974 0 1.808.347 2.501 1.04.694.694 1.04 1.528 1.04 2.502v12.396H17.709v12.395H7.083L0 35.417z"
        fill="#4F0696"
      />
    </svg>
            </div>
          </div>
          <div className={`${i18n.language==='en'? 'text-start':'text-end'}`}>
            <h6 className="mb-3 text-xl font-bold leading-5">
            {t('Enhanced Communication')}
            </h6>
            <p className="mb-3 text-sm text-gray-900">
            {t('Simplified communication channels fostering a collaborative environment.')}</p>
            
          </div>
        </div>
        <div className="flex flex-col max-w-md sm:mx-auto ">
          <div className={`mr-4 flex ${i18n.language==='en'?'justify-start' : 'justify-end'}`}>
            <div className="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-indigo-50">
            <svg
      className="w-6 h-6 text-deep-purple-accent-400"
      viewBox="0 0 31 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.813 37.188a5.214 5.214 0 01-2.325-.532 5.171 5.171 0 01-1.837-1.505L0 22.888l.841-.885a3.558 3.558 0 012.125-1.107 3.328 3.328 0 012.302.487l3.276 1.992V8.855c0-.503.17-.923.51-1.263.339-.339.76-.509 1.261-.509.502 0 .93.17 1.284.51.354.339.531.76.531 1.261v8.854h13.237c1.476 0 2.73.517 3.763 1.55s1.55 2.287 1.55 3.763v7.083c0 1.948-.694 3.616-2.081 5.003s-3.055 2.08-5.003 2.08h-9.784zM2.655 13.28a8.74 8.74 0 01-1.195-4.427c0-2.45.863-4.538 2.59-6.264C5.777.863 7.865 0 10.315 0s4.538.863 6.264 2.59c1.727 1.726 2.59 3.815 2.59 6.264a8.74 8.74 0 01-1.195 4.427l-3.055-1.77c.236-.414.413-.834.531-1.262.119-.428.178-.893.178-1.395 0-1.476-.517-2.73-1.55-3.763s-2.287-1.55-3.763-1.55-2.73.517-3.763 1.55-1.55 2.287-1.55 3.763c0 .502.06.967.178 1.395.118.428.295.848.53 1.261l-3.054 1.771z"
        fill="#4F0696"
      />
    </svg>
            </div>
          </div>
          <div className={`${i18n.language==='en'? 'text-start':'text-end'}`}>
            <h6 className="mb-3 text-xl font-bold leading-5">
            {t('Personalize the Experience')}
            </h6>
            <p className="mb-3 text-sm text-gray-900">
            {t('Our tailored recommendations ensure every student finds activities that match their interests.')}</p>
            
          </div>
        </div>
      </div>
</TabPanel>


<TabPanel>
<div className="grid rounded-xl bg-white row-gap-12 mx-auto lg:grid-cols-4" style={{padding:'3.5rem 3rem 3rem', gap:'3rem'}}>
        <div className={`flex flex-col max-w-md sm:mx-auto border-r pe-12 ${i18n.language==='en'? 'items-start':'items-end'}`}>
          <div className="mr-4">
            <div className="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-indigo-50">
           

    <svg
      className="w-6 h-6 text-deep-purple-accent-400"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"

    >
      <mask
        id="a"
        style={{
          maskType: "alpha"
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={42}
        height={42}
      >
        <path fill="#D9D9D9" d="M0 0H42V42H0z" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M21 7c-.962 0-1.787-.343-2.472-1.028-.685-.686-1.028-1.51-1.028-2.472s.343-1.786 1.028-2.472C19.214.343 20.038 0 21 0s1.787.343 2.472 1.028c.685.686 1.028 1.51 1.028 2.472 0 .963-.343 1.786-1.028 2.472C22.786 6.657 21.962 7 21 7zm-5.25 26.25v-21a70.077 70.077 0 01-5.338-.656A51.898 51.898 0 015.25 10.5L6.125 7c2.275.612 4.696 1.057 7.262 1.334A70.89 70.89 0 0021 8.75a70.89 70.89 0 007.613-.416C31.178 8.057 33.6 7.613 35.874 7l.875 3.5a51.898 51.898 0 01-5.163 1.094c-1.808.291-3.587.51-5.337.656v21h-3.5v-10.5h-3.5v10.5h-3.5zM14 42c-.496 0-.912-.168-1.247-.503a1.693 1.693 0 01-.503-1.247c0-.496.168-.911.503-1.247A1.693 1.693 0 0114 38.5c.496 0 .912.168 1.247.503.335.336.503.751.503 1.247 0 .496-.168.911-.503 1.247A1.693 1.693 0 0114 42zm7 0c-.496 0-.912-.168-1.247-.503a1.693 1.693 0 01-.503-1.247c0-.496.168-.911.503-1.247A1.693 1.693 0 0121 38.5c.496 0 .912.168 1.247.503.335.336.503.751.503 1.247 0 .496-.168.911-.503 1.247A1.693 1.693 0 0121 42zm7 0c-.496 0-.912-.168-1.247-.503a1.693 1.693 0 01-.503-1.247c0-.496.168-.911.503-1.247A1.693 1.693 0 0128 38.5c.496 0 .912.168 1.247.503.335.336.503.751.503 1.247 0 .496-.168.911-.503 1.247A1.693 1.693 0 0128 42z"
          fill="#4F0696"
        />
      </g>
    </svg>
            </div>
          </div>
          <div className={`${i18n.language==='en'? 'text-start':'text-end'}`}>
            <h6 className="mb-3 text-xl font-bold leading-5">{t('Engagement & Personal Growth')}</h6>
            <p className="mb-3 text-sm text-gray-900">
            {t('Less time spent on manual tasks means more time for student interaction and support.')}
            </p>
            
          </div>
        </div>
        <div className={`flex flex-col max-w-md sm:mx-auto border-r pe-12 ${i18n.language==='en'? 'items-start':'items-end'}`}>
          <div className="mr-4">
            <div className="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-indigo-50">
            <svg
className="w-6 h-6 text-deep-purple-accent-400"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="a"
        style={{
          maskType: "alpha"
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={42}
        height={42}
      >
        <path fill="#D9D9D9" d="M0 0H42V42H0z" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M29.137 22.75l-9.887-9.887 9.887-9.888 9.888 9.888-9.887 9.887zM5.25 19.25v-14h14v14h-14zm17.5 17.5v-14h14v14h-14zm-17.5 0v-14h14v14h-14z"
          fill="#4F0696"
        />
      </g>
    </svg>

    
            </div>
          </div>
          <div className={`${i18n.language==='en'? 'text-start':'text-end'}`}>
            <h6 className="mb-3 text-xl font-bold leading-5">
            {t('Student Skill Development')}
            </h6>
            <p className="mb-3 text-sm text-gray-900">
            {t('Opportunities to develop essential life skills such as leadership, teamwork, and time management.')}
            </p>
            
          </div>
        </div>
        <div className={`flex flex-col max-w-md sm:mx-auto border-r pe-12 ${i18n.language==='en'? 'items-start':'items-end'}`}>
          <div className="mr-4">
            <div className="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-indigo-50">

            <svg
className="w-6 h-6 text-deep-purple-accent-400"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="a"
        style={{
          maskType: "alpha"
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={42}
        height={42}
      >
        <path fill="#D9D9D9" d="M0 0H42V42H0z" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M12.25 36.75v-3.5h7v-5.425a9.395 9.395 0 01-3.828-1.816 7.84 7.84 0 01-2.472-3.346c-2.188-.263-4.018-1.218-5.49-2.866-1.474-1.648-2.21-3.58-2.21-5.797v-1.75c0-.963.343-1.787 1.028-2.472.686-.685 1.51-1.028 2.472-1.028h3.5v-3.5h17.5v3.5h3.5c.962 0 1.786.343 2.472 1.028.685.685 1.028 1.51 1.028 2.472V14c0 2.217-.736 4.149-2.21 5.797-1.472 1.648-3.302 2.603-5.49 2.866a7.84 7.84 0 01-2.472 3.346 9.395 9.395 0 01-3.828 1.816v5.425h7v3.5h-17.5zm0-17.85v-6.65h-3.5V14a5 5 0 00.963 2.997A5.256 5.256 0 0012.25 18.9zm17.5 0a5.256 5.256 0 002.538-1.903A5 5 0 0033.25 14v-1.75h-3.5v6.65z"
          fill="#4F0696"
        />
      </g>
    </svg>
            </div>
          </div>
          <div className={`${i18n.language==='en'? 'text-start':'text-end'}`}>
            <h6 className="mb-3 text-xl font-bold leading-5">
            {t('Recognition & Motivation')}
            </h6>
            <p className="mb-3 text-sm text-gray-900">
            {t('Gamified achievements and a personalized extracurricular report that tracks and showcases accomplishments.')}</p>
            
          </div>
        </div>
        <div className="flex flex-col max-w-md sm:mx-auto ">
          <div className="mr-4">
            <div className="flex items-center justify-center w-12 h-12 mb-4 rounded-full bg-indigo-50">


    <svg
className="w-6 h-6 text-deep-purple-accent-400"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="a"
        style={{
          maskType: "alpha"
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={42}
        height={42}
      >
        <path fill="#D9D9D9" d="M0 0H42V42H0z" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M21 38.5c-.962 0-1.787-.343-2.472-1.028-.685-.686-1.028-1.51-1.028-2.472h7c0 .962-.343 1.786-1.028 2.472-.686.685-1.51 1.028-2.472 1.028zM7 33.25v-3.5h3.5V17.5c0-2.42.73-4.572 2.188-6.453 1.458-1.881 3.354-3.114 5.687-3.697V6.125c0-.73.255-1.349.766-1.86.51-.51 1.13-.765 1.859-.765.73 0 1.349.255 1.86.766.51.51.765 1.13.765 1.859v.569a8.111 8.111 0 00-.656 1.837 8.851 8.851 0 00-.219 1.969c0 2.42.853 4.484 2.56 6.19 1.706 1.707 3.77 2.56 6.19 2.56v10.5H35v3.5H7zm24.5-17.5c-1.458 0-2.698-.51-3.719-1.531-1.02-1.021-1.531-2.26-1.531-3.719 0-1.458.51-2.698 1.531-3.719 1.021-1.02 2.26-1.531 3.719-1.531 1.458 0 2.698.51 3.719 1.531 1.02 1.021 1.531 2.26 1.531 3.719 0 1.458-.51 2.698-1.531 3.719-1.021 1.02-2.26 1.531-3.719 1.531z"
          fill="#4F0696"
        />
      </g>
    </svg>

            </div>
          </div>
          <div className={`${i18n.language==='en'? 'text-start':'text-end'}`}>
            <h6 className="mb-3 text-xl font-bold leading-5">
            {t('Full Informed Involvement')}
            </h6>
            <p className="mb-3 text-sm text-gray-900">
            {t("Stay updated with real-time notifications and detailed insights into children's extracurricular activities.")}</p>
            
          </div>
        </div>
      </div>
</TabPanel>

</Tabs>

      
    </div>
</div>








<>
  {/* Testimonials */}
  <div className="relative max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
    {/* Blockquote */}
    <blockquote className="text-center lg:mx-auto lg:w-4/5">
      <div>
      <img class="hidden inline-block size-[62px] rounded-full w-[6rem] h-auto" src="https://static.vecteezy.com/system/resources/previews/036/280/651/non_2x/default-avatar-profile-icon-social-media-user-image-gray-avatar-icon-blank-profile-silhouette-illustration-vector.jpg" alt="Image Description"/>
      </div>
      <div className="mt-6 lg:mt-10">
        <p className="relative text-xl sm:text-2xl md:text-2xl md:leading-normal font-medium text-gray-800">
          <svg
            className="absolute top-0 start-0 transform -translate-x-8 -translate-y-8 size-16 text-gray-200 sm:h-24 sm:w-24 darki:text-gray-700"
            width={16}
            height={13}
            viewBox="0 0 16 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <path
              d="M7.18079 9.25611C7.18079 10.0101 6.93759 10.6211 6.45119 11.0891C5.96479 11.5311 5.35039 11.7521 4.60799 11.7521C3.71199 11.7521 2.96958 11.4531 2.38078 10.8551C1.81758 10.2571 1.53598 9.39911 1.53598 8.28111C1.53598 7.08511 1.86878 5.91511 2.53438 4.77111C3.22559 3.60111 4.18559 2.67811 5.41439 2.00211L6.29759 3.36711C5.63199 3.83511 5.09439 4.35511 4.68479 4.92711C4.30079 5.49911 4.04479 6.16211 3.91679 6.91611C4.14719 6.81211 4.41599 6.76011 4.72319 6.76011C5.43999 6.76011 6.02879 6.99411 6.48959 7.46211C6.95039 7.93011 7.18079 8.52811 7.18079 9.25611ZM14.2464 9.25611C14.2464 10.0101 14.0032 10.6211 13.5168 11.0891C13.0304 11.5311 12.416 11.7521 11.6736 11.7521C10.7776 11.7521 10.0352 11.4531 9.44639 10.8551C8.88319 10.2571 8.60159 9.39911 8.60159 8.28111C8.60159 7.08511 8.93439 5.91511 9.59999 4.77111C10.2912 3.60111 11.2512 2.67811 12.48 2.00211L13.3632 3.36711C12.6976 3.83511 12.16 4.35511 11.7504 4.92711C11.3664 5.49911 11.1104 6.16211 10.9824 6.91611C11.2128 6.81211 11.4816 6.76011 11.7888 6.76011C12.5056 6.76011 13.0944 6.99411 13.5552 7.46211C14.016 7.93011 14.2464 8.52811 14.2464 9.25611Z"
              fill="currentColor"
            />
          </svg>
          <span className="relative z-10 italic text-gray-800 darki:text-gray-200">
          {t("Start Up Early's platform transformed our approach to education. The products not only bridge academic rigor with co-curricular growth but have made a tangible impact on our student engagement and success.")}
          </span>
        </p>
      </div>
      <footer className="mt-6">
        <div className="font-semibold text-gray-800 darki:text-gray-200">
          {t('Ashar Javaid')}
        </div>
        <div className="text-sm text-gray-500">{t('Advisor Communication at LUMS')}</div>
      </footer>
    </blockquote>
    {/* End Blockquote */}
  </div>
  {/* End Testimonials */}


        <LogoCloud/>

</>


{/* <div>

        <BlogFeaturesLanding/>

        
</div> */}


<BottomCTA/>

<Footer/>


    </div>
  );
}

export default LandingPage;
