import React, { useEffect, useState, useContext } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import AuthContext from '../context/AuthContext'

const MobileBottom = () => {
  let { user } = useContext(AuthContext)
  let location = useLocation()

  return (
    <div className="bg-gray-100 mobileTab flex md:hidden">
      <div className="fixed bottom-0 left-0 right-0 bg-white border-t shadow bg-primary mobileBottom text-white pb-4">
        <div className="flex justify-around p-2 mobileBottom-inner">
          <NavLink
            to={`/student`}
            href="#"
            className={`flex flex-col w-1/5 text-center py-2 ${
              location.pathname === `/student/${user?.user_id}`
                ? 'mobBtm_active'
                : 'text-white hover:mobBtm_active'
            }`}
          >
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8 mx-auto mb-1">
  <path d="M21.721 12.752a9.711 9.711 0 00-.945-5.003 12.754 12.754 0 01-4.339 2.708 18.991 18.991 0 01-.214 4.772 17.165 17.165 0 005.498-2.477zM14.634 15.55a17.324 17.324 0 00.332-4.647c-.952.227-1.945.347-2.966.347-1.021 0-2.014-.12-2.966-.347a17.515 17.515 0 00.332 4.647 17.385 17.385 0 005.268 0zM9.772 17.119a18.963 18.963 0 004.456 0A17.182 17.182 0 0112 21.724a17.18 17.18 0 01-2.228-4.605zM7.777 15.23a18.87 18.87 0 01-.214-4.774 12.753 12.753 0 01-4.34-2.708 9.711 9.711 0 00-.944 5.004 17.165 17.165 0 005.498 2.477zM21.356 14.752a9.765 9.765 0 01-7.478 6.817 18.64 18.64 0 001.988-4.718 18.627 18.627 0 005.49-2.098zM2.644 14.752c1.682.971 3.53 1.688 5.49 2.099a18.64 18.64 0 001.988 4.718 9.765 9.765 0 01-7.478-6.816zM13.878 2.43a9.755 9.755 0 016.116 3.986 11.267 11.267 0 01-3.746 2.504 18.63 18.63 0 00-2.37-6.49zM12 2.276a17.152 17.152 0 012.805 7.121c-.897.23-1.837.353-2.805.353-.968 0-1.908-.122-2.805-.353A17.151 17.151 0 0112 2.276zM10.122 2.43a18.629 18.629 0 00-2.37 6.49 11.266 11.266 0 01-3.746-2.504 9.754 9.754 0 016.116-3.985z" />
</svg>
            <span className='text-sm'>Profile</span>
          </NavLink>

          <NavLink
            to={`/badges`}
            href="#"
            className={`flex flex-col w-1/5 text-center py-2 ${
              location.pathname === `/chests/${user?.user_id}`
                ? 'mobBtm_active'
                : 'text-white hover:mobBtm_active'
            }`}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8 mx-auto mb-1">
  <path fill-rule="evenodd" d="M5.166 2.621v.858c-1.035.148-2.059.33-3.071.543a.75.75 0 00-.584.859 6.753 6.753 0 006.138 5.6 6.73 6.73 0 002.743 1.346A6.707 6.707 0 019.279 15H8.54c-1.036 0-1.875.84-1.875 1.875V19.5h-.75a2.25 2.25 0 00-2.25 2.25c0 .414.336.75.75.75h15a.75.75 0 00.75-.75 2.25 2.25 0 00-2.25-2.25h-.75v-2.625c0-1.036-.84-1.875-1.875-1.875h-.739a6.706 6.706 0 01-1.112-3.173 6.73 6.73 0 002.743-1.347 6.753 6.753 0 006.139-5.6.75.75 0 00-.585-.858 47.077 47.077 0 00-3.07-.543V2.62a.75.75 0 00-.658-.744 49.22 49.22 0 00-6.093-.377c-2.063 0-4.096.128-6.093.377a.75.75 0 00-.657.744zm0 2.629c0 1.196.312 2.32.857 3.294A5.266 5.266 0 013.16 5.337a45.6 45.6 0 012.006-.343v.256zm13.5 0v-.256c.674.1 1.343.214 2.006.343a5.265 5.265 0 01-2.863 3.207 6.72 6.72 0 00.857-3.294z" clip-rule="evenodd" />
</svg>
            <span className='text-sm'>Badge</span>
          </NavLink>

          <NavLink
            to="/cats"
            className="block w-1/5 text-center py-2"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-12 h-12 p-1 bg-amber-400 mx-auto mb-1 rounded-full shadow-xl">
  <path fill-rule="evenodd" d="M12 3.75a.75.75 0 01.75.75v6.75h6.75a.75.75 0 010 1.5h-6.75v6.75a.75.75 0 01-1.5 0v-6.75H4.5a.75.75 0 010-1.5h6.75V4.5a.75.75 0 01.75-.75z" clip-rule="evenodd" />
</svg>
          </NavLink>

          <NavLink
            to={`/categories/${user?.user_id}`}
            href="#"
            className={`block hidden w-1/5 text-center py-2 ${
              location.pathname === `/activity/${user?.user_id}`
                ? 'mobBtm_active'
                : 'text-white hover:mobBtm_active'
            }`}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8 mx-auto mb-1">
  <path d="M6 3a3 3 0 00-3 3v2.25a3 3 0 003 3h2.25a3 3 0 003-3V6a3 3 0 00-3-3H6zM15.75 3a3 3 0 00-3 3v2.25a3 3 0 003 3H18a3 3 0 003-3V6a3 3 0 00-3-3h-2.25zM6 12.75a3 3 0 00-3 3V18a3 3 0 003 3h2.25a3 3 0 003-3v-2.25a3 3 0 00-3-3H6zM17.625 13.5a.75.75 0 00-1.5 0v2.625H13.5a.75.75 0 000 1.5h2.625v2.625a.75.75 0 001.5 0v-2.625h2.625a.75.75 0 000-1.5h-2.625V13.5z" />
</svg>

            <span className='text-sm'>Progress</span>
          </NavLink>



{/* 
          <NavLink
            to={`/notifications`}
            href="#"
            className={`block  w-1/5 text-center py-2 ${
              location.pathname === `/notifications`
                ? 'mobBtm_active'
                : 'text-white hover:mobBtm_active'
            }`}
          > */}

<NavLink
            to={`/notifications`}
            href="#"
            className={`flex flex-col w-1/5 text-center py-2 ${
              location.pathname === `/notifications/${user?.user_id}`
                ? 'mobBtm_active'
                : 'text-white hover:mobBtm_active'
            }`}
          >



          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8 mx-auto mb-1">
            <path fill-rule="evenodd" d="M6.32 2.577a49.255 49.255 0 0111.36 0c1.497.174 2.57 1.46 2.57 2.93V21a.75.75 0 01-1.085.67L12 18.089l-7.165 3.583A.75.75 0 013.75 21V5.507c0-1.47 1.073-2.756 2.57-2.93z" clip-rule="evenodd" />
          </svg>


            <span className='text-sm'>Library</span>
          </NavLink>

          <NavLink
            to={`/sidenav`}
            href="#"
            className={`flex flex-col w-1/5 text-center py-2 ${
              location.pathname === `/sidenav/${user?.user_id}`
                ? 'mobBtm_active'
                : 'text-white hover:mobBtm_active'
            }`}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8 mx-auto mb-1">
  <path fill-rule="evenodd" d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z" clip-rule="evenodd" />
</svg>

            <span className='text-sm'>More</span>
          </NavLink>

        </div>
      </div>
    </div>
  )
}

export default MobileBottom
