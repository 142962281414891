// src/components/ClubCard.js
import React from 'react';
import { Link } from 'react-router-dom';

const ClubCard = ({ title, category, img, content, imageUrl }) => {

    const truncatedCategory = content.length > 20 ? content.slice(0, 20) + '...' : content;

    
  return (
 
    <div className="!z-5 relative flex flex-col rounded-[20px] max-w-[300px] bg-white bg-clip-border shadow-3xl shadow-shadow-500 flex flex-col w-full !p-4 3xl:p-![18px] bg-white undefined">
    <div className="h-full w-full">
      <div className="relative w-full">
        <img
          src={img}
          className="mb-3 img-fit h-52 w-full rounded-xl 3xl:h-full 3xl:w-full"
          alt=""
        />
      </div>
      <div className="mb-3 flex items-center justify-between px-1 md:items-start">
        <div className="mb-2">
          <p className="text-lg font-bold text-navy-700"> {title} </p>
          <p className="text-sm font-medium text-gray-600 m-0">
          {truncatedCategory}
          </p>
        </div>
      </div>
      <div className="flex items-center justify-between md:items-center lg:justify-between ">
        <Link to={'/club_timeline'} className="btn btn-sm btn-ghost text-primary"> View Details </Link>
      </div>
    </div>
  </div>
  
  );
};

export default ClubCard;
