import React, { useState, useEffect } from 'react';

function FeaturesRight({title, desc, img}) {


    return (
        <div>
            <>
  {/* Features */}
  <div className="max-w-[85rem] px-4 py-6 sm:px-6 lg:px-8 mx-auto">
    {/* Grid */}
    <div className="md:grid md:grid-cols-2 md:items-center md:gap-12 xl:gap-32">
      
      {/* End Col */}
      <div className="mt-5 sm:mt-10 lg:mt-0">
        <div className="space-y-6 sm:space-y-8">
          {/* Title */}
          <div className="space-y-2 md:space-y-4 text-center md:text-start">
            <h2 className="font-bold text-3xl text-gray-800 darki:text-gray-200">
              {title}
            </h2>
            <p className="text-gray-500 text-base">
              {desc}
            </p>
          </div>
          {/* End Title */}
          {/* List */}
          
          {/* End List */}
        </div>
      </div>
      {/* End Col */}

      <div>
        <img
          className="rounded-xl  w-3/4 mx-auto"
src={img}          alt="Image Description"
        />
      </div>
    </div>
    {/* End Grid */}
  </div>
  {/* End Features */}
</>

        </div>
    )
}

export default FeaturesRight;