// src/components/BlogPagination.js
import React from 'react';

const ClubPagination = ({ currentPage, totalPages, onPageChange }) => {
  return (
    <div className="flex justify-center gap-6 items-center mt-4 bg-white p-2 rounded-xl">
      <button
        className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-full border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none darki:bg-slate-900 darki:border-gray-700 darki:text-white darki:hover:bg-gray-800 darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
        disabled={currentPage === 1}
        onClick={() => onPageChange(currentPage - 1)}
      >
        Previous
      </button>
      <span className="text-gray-700">{` ${currentPage} of ${totalPages}`}</span>
      <button
        className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-full border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none darki:bg-slate-900 darki:border-gray-700 darki:text-white darki:hover:bg-gray-800 darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
        disabled={currentPage === totalPages}
        onClick={() => onPageChange(currentPage + 1)}
      >
        Next
      </button>
    </div>
  );
};

export default ClubPagination;
