import React, { useEffect,useState } from "react";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import SideInnerSM from "../components/SideInnerSM";
import Sidebar from "../components/Sidebar";
import { useLanguage } from "../components/language";
import Navbar from "../components/Navbar";
import { MultiSelect } from "react-multi-select-component";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Toggle from 'react-toggle'
import "react-toggle/style.css" 



const AddEvent = () => {
const [isSidebarVisible, setIsSidebarVisible] = useState(false);
const [selected, setSelected] = useState([]);
const [startDate, setStartDate] = useState(new Date());
const { t, changeLanguage, lanArabic } = useLanguage();
const [toggle, setToggle] = useState(true)

      let {user} = useContext(AuthContext);
let profileID = user['user_id'];
const [profile, setProfile] = useState();
const [loading, setLoading] = useState(true);


const options = [
  { label: "Grapes 🍇", value: "grapes" },
  { label: "Mango 🥭", value: "mango" },
  { label: "Strawberry 🍓", value: "strawberry", },
];


const toggleSidebar = () => {
    setIsSidebarVisible(prevState => !prevState);
  }
useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_KEY}/api/network_profile/${profileID}`);
      let data = await response.json();
      setProfile(data);
      setLoading(false);
    } catch (err) {
      //console.log("The requested profile does not exist.");
      setLoading(false); // Set loading to false even on error to stop showing the loader
    }
  };

  // Simulate the loader by setting a timer for 3 seconds
  const timer = setTimeout(() => {
    fetchData();
  }, 900);

  // Clean up the timer when the component unmounts
  return () => clearTimeout(timer);
}, [profileID]);
  return (
    <div>


    <div>
      <>
    
    <div class="hidden md:block absolute w-full bg-lilac h-96  min-h-75"></div>
    
    
    <Sidebar />
    
    {isSidebarVisible && <SideInnerSM/>}
    
        <main className={`relative h-full max-h-screen transition-all duration-200 ease-in-out ${lanArabic ? 'xl:mr-68' : 'xl:ml-68'} rounded-xl`}>
          {/* Navbar */}
          <Navbar toggleSidebar={toggleSidebar} />
    
          {/* end Navbar */}
          {/* cards */}
          <div className="w-full px-6 py-6 mx-auto">
    
    
    
               <div className="bg-[#f9f3ff] rounded-lg p-14 flex flex-col ">
                    
                    <div className="flex flex-col w-2/3 mx-auto bg-white px-8 py-7 rounded-lg ">
                      <h4>Add Event</h4>
                      <div className="flex items-center gap-10">
                      <div className="flex flex-col w-2/4 ">
                    <label  className="inline-block text-sm font-medium text-gray-800 mt-2.5 darki:text-gray-200">
                    Event name
                   </label>

                 <input type="text" className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none darki:bg-slate-900 darki:border-gray-700 darki:text-gray-400 darki:focus:ring-gray-600" placeholder="Enter project name"/>
                     </div>
             <div className="flex flex-col w-2/4 ">
             <label  className="inline-block text-sm font-medium text-gray-800 mt-2.5 darki:text-gray-200">
             Faculty Advisor(s)
                   </label>
             <MultiSelect
             className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none darki:bg-slate-900 darki:border-gray-700 darki:text-gray-400 darki:focus:ring-gray-600"
        options={options}
        value={selected}
        onChange={setSelected}
        labelledBy="Select"
      />

             </div>
                      </div>
                      <div className="flex flex-col w-full gap-1 ">
                      <label  className="inline-block text-sm font-medium text-gray-800 mt-2.5 darki:text-gray-200">
                      Description
                   </label>
                   <textarea rows={7} className="w-full p-4 border-2 border-grey-500 rounded-lg ">Welcome to Pinnacle Strikers FC – where passion meets the pitch! Our football club is a dynamic community within Jefferson High School, dedicated to fostering a love for the beautiful game and creating a space for students to come together, learn, and excel in the sport. Whether you're a seasoned player or a beginner eager to kick off your football journey, Pinnacle Strikers FC is the place for you.</textarea>
                      </div>

                      <div className="flex flex-col gap-1 w-full pt-4 ">
                      <label  className="inline-block text-sm font-medium text-gray-800 mt-2.5 darki:text-gray-200">
                      Activities
                   </label>
                   <textarea rows={8} className="w-full p-4  rounded-lg border-2 border-grey-500 ">
                   Our mission is to instill a deep passion for football, promote inclusivity and diversity, and provide quality coaching and training sessions aimed at enhancing the skills of our players. We prioritize teamwork, fair play, and sportsmanship, both within the club and during inter-school competitions. Beyond the pitch, we actively engage with the school and local community through outreach programs, charity events, and football clinics, contributing positively to our surroundings. Joining Pinnacle Strikers FC means striving for competitive excellence, fostering leadership skills, and ensuring that every member experiences the sheer joy of playing football
                    </textarea>
                      </div>
                      
                   

                      <div className="flex items-center gap-10">
                      <div className="flex flex-col gap-1  w-2/4 pt-4 ">
                      <label  className="inline-block text-sm font-medium text-gray-800 mt-2.5 darki:text-gray-200">
                     Date & Time
                     </label>

                     <DatePicker style={{width:"100%"}} selected={startDate} onChange={(date) => setStartDate(date)} />
                      </div>
                      
                      </div>
                      <div className="py-6 flex items-center text-sm text-gray-800 before:flex-[1_1_0%] before:border-t before:border-gray-200 before:me-6 after:flex-[1_1_0%] after:border-t after:border-gray-200 after:ms-6 darki:text-white darki:before:border-gray-600 darki:after:border-gray-600">Constraints and Criterias</div>


               


                <div className="w-full flex items-center gap-10 pt-4"> 
        
                <div className="flex flex-col gap-4 w-2/4 ">
                    <label  className="inline-block text-sm font-medium text-black mt-2.5 darki:text-gray-200">
                    Location
                   </label>

                   <select class="py-2 px-3 pe-9 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none darki:bg-slate-900 darki:border-gray-700 darki:text-gray-400 darki:focus:ring-gray-600">
  <option selected>Main FootBall Ground</option>
  <option>1</option>
  <option>2</option>
  <option>3</option>
</select>



                     </div>
                <div className="flex flex-col w-2/4  ">
                <div className="flex flex-col gap-4 ">
                    <label  className="inline-block text-sm font-medium text-black mt-2.5 darki:text-gray-200">
                    Type
                   </label>
                   <select class="py-2 px-3 pe-9 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none darki:bg-slate-900 darki:border-gray-700 darki:text-gray-400 darki:focus:ring-gray-600">
  <option selected>Onsite Event</option>
  <option>1</option>
  <option>2</option>
  <option>3</option>
</select>
                     </div>
                     </div>

              
                     
                </div>
                      
                    
              <div className="py-6 flex items-center text-sm text-gray-800 before:flex-[1_1_0%] before:border-t before:border-gray-200 before:me-6 after:flex-[1_1_0%] after:border-t after:border-gray-200 after:ms-6 darki:text-white darki:before:border-gray-600 darki:after:border-gray-600">Budget</div>




              <div className="flex items-center gap-10">
                      <div className="flex flex-col w-2/4 ">
                    <label  className="inline-block text-sm font-medium text-gray-800 mt-2.5 darki:text-gray-200">
                    Event Participation Fee 
                   </label>

                 <input type="text" className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none darki:bg-slate-900 darki:border-gray-700 darki:text-gray-400 darki:focus:ring-gray-600" placeholder="Enter project name"/>
                     </div>
             <div className="flex flex-col w-2/4 ">
             <label  className="inline-block text-sm font-medium text-gray-800 mt-2.5 darki:text-gray-200">
             Total Budget Allocated
                   </label>
                   <input type="text" className="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none darki:bg-slate-900 darki:border-gray-700 darki:text-gray-400 darki:focus:ring-gray-600" placeholder="Enter project name"/>

      

              </div>
                      </div>

                      <div className="w-full flex items-center pt-12 gap-4">
                   <button className="px-4 py-3 text-white rounded-lg bg-[#4F0696]">Save & Create</button>
                   <button className="px-4 py-3 text-black rounded-lg bg-white border-2 border-black">Save as Draft</button>
              </div>  

                    </div>
    
               
               
               </div> 
    
    
          </div>
        </main>
      </>
      </div>
    
        
        </div>
  )
}

export default AddEvent