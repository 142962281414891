import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AverageApp = () => {
  const [selectedApi, setSelectedApi] = useState('');
  const [topUserId, setTopUserId] = useState('');
  const [topUserActivities, setTopUserActivities] = useState([]);
  const [averageActivities, setAverageActivities] = useState(null);

  const apiEndpoints = [
    'get_lead',
    'get_society',
    'get_social',
    'get_awards',
    'get_sports',
    'get_intern',
    'get_cert',
    'get_talent',
    'get_lang',
    'get_eventStd',
  ];

  const fetchActivities = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_KEY}/api/${selectedApi}/`);
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const handleApiChange = (event) => {
    setSelectedApi(event.target.value);
  };

  const handleTopUserChange = (event) => {
    setTopUserId(event.target.value);
  };

  const calculateAverageActivities = (activities) => {
    const totalActivities = activities.length;
    const totalUsers = activities.reduce((acc, activity) => {
      if (!acc.includes(activity.posted_by)) {
        acc.push(activity.posted_by);
      }
      return acc;
    }, []).length;

    return totalActivities / totalUsers;
  };

  useEffect(() => {
    const fetchData = async () => {
      const activities = await fetchActivities();
      setTopUserActivities(activities.filter((activity) => activity.posted_by === topUserId));
    };

    if (topUserId && selectedApi) {
      fetchData();
    }
  }, [topUserId, selectedApi]);  // Include topUserId and selectedApi as dependencies

  useEffect(() => {
    const fetchAllActivities = async () => {
      const activities = await fetchActivities();
      setTopUserActivities(activities.filter((activity) => activity.posted_by === topUserId));
      setAverageActivities(calculateAverageActivities(activities)); // Calculate average for all users
    };

    if (topUserId && selectedApi) {
      fetchAllActivities();
    }
  }, [topUserId, selectedApi]);  // Include topUserId and selectedApi as dependencies

  return (
    <div>
      <label>
        Choose API Endpoint:
        <select value={selectedApi} onChange={handleApiChange}>
          {apiEndpoints.map((endpoint) => (
            <option key={endpoint} value={endpoint}>
              {endpoint}
            </option>
          ))}
        </select>
      </label>

      <br />

      <label>
        Enter top user's ID:
        <input type="text" value={topUserId} onChange={handleTopUserChange} />
      </label>

      

      {topUserActivities.length > 0 && (
        <div>
        Count: {topUserActivities.length}
          <h2>Top User's Activities:</h2>
          <ul>
            {topUserActivities.map((activity) => (
              <li key={activity.id}>{activity.activity_name}</li>
            ))}
          </ul>
        </div>
      )}

      {averageActivities !== null && (
        <div>
          <h2>Average Activities per User:</h2>
          <p>{Math.ceil(averageActivities)}</p>

        </div>
      )}
    </div>
  );
};

export default AverageApp;
