const Social_Work_Data = [
  {
    "Main Categories": "Social work",
    "Sub Categories": "Food drive",
    "Technology": "",
    "Leadership": "",
    "Interpersonal Skills": "",
    "Social Responsibility": "3",
    "Critical Thinking": "",
    "Physical Fitness": ""
  },
  {
    "Main Categories": "Social work",
    "Sub Categories": "Clothes drive",
    "Technology": "",
    "Leadership": "",
    "Interpersonal Skills": "",
    "Social Responsibility": "3",
    "Critical Thinking": "",
    "Physical Fitness": ""
  },
  {
    "Main Categories": "Social work",
    "Sub Categories": "Charity collection",
    "Technology": "",
    "Leadership": "",
    "Interpersonal Skills": "3",
    "Social Responsibility": "3",
    "Critical Thinking": "",
    "Physical Fitness": ""
  },
  {
    "Main Categories": "Social work",
    "Sub Categories": "Blood donation drive",
    "Technology": "",
    "Leadership": "",
    "Interpersonal Skills": "",
    "Social Responsibility": "3",
    "Critical Thinking": "",
    "Physical Fitness": ""
  },
  {
    "Main Categories": "Social work",
    "Sub Categories": "Hospital volunteer",
    "Technology": "",
    "Leadership": "",
    "Interpersonal Skills": "3",
    "Social Responsibility": "3",
    "Critical Thinking": "",
    "Physical Fitness": "2"
  },
  {
    "Main Categories": "Social work",
    "Sub Categories": "Trash clean-up",
    "Technology": "",
    "Leadership": "",
    "Interpersonal Skills": "",
    "Social Responsibility": "2",
    "Critical Thinking": "",
    "Physical Fitness": "2"
  },
  {
    "Main Categories": "Social work",
    "Sub Categories": "Charity bake sale",
    "Technology": "",
    "Leadership": "2",
    "Interpersonal Skills": "2",
    "Social Responsibility": "2",
    "Critical Thinking": "",
    "Physical Fitness": ""
  }
]

export default Social_Work_Data;