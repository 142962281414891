import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import AuthContext from '../context/AuthContext';
import MobileBottom from '../components/MobileBottom';
// import WeeklyLeaderboard from './WeeklyLeaderboard';
import { toast, Toaster } from 'react-hot-toast';
import moment from 'moment';
import YouTube from 'react-youtube';
import { ReactTinyLink } from 'react-tiny-link'
import { useLanguage } from '../components/language';
import ReactPlayer from 'react-player'

const NotificationDetails = () => {
  const [loading, setLoading] = useState(true);
  let { user } = useContext(AuthContext);


  const navigate = useNavigate();
  const { t, changeLanguage, lanArabic } = useLanguage();

  let userID = user?.user_id;


  useEffect(() => {
    if (!user && !userID) {
      console.log('Navigating to login due to lack of user or userID');
      window.location.replace("/login");
    }
  }, [user, userID, navigate]);

  const [profile, setProfile] = useState();
  const { id } = useParams(); // Get the challengeID from the URL using useParams
  const parsedID = parseInt(id);
  useEffect(() => {
    const getProfile = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_KEY}/api/profile/${userID}`);

        // parse the data in json
        let data = await response.json();

        setProfile(data);
      } catch (err) {
        console.log("The requested profile does not exist.");
      }
    };
    getProfile();
  }, []);




  const [notification, setNotification] = useState([]);

  useEffect(() => {
    // Fetch notifications data from your Django backend


      axios
      .get(`${process.env.REACT_APP_API_KEY}/api/get_notifications/`)
      .then((response) => {
        // Filter the challenges based on id
        const matchingNotif = response.data.find((notif) => notif.id === parsedID);

        if (matchingNotif) {
            setNotification(matchingNotif);
        }

        setLoading(false); // Update loading state when data is fetched
      })
      .catch((error) => {
        console.error('Error fetching weekly challenges:', error);
        setLoading(false); // Update loading state on error
      });
  }, []);

  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScroll(isScrolled);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  let { logoutUser } = useContext(AuthContext);

  const opts = {
    width: '100%',
    heigh: '30vh',
    playerVars: {
      autoplay: 0,
    },
  };


  return (
    <div>


<div className={`navbar sticky pb-4 pt-8 top-0 z-30 w-full bg-base-100 px-4 ${scroll ? 'nav-scroll' : ''}`}>
  <div className="flex-1">
    <button className='btn btn-ghost' onClick={() => navigate(-1)}> <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
</svg>
 </button>
    <a className="btn btn-ghost normal-case text-2xl">Notification</a>
  </div>
  <div className="flex-none gap-2">
    <div className="dropdown dropdown-end hidden">
      <label tabIndex={0} className="btn btn-ghost btn-circle">
        <div className="indicator">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="h-5 w-5">
            <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
          </svg>
          <span className="badge badge-sm indicator-item">0</span>
        </div>
      </label>
      <div tabIndex={0} className="mt-3 z-[1] card card-compact dropdown-content w-52 bg-base-100 shadow">
        <div className="card-body">
          <span className="text-base">No new notification</span>
        </div>
      </div>
    </div>
    <div className="dropdown dropdown-end">
      <label tabIndex={0} className="btn btn-ghost btn-circle avatar">
        <div className="rounded-full">

        </div>
      </label>
      <ul tabIndex={0} className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52">
        <li className='hidden'>
          <a className="justify-between hidden">
            Profile
            <span className="badge">New</span>
          </a>
        </li>
        <li className='hidden'><a>Settings</a></li>
        <li onClick={logoutUser}><Link to="/login" className='text-red-400'>Logout</Link></li>
      </ul>
    </div>
  </div>
</div>

    <div className="max-w-2xl px-6 py-4 mx-auto space-y-12">
        <article className="space-y-8 text-gray-900">
            <div className="">
                <h1 className="text-2xl font-bold flex items-center gap-4">{notification?.title} <span className='badge badge-primary'> {notification?.type} </span> </h1>
                <div className="flex flex-col items-start justify-between w-full md:flex-row md:items-center text-gray-600">
                    <p className="flex-shrink-0 text-sm md:mt-0">Posted {moment(notification.post_date, 'YYYY-MM-DD').fromNow()}</p>
                </div>
            </div>
            <div className="text-gray-800">
                <p>{notification?.description}</p>
            </div>
        </article>
        <div>

        {(() => {
              if (notification.type === "Quiz") {
                return (
                 <Link to={
                                        notification.type === "Quiz"
                                          ? `/challenge/${notification.quiz_id}`
                                          : `/notification_detail/${notification.id}`
                                      } className='btn btn-primary'> Start Quiz Now! </Link>
                );
              } else if (notification.type === "External Link") {
                return (
                    <ReactTinyLink
                        cardSize="large"
                        showGraphic={true}
                        maxLine={2}
                        minLine={1}
                        url={notification?.external_link}
                    />
                );
              } else if (notification.type === "Video") {
                return (


                  <div>


                  {(() => {
        if (notification?.is_internalVideo===1) {
          return (
            <ReactPlayer
  url={notification?.internal_video}
  controls={true} // Show video controls (play, pause, etc.)
  width="100%"    // Set the width of the player
className="rounded-2xl"
  playing={true}  // Auto-play the video
  config={{
    file: {
      attributes: {
        controlsList: 'nodownload', // Disable download option
      },
    },
  }}
/>

          )
        }else {
          return (
            <div style={{ width: '100%', borderRadius: '10px', overflow: 'hidden' }}>
                        <YouTube videoId={notification?.video} opts={opts} />
                    </div>
          )
        }
      })()}
                  </div>

                  
                    
                );
              }
            })()}

        </div>
    </div>
    
      <MobileBottom />
    </div>
  );
};

export default NotificationDetails;
