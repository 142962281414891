import React, { useState } from "react";
import { axiosInstance } from "../Axios";

import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import { useEffect } from "react";
import SideInnerSM from "../components/SideInnerSM";
import Sidebar from "../components/Sidebar";
import MyCalendar from "../components/EventCalendar";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Stats from "../components/Stats";
import ApexChart from "../components/AreaChart";

import Navbar from "../components/Navbar";
import BarChart from '../components/Barchart';
import RadarChart from '../components/RadarChart';
import BarStacked from '../components/BarStacked'
import CoCurricularEventsTable from '../components/CoCurricularEventsTable'
import NetworkStats from "../components/NetworkStats";
import Network_StudentList from "../components/Network_StudentList";
import Calendar from "react-calendar";
import EventCalendar from "../components/EventCalendar";
import NetworkStudents from "../components/NetworkStudents";
import { useLanguage } from "../components/language";
import SidebarPilot from "../components/SidebarPilot";
import SideInnerSMPilot from "../components/SideInnerSMPilot";
import AddStaff from "../pages/AddStaff";

export default function NetworkStaff() {
 
  const { t, changeLanguage, lanArabic } = useLanguage();
const [isSidebarVisible, setIsSidebarVisible] = useState(false);
const toggleSidebar = () => {
  setIsSidebarVisible(prevState => !prevState);
}

let {user} = useContext(AuthContext);
let profileID = user['user_id'];
const [profile, setProfile] = useState();
const [loading, setLoading] = useState(true);
useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_KEY}/api/network_profile/${profileID}`);
      let data = await response.json();
      setProfile(data);
      setLoading(false);
    } catch (err) {
      //console.log("The requested profile does not exist.");
      setLoading(false); // Set loading to false even on error to stop showing the loader
    }
  };

  // Simulate the loader by setting a timer for 3 seconds
  const timer = setTimeout(() => {
    fetchData();
  }, 900);

  // Clean up the timer when the component unmounts
  return () => clearTimeout(timer);
}, [profileID]);


const [date, setDate] = useState(new Date());
  const [selectedEvent, setSelectedEvent] = useState(null);

  const [events, setEvents] = useState([]);

  useEffect(() => {
    // Fetch data from the '/get_events/' endpoint
    fetch(`${process.env.REACT_APP_API_KEY}/api/get_events/`)
      .then((response) => response.json())
      .then((data) => {
        // Filter events where campus matches the username
        const filteredEvents = data.filter((event) => event.campus === user.username);
        setEvents(filteredEvents);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [user.username]); // Include username as a dependency to re-fetch when it changes


  
  return (
    <div>


<div><>

<div class="hidden md:block absolute w-full bg-lilac h-96  min-h-75"></div>

{(() => {
        if (profileID===263) {
          return (
            <SidebarPilot />
          )
        } else {
          return (
            <Sidebar />
          )
        }
      })()}



      {isSidebarVisible && (
  (() => {
    if (profileID === 263) {
      return <SideInnerSMPilot />;
    } else {
      return <SideInnerSM />;
    }
  })()
)}





    <main className={`relative h-full max-h-screen transition-all duration-200 ease-in-out ${lanArabic ? 'xl:mr-68' : 'xl:ml-68'} rounded-xl`}>
      {/* Navbar */}
      <Navbar toggleSidebar={toggleSidebar} />

      {/* end Navbar */}
      {/* cards */}
      <div className="w-full px-6 py-6 mx-auto">

      <Tabs>
      <TabList className='pb-8'>
        <Tab>All Staff Members</Tab>
        <Tab>Add Staff Member</Tab>
      </TabList>


      <TabPanel>
      <div className="overflow-x-auto bg-white rounded-xl">
  <table className="table">
    {/* head */}
    <thead>
      <tr>
        <th>
          <label>
            <input type="checkbox" className="checkbox" />
          </label>
        </th>
        <th>Name</th>
        <th>Role(s)</th>
        <th>Campus Assigned</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {/* row 1 */}
      <tr>
        <th>
          <label>
            <input type="checkbox" className="checkbox" />
          </label>
        </th>
        <td>
          <div className="flex items-center gap-3">
            <div className="avatar">
              <div className="mask mask-squircle w-12 h-12">
                <img src="https://daisyui.com/tailwind-css-component-profile-3@56w.png" alt="Avatar Tailwind CSS Component" />
              </div>
            </div>
            <div>
              <div className="font-bold">Bob Johnson</div>
              <div className="text-sm opacity-50">bob@northville.com</div>
            </div>
          </div>
        </td>
        <td>
          <span className="badge badge-error badge-sm">Network Admin</span><br/>
          <span className="badge badge-info badge-sm">Campus Admin</span><br/>
        </td>
        <td>Northville Central</td>
        <th>
          <button className="btn btn-ghost btn-xs">details</button>
        </th>
      </tr>
      {/* row 2 */}
      
    </tbody>
    
  </table>
</div>
      </TabPanel>


      <TabPanel>
       <AddStaff/>
      </TabPanel>

    </Tabs>

      </div>
    </main>
  </>
  </div>

    
    </div>
  );
}