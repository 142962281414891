import React, { useState } from "react";
import { axiosInstance } from "../Axios";

import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import { useEffect } from "react";
import SideInnerSM from "../components/SideInnerSM";
import Sidebar from "../components/Sidebar";
import MyCalendar from "../components/EventCalendar";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Stats from "../components/Stats";
import ApexChart from "../components/AreaChart";

import Navbar from "../components/Navbar";
import BarChart from "../components/Barchart";
import RadarChart from "../components/RadarChart";
import BarStacked from "../components/BarStacked";
import CoCurricularEventsTable from "../components/CoCurricularEventsTable";
import NetworkStats from "../components/NetworkStats";
import Network_StudentList from "../components/Network_StudentList";
import Calendar from "react-calendar";
import EventCalendar from "../components/EventCalendar";
import BarchartSchoolTrend from "./BarchartSchoolTrend";
import NetworkStudents from "../components/NetworkStudents";
import { useLanguage } from "../components/language";
import SidebarPilot from "../components/SidebarPilot";
import SideInnerSMPilot from "../components/SideInnerSMPilot";
import ClubSidebar from "./ClubSidebar";
import ClubPagination from "./ClubPagination";
import ClubListList from "./ClubListList";
import clubsData from "./ClubsData";
import { createGlobalStyle } from 'styled-components';
import RoleDropdown from "./RoleDropdown";
import AllClubsPublic from './AllClubsPublic'
import { SiEclipseide } from "react-icons/si";
import MyClubs from "./MyClubs";
import { useNavigate } from "react-router-dom";


const NetworkClubs = () => {
  const { t, changeLanguage, lanArabic } = useLanguage();
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const toggleSidebar = () => {
    setIsSidebarVisible((prevState) => !prevState);
  };

  let { user } = useContext(AuthContext);
  let profileID = user["user_id"];
  const [profile, setProfile] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/network_profile/${profileID}`
        );
        let data = await response.json();
        setProfile(data);
        setLoading(false);
      } catch (err) {
        //console.log("The requested profile does not exist.");
        setLoading(false); // Set loading to false even on error to stop showing the loader
      }
    };

    // Simulate the loader by setting a timer for 3 seconds
    const timer = setTimeout(() => {
      fetchData();
    }, 900);

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [profileID]);

  const [date, setDate] = useState(new Date());
  const [selectedEvent, setSelectedEvent] = useState(null);

  const [events, setEvents] = useState([]);

  useEffect(() => {
    // Fetch data from the '/get_events/' endpoint
    fetch(`${process.env.REACT_APP_API_KEY}/api/get_events/`)
      .then((response) => response.json())
      .then((data) => {
        // Filter events where campus matches the username
        const filteredEvents = data.filter(
          (event) => event.campus === user.username
        );
        setEvents(filteredEvents);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [user.username]); // Include username as a dependency to re-fetch when it changes



  const GlobalStyle = createGlobalStyle`
  html {
    background: #F9F3FF !important;
    /* Add other global styles here if needed */
  }
`;


const [selectedRole, setSelectedRole] = useState('');
const navigate = useNavigate();
useEffect(() => {
  const data = localStorage.getItem('selectedRole');
  if (data) {
    setSelectedRole(data);
  }

    if(data === "Public") {
    navigate("/allclubpublic");
  }else {
    navigate("/myclub");
  }
}, [selectedRole]);

// 
// useEffect(() => {
//   localStorage.setItem('selectedRole', selectedRole);
//   if(selectedRole === "Public") {
//     navigate("/allclubpublic");
//   }else {
//     navigate("/myclub");
//   }

// }, [selectedRole]);

  return (
   
    


<div className="">
{selectedRole === 'Public'? <AllClubsPublic/> : <MyClubs/>}


{/* {(() => {
        if (selectedRole === 'Public') {
          return (
            <AllClubsPublic/>
          )
        } else{
          return (
            <MyClubs/>
          )
        }
      })()} */}


</div>


  );
}
export default NetworkClubs;