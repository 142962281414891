import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function Policy() {


    return (
        <div>
<>
  <div
    style={{
      textAlign: "center",
      margin: "3vh 9vw",
      lineHeight: 36,
      backgroundColor: "#4f06961c",
      padding: 30,
      borderRadius: 25
    }}
    className="elementor-widget-wrap elementor-element-populated policies"
  >
    <div
      className="elementor-element elementor-element-8137d33 elementor-widget elementor-widget-text-editor"
      data-id="8137d33"
      data-element_type="widget"
      data-widget_type="text-editor.default"
    >
      <div className="elementor-widget-container">
        <p>
          Startup Early (“us”, “we”, or “our”) operates the{" "}
          <a href="/">www.startupearly.com</a> website
        </p>
        <p>(hereinafter referred to as the “Service”).</p>
        <p>
          This page informs you of our policies regarding the collection, use
          and disclosure of personal data when you use our Service and the
          choices you have associated with that data. We use your data to
          provide and improve the Service. By using the Service, you agree to
          the collection and use of information in accordance with this policy.
          Unless otherwise defined in this Privacy Policy, the terms used in
          this Privacy Policy have the same meanings as in our Terms and
          Conditions, accessible from <a href="/">www.startupearly.com</a>
        </p>
      </div>
    </div>
    <div
      className="elementor-element elementor-element-33b6963 elementor-widget elementor-widget-heading"
      data-id="33b6963"
      data-element_type="widget"
      data-widget_type="heading.default"
    >
      <div className="elementor-widget-container">
        <h4 className="elementor-heading-title elementor-size-default">
          Definitions
        </h4>
      </div>
    </div>
    <div
      className="elementor-element elementor-element-19258b5 elementor-widget elementor-widget-text-editor"
      data-id="19258b5"
      data-element_type="widget"
      data-widget_type="text-editor.default"
    >
      <div className="elementor-widget-container">
        <p>
          <strong>Service</strong>
        </p>
        <p>
          Service is the <a href="/">www.StartupEarly.pk</a> website operated by
          Startup Early
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Personal Data</strong>
        </p>
        <p>
          <em>
            Personal Data means data about a living individual who can be
            identified from those data (or from those and other information
            either in our possession or likely to come into our possession)
          </em>
          .
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Usage Data</strong>
        </p>
        <p>
          <em>
            Usage Data is data collected automatically either generated by the
            use of the Service or from the Service infrastructure itself (for
            example, the duration of a page visit)
          </em>
          .
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Cookies</strong>
        </p>
        <p>
          <em>
            Cookies are small files stored on your device (computer or mobile
            device).
          </em>
        </p>
      </div>
    </div>
    <div
      className="elementor-element elementor-element-8f44f74 elementor-widget elementor-widget-text-editor"
      data-id="8f44f74"
      data-element_type="widget"
      data-widget_type="text-editor.default"
    >
      <div className="elementor-widget-container">
        <p>
          <strong>Information Collection and Use</strong>
        </p>
        <p>
          We collect several different types of information for various purposes
          to provide and improve our Service to you
        </p>
      </div>
    </div>
    <div
      className="elementor-element elementor-element-af1b827 elementor-widget elementor-widget-heading"
      data-id="af1b827"
      data-element_type="widget"
      data-widget_type="heading.default"
    >
      <div className="elementor-widget-container">
        <h4 className="elementor-heading-title elementor-size-default">
          Types of Data Collected
        </h4>
      </div>
    </div>
    <div
      className="elementor-element elementor-element-302d102 elementor-widget elementor-widget-text-editor"
      data-id="302d102"
      data-element_type="widget"
      data-widget_type="text-editor.default"
    >
      <div className="elementor-widget-container">
        <p>
          <strong>
            <em>Personal Data</em>
          </strong>
        </p>
        <p>
          While using our Service, we may ask you to provide us with certain
          personally identifiable information that can be used to contact or
          identify you (“Personal Data”). Personal Data may include, but is not
          limited to:
        </p>
        <p>&nbsp;</p>
        <p>
          <em>Email address </em>
        </p>
        <p>
          <em>First name and last name</em>
        </p>
        <p>
          <em>Phone number Address, State, Province, ZIP/Postal code, City</em>
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            <em> Cookies and Usage Data</em>
          </strong>
        </p>
        <p>
          We may use your Personal Data to contact you with newsletters,
          marketing or promotional materials and other information that may be
          of interest to you. You may opt out of receiving any, or all, of these
          communications from us by contacting us.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Usage Data</strong>
        </p>
        <p>
          We may also collect information on how the Service is accessed and
          used (“Usage Data”). This Usage Data may include information such as
          your computer’s Internet Protocol address (e.g., IP address), browser
          type, browser version, the pages of our Service that you visit, the
          time and date of your visit, the time spent on those pages, unique
          device identifiers and other diagnostic data.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong> Location Data</strong>
        </p>
        <p>
          We may use and store information about your location if you give us
          permission to do so (“Location Data”). We use this data to provide
          features of our Service, to improve and customize our Service. You can
          enable or disable location services when you use our Service at any
          time by way of your device settings.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Tracking &amp; Cookies Data</strong>
        </p>
        <p>
          We use cookies and similar tracking technologies to track the activity
          on our Service and we hold certain information. Cookies are files with
          a small amount of data which may include an anonymous unique
          identifier. Cookies are sent to your browser from a website and stored
          on your device. Other tracking technologies are also used such as
          beacons, tags and scripts to collect and track information and to
          improve and analyse our Service. You can instruct your browser to
          refuse all cookies or to indicate when a cookie is being sent.
          However, if you do not accept cookies, you may not be able to use some
          portions of our Service.
        </p>
      </div>
    </div>
    <div
      className="elementor-element elementor-element-e14fcef elementor-widget elementor-widget-heading"
      data-id="e14fcef"
      data-element_type="widget"
      data-widget_type="heading.default"
    >
      <div className="elementor-widget-container">
        <h4 className="elementor-heading-title elementor-size-default">
          Examples of Cookies we use:
        </h4>
      </div>
    </div>
    <div
      className="elementor-element elementor-element-a5c63ce elementor-widget elementor-widget-text-editor"
      data-id="a5c63ce"
      data-element_type="widget"
      data-widget_type="text-editor.default"
    >
      <div className="elementor-widget-container">
        <p>
          <strong>Session Cookies</strong>
        </p>
        <p>We use Session Cookies to operate our Service.</p>
        <p>&nbsp;</p>
        <p>
          <strong>Preference Cookies</strong>
        </p>
        <p>
          We use Preference Cookies to remember your preferences and various
          settings.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Security Cookies</strong>
        </p>
        <p>We use Security Cookies for security purposes</p>
      </div>
    </div>
    <div
      className="elementor-element elementor-element-70b4906 elementor-widget elementor-widget-heading"
      data-id="70b4906"
      data-element_type="widget"
      data-widget_type="heading.default"
    >
      <div className="elementor-widget-container">
        <h4 className="elementor-heading-title elementor-size-default">
          Use of Data:
        </h4>
      </div>
    </div>
    <div
      className="elementor-element elementor-element-1878579 elementor-widget elementor-widget-heading"
      data-id={1878579}
      data-element_type="widget"
      data-widget_type="heading.default"
    >
      <div className="elementor-widget-container">
        <p className="elementor-heading-title elementor-size-default">
          Startup Early uses the collected data for various purposes:
        </p>
      </div>
    </div>
    <div
      className="elementor-element elementor-element-4e7e0c4 elementor-widget elementor-widget-text-editor"
      data-id="4e7e0c4"
      data-element_type="widget"
      data-widget_type="text-editor.default"
    >
      <div className="elementor-widget-container">
        <p>To provide and maintain our Service</p>
        <p>To notify you about changes to our Service</p>
        <p>
          To allow you to participate in interactive features of our Service
          when you choose to do so
        </p>
        <p>To provide customer support</p>
        <p>
          To gather analysis or valuable information so that we can improve our
          Service
        </p>
        <p>To monitor the usage of our Service</p>
        <p>To detect, prevent and address technical issues</p>
        <p>
          To provide you with news, special offers and general information about
          other goods, services and events which we offer that are similar to
          those that you have already purchased or enquired about unless you
          have opted not to receive such information
        </p>
      </div>
    </div>
    <div
      className="elementor-element elementor-element-3b5eaed elementor-widget elementor-widget-heading"
      data-id="3b5eaed"
      data-element_type="widget"
      data-widget_type="heading.default"
    >
      <div className="elementor-widget-container">
        <h4 className="elementor-heading-title elementor-size-default">
          Transfer of Data
        </h4>
      </div>
    </div>
    <div
      className="elementor-element elementor-element-54d46e4 elementor-widget elementor-widget-text-editor"
      data-id="54d46e4"
      data-element_type="widget"
      data-widget_type="text-editor.default"
    >
      <div className="elementor-widget-container">
        <p>
          Your information, including Personal Data, may be transferred to – and
          maintained on – computers located outside of your state, province,
          country or other governmental jurisdiction where the data protection
          laws may differ from those of your jurisdiction.
        </p>
        <p>&nbsp;</p>
        <p>
          If you are located outside Pakistan and choose to provide information
          to us, please note that we transfer the data, including Personal Data,
          to Pakistan and process it there.
        </p>
        <p>&nbsp;</p>
        <p>
          Your consent to this Privacy Policy followed by your submission of
          such information represents your agreement to that transfer.
        </p>
        <p>&nbsp;</p>
        <p>
          Startup Early will take all the steps reasonably necessary to ensure
          that your data is treated securely and in accordance with this Privacy
          Policy and no transfer of your Personal Data will take place to an
          organisation or a country unless there are adequate controls in place
          including the security of your data and other personal information
        </p>
      </div>
    </div>
    <div
      className="elementor-element elementor-element-2602809 elementor-widget elementor-widget-heading"
      data-id={2602809}
      data-element_type="widget"
      data-widget_type="heading.default"
    >
      <div className="elementor-widget-container">
        <h4 className="elementor-heading-title elementor-size-default">
          Disclosure of Data
        </h4>
      </div>
    </div>
    <div
      className="elementor-element elementor-element-28f1aab elementor-widget elementor-widget-heading"
      data-id="28f1aab"
      data-element_type="widget"
      data-widget_type="heading.default"
    >
      <div className="elementor-widget-container">
        <p className="elementor-heading-title elementor-size-default">
          <b>Business Transaction</b>
        </p>
      </div>
    </div>
    <div
      className="elementor-element elementor-element-ff7a47e elementor-widget elementor-widget-text-editor"
      data-id="ff7a47e"
      data-element_type="widget"
      data-widget_type="text-editor.default"
    >
      <div className="elementor-widget-container">
        <p>
          If Startup Early is involved in a merger, acquisition or asset sale,
          your Personal Data may be transferred. We will provide notice before
          your Personal Data is transferred and becomes subject to a different
          Privacy Policy
        </p>
      </div>
    </div>
    <div
      className="elementor-element elementor-element-8ffa32b elementor-widget elementor-widget-heading"
      data-id="8ffa32b"
      data-element_type="widget"
      data-widget_type="heading.default"
    >
      <div className="elementor-widget-container">
        <p className="elementor-heading-title elementor-size-default">
          <b>Disclosure for Law Enforcement</b>
        </p>
      </div>
    </div>
    <div
      className="elementor-element elementor-element-938dd9c elementor-widget elementor-widget-text-editor"
      data-id="938dd9c"
      data-element_type="widget"
      data-widget_type="text-editor.default"
    >
      <div className="elementor-widget-container">
        <p>
          Under certain circumstances, Startup Early may be required to disclose
          your Personal Data if required to do so by law or in response to valid
          requests by public authorities (e.g., a court or a government agency).
        </p>
      </div>
    </div>
    <div
      className="elementor-element elementor-element-7c1ae4c elementor-widget elementor-widget-heading"
      data-id="7c1ae4c"
      data-element_type="widget"
      data-widget_type="heading.default"
    >
      <div className="elementor-widget-container">
        <p className="elementor-heading-title elementor-size-default">
          <b>Legal Requirements</b>
        </p>
      </div>
    </div>
    <div
      className="elementor-element elementor-element-2974778 elementor-widget elementor-widget-text-editor"
      data-id={2974778}
      data-element_type="widget"
      data-widget_type="text-editor.default"
    >
      <div className="elementor-widget-container">
        <p>
          Startup Early may disclose your Personal Data in the good faith belief
          that such action is necessary to:
        </p>
        <p>To comply with a legal obligation</p>
        <p>To protect and defend the rights or property of Startup Early</p>
        <p>
          To prevent or investigate possible wrongdoing in connection with the
          Service
        </p>
        <p>
          To protect the personal safety ofusers of the Service or the public
        </p>
        <p>To protect against legal liability</p>
      </div>
    </div>
    <div
      className="elementor-element elementor-element-ad4437f elementor-widget elementor-widget-text-editor"
      data-id="ad4437f"
      data-element_type="widget"
      data-widget_type="text-editor.default"
    >
      <div className="elementor-widget-container">
        <p>
          <strong>Security of Data</strong>
        </p>
        <p>
          The security of your data is important to us but remember that no
          method of transmission over the Internet or method of electronic
          storage is 100% secure. While we strive to use commercially acceptable
          means to protect your Personal Data, we cannot guarantee its absolute
          security.
        </p>
        <p>
          <strong>Service Providers</strong>
        </p>
        <p>
          We may employ third party companies and individuals to facilitate our
          Service (“Service Providers”), provide the Service on our behalf,
          perform Service-related services or assist us in analysing how our
          Service is used. These third parties have access to your Personal Data
          only to perform these tasks on our behalf and are obligated not to
          disclose or use it for any other purpose.
        </p>
        <p>
          <strong>Analytics</strong>
        </p>
        <p>
          We may use third-party Service Providers to monitor and analyse the
          use of our Service.
        </p>
        <p>Piwik / Matomo</p>
        <p>
          Piwik or Matomo is a web analytics service. You can visit their
          Privacy Policy page here:{" "}
          <a
            href="https://matomo.org/privacy-policy"
            target="_blank"
            rel="noopener"
          >
            https://matomo.org/privacy-policy
          </a>
        </p>
        <p>Clicky</p>
        <p>
          Clicky is a web analytics service. Read the Privacy Policy for Clicky
          here:{" "}
          <a href="https://clicky.com/terms" target="_blank" rel="noopener">
            https://clicky.com/terms
          </a>
        </p>
        <p>Statcounter</p>
        <p>
          Statcounter is a web traffic analysis tool. You can read the Privacy
          Policy for Statcounter here:{" "}
          <a
            href="https://statcounter.com/about/legal/"
            target="_blank"
            rel="noopener"
          >
            https://statcounter.com/about/legal/
          </a>
        </p>
        <p>
          <strong>Payments</strong>
        </p>
        <p>
          We may provide paid products and/or services within the Service. In
          that case, we use third-party services for payment processing (e.g.,
          payment processors). We will not store or collect your payment card
          details. That information is provided directly to our third-party
          payment processors whose use of your personal information is governed
          by their Privacy Policy. These payment processors adhere to the
          standards set by PCI-DSS as managed by the PCI Security Standards
          Council, which is a joint effort of brands likeVisa, MasterCard,
          American Express and Discover. PCI-DSS requirements help ensure the
          secure handling of payment information.
        </p>
        <p>The payment processors we work with are:</p>
        <p>
          <strong>Stripe</strong>
        </p>
        <p>
          Their Privacy Policy can be viewed at&nbsp;
          <a
            href="https://stripe.com/us/privacy"
            target="_blank"
            rel="noopener"
          >
            https://stripe.com/us/privacy
          </a>
        </p>
        <p>
          <strong>Links to Other Sites</strong>
        </p>
        <p>
          Our Service may contain links to other sites that are not operated by
          us. If you click a third party link, you will be directed to that
          third party’s site. We strongly advise you to review the Privacy
          Policy of every site you visit. We have no control over and assume no
          responsibility for the content, privacy policies or practices of any
          third-party sites or services.
        </p>
        <p>
          <strong>Changes to This Privacy Policy</strong>
        </p>
        <p>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page. We will
          let you know via email and/or a prominent notice on our Service, prior
          to the change becoming effective and update the “effective date” at
          the top of this Privacy Policy. You are advised to review this Privacy
          Policy periodically for any changes. Changes to this Privacy Policy
          are effective when they are posted on this page
        </p>
        <p>
          Contact Us If you have any questions about this Privacy Policy, please
          contact us: By email:{" "}
          <a href="mailto:info@startupearly.com">info@startupearly.com</a>
        </p>
      </div>
    </div>
  </div>
  <div
    style={{
      textAlign: "center",
      margin: "3vh 9vw",
      lineHeight: 36,
      backgroundColor: "#DAFFF8",
      padding: 30,
      borderRadius: 25
    }}
    className="elementor-widget-wrap elementor-element-populated terms"
  >
    <div
      className="elementor-element elementor-element-801e5ac elementor-widget elementor-widget-text-editor"
      data-id="801e5ac"
      data-element_type="widget"
      data-widget_type="text-editor.default"
    >
      <div className="elementor-widget-container">
        {" "}
        <strong>Commitment</strong> I agree to completely comply with the social
        norms and legal jurisdiction of the place from where I belong and will
        treat everyone on Startup Early platform regardless of their race,
        religion, skin color, disability, sex, gender identity, national,
        origin, ethnicity, sexual orientation or age with respect, and without
        judgment or bias. <strong> Definitions</strong> By Startup Early we will
        mean our platform and all registered users shall be referred to as
        “Members”. Members who publish and offer services are “Hosts” and
        Members who search for, book, or use services are “Guests.” The
        accommodations listed by Hosts are called as Listings.
      </div>
    </div>
    <div
      className="elementor-element elementor-element-5e619ba elementor-widget elementor-widget-heading"
      data-id="5e619ba"
      data-element_type="widget"
      data-widget_type="heading.default"
    >
      <div className="elementor-widget-container">
        <h4 className="elementor-heading-title elementor-size-default">
          Startup Early Services
        </h4>
      </div>
    </div>
    <div
      className="elementor-element elementor-element-22ade1f elementor-widget elementor-widget-text-editor"
      data-id="22ade1f"
      data-element_type="widget"
      data-widget_type="text-editor.default"
    >
      <div className="elementor-widget-container">
        {" "}
        Startup Early is online marketplace that enables Members and certain
        third-parties who offer services to publish such Host Services on the
        Startup Early Platform and to communicate and transact directly with
        Members that are looking for booking Host Services such as offering of
        vacation or other properties for accommodation. Startup Early is not
        acting as an agent for any member nor is a real estate broker. The
        ownership of any property listed on Startup Early does not belongs to
        the Startup Early, and Startup Early doesn’t, sell, resell, deliver,
        control, manage, offer, carry, or supply any Listings or Host Services.
        Hosts alone are responsible for their Listings and Host Services.
        Startup Early is not and does not become a party to or other participant
        in any contractual relationship between Members except as deﬁned in
        Payments terms and providing services as an online digital Marketplace.
        When Members make or accept a booking, they are entering into a contract
        directly with each other while we may help facilitate the resolution of
        disputes, Startup Early has no control over and does not guarantee,
        without limitation, the following: <strong>–&nbsp; </strong>The quality,
        safety, correctness, or legality of Listings; <strong>–&nbsp; </strong>
        The truth or precision of any Listing accounts, Ratings, Reviews, or
        other Member Content, the performance or manner of any Member or third
        party; <strong>–&nbsp; </strong>On Startup Early Platform any mentions
        to a Member being ‘veriﬁed’ only show that the Member has completed a
        related veriﬁcation or identiﬁcation process and nothing else. Any such
        report is not a conﬁrmation, guarantee or assurance by Startup Early
        about any Member, including of the Member’s identity or background or
        whether the Member is reliable, safe or suitable. You should always
        exercise due thoroughness and care when determining whether to stay in
        an Accommodation, accept a booking request from a Guest, or communicate
        and interact with other Members, whether online or in person;{" "}
        <strong>–&nbsp; </strong>Any photos / videos of Listings are only a
        graphic depiction of Listings at the time photograph / video was taken
        and their veriﬁcation does not intent any guarantee, assurance or
        representation from Startup Early. The association between Host or
        Co-host and Startup Early is limited to being an independent,
        third-party service provider and not as, without limitation, a
        sub-contractor, independent contractor, employee, partner, or negotiator
        of Startup Early for any motive and Hosts act completely on their own
        will and for their own advantage and not on the behalf of Startup Early
        or for the beneﬁt of Startup Early. Startup Early does not, and shall
        not be supposed to, control you in general or in your performance. You
        acknowledge and agree that you have complete freedom of choice whether
        to list Host Services. Content may be displayed on other websites, in
        applications, in online and offline advertisements and in emails to
        stimulate Startup Early Platform and increase the coverage of Listings
        to prospective Guests Listings and other Members. The Startup Early
        Platform may contain translations powered by Google over which Startup
        Early has no control, and Startup Early shall at all times comply with
        any claims / requests of Google on such matters. Startup Early is not
        liable for the availability or accuracy of any Third-Party Services, or
        the content, products, or services available from such Third-Party
        Services linked to Startup Early platform. Links to such Third-Party
        Services are not validation by Startup Early of such Third-Party
        Services. • Startup Early cannot guarantee the unbroken and continuous
        accessibility and availability of the Startup Early Platform as it is
        dependent on internet. Startup Early may limit the accessibility of the
        Startup Early Platform or certain areas or features thereof, if this is
        needed in view of the security or integrity of our servers, or to carry
        out maintenance approaches that ensure the proper or improved running of
        the Startup Early Platform. Startup Early may develop, enhance and amend
        the Startup Early Platform and introduce new Startup Early Services from
        time to time.
      </div>
    </div>
    <div
      className="elementor-element elementor-element-5828b84 elementor-widget elementor-widget-heading"
      data-id="5828b84"
      data-element_type="widget"
      data-widget_type="heading.default"
    >
      <div className="elementor-widget-container">
        <h4 className="elementor-heading-title elementor-size-default">
          Entitlement, Using the Startup Early Platform and Member Verification
        </h4>
      </div>
    </div>
    <div
      className="elementor-element elementor-element-f1def91 elementor-widget elementor-widget-text-editor"
      data-id="f1def91"
      data-element_type="widget"
      data-widget_type="text-editor.default"
    >
      <div className="elementor-widget-container">
        {" "}
        Registration is only permitted for legal entities, partnerships and
        natural persons who are 18 years or older. All Members are required to
        provide accurate, current, and complete information during registration
        and keep your account information up-to-date. However, Startup Early
        does not represent, or undertake any responsibility for confirming the
        veracity of each User’s claimed identity. It is encouraged that Members
        may directly communicate with one another through any available mode of
        communication, or through the tools available on the Site, though this
        may not assure you of the identity of the person with which you are
        communicating in every instance. We encourage you to take other
        reasonable measures to assure yourself of the other person’s identity
        and, for travelers, of the property and relevant details of your booking
        or proposed booking. Although the above, for transparency and fraud
        prevention purposes, and as permitted by applicable laws, we may, but
        have no obligation to ask Members to provide a form of government
        identiﬁcation or other information or undertake additional checks
        designed to help verify the identities or backgrounds of Members.
      </div>
    </div>
    <div
      className="elementor-element elementor-element-36668d3 elementor-widget elementor-widget-text-editor"
      data-id="36668d3"
      data-element_type="widget"
      data-widget_type="text-editor.default"
    >
      <div className="elementor-widget-container">
        <p>
          <strong>Modification of the Terms </strong>
        </p>
        <p>
          Startup Early reserves the right to modify the Terms and Conditions at
          any given time, for any reason whatsoever, without providing any prior
          written notice to any Member.
        </p>
      </div>
    </div>
    <div
      className="elementor-element elementor-element-4262fd0 elementor-widget elementor-widget-heading"
      data-id="4262fd0"
      data-element_type="widget"
      data-widget_type="heading.default"
    >
      <div className="elementor-widget-container">
        <p className="elementor-heading-title elementor-size-default">
          <b>Account Registration</b>
        </p>
      </div>
    </div>
    <div
      className="elementor-element elementor-element-58cb2e5 elementor-widget elementor-widget-text-editor"
      data-id="58cb2e5"
      data-element_type="widget"
      data-widget_type="text-editor.default"
    >
      <div className="elementor-widget-container">
        <p>
          You must register an account to access and use many of the features of
          Startup Early, such as booking or publishing a Listing. If you are
          registering a Startup Early Account for a business, organization or
          other legal entity, you represent and warrant that you have the right
          to legally impasse that entity and grant us all approvals and licenses
          provided in these Terms.
        </p>
        <p>
          Startup Early Account can be registered using an email address and
          creating a password, or through your account with certain third-party
          social networking services, such as Facebook or Google.
        </p>
        <p>
          You must provide complete information during the registration process
          and the information provided must be accurate and unbiased keep your
          Startup Early Account and public Startup Early Account proﬁle page
          information up-to-date at all times.
        </p>
        <p>
          You may not register more than one account or transfer your account to
          someone else. You are responsible for maintaining the confidentiality
          and security of your account credentials and may not disclose your
          credentials to any unauthorized third-party. You must immediately
          notify Startup Early if you suspect that your credentials have been
          lost, stolen, or your account is otherwise compromised.
        </p>
        <p>For the purposes of Account Registration, you must agree to:</p>
        <p>
          <strong>–</strong>&nbsp; Keep your password and online Startup Early
          ID for both your account with us and your email account secure and
          strictly conﬁdential, providing it only to authorized users of your
          accounts (if any);
        </p>
        <p>
          <strong>–&nbsp;</strong> Instruct each person to whom you give your
          online ID and password that he or she is not to disclose it to any
          unauthorized person;
        </p>
        <p>
          <strong>–&nbsp; </strong>Notify us immediately and select a new online
          ID and password if you believe your password for either your account
          with us or your email account may have become known to an unauthorized
          person; and
        </p>
        <p>
          <strong>–</strong>&nbsp; Notify us immediately if you are contacted by
          anyone requesting your online ID and password;
        </p>
        <p>
          <strong>–</strong>&nbsp; Further, if we suspect any unauthorized
          access to your account, upon our request, you agree to promptly change
          your ID and password and take any other related action as we may
          reasonably request.
        </p>
      </div>
    </div>
    <div
      className="elementor-element elementor-element-af93b16 elementor-widget elementor-widget-text-editor"
      data-id="af93b16"
      data-element_type="widget"
      data-widget_type="text-editor.default"
    >
      <div className="elementor-widget-container">
        <p>
          You are responsible and liable for activities conducted through your
          Startup Early Account, unless such activities are not authorized by
          you and you are not otherwise negligent (such as failing to report the
          unauthorized use or loss of your credentials).
        </p>
        <p>
          <strong>Content</strong>
        </p>
        <p>Startup Early may, at its sole decision, enable Members to:</p>
        <p>
          Access and view their Content and any content that Startup Early
          itself makes accessible on or through the Startup Early Platform.
        </p>
        <p>
          You are solely liable for all Member Content that you make accessible
          on or through the Startup Early Platform. Accordingly, you represent
          and warrant that you either are the sole and private owner of all
          Member Content that you make available on or through the Startup Early
          Platform or you have all rights, licenses, consents and announcements
          that are necessary to grant to Startup Early the rights in and to such
          Member Content, as envisioned under these Terms.
        </p>
        <p>
          Startup Early reserves the right to delete any content which is
          objectionable, misleading, offensive, discriminatory or threatening to
          any one or any speciﬁc group of people.
        </p>
        <p>
          Copyright Notification: If you believe that any Content on the Startup
          Early Platform infringes copyrights, please notify us in accordance
          with our Copyright Policy.
        </p>
        <p>
          <strong>Service Fees</strong>
        </p>
        <p>
          A nominal Service fees is charged by Startup Early from the guests and
          hosts to cover the expenses including such features as 24/7 user
          support and website running cost and is calculated as a variable
          percentage of the total reservation amount. Startup Early operates its
          transactional mechanism in accordance with the laws and regulation of
          Pakistan. The service fee will only be refunded in the event a member
          accepts cancellation of the traveler’s reservation and refunds the
          entire rental amount.
        </p>
        <p>
          Startup Early reserves the right to change the Service Fees at any
          time, and will provide Members adequate notice of any fee changes
          before they become effective. Such fee changes will not impact any
          bookings made earlier to the effective date of the fee change. All
          applicable fees and charges will be displayed prior to booking any
          listing.
        </p>
        <p>
          You are liable for disbursing any Service Fees that you owe to Startup
          Early. The pertinent Service Fees (including any applicable Taxes) are
          collected by Startup Early Payments. Startup Early Payments will
          deduct any Host Fees from the Listing Fee before remitting the payout
          to the Host. Any Guest Fees are included in the Total Fees collected
          by Startup Early Payments. Except as otherwise provided on the Startup
          Early Platform, Service Fees are non-refundable.
        </p>
        <p>
          <strong>Hosting</strong>
        </p>
        <p>
          <strong>–</strong>&nbsp; To post a listing/accommodation on Startup
          Early you must:
        </p>
        <p>
          <strong>–&nbsp; </strong>Provide complete and accurate information
          about your accommodation such as location, availability, and
          descriptions etc.;
        </p>
        <p>
          <strong>–&nbsp; </strong>Disclose any insufﬁciencies, restraints (such
          as house rules) and requirements that apply;
        </p>
        <p>
          <strong>–&nbsp; </strong>Provide any other relevant information
          requested by Startup Early.
        </p>
        <p>
          You are responsible for keeping your Listing information up-to-date at
          all times. You are solely responsible for setting a price. Once a
          Guest requests a booking of your Listing, you may not request that the
          Guest pays a higher price than in the booking request.
        </p>
        <p>
          Any terms and conditions included in your Listing, in particular in
          relation to cancellations, must not conflict with these Terms or the
          relevant cancellation policy for your Listing.
        </p>
        <p>
          Photos and videos used in your Listings must accurately reflect the
          features and condition of your Host Services. Startup Early reserves
          the right to require that Listings have a minimum number of Images of
          a certain size, format and resolution. However, at no time does
          Startup Early represent and warrant the veracity and authenticity of
          the description, images and/or videos.
        </p>
        <p>
          The engagement and ranking of Listings in search results on the
          Startup Early Platform may vary and depend on a many factor, such as
          Guest search inclinations, search ﬁlters Host requests, price and
          calendar availability, number and quality of Images, customer service
          and cancellation history, Reviews and Ratings, type of Host Service,
          and/or ease of booking.
        </p>
        <p>
          When you accept a booking request, or receive a booking confirmation
          through the Startup Early Platform, you are entering into a contract
          directly with the Guest, and are responsible for delivering your Host
          Service under the terms and at the price specified in your Listing.
          You are also agreeing to pay applicable fees like Startup Early’s
          Service Fee (and applicable taxes) for each booking.
        </p>
        <p>
          Your relationship with Startup Early is that of an independent
          individual or entity and not an employee, agent, joint venturer, or
          partner of Startup Early, except that Startup Early Payments acts as a
          payment collection agent as described in the Payments Terms. Startup
          Early does not direct or control your Host Service, and you agree that
          you have complete discretion whether and when to provide Host
          Services, and at what price and on what terms to offer them.
        </p>
        <p>
          You are responsible for understanding and complying with any Federal,
          Provincial or local laws, rules, regulations, and contracts with third
          parties that apply to your Listing or Host Services.
        </p>
        <p>
          You are responsible and liable for your own acts and omissions and are
          also responsible for the acts and omissions of anyone you allow to
          participate in providing your Host Services. You are responsible for
          setting your price and establishing rules and requirements for your
          Listing.
        </p>
        <p>
          Do not encourage Guests to create third-party accounts, submit
          reviews, provide their contact information, or take other actions
          outside the Airbnb Platform. In the event that Startup Early discovers
          that any Host has artificially inflated their Listing ranking in the
          Startup Early Platform, Startup Early reserves the right to take any
          action against the Host, which includes, a suspension or
          temporary/permanent ban of the Host from the Startup Early Platform
          for violation of the terms &amp; conditions.
        </p>
        <p>
          <strong>Terms Applicable to All Bookings</strong>
        </p>
        <p>
          You can book a Listing available on the Startup Early Platform by
          following the booking process.
        </p>
        <p>
          All applicable fees, Guest Fee will be presented to you prior to
          booking a Listing.
        </p>
        <p>
          You agree to pay the Total Fees for any booking requested in
          connection with your Startup Early Account.
        </p>
        <p>
          Upon receipt of a booking conﬁrmation from Startup Early, a legally
          mandatory agreement is formed between you and your Host, subject to
          any added terms and conditions of the Host that apply, including in
          particular the applicable cancellation policy and any rules and
          restrictions speciﬁed in the Listing.
        </p>
        <p>
          Startup Early Payments will collect the Total Fees at the time of the
          booking request or upon the Host’s conﬁrmation pursuant to the
          Payments Terms.
        </p>
        <p>
          If you book a Host Service on behalf of additional guests, you are
          required to ensure that every additional guest meets any requirements
          set by the Host, and is made aware of and agrees to these Terms and
          any terms and conditions, rules and restrictions set by the Host. You
          need authorization to act on the behalf of the minor or additional
          guest for making accommodation reservation on their behalf.
        </p>
        <p>
          Minors may only be eligible for Host Service if accompanied by an
          adult who is responsible for them.
        </p>
        <p>
          <strong>Booking Accommodations</strong>
        </p>
        <p>
          You comprehend that a conﬁrmed booking of an Accommodation is a
          limited license granted to you by the Host to arrive, lodge and use
          the Accommodation for the duration of your stay, during which time the
          Host retains the right to re-enter the Accommodation, in accordance
          with your agreement with the Host. You approve to leave the
          Accommodation no later than the checkout time that the Host speciﬁes
          in the Listing or such other time as mutually agreed upon between you
          and the Host. You are entitled to pay for overstays as per applicable
          agreement. If you Overstay at an Accommodation, you authorize Startup
          Early (via Startup Early Payments) to charge you to collect Overstay
          Fees.
        </p>
        <p>
          Booking Modifications, Cancellations, Refunds and Resolution Center:
        </p>
        <p>
          Hosts and Guests are responsible for any Booking Modifications they
          agree to make via the Startup Early Platform or direct Startup Early
          customer service to make on their behalf, and agree to pay any
          additional amounts, fees or taxes associated with a Booking
          Modification.
        </p>
        <p>
          In general, if a Guest cancels a reservation, the amount paid to you
          is determined by the cancellation policy that applies to that
          reservation. Unless justifying circumstances exist, any portion of the
          Total Fees due to the Host under the applicable cancellation policy
          will be remitted to the Host by Startup Early Payments pursuant to the
          Payments Terms. If a Guest receives a refund after you have already
          been paid, or the amount of the refund and other costs incurred by
          Startup Early exceeds your payout, Startup Early (via Startup Early
          Payments) may recover that amount from you, including by offsetting
          the refund against your future payouts.
        </p>
        <p>
          If a Guest or Startup Early cancels a conﬁrmed booking, and the Guest
          receives a refund in accordance with the Guest Refund Policy. Guest
          Refund Policy, Justifying Circumstances Policy, or the applicable
          cancellation policy set by the Host and mentioned in the Listing,
          after the Host has already been paid, Startup Early Payments will be
          permitted to recover the amount of any such refund from the Host,
          including by subtracting such refund amount out from any future
          Payouts due to the Host
        </p>
        <p>
          If a Host cancels a conﬁrmed booking, the Guest will receive a full
          refund of the Total Fees for such booking.
        </p>
        <p>
          If: (i) a Guest experiences a Travel Issue (as defined by the Guest
          Refund Policy), Startup Early may determine, in its sole discretion,
          to refund the Guest part or all of the Total Fees in accordance with
          the Guest Refund Policy Except as otherwise set out in these Terms,
          Members may use the Resolve Center to send or request money for
          refunds, additional Host Services or Damage Assertions related to
          bookings. You agree to pay all amounts sent through the Resolve Center
          in connection with your Startup Early Account, and Startup Early
          Payments will handle all such payments.
        </p>
        <p>
          <strong>Ratings and Review</strong>
        </p>
        <p>
          After completing a booking, each Host Service, Guests and Hosts will
          have an opportunity to review each other. Your Review must be accurate
          and may not contain any discriminatory, offensive, defamatory, or
          other language that violates our Content Policy or Review Policy, or
          any laws or regulations, whether Federal, Provincial or Local. Reviews
          are not verified by Airbnb for accuracy and may be incorrect or
          misleading. The Ratings or Reviews reflect the opinions of individual
          Members and do not imitate the opinion of Startup Early.
        </p>
        <p>
          <strong>
            Damage of Accommodations, and Disputes between Members
          </strong>
        </p>
        <p>
          As a Guest, you are responsible for leaving the Accommodation
          including any personal or other belongings located at the
          Accommodation in the condition it was in when you arrived. You are
          liable for your own acts and slips and are also responsible for the
          acts and lapses of any individuals whom you invite to, or otherwise
          provide access to, the Accommodation, excluding the Host and the
          individuals the Host invites to the Accommodation, if applicable.
        </p>
        <p>
          If a Host claims and provides proof that you as a Guest have damaged
          an Accommodation or any personal or other property at an Accommodation
          “Damage Claim”, the Host can seek payment from you through the Startup
          Early Resolve Center.
        </p>
        <p>
          <strong>Taxes</strong>
        </p>
        <p>
          As a Host you are solely responsible for determining your obligations
          to report, collect, remit or include in your Listing Fees any
          applicable VAT or other indirect sales taxes, tourist or other or
          income taxes (“Taxes”). You understand that any appropriate
          governmental agency, department and/or authority “Tax Authority” where
          your Accommodation is located may require Taxes to be collected from
          Guests or Hosts on Listing Fees, and to be remitted to the respective
          Tax Authority.
        </p>
        <p>
          Startup Early shall at no time, or in any event whatsoever be
          responsible for the payment for any tax, duty, levy, charge, fine, or
          any payment due by the Host to any governmental agency, department,
          authority, or quasi-judicial body, and/or court of law. The same shall
          remain the sole obligation of the Host.
        </p>
        <p>
          <strong>Prohibited Activities</strong>
        </p>
        <p>
          You are exclusively liable for obedience with any and all laws, rules,
          and regulation that may apply to your use of the Startup Early
          Platform. In association with your use of the Startup Early Platform,
          you will not and will not help or facilitate others to: (i) List, as a
          Host, any Accommodation that you do not yourself own or have
          authorization to make available as a residential or other property
          through the Startup Early Platform; (ii) Use the Startup Early
          Platform to appeal, make or accept a booking independent of the
          Startup Early Platform, to evade any Service Fees or for any other
          reason; (iii) Request, accept or make any payment for Listing Fees
          outside of the Startup Early Platform or Startup Early Payments; (iv)
          Discriminate against or harass anyone on the basis of race, national
          origin, religion, gender, gender identity, physical or mental
          disability, medical condition, marital status, age or sexual
          orientation, or otherwise engage in any violent, harmful, abusive or
          disruptive behavior.
        </p>
        <p>
          <strong>Term and Closure, Pause and other Measures</strong>
        </p>
        <p>
          Startup Early may immediately, without notice, terminate this
          Agreement and/or stop providing access to the Platform if you violate
          the terms. In addition, Startup Early may take any of the following
          measures:
        </p>
        <p>
          To comply with applicable law, or the order or request of a court, law
          enforcement or other administrative agency or governmental body, or;
        </p>
        <p>
          You have ruptured these Terms, the Payments Terms, our Policies or
          Standards, applicable laws, regulations, or third-party rights;
        </p>
        <p>
          You have provided imprecise, untrue, outdated or incomplete
          information during the Startup Early Account registration, listing
          process.
        </p>
        <p>
          <strong>Disclaimers</strong>
        </p>
        <p>
          You agree that you have had whatever occasion you deem necessary to
          investigate the Startup Early Services, laws, rules, or regulations
          that may be applicable to your Listings and/or Host Services you are
          receiving and that you are not relying upon any statement of law or
          fact made by Startup Early relating to a Listing. If we choose to
          conduct identity veriﬁcation or background checks on any Member, to
          the extent permitted by applicable law, we disclaim warranties of any
          kind, either express or implied, that such checks will identify
          earlier misconduct by a Member or guarantee that a Member will not
          engage in misconduct in the future.
        </p>
        <p>
          <strong>Liability</strong>
        </p>
        <p>
          You admit and agree that, to the maximum extent allowed by law, the
          entire risk arising out of your access to and use of the Startup Early
          Platform and Collective Content, your publishing or booking of any
          Listing via the Startup Early Platform, your stay at any
          Accommodation, or use of any other Host Service, participation in
          Payment Service, or any other interaction you have with other Members
          whether in person or online remains with you. Neither Startup Early
          nor any other party involved in forming, producing, or delivering the
          Startup Early Platform or Collective Content will be liable for any
          incidental, special, typical or consequential damages, including lost
          proﬁts, loss of data or loss of goodwill, service interruption,
          computer damage or system failure or the cost of substitute products
          or services, or for any damages for personal or bodily injury or
          emotional distress arising out of or in connection with:
        </p>
      </div>
    </div>
    <div
      className="elementor-element elementor-element-b6cf348 elementor-widget elementor-widget-text-editor"
      data-id="b6cf348"
      data-element_type="widget"
      data-widget_type="text-editor.default"
    >
      <div className="elementor-widget-container">
        <p>These Terms &amp; Conditions;</p>
        <p>&nbsp;</p>
        <p>
          From the use of or inability to use the Startup Early Platform or
          Collective Content;
        </p>
        <p>&nbsp;</p>
        <p>
          From any communications, interactions or meetings with other Members
          or other persons with whom you communicate, interact or meet with as a
          result of your use of the Startup Early Platform incidental damages,
          so the above limitation may not apply to you.
        </p>
        <p>&nbsp;</p>
      </div>
    </div>
    <div
      className="elementor-element elementor-element-17500a1 elementor-widget elementor-widget-text-editor"
      data-id="17500a1"
      data-element_type="widget"
      data-widget_type="text-editor.default"
    >
      <div className="elementor-widget-container">
        <p>
          <strong>Disclaimer</strong>
        </p>
        <p>
          We provide the Startup Early Platform and all Content “as is” without
          warranty of any kind and we disclaim all warranties, whether express
          or implied. For example: (i) we do not endorse or warrant the
          existence, conduct, performance, safety, quality, legality or
          suitability of any Guest, Host, Host Service, Listing or third party;
          (ii) we do not warrant the performance or non-interruption of the
          Startup Early Platform; and (iii) we do not warrant that verification,
          identity or background checks conducted on Listings or Members (if
          any) will identify past misconduct or prevent future misconduct. Any
          references to a Member or Listing being “verified” (or similar
          language) indicate only that the Member or Listing or Airbnb has
          completed a relevant verification or identification process and
          nothing else. The disclaimers in these Terms apply to the maximum
          extent permitted by law. If you have statutory rights or warranties,
          we cannot disclaim, the duration of any such statutorily required
          rights or warranties, will be limited to the maximum extent permitted
          by law.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Limitation of Liability</strong>
        </p>
        <p>
          Neither Startup Early (including its affiliates and personnel) nor any
          other party involved in creating, producing, or delivering the Startup
          Early Platform or any Content will be liable for any incidental,
          special, exemplary or consequential damages, including lost profits,
          data breach, loss of data or loss of goodwill, service interruption,
          computer damage or system failure or the cost of substitute products
          or services, or for any damages for personal or bodily injury or
          emotional distress arising out of or in connection with (i) these
          Terms, (ii) the use of or inability to use the Startup Early Platform
          or any Content, (iii) any communications, interactions or meetings you
          may have with someone you interact or meet with through, or as a
          result of, your use of the Startup Early Platform, or (iv) publishing
          or booking of a Listing, including the provision or use of Host
          Services, whether based on warranty, contract, tort (including
          negligence), product liability or any other legal theory, and whether
          or not Startup Early has been informed of the possibility of such
          damage.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Feedback</strong>
        </p>
        <p>
          Startup Early welcome and encourage you to provide feedback, comments
          and suggestions for improvements to the Startup Early Platform
          Feedback. You may submit Feedback by emailing us, through the
          “Contact” section of the Startup Early Platform.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Applicable Law and Jurisdiction</strong>
        </p>
        <p style={{ textAlign: "center" }}>
          <strong>Payment Terms of Service</strong>
        </p>
        <p>
          <strong>Scope and Use of the Payment Services</strong>
        </p>
      </div>
    </div>
    <div
      className="elementor-element elementor-element-20b6e90 elementor-widget elementor-widget-text-editor"
      data-id="20b6e90"
      data-element_type="widget"
      data-widget_type="text-editor.default"
    >
      <div className="elementor-widget-container">
        <p>
          Startup Early Payments delivers payments services to Members,
          including payment collection services, payments and payouts to hosts,
          in connection with and through the Startup Early Platform.
        </p>
        <p>&nbsp;</p>
        <p>
          Startup Early Payments may provisionally and under consideration of
          the Members’ genuine interests, limit the obtainability of the Payment
          Services, or certain services or features thereof, to carry out up
          gradation measures that ensure the proper or improved functioning of
          the Payment Services. Startup Early Payments may improve, enhance and
          modify the Payment Services and introduce new Payment Services from
          time to time. Startup Early Payments will provide notice to Members of
          any changes to the Payment Services, unless such changes do not
          materially increase the Members’ contractual obligations or decrease
          the Members’ rights under these Payments Terms.
        </p>
        <p>&nbsp;</p>
        <p>
          The Payment Services may contain links to third-party websites or
          resources (the “Third- Party Services”). Such Third-Party Services are
          subject to different terms and conditions and privacy practices and
          Members should review them independently. Startup Early Payments is
          not responsible or liable for the availability or accuracy of such
          Third-Party Services, or the content, products, or services available
          from such Third-Party Services. Links to such Third-Party Services are
          not an endorsement by Startup Early Payments of such Third-Party
          Services.
        </p>
        <p>&nbsp;</p>
        <p>
          You may not use the Payment Services except as authorized, the laws of
          the jurisdiction that is your country of residence, and any other
          applicable laws.
        </p>
      </div>
    </div>
    <div
      className="elementor-element elementor-element-66efd8a elementor-widget elementor-widget-heading"
      data-id="66efd8a"
      data-element_type="widget"
      data-widget_type="heading.default"
    >
      <div className="elementor-widget-container">
        <p className="elementor-heading-title elementor-size-default">
          <b>Modification of these Payment Terms</b>
        </p>
      </div>
    </div>
    <div
      className="elementor-element elementor-element-a0b82bc elementor-widget elementor-widget-text-editor"
      data-id="a0b82bc"
      data-element_type="widget"
      data-widget_type="text-editor.default"
    >
      <div className="elementor-widget-container">
        <p>
          Startup Early Payments keeps sole discretion to modify these Payments
          Terms at any time in accordance with this provision. If we make
          changes to these Payments Terms, we will post the revised Payments
          Terms on the Startup Early Platform.
        </p>
      </div>
    </div>
    <div
      className="elementor-element elementor-element-458d570 elementor-widget elementor-widget-heading"
      data-id="458d570"
      data-element_type="widget"
      data-widget_type="heading.default"
    >
      <div className="elementor-widget-container">
        <p className="elementor-heading-title elementor-size-default">
          <b>Eligibility, and Member Verification</b>
        </p>
      </div>
    </div>
    <div
      className="elementor-element elementor-element-9e6f8af elementor-widget elementor-widget-text-editor"
      data-id="9e6f8af"
      data-element_type="widget"
      data-widget_type="text-editor.default"
    >
      <div className="elementor-widget-container">
        <p>
          You must be at least 18 years old and able to enter into legally
          requisite contracts to use the Payment Services. By using the Payment
          Services, you represent and warrant that you are 18 or older.
        </p>
        <p>&nbsp;</p>
        <p>
          Startup Early Payments may make access to and use of certain areas or
          features of the Payment Services subject to certain conditions or
          requirements, such as completing a veriﬁcation process or meeting
          speciﬁc eligibility criteria.
        </p>
        <p>&nbsp;</p>
        <p>
          We may make inquiries we consider necessary to help verify or check
          your identity or prevent fraud. Towards this end, you authorize
          Startup Early Payments to screen you against third party databases or
          other sources and request reports from service providers.
        </p>
      </div>
    </div>
    <div
      className="elementor-element elementor-element-71540b5 elementor-widget elementor-widget-heading"
      data-id="71540b5"
      data-element_type="widget"
      data-widget_type="heading.default"
    >
      <div className="elementor-widget-container">
        <p className="elementor-heading-title elementor-size-default">
          <b>Payment Methods</b>
        </p>
      </div>
    </div>
    <div
      className="elementor-element elementor-element-e75fc01 elementor-widget elementor-widget-heading"
      data-id="e75fc01"
      data-element_type="widget"
      data-widget_type="heading.default"
    >
      <div className="elementor-widget-container">
        <p className="elementor-heading-title elementor-size-default">
          At present, the proposed payment channels are:
        </p>
      </div>
    </div>
    <div
      className="elementor-element elementor-element-3d8cab5 elementor-widget elementor-widget-text-editor"
      data-id="3d8cab5"
      data-element_type="widget"
      data-widget_type="text-editor.default"
    >
      <div className="elementor-widget-container">
        <p>Master Card;</p>
        <p>&nbsp;</p>
        <p>Visa Card;</p>
        <p>&nbsp;</p>
        <p>Eaisy Paisa</p>
      </div>
    </div>
    <div
      className="elementor-element elementor-element-d5f1861 elementor-widget elementor-widget-text-editor"
      data-id="d5f1861"
      data-element_type="widget"
      data-widget_type="text-editor.default"
    >
      <div className="elementor-widget-container">
        <p>
          Startup Early reserves the right to add/remove payment channels at its
          sole discretion.
        </p>
        <p>
          When you add a Payment Method or Payout Method to your Startup Early
          Account, you will be asked to provide ﬁnancial instrument information
          either to Startup Early Payments or its third-party payment processor.
          You must provide accurate, current, and complete information when
          adding a Payment Method or Payout Method, and it is your obligation to
          keep your Payment Method and Payout Method up-to-date at all times.
          The information required for Payout Methods will depend on the
          particular Payout Method and may include: Your residential address,
          name on the account, account type, routing number, account number,
          email address, payout currency, identiﬁcation number and account
          information associated with a particular payment processor.
        </p>
        <p>
          When you add or use a new Payment Method, Startup Early Payments may
          verify the Payment Method by authorizing a nominal amount or by
          authenticating your account via a third-party payment service
          provider.
        </p>
        <p>
          Please note that Payment Methods and Payout Methods may involve the
          use of third- party payment service providers. These service providers
          may charge you additional fees when processing payments and Payouts in
          connection with the Payment Services including deducting charges from
          the Payout amount, and Startup Early Payments is not responsible for
          any such fees and disclaims all liability in this regard. Your Payment
          Method or Payout Method may also be subject to additional terms and
          conditions imposed by the applicable third-party payment service
          provider; please review these terms and conditions before using your
          Payment Method or Payout Method.
        </p>
        <p>
          You authorize Startup Early Payments to store your Payment Method
          information and charge your Payment Method as outlined in these
          Payments Terms. If your Payment Method’s account information changes
          e.g., account number, expiration date as a result of re-issuance or
          otherwise, we may acquire that information from our ﬁnancial services
          partner or your bank and update your Payment Method on ﬁle in your
          Startup Early Account.
        </p>
        <p>
          You are solely liable for the accuracy and inclusiveness of your
          Payment Method and Payout Method information. Startup Early Payments
          is not responsible for any loss suffered by you as a result of
          incorrect Payment Method or Payout Method information provided by you.
        </p>
        <p>
          <strong>Financial Terms for Hosts</strong>
        </p>
        <p>
          Startup Early Payments will collect the Total Fees from a Guest at the
          time the Guest’s booking request is accepted by the host, or at any
          other time mutually agreed between the Guest and Startup Early
          Payments.
        </p>
        <p>
          In order to receive a Payout, you must have a valid Payout Method
          linked to your Startup Early Account. Startup Early Payments will
          generally initiate Payouts to your selected Payout Method.
        </p>
        <p>
          The time it takes to receive Payouts once released by Startup Early
          Payments may depend upon the Payout Method you select and the Payout
          Method provider’s processing schedule.
        </p>
        <p>
          Startup Early Payments may delay or cancel any Payout for purposes of
          preventing unlawful activity or fraud, for risk aversion, security, or
          investigation.
        </p>
        <p>
          In the event of a Guest’s cancellation of a conﬁrmed booking, Startup
          Early Payments will remit a Payout of any portion of the Total Fees
          due to you under the applicable cancellation policy.
        </p>
        <p>
          <strong>Financial Terms for Guests</strong>
        </p>
        <p>
          You allow Startup Early Payments to charge your Payment Method the
          Total Fees for any booking requested in connection with your Startup
          Early Account. Startup Early Payments will collect the Total Fees in
          the manner agreed between you and Startup Early Payments via the
          Startup Early Platform.
        </p>
        <p>
          If a requested booking is cancelled either because it is not accepted
          by the Host or you cancel the booking request before it is accepted by
          the Host, any amounts collected by Startup Early Payments will be
          refunded to you, and any pre-authorization of your Payment Method will
          be released if applicable. The timing to receive the refund or for the
          pre- authorization to be released will vary based on the Payment
          Method and any applicable payment system rules.
        </p>
        <p>
          <strong>Fees</strong>
        </p>
        <p>
          Startup Early Payments may charge fees for use of certain Payment
          Services and any applicable fees will be disclosed to Members via the
          Startup Early Platform.
        </p>
        <p>
          <strong>Payment Authorizations</strong>
        </p>
        <p>
          You authorize Startup Early Payments to collect from you amounts due
          agreeable to these Payment Terms or the Startup Early Terms.
          Speciﬁcally, you authorize Startup Early Payments to collect from you:
          Any amount due to Startup Early such as, Booking Modiﬁcations,
          cancellations, or other actions as a Guest, Host or user of the
          Startup Early Platform, including reimbursement for costs prepaid by
          Startup Early on your behalf, by charging any Payment Method on ﬁle in
          your Startup Early Account. Any funds collected by Startup Early
          Payments will set off the amount owed by you to Startup Early and
          extinguish your obligation to Startup Early.
        </p>
        <p>
          Overstay Fees payable under the Startup Early Terms. In addition,
          Startup Early Payments may recover any costs and expenses it suffers
          in collecting the Overstay Fees by charging any Payment Method you
          have on ﬁle in your Startup Early Account.
        </p>
        <p>
          Any Service Fees or cancellation fees imposed agreeable to the Startup
          Early Terms such as if you cancel conﬁrmed booking. Startup Early
          Payments will be entitled to recover the amount of any such fees from
          you, including by subtracting such refund amount out from any future
          Payouts due to you.
        </p>
        <p>
          If as a Host, your Guest cancels a conﬁrmed booking or Startup Early
          decides that it is necessary to cancel a conﬁrmed booking, and Startup
          Early issues a refund to the Guest in accordance with the Startup
          Early Terms, you agree that in the event you have already been paid,
          Startup Early Payments will be entitled to recover the amount of any
          such refund from you, including by subtracting such refund amount out
          from any future Payouts due to you.
        </p>
        <p>
          <strong>Refunds</strong>
        </p>
        <p>
          Any refunds or credits due to a Member pursuant to the Startup Early
          Terms, Justifying Circumstances Policy, Guest Refund Policy, will be
          initiated and remitted by Startup Early Payments in accordance with
          these Payments Terms.
        </p>
        <p>
          Startup Early Payments will process refunds immediately, however, the
          timing to receive any refund will vary based on the Payment Method and
          any applicable payment system.
        </p>
        <p>
          We will take steps to rectify any payment processing errors that we
          become aware of. These steps may include crediting or debiting the
          same Payout Method or Payment Method used for the original Payout to
          or payment by you, so that you end up receiving or paying the correct
          amount. This may be performed by Startup Early Payments or a third
          party such as your ﬁnancial institution.
        </p>
        <p>
          <strong>Disclaimers</strong>
        </p>
        <p>
          If you choose to use the Payment Services, you do so voluntarily and
          at your sole risk. To the maximum extent permitted by law, the Payment
          Services are provided “as is”, without warranty of any kind, either
          express or implied.
        </p>
        <p>Applicable Law and Jurisdictions</p>
        <p>
          <strong>Additional Services by Hosts</strong>
        </p>
        <p>
          Hosts may need to use third party services available through the
          Startup Early Platform to assist with managing their Accommodation or
          providing additional services requested by you, such as cleaning
          services or lock providers. Hosts may use features on the Startup
          Early Platform to share information about the Guest (like check-in and
          check-out dates, Guest name, Guest phone number) with such third-party
          service providers for the purposes of coordinating the stay, managing
          the Accommodation, or providing other services. Hosts are responsible
          for third party service providers they use and ensuring those service
          providers process Guest information securely and in compliance with
          applicable law including data privacy and data protection laws.
        </p>
        <p>
          Startup Early disclaims all liability for the acts and/or omissions of
          any third-party service provider providing additional services.
          Startup Early shall not be responsible for any loss of data, data
          breach, loss of Confidential Information, or any similar loss if the
          same is due to the fault and/or omission of the third-party service.
        </p>
        <p>
          <strong>Other Important Information&nbsp;</strong>
        </p>
        <p>
          <strong>Managing your Information</strong>
        </p>
        <p>
          You may access and update some of your information through your
          Account settings. If you have chosen to connect your Startup Early
          Account to a third-party application, like Facebook or Google, you can
          change your settings and remove permission for the app by changing
          your Account settings. You are responsible for keeping your personal
          information up-to-date.
        </p>
        <p>
          <strong>Rectification of Inaccurate or Incomplete Information</strong>
        </p>
        <p>
          You have the right to ask us to correct inaccurate or incomplete
          personal information about you (and which you cannot update yourself
          within your Startup Early Account).
        </p>
        <p>
          <strong>Host Guarantee</strong>
        </p>
        <p>
          <strong>Guest Refund:</strong>
        </p>
        <p>
          These terms and conditions govern Startup Early policy for Guest
          refunds and the obligations of the Host associated with the Guest
          Refund Policy. The Guest Refund Policy applies in addition to Startup
          Early Terms of Service. The Guest Refund Policy is available to Guests
          who book and pay for an Accommodation through the Startup Early
          Platform and suffer a Travel Issue as deﬁned below. The Guest’s rights
          under this Guest Refund Policy will surpass the Host’s cancellation
          policy. By using the Startup Early Platform as a Host or Guest, you
          are indicating that you have read and that you understand and agree to
          be bound by this Guest Refund Policy. This refund policy will be
          applicable if:
        </p>
        <p>
          The Host of the accommodation: Cancels a booking shortly before the
          scheduled start of the booking, or Fails to provide the Guest with the
          reasonable ability to access the Accommodation;
        </p>
        <p>
          The Listing’s description or depiction of the accommodation is
          materially inaccurate with respect to:
        </p>
        <p>
          The size of the accommodation (e.g., number and size of the bedroom,
          bathroom and/or kitchen or other rooms),whether the Booking for the
          Accommodation is for an entire home, private room or shared room, and
          whether another party, including the Host, is staying at the
          Accommodation during the booking size of the accommodation (e.g.,
          number and size of the bedroom, bathroom and/or kitchen or other
          rooms),whether the Booking for the Accommodation is for an entire
          home, private room or shared room, and whether another party,
          including the Host, is staying at the Accommodation during the
          Booking.
        </p>
        <p>
          <strong>Guest Refund Policy:</strong>
        </p>
        <p>
          If you are a Guest and suffer a Travel Issue, we agree, at our
          decision, to either:
        </p>
        <p>
          Reimburse you up to the amount paid by you through the Startup Early
          Platform “Total Fees” depending on the nature of the Travel Issue
          suffered OR use our reasonable efforts to ﬁnd and book you another
          accommodation for any unused nights left in your booking which is
          reasonably similar to the Accommodation described in your original
          booking in terms of size, rooms, features and quality. All
          determinations of Startup Early with respect to the Guest Refund
          Policy, including without limitation the size of any refund and the
          comparability of alternate Accommodations, shall be in Startup Early
          discretion, and ﬁnal and obligatory on the Guests and Hosts.
        </p>
        <p>
          <strong>Conditions to Claim a Travel Issue</strong>
        </p>
        <p>
          To submit a valid claim for a Travel Issue and receive the beneﬁts
          with respect to your booking, you are required to meet each of the
          following conditions:
        </p>
        <p>You must be the Guest that booked the accommodation;</p>
        <p>
          You must bring the Travel Issue to our attention in writing or via
          telephone and provide us with information including photographs or
          other evidence about the Accommodation and the circumstances of the
          Travel Issue within 24 hours after the later of:
        </p>
        <p>the start of your Booking; Effective date | November 05, 2019</p>
      </div>
    </div>
  </div>
</>


        </div>
    )
}

export default Policy;