import React, { useState, useEffect } from "react";
import Navbar from "../Static/Navbar";
import Hero from "./Hero";
import LandingStats from "./LandingStats";
import FeaturedNavs from "./FeaturedNavs";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import LogoCloud from "./LogoCloud";
import Footer from "./Footer";

function GetDemo() {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => setIsOpen(!isOpen);

  return (
    <div>
      <Navbar />

      <div
        className="bg-[#FCF9FF]"
        style={{
          backgroundImage: `url('https://res.cloudinary.com/ahbranding/image/upload/v1708365366/AHbranding/Asset_3_1_grh1cw.png')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div class="">
          <>
            {/* Contact Us */}
            <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
              <div className="max-w-2xl lg:max-w-5xl mx-auto">
                <div className="mt-12 grid items-start lg:grid-cols-2 gap-6 lg:gap-16">
                  {/* Card */}

                  {/* End Card */}
                  <div className="divide-y divide-gray-200 darki:divide-gray-800">
                    {/* Icon Block */}
                    <div className="flex gap-x-7 py-6">
                      <div className="grow">
                        <h2 className="font-semibold text-black text-gray-800 darki:text-gray-200">
                          Ready to see Start Up Early in action?
                        </h2>
                        <p className="mt-1 text-base font-medium text-gray-500">
                          Talk to our friendly team to learn how Start Up Early
                          could streamline extracurricular management at your
                          school.
                        </p>
                        <br />
                        <h4 className="text-primary mt-4">
                          How Start Up Early can help you
                        </h4>

                        <ul class="space-y-3 text-sm">
                          <li class="flex space-x-3">
                            <span class="size-5 flex justify-center items-center rounded-full bg-[#4f069621] text-primary darki:bg-blue-800/30 darki:text-blue-500">
                              <svg
                                class="flex-shrink-0 size-3.5"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <polyline points="20 6 9 17 4 12" />
                              </svg>
                            </span>
                            <span class="text-gray-800 darki:text-gray-400">
                            Increase child safety
                            </span>
                          </li>

                          <li class="flex space-x-3">
                            <span class="size-5 flex justify-center items-center rounded-full bg-[#4f069621] text-primary darki:bg-blue-800/30 darki:text-blue-500">
                              <svg
                                class="flex-shrink-0 size-3.5"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <polyline points="20 6 9 17 4 12" />
                              </svg>
                            </span>
                            <span class="text-gray-800 darki:text-gray-400">
                            Save time and money on administration
                            </span>
                          </li>

                          <li class="flex space-x-3">
                            <span class="size-5 flex justify-center items-center rounded-full bg-[#4f069621] text-primary darki:bg-blue-800/30 darki:text-blue-500">
                              <svg
                                class="flex-shrink-0 size-3.5"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <polyline points="20 6 9 17 4 12" />
                              </svg>
                            </span>
                            <span class="text-gray-800 darki:text-gray-400">
                            Improve the parent and student experience
                            </span>
                          </li>
                        </ul>
<br/>
                        <h4 className="text-primary mt-4">
                        Leading schools use Start Up Early
                        </h4>
                        <div className="mt-10 overflow-x-auto pb-6">
          <div className="grid grid-cols-3 gap-4">
            {/* Logo 1 */}
            <div className="">
              <img
                className="h-auto w-[8rem] bg-white"
                src="https://res.cloudinary.com/ahbranding/image/upload/v1708414683/AHbranding/Group_34784435_nxbpul.png"
                alt="Logo 1"
              />
            </div>
            {/* Logo 2 */}
            <div className="">
              <img
                className="h-auto w-[8rem] bg-white"
                src="https://res.cloudinary.com/ahbranding/image/upload/v1708414683/AHbranding/Group_34784436_mdjdc7.png"
                alt="Logo 2"
              />
            </div>
            {/* Logo 3 */}
            <div className="">
              <img
                className="h-auto w-[8rem] bg-white"
                src="https://res.cloudinary.com/ahbranding/image/upload/v1708414683/AHbranding/Group_34784433_u2m3rq.png"
                alt="Logo 3"
              />
            </div>
            {/* Logo 4 */}
            <div className="">
              <img
                className="h-auto w-[8rem] bg-white"
                src="https://res.cloudinary.com/ahbranding/image/upload/v1708414683/AHbranding/Group_34784432_dbiish.png"
                alt="Logo 4"
              />
            </div>
            {/* Logo 5 */}
            <div className="">
              <img
                className="h-auto w-[8rem] bg-white"
                src="https://res.cloudinary.com/ahbranding/image/upload/v1708414682/AHbranding/Group_34784434_mytvjq.png"
                alt="Logo 5"
              />
            </div>
          </div>
        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col border bg-white rounded-xl p-4 sm:p-6 lg:p-8 darki:border-gray-700">
                    <h2 className="mb-8 text-xl font-semibold text-gray-800 darki:text-gray-200">
                      Fill in the form
                    </h2>
                    <form>
                      <div className="grid gap-4">
                        {/* Grid */}
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                          <div>
                            <label
                              htmlFor="hs-firstname-contacts-1"
                              className="mb-2"
                            >
                              First Name <span className="text-red-600">*</span>
                            </label>
                            <input
                              type="text"
                              name="hs-firstname-contacts-1"
                              id="hs-firstname-contacts-1"
                              className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none darki:bg-slate-900 darki:border-gray-700 darki:text-gray-400 darki:focus:ring-gray-600"
                              placeholder="John"
                            />
                          </div>
                          <div>
                            <label
                              htmlFor="hs-lastname-contacts-1"
                              className="mb-2"
                            >
                              Last Name <span className="text-red-600">*</span>
                            </label>
                            <input
                              type="text"
                              name="hs-lastname-contacts-1"
                              id="hs-lastname-contacts-1"
                              className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none darki:bg-slate-900 darki:border-gray-700 darki:text-gray-400 darki:focus:ring-gray-600"
                              placeholder="Doe"
                            />
                          </div>
                        </div>
                        {/* End Grid */}
                        <div>
                          <label
                            htmlFor="hs-email-contacts-1"
                            className="mb-2"
                          >
                            Work Email <span className="text-red-600">*</span>
                          </label>
                          <input
                            type="email"
                            name="hs-email-contacts-1"
                            id="hs-email-contacts-1"
                            autoComplete="email"
                            className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none darki:bg-slate-900 darki:border-gray-700 darki:text-gray-400 darki:focus:ring-gray-600"
                            placeholder="john.doe@company.com"
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="hs-phone-number-1"
                            className="mb-2"
                          >
                            Phone Number
                          </label>
                          <input
                            type="text"
                            name="hs-phone-number-1"
                            id="hs-phone-number-1"
                            className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none darki:bg-slate-900 darki:border-gray-700 darki:text-gray-400 darki:focus:ring-gray-600"
                            placeholder="Phone Number"
                          />
                        </div>
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                          <div>
                            <label
                              htmlFor="hs-firstname-contacts-1"
                              className="mb-2"
                            >
                              School / Institute <span className="text-red-600">*</span>
                            </label>
                            <input
                              type="text"
                              name="hs-firstname-contacts-1"
                              id="hs-firstname-contacts-1"
                              className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none darki:bg-slate-900 darki:border-gray-700 darki:text-gray-400 darki:focus:ring-gray-600"
                              placeholder="John"
                            />
                          </div>
                          <div>
                            <label
                              htmlFor="hs-lastname-contacts-1"
                              className="mb-2"
                            >
                              Role / Position <span className="text-red-600">*</span>
                            </label>
                            <input
                              type="text"
                              name="hs-lastname-contacts-1"
                              id="hs-lastname-contacts-1"
                              className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none darki:bg-slate-900 darki:border-gray-700 darki:text-gray-400 darki:focus:ring-gray-600"
                              placeholder="Doe"
                            />
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor="hs-about-contacts-1"
                            className="mb-2"
                          >
                            What is your school looking for?

                          </label>
                          <textarea
                            id="hs-about-contacts-1"
                            name="hs-about-contacts-1"
                            rows={4}
                            className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none darki:bg-slate-900 darki:border-gray-700 darki:text-gray-400 darki:focus:ring-gray-600"
                            placeholder="Details"
                            defaultValue={""}
                          />
                        </div>
                      </div>
                      {/* End Grid */}
                      <div className="mt-4 grid">
                        <button
                          type="submit"
                          className="btn btn-primary"
                        >
                          Book a demo
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* End Contact Us */}
          </>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default GetDemo;
