import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Hero() {
    const { t } = useTranslation();

    return (
    <div className='bg-[#FCF9FF]' style={{ backgroundImage: `url('https://res.cloudinary.com/ahbranding/image/upload/v1708365366/AHbranding/Asset_3_1_grh1cw.png')`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
<div class="container px-6 py-16 mx-auto text-center ">
        <div class="max-w-xl mx-auto">
            <h1 class="text-5xl font-semibold text-gray-800 darki:text-white">{t('heroMainTitle')}</h1>
            <p class="mt-6 text-gray-500 darki:text-gray-300">{t('heroDescription')}</p>
            <Link to='/book_demo' class="btn btn-primary">
                {t('BookDemo')}
            </Link>
        </div>

        <div class="flex justify-center mt-10">
            <img class="img fit w-full h-full rounded-xl lg:w-4/5" src="https://res.cloudinary.com/ahbranding/image/upload/v1708364790/AHbranding/1Group_34784382_w0ekrz.png" />
        </div>
    </div>
</div>
    )
}

export default Hero;