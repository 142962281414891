import React, { useState, useEffect } from "react";
import Navbar from "../Static/Navbar";
import Hero from "./Hero";
import LandingStats from "./LandingStats";
import FeaturedNavs from "./FeaturedNavs";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import LogoCloud from "./LogoCloud";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import FeaturesLeft from "./FeatureLeft";
import FeaturesRight from "./FeaturesRight";
import AOS from "aos";
import "aos/dist/aos.css";
import { MdOutlineSwipeLeft } from "react-icons/md";

import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/swiper-bundle.min.css';
import 'swiper/css';
import 'swiper/css/pagination';


function Features_Leap() {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => setIsOpen(!isOpen);
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);


  const features = [
    {
      position: 'left',
      title: 'Quick Extracurricular Activity Logging',
      img:'https://res.cloudinary.com/ahbranding/image/upload/v1710084314/AHbranding/Group_34784597_fr1u33.png',
      desc: 'Enables users to swiftly log extracurricular activities for on-the-go updates. Whether it’s attending a club meeting or participating in a sports event, users can easily input their activities, ensuring real-time updates and accurate records of their involvement.',
    },
    {
      position: 'right',
      title: 'Access Offline Data and Functionalities',
      img:'https://res.cloudinary.com/ahbranding/image/upload/v1710084313/AHbranding/Group_34784598_azial8.png',
      desc: 'Allows users to access data and functionalities offline, ensuring uninterrupted use even in areas with limited connectivity. Whether on a smartphone, tablet, or desktop, users can rely on this tool to maintain access to essential information and features without being reliant on internet access.',
    },
    {
      position: 'left',
      title: 'Consistent Information Across Devices',
      img:'https://res.cloudinary.com/ahbranding/image/upload/v1710084313/AHbranding/Group_34784599_ohssyy.png',
      desc: 'Ensures consistent information across smartphones, tablets, and desktops, providing a seamless user experience regardless of the device being used. Users can access their extracurricular data from any device, with updates and changes reflected uniformly across all platforms.',
    },
    {
      position: 'right',
      title: 'Instant Activity Viewing and Updates',
      img:'https://res.cloudinary.com/ahbranding/image/upload/v1710084315/AHbranding/Group_34784600_tzn1zh.png',
      desc: 'Users can instantly view their activities, track progress, and stay informed about upcoming events. Whether checking recent participation or planning future engagements, users have quick access to pertinent information, enhancing their ability to stay organized and engaged in extracurricular pursuits.',
    },
    {
      position: 'left',
      title: 'Tailored Activity Suggestions',
      img:'https://res.cloudinary.com/ahbranding/image/upload/v1710084314/AHbranding/Group_34784602_tftnql.png',
      desc: "Provides users with tailored activity suggestions based on their interests and preferences. By analyzing user data, including past participation and stated interests, the tool offers personalized recommendations for extracurricular activities, maximizing user engagement and satisfaction.",
    },
    {
      position: 'right',
      title: 'Extracurricular Goal Setting and Monitoring',
      img:'https://res.cloudinary.com/ahbranding/image/upload/v1710084313/AHbranding/Group_34784601_wrar1j.png',
      desc: 'Empowers users to set and monitor extracurricular goals to foster achievement. Whether aiming to improve skills, increase participation, or achieve specific milestones, users can establish goals within the platform and track their progress over time, facilitating personal growth and development.',
    },
  ];

  


  return (
    <div>
      <Navbar />

      <div
        className="bg-[#FCF9FF]"
        style={{
          backgroundImage: `url('https://res.cloudinary.com/ahbranding/image/upload/v1708365366/AHbranding/Asset_3_1_grh1cw.png')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div class="container px-6 py-16 mx-auto text-center ">
          <div class="max-w-5xl mx-auto">
            <span className="block text-primary uppercase font-bold ">
              {" "}
              LEAP MOBILE APPLICATION{" "}
            </span>{" "}
            <br />
            <h1 class="text-3xl md:text-5xl font-semibold text-gray-800 darki:text-white">
            Streamline extracurriculars with LEAP: Discover, join, and track activities on-the-go.
            </h1>
            <p class="mt-6 text-gray-500 darki:text-gray-300">
            Our mobile application extends the power of the Extracurricular Management System (EMS) directly into the hands of students, parents, and staff, enabling seamless management and engagement with extracurricular activities anytime, anywhere. Designed with a user-friendly, mobile-first approach, our app ensures that the full spectrum of EMS capabilities is just a tap away.
            </p>
            <Link to="/book_demo" class="btn btn-primary">
              Book a demo
            </Link>
          </div>
        </div>
      </div>
<div className="block md:hidden m-6">
<div
  className="bg-gray-50 border border-gray-200 text-sm text-gray-600 rounded-lg p-4 darki:bg-white/[.05] darki:border-white/10 darki:text-gray-400"
  role="alert"
>
  <div className="flex">
    <div className="flex-shrink-0">
      <MdOutlineSwipeLeft />
    </div>
    <div className="flex-1 md:flex md:justify-between ms-2">
      <p className="text-sm m-0">
        Swipe left/right to explore more features
      </p>
    </div>
  </div>
</div>

</div>

      <div className="md:hidden">
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          onSlideChange={() => console.log('slide change')}
          onSwiper={(swiper) => console.log(swiper)}
        >
          {features.map((feature, index) => (
            <SwiperSlide key={index}>
              {feature.position === 'left' ? (
                <FeaturesLeft title={feature.title} desc={feature.desc} img={feature.img} />
              ) : (
                <FeaturesRight title={feature.title} desc={feature.desc} img={feature.img} />
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {/* Desktop view: Standard layout */}
      <div className="hidden md:block">
        {features.map((feature, index) => (
          <React.Fragment key={index}>
            {feature.position === 'left' ? (
              <FeaturesLeft title={feature.title} desc={feature.desc} img={feature.img} />
            ) : (
              <FeaturesRight title={feature.title} desc={feature.desc} img={feature.img} />
            )}
          </React.Fragment>
        ))}
      </div>



      
<>
  {/* Testimonials */}
  <div className="relative max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
    {/* Blockquote */}
    <blockquote className="text-center lg:mx-auto lg:w-4/5">
      <div>
      <img class="hidden inline-block size-[62px] rounded-full w-[6rem] h-auto" src="https://static.vecteezy.com/system/resources/previews/036/280/651/non_2x/default-avatar-profile-icon-social-media-user-image-gray-avatar-icon-blank-profile-silhouette-illustration-vector.jpg" alt="Image Description"/>
      </div>
      <div className="mt-6 lg:mt-10">
        <p className="relative text-xl sm:text-2xl md:text-2xl md:leading-normal font-medium text-gray-800">
          <svg
            className="absolute top-0 start-0 transform -translate-x-8 -translate-y-8 size-16 text-gray-200 sm:h-24 sm:w-24 darki:text-gray-700"
            width={16}
            height={13}
            viewBox="0 0 16 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <path
              d="M7.18079 9.25611C7.18079 10.0101 6.93759 10.6211 6.45119 11.0891C5.96479 11.5311 5.35039 11.7521 4.60799 11.7521C3.71199 11.7521 2.96958 11.4531 2.38078 10.8551C1.81758 10.2571 1.53598 9.39911 1.53598 8.28111C1.53598 7.08511 1.86878 5.91511 2.53438 4.77111C3.22559 3.60111 4.18559 2.67811 5.41439 2.00211L6.29759 3.36711C5.63199 3.83511 5.09439 4.35511 4.68479 4.92711C4.30079 5.49911 4.04479 6.16211 3.91679 6.91611C4.14719 6.81211 4.41599 6.76011 4.72319 6.76011C5.43999 6.76011 6.02879 6.99411 6.48959 7.46211C6.95039 7.93011 7.18079 8.52811 7.18079 9.25611ZM14.2464 9.25611C14.2464 10.0101 14.0032 10.6211 13.5168 11.0891C13.0304 11.5311 12.416 11.7521 11.6736 11.7521C10.7776 11.7521 10.0352 11.4531 9.44639 10.8551C8.88319 10.2571 8.60159 9.39911 8.60159 8.28111C8.60159 7.08511 8.93439 5.91511 9.59999 4.77111C10.2912 3.60111 11.2512 2.67811 12.48 2.00211L13.3632 3.36711C12.6976 3.83511 12.16 4.35511 11.7504 4.92711C11.3664 5.49911 11.1104 6.16211 10.9824 6.91611C11.2128 6.81211 11.4816 6.76011 11.7888 6.76011C12.5056 6.76011 13.0944 6.99411 13.5552 7.46211C14.016 7.93011 14.2464 8.52811 14.2464 9.25611Z"
              fill="currentColor"
            />
          </svg>
          <span className="relative z-10 italic text-gray-800 darki:text-gray-200">
          Start Up Early's platform transformed our approach to education. The products not only bridge academic rigor with co-curricular growth but have made a tangible impact on our student engagement and success.
          </span>
        </p>
      </div>
      <footer className="mt-6">
        <div className="font-semibold text-gray-800 darki:text-gray-200">
          Ashar Javaid
        </div>
        <div className="text-sm text-gray-500">Advisor Communication & Co-curricular Activities, LUMS</div>
      </footer>
    </blockquote>
    {/* End Blockquote */}
  </div>
  {/* End Testimonials */}


        <LogoCloud/>

</>





<div className="bg-[#FCF9FF]">
<div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">

      <div className="max-w-screen-lg gap-8 p-6 rounded-xl bg-white row-gap-10 mx-auto lg:grid-cols-2">

        <h2 className="font-bold text-3xl text-center text-primary darki:text-gray-200">
        Revolutionizing education with innovative tools that capture every achievement, highlighting students’ contributions beyond the classroom, to help them unleash their full potential.
          </h2>


        <div className="flex flex-col justify-center items-center gap-2 mt-8">
<p className="text-gray-500 text-sm text-center ">Take the Next Step with Start Up Early</p>

<Link to='/book_demo' className="btn btn-primary">Book a demo</Link>

</div>
      </div>




      
    </div>
</div>


      <Footer />
    </div>
  );
}

export default Features_Leap;
