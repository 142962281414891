import { format } from "date-fns";
import React from "react";
import { Link } from "react-router-dom";

function EventList({ events }) {
  return (
    <ul className="flex flex-col divide-y divide-gray-200 darki:divide-gray-700 w-full">
      {events.map((event) => (
        <Link
          to={'/event_details'}
          key={event.id} // Make sure to add a unique key to each list item
          className="flex eventList-BC flex-col gap-x-2 py-3 px-4 text-sm font-medium text-gray-800 darki:text-white transition-colors duration-300"
        >
          <span className="font-semibold ">{event.title}</span>
          <span className="font-normal text-gray-400">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-4 h-4 mr-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
              />
            </svg>
            {format(event.start, "MMM dd, yyyy HH:mm")}
          </span>
        </Link>
      ))}
    </ul>
  );
}

export default EventList;
