import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { toast, Toaster } from 'react-hot-toast';
import AuthContext from "../context/AuthContext";
import Select from "react-select";

function CreateClubForm() {
  const { user } = useContext(AuthContext);
  const userID = user["user_id"];

  console.log("Type:  " + typeof userID);

  // extract profile id
  const params = useParams();
  const profileID = userID;
  const [allStudents, setallStudents] = useState([]);
  const [clubData, setClubData] = useState({
    name: "",
    description: "",
    campus: "",
    creator: "",
    thumbnail: "",
    students: [],
  });

  const [filteredStudents, setFilteredStudents] = useState([]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name !== "students") {
      setClubData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else {
      // For multi-select (students)
      const selectedStudentIDs = value.map((option) => option.value);
      setClubData((prevState) => ({
        ...prevState,
        students: selectedStudentIDs,
      }));
    }
    if (name === "campus") {
      const campusID = parseInt(value);
      const filteredStudents = students.filter(
        (student) => student.school_in === campusID
      );
      setFilteredStudents(filteredStudents);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const newClubData = {
      ...clubData,
      creator: userID, // Set the creator field to the userID
      creator_id: userID,
      students: selectedStudents.map((students) => students.value),
    };

    axios
      .post(`${process.env.REACT_APP_API_KEY}/api/clubs/`, newClubData)
      .then((response) => {
        console.log("Club data saved successfully!", response.data);
        // Show toast message on successful save
        toast.success("Club data saved successfully!");
        // Clear the form fields
        setClubData({ name: "", description: "" });
      })
      .catch((error) => {
        console.error("Error saving club data:", error);
        // Show toast message on error
        toast.error("Error saving club data!");
      });
  };

  const [allClubs, setAllClubs] = useState([]);
  useEffect(() => {
    // Fetch all clubs data from the backend for all users (no authentication required)

    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/clubs/`)
      .then((response) => {
        // Ensure that the creator field is compared as a number (if it's a string in the response)
        const clubsWithCreator2 = response.data.filter(
          (club) => parseInt(club.creator, 10) === userID
        );
        setAllClubs(clubsWithCreator2);

        console.log("Club resp:", response.data);
      })
      .catch((error) => {
        console.error("Error fetching clubs data:", error);
      });
  }, []);


  axios.get(`${process.env.REACT_APP_API_KEY}/api/student_count/`)
      .then(response => {
        setallStudents(response.data);
      })
      .catch(error => {
        console.error('Error fetching schools:', error);
      });

  const handleDeleteClub = (clubId) => {
    axios.defaults.xsrfCookieName = "csrftoken"; // Set the CSRF cookie name used by Django
    axios.defaults.xsrfHeaderName = "X-CSRFToken"; // Set the CSRF header name expected by Django

    axios
      .delete(`${process.env.REACT_APP_API_KEY}/api/clubs/`)
      .then((response) => {
        console.log("Club deleted successfully!", response.data);
        // Show toast message on successful deletion
        toast.success("Club deleted successfully!");
        // Update the allClubs state to remove the deleted club from the UI
        setAllClubs((prevClubs) =>
          prevClubs.filter((club) => club.id !== clubId)
        );
      })
      .catch((error) => {
        console.error("Error deleting club:", error);
        // Show toast message on error
        toast.error("Error deleting club!");
      });
  };

  const [profile, setProfile] = useState();
  const [currentNetwork, setCurrentNetwork] = useState("");
  const [networkName, setNetworkName] = useState("");

  useEffect(() => {
    const getProfile = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/network_profile/${profileID}`
        );

        // parse the data in json

        let data = await response.json();
        setCurrentNetwork(data?.network_id);
        setNetworkName(data?.network_name);
        setProfile(data);
      } catch (err) {
        console.log("The requested profile does not exists.");
      }
    };

    getProfile();
  }, [profileID]);

  const [students, setStudents] = useState([]);
  const [schools, setSchools] = useState([]);
  // useEffect(() => {
  //   if (currentNetwork) {
  //     fetch(`${process.env.REACT_APP_API_KEY}/api/schools/`)
  //       .then((response) => {
  //         if (!response.ok) {
  //           throw new Error("Network response was not ok");
  //         }
  //         return response.json();
  //       })
  //       .then((data) => {
  //         const allStudents = data.filter(
  //           (student) =>
  //             parseInt(student.network_in) === parseInt(currentNetwork)
  //         );
  //         setSchools(data.data);
  //       })
  //       .catch((error) => {
  //         console.error("Error fetching schools:", error);
  //       });
  //   }
  // }, [currentNetwork]);

  useEffect(() => {
    if (currentNetwork) {
      fetch(`${process.env.REACT_APP_API_KEY}/api/student_count/`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          const allStudents = data.filter(
            (student) =>
              parseInt(student.network_in) === parseInt(currentNetwork)
          );
          setStudents(allStudents);
        })
        .catch((error) => {
          console.error("Error fetching students:", error);
        });

      fetch(`${process.env.REACT_APP_API_KEY}/api/schools/`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          const allSchools = data.filter(
            (student) => parseInt(student.network) === parseInt(currentNetwork)
          );
          setSchools(allSchools);
        })
        .catch((error) => {
          console.error("Error fetching schools:", error);
        });
    }
  }, [currentNetwork, setStudents, setSchools]);

  console.log("profile:  " + profile);
  console.log("Students:  " + schools);

  const handleStudentSelect = (selectedOptions) => {
    setSelectedStudents(selectedOptions);
  };
  const [selectedStudents, setSelectedStudents] = useState([]);

  const [file, setFile] = useState();
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFileSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("club_thumbnail", file);
    formData.append("club_name", clubData.name);
    axios
      .post(
        `${process.env.REACT_APP_API_KEY}/api/upload_club_thumbnail/`,
        formData
      )
      .then((response) => {
        console.log(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleBoth = (e) => {
    handleSubmit(e);
    handleFileSubmit(e);
  };

  return (
    <div>



<div>
            <Tabs className="tabs block mt-4 px-0">
              <TabList>
                <Tab className="tab tab-bordered">All Clubs</Tab>
                <Tab className="tab tab-bordered">Add new Club</Tab>
              </TabList>

              <TabPanel>
                {allClubs.length === 0 ? (
                  <div className="flex justify-center mt-20 items-center gap-2 flex-col">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    <p className="text-gray-400">No Club found</p>
                  </div>
                ) : (
                  <ul className="grids grid-cols-3 gap-4">
                    {allClubs.map((club) => (


                        <div class="max-w-xs rounded-lg overflow-hidden shadow-lg" key={club.id}>
	<img class="w-full h-46 object-cover object-center" src={`${process.env.REACT_APP_API_KEY}/api/media/club_thumbnails/${club.thumbnail}`} alt="Sunset in the mountains"/>
	<div class="px-6 py-4">
		<div class="font-bold text-xl mb-2">{club.name}</div>
		<p class="text-gray-700 text-base">
        {club.description}
		</p>
		<div class="mt-8 flex justify-start items-center">

        {club.students.length > 0 && (
                        <div className="avatar-group -space-x-6">

                        
                        {allStudents
                        .filter(student => club.students.includes(student.id))
                        .slice(0, 3) // This will remove the last element from the filtered array
                        .map(student => (
                            <div key={student.id} className="avatar placeholder">
                            <div className="bg-neutral-focus text-neutral-content rounded-full w-12">
                                <img
                                src={`${process.env.REACT_APP_API_KEY}/api/media/profile_picture/${student.avatar}`} // Assuming the avatar URL is stored in student.avatarUrl
                                alt={`${student.firstname}'s Avatar`}
                                className="w-12 h-12 rounded-full object-cover"
                                />
                            </div>
                            </div>
                        ))}

                            <div className="avatar placeholder">
                                <div className="w-12 bg-neutral-focus text-neutral-content">
                                <span>+{club.students.length - 0}</span>
                                </div>
                            </div>
                            </div> )}
		</div>
	</div>



                      {/* <li
                        key={club.id}
                        className="card w-full md:w-96 bg-base-100 shadow-xl"
                      >
                        <div className="card-body">

                                <img
                                src={`${process.env.REACT_APP_API_KEY}/api/media/club_thumbnails/${club.thumbnail}`} // Assuming the avatar URL is stored in student.avatarUrl
                                alt={`${club.firstname}'s Avatar`}
                                className="w-12 h-12 rounded-full object-cover"
                                />

                          <h2 className="card-title">{club.name}</h2>
                          <p>{club.description}</p>


                          <div className="flex justify-between mt-4">
                        {club.students.length > 0 && (
                        <div className="avatar-group -space-x-6">

                        
                        {allStudents
                        .filter(student => club.students.includes(student.id))
                        .slice(0, 3) // This will remove the last element from the filtered array
                        .map(student => (
                            <div key={student.id} className="avatar placeholder">
                            <div className="bg-neutral-focus text-neutral-content rounded-full w-12">
                                <img
                                src={`${process.env.REACT_APP_API_KEY}/api/media/profile_picture/${student.avatar}`} // Assuming the avatar URL is stored in student.avatarUrl
                                alt={`${student.firstname}'s Avatar`}
                                className="w-12 h-12 rounded-full object-cover"
                                />
                            </div>
                            </div>
                        ))}

                            <div className="avatar placeholder">
                                <div className="w-12 bg-neutral-focus text-neutral-content">
                                <span>+{club.students.length - 0}</span>
                                </div>
                            </div>
                            </div> )}
                        </div>

                          <div className="flex justify-between mt-4 hidden">

                            <button
                              className="btn btn-sm btn-error"
                              onClick={() => handleDeleteClub(club.id)}
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      </li> */}</div>
                    ))}
                  </ul>
                )}
              </TabPanel>

              <TabPanel className="px-2">
                <form
                  onSubmit={handleBoth}
                  className="mx-auto max-w-screen-xl p-0"
                >
                  <div className="mx-auto max-w-lg text-center bg-slate-50 p-12 rounded-xl">
                    <h1 className="text-xl font-bold text-gray-800 text-start">
                      {" "}
                      Add Club Details
                    </h1>
                    <p className="text-base text-gray-600 text-start mt-2 mb-6">
                      Enter necessary details to add a new club
                    </p>

                    <div className="form-control w-full ">
                      <label className="label">
                        <span className="label-text">Club</span>
                      </label>
                      <input
                        type="text"
                        name="name"
                        placeholder="Type here"
                        value={clubData.name}
                        onChange={handleChange}
                        className="input input-bordered w-full "
                      />
                    </div>

                    <div className="form-control w-full ">
                      <label className="label">
                        <span className="label-text">Club Thumbnail</span>
                      </label>
                      <input
                        type="file"
                        name="thumbnail"
                        //value={clubData.name}
                        onChange={(e) => {
                          handleFileChange(e);
                          handleChange(e);
                        }}
                        className="input input-bordered w-full "
                      />
                    </div>

                    <div className="form-control w-full ">
                      <label className="label">
                        <span className="label-text">Select Campus</span>
                      </label>
                      <select
                        type="text"
                        name="campus"
                        placeholder="Type here"
                        value={clubData.campus}
                        onChange={handleChange}
                        className="input input-bordered w-full "
                      >
                        <option> Pick one </option>
                        {schools.map((school) => (
                          <option
                            key={school.school_id}
                            value={school.school_id}
                          >
                            {school.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="form-control w-full ">
                      <label className="label">
                        <span className="label-text">Select Students</span>
                      </label>
                      <Select
                        isMulti
                        name="students"
                        placeholder="Select multiple students"
                        value={selectedStudents}
                        onChange={handleStudentSelect}
                        options={filteredStudents.map((student) => ({
                          value: student.id,
                          label: `${student.firstname} ${student.lastname}`,
                        }))}
                      />
                    </div>

                    <div className="form-control">
                      <label className="label">
                        <span className="label-text">Club Description</span>
                      </label>
                      <textarea
                        name="description"
                        className="textarea textarea-bordered h-24"
                        placeholder="Bio"
                        value={clubData.description}
                        onChange={handleChange}
                      ></textarea>
                    </div>

                    <button
                      className="btn btn-primary mt-4 flex justify-start"
                      type="submit"
                    >
                      Add Club
                    </button>
                  </div>
                </form>
              </TabPanel>
            </Tabs>
          </div>


      {/* Toast container to display toast messages */}
      <div style={{ zIndex: 999999999999999}}>
  <Toaster position="bottom-center" />
</div>
    </div>
  );
}

export default CreateClubForm;