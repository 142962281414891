const coCurricularEvents = [
  {
    category: "Technology",
    description: "Graphics Designing Course from Coursera",
    timeline: "During Summer Break"
  },
  {
    category: "Leadership",
    description: "Participate in a creative writing tournament such as the Youth for Pakistan Writing Competition",
    timeline: "During the school year"
  },
  {
    category: "Interpersonal Skills",
    description: "Participate in an internship in a field of your choice, reach out to companies that interest you for opportunities",
    timeline: "During Summer Break"
  },
  {
    category: "Social Responsibility",
    description: "Volunteer to collect donations for a food drive",
    timeline: "During the summer break"
  },
  {
    category: "Critical Thinking",
    description: "Write and publish a creative story",
    timeline: "During the school year"
  },
  {
    category: "Physical Fitness",
    description: "Join a school sports team",
    timeline: "During the school year"
  },
];

export default coCurricularEvents;