import React, { Component } from 'react';

class CSVUploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      message: null,
      error: null,
    };
  }

  handleFileChange = (e) => {
    this.setState({
      selectedFile: e.target.files[0],
    });
  };

  handleUpload = () => {
    const formData = new FormData();
    formData.append('file', this.state.selectedFile);

    fetch(`${process.env.REACT_APP_API_KEY}/api/import_students/`, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.message) {
          this.setState({
            message: data.message,
            error: null,
          });
        } else if (data.error) {
          this.setState({
            message: null,
            error: data.error,
          });
        }
      })
      .catch((error) => {
        console.error('Error uploading CSV:', error);
        this.setState({
          message: null,
          error: 'An error occurred while uploading the CSV file.',
        });
      });
  };

  render() {
    return (
      <div>
        <input type="file" onChange={this.handleFileChange} accept=".csv" />
        <button onClick={this.handleUpload}>Upload CSV</button>
        {this.state.message && <p>{this.state.message}</p>}
        {this.state.error && <p>Error: {this.state.error}</p>}
      </div>
    );
  }
}

export default CSVUploader;
