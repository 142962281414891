import React, { useState } from "react";
import { axiosInstance } from "../Axios";

import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import { useEffect } from "react";
import Navbar from "../components/Navbar";
import SideInnerSM from "../components/SideInnerSM";
import Sidebar from "../components/Sidebar";
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Stats from "../components/Stats";

import Network_StudentList from "../components/Network_StudentList";
import { useLanguage } from "../components/language";
import AddSchool from "./AddSchool";
export default function NetworkSchools() {

  const { t, changeLanguage, lanArabic } = useLanguage();
    const [schools, setSchools] = useState([]);
    const [students, setStudents] = useState([]);
    const [error, setError] = useState(null);
    const { user } = useContext(AuthContext);
    const userID = user['user_id'];
    const profileID = userID;

  const [loadingStudents, setLoadingStudents] = useState(true);

  const [profile, setProfile] = useState();
  const [currentNetwork, setCurrentNetwork] = useState(null); // Initialize the currentNetwork state

  useEffect(() => {
    const getProfile = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_KEY}/api/network_profile/${profileID}`);
        const data = await response.json();
        setProfile(data);

        // Set the currentNetwork state once the profile data is fetched
        setCurrentNetwork(data?.network_id); // Ensure data is not null before accessing the property
      } catch (err) {
        console.log("The requested profile does not exist.");
      }
    };

    getProfile();
  }, [profileID]);

  useEffect(() => {
    if (currentNetwork) {
      // Fetch schools from the Django API
      fetch(`${process.env.REACT_APP_API_KEY}/api/schools/`)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          // Filter schools with network matching the currentNetwork
          const filteredSchools = data.filter(school => school.network === currentNetwork);
          setSchools(filteredSchools);
        })
        .catch(error => {
          console.error('Error fetching schools:', error);
          setError(error.message);
        });
    }
  }, [currentNetwork]);


     
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarVisible(prevState => !prevState);
  }
  
  
    if (error) {
      return <div>Error: {error}</div>;
    }


  return (
    <div>


<div><>

<div class="hidden md:block absolute w-full bg-lilac h-96  min-h-75"></div>


<Sidebar />

{isSidebarVisible && <SideInnerSM/>}

    <main className={`relative h-full max-h-screen transition-all duration-200 ease-in-out ${lanArabic ? 'xl:mr-68' : 'xl:ml-68'} rounded-xl`}>
      {/* Navbar */}
      <Navbar toggleSidebar={toggleSidebar} />

      {/* end Navbar */}
      {/* cards */}
      <div className="w-full px-6 py-6 mx-auto">

      <Tabs>
      <TabList className='pb-4'>
        <Tab>All Schools</Tab>
        <Tab>Add new Campus</Tab>
      </TabList>

      <TabPanel>

      <div className="bg-white rounded-lg p-6">

        
{schools.length === 0 ? (
          <div className='flex justify-center mt-20 items-center gap-2 flex-col'>
          
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
            <p className='text-gray-400'>No school found</p>

          </div>
        ) : (
          <ul className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 grids gap-4">
          {schools.map(school => (
            <li key={school.school_id} className="card bg-base-100 shadow-xl">





   
            <figure>
<img
src={school.card_photo || "https://edexec.co.uk/wp-content/uploads/2021/01/school-building-modern-thin-line-design-style-vector-illustration-vector-id1030995160.jpg"}
className="w-full max-h-64"
alt="School"
/>
</figure>




<div className="card-body">



<h2 className="card-title">
{school.name}
</h2>

<div className="card-actions justify-start">
<div className="badge badge-outline">{school.city} City</div> 
<div className="badge badge-outline">{school.number_of_students} Students</div>
</div>

<div className="card-actions justify-start mt-4 hidden" >
                    <Link to={`/school_data/${school.school_id}`}>
                     <button className="btn btn-primary">View Details</button>
                    </Link>
                    </div>

</div>


            
                {/* <figure><img src={school.card_photo} className="h-28 w-full" alt="Shoes" /></figure>
                <div className="card-body">
                    <h2 className="card-title">{school.name} Campus</h2>
                    <div className='flex flex-row justify-between'>

                        <p className='flex flex-row gap-2 text-sm'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-4 h-4 text-primary">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                        </svg>
                        {school.city}
                        </p>

                        <p className='flex flex-row gap-2 text-sm'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-4 h-4 text-primary">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z" />
                        </svg>
                        {school.number_of_students}
                        </p>
                        

                        <p className='flex flex-row gap-2 text-sm'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-4 h-4 text-primary">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                        </svg>
                         05
                        </p>


                    </div>
                    <div className="card-actions justify-start mt-4" >
                    <Link to={`/school_data/${school.school_id}`}>
                     <button className="btn btn-primary">View Details</button>
                    </Link>
                    </div>
                </div> */}
            </li>
            ))}

        </ul>

        )}



</div>


      </TabPanel>
      <TabPanel>

<AddSchool/>

      </TabPanel>

    </Tabs>
      </div>
    </main>
  </>
  </div>

    
    </div>
  );
}
