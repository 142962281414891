import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { GoogleOAuthProvider } from '@react-oauth/google';

console.log = () => {};
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  //<React.StrictMode >
    <GoogleOAuthProvider clientId="412283018548-ls0cgmii17jb4netsfok42n532snb0rk.apps.googleusercontent.com" options={{ux_mode: "redirect"}}>
    <App />
    </GoogleOAuthProvider>
  //</React.StrictMode>
);

