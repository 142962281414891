import React from 'react';
import Moment from 'react-moment';
import moment from 'moment';
import 'moment-timezone'; // If needed, to manage timezones
import { useLanguage } from '../components/language';

function TimeAgo({ postDate }) {
  // Calculate the time difference
  const timeDifference = moment(postDate).fromNow();
  const { t, changeLanguage, lanArabic } = useLanguage();
  return (
    <div className="badge badge-ghost items-end w-full">
      Posted <Moment fromNow>{timeDifference}</Moment>
    </div>
  );
}

export default TimeAgo;
