import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../context/AuthContext";
import "react-calendar/dist/Calendar.css";
import { axiosInstance } from "../Axios";
import { Link } from "react-router-dom";
import axios from "axios";
import { HiMiniBell, HiOutlineBell, HiBell } from "react-icons/hi2";
import MobileNav from "./MobileNav";
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
const Navbar = ({ toggleSidebar }) => {

  const [events, setEvents] = useState();

  let { user } = useContext(AuthContext);
  let { logoutUser } = useContext(AuthContext);
  const [profile, setProfile] = useState();
  const userID = user["user_id"];

  let url = '';
  if(user['role']==='Student'){
    url = 'profile';
  } else if(user['role']==='Network Admin'){
    url = 'network_profile';
  }
  

  let role = user["role"];

  if (profile?.status == "2") {
    role = "Student";
  }

  const [page, setPage] = useState("");






  // function extractAfter3000(url) {
  //   const match = url.match(/https?:\/\/startupearly\.com\/([^/0-9]+)/);
  //   if (match && match[1]) {
  //     return match[1];
  //   }
  //   return null; // Return null if the pattern is not found
  // }

  function extractAfter3000(url) {
    // Allow for http or https, optional "www", and match any hostname
    const match = url.match(/https?:\/\/(?:www\.)?([^/]+)\/([^/0-9]+)/);
    if (match && match[2]) {
      return match[2];
    }
    return null; // Return null if the pattern is not found
  }
  
  


  useEffect(() => {
    setPage(extractAfter3000(window.location.href));
  }, []);

  let contentTitle = "";
  let contentSubtitle = "";

  if (page === "student" || page === "network" || page === "preview") {
    contentTitle = "Dashboard";
    contentSubtitle = "Overview of activities";
  } 
  else if (page === "cats") {
    contentTitle = "Extracurricular Categories";
    contentSubtitle = "Details of current event";
  } 

  else if (page === "leads") {
    contentTitle = "Leadership Activities";
    contentSubtitle = "Details of current event";
  } 


  else if (page === "add_lead") {
    contentTitle = "Leadership Form";
    contentSubtitle = "Details of current event";
  } 

  
  else if (page === "add_society") {
    contentTitle = "Society Form";
    contentSubtitle = "Details of current event";
  } 

  else if (page === "societies") {
    contentTitle = "Society Activities";
    contentSubtitle = "Details of current event";
  } 


  else if (page === "add_award") {
    contentTitle = "Award Form";
    contentSubtitle = "Details of current event";
  } 

  else if (page === "awards") {
    contentTitle = "Awards & Achievements Activities";
    contentSubtitle = "Details of current event";
  } 

  
  else if (page === "student_nw") {
    contentTitle = "Student Details";
    contentSubtitle = "Details of current event";
  } 

  else if (page === "network_students") {
    contentTitle = "Students";
    contentSubtitle = "Details of current event";
  } 

  
  else if (page === "network_schools") {
    contentTitle = "Campuses";
    contentSubtitle = "Details of current event";
  } 
  
  else if (page === "clubs") {
    contentTitle = "My Clubs";
    contentSubtitle = "Details of current event";
  } 
  else if (page === "categories") {
    contentTitle = "Add Activities";
    contentSubtitle = "Details of current event";
  } 


  else if (page === "student_profile") {
    contentTitle = "Student Profile";
    contentSubtitle = "Details of current event";
  } 

  
  else if (page === "badges") {
    contentTitle = "Student Badges";
    contentSubtitle = "Details of current event";
  } 


    
  else if (page === "notifications") {
    contentTitle = "Notifications";
    contentSubtitle = "Details of current event";
  } 

  else if (page === "challenges") {
    contentTitle = "Challenges";
    contentSubtitle = "Details of current event";
  } 

  else if (page === "moderate_network") {
    contentTitle = "Moderation";
    contentSubtitle = "Details of current event";
  } 
  

  else if (page === "challenge") {
    contentTitle = "Challenge Details";
    contentSubtitle = "Details of current event";
  } 

  else if (page === "pathway") {
    contentTitle = "Guided Pathways";
    contentSubtitle = "Details of current event";
  } 

  else if (page === "bisr" || page === "doe" || page === "rowad" || page === "dahran") {
    contentTitle = "Extracurricular Activity Form";
    contentSubtitle = "Details of current event";
  } 

  else if (page === "myclub") {
    contentTitle = "My Clubs";
    contentSubtitle = "Details of current event";
  } 


  
  else if (page === "club_details") {
    contentTitle = "Club Details";
    contentSubtitle = "Details of current event";
  } 

  
  
  else {
    contentTitle = "🕛";
    contentSubtitle = "Other Name Overview";
  }

  let fullname = user["first_name"] + " " + user["last_name"];



  const [profilePhoto, setProfilePhoto] = useState(null);
  const [profilePhotoUrl, setProfilePhotoUrl] = useState('');

  const [profileData, setProfileData] = useState({
    name: "",
    dob: "",
    gender: "",
    contact: "",
    bio: "",
    active_status: "",
    employeeID: "",

  });
  const [isEditing, setIsEditing] = useState(false);



  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/${url}/${userID}`)
      .then((response) => {
        const data = response.data;
        setProfileData(data);
        setProfile(data);
        setProfilePhotoUrl(data.profile_photo);
        //console.log("Profile Photo URL: ", data.profile_photo);

      })
      .catch((error) => {
        console.error(error);
      });
  }, [userID]);




  const {t} = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  
  const lanArabic = i18n.language === 'ar'
const [isDrawerOpen, setIsDrawerOpen] = useState(false);

const [isDropdownOpen, setIsDropdownOpen] = useState(false);

const toggleDropdown = () => {
  setIsDropdownOpen(!isDropdownOpen);
};


const [isShown, setIsShown] = useState(false);

const handleClick = event => {
  // 👇️ toggle shown state
  setIsShown(current => !current);

  // 👇️ or simply set it to true
  // setIsShown(true);
};

  return (

    <div>
            <nav
        className="relative hidden md:flex flex-wrap items-center justify-between px-0 py-2 mx-6 transition-all ease-in shadow-none duration-250 rounded-2xl lg:flex-nowrap lg:justify-start "
        navbar-main=""
        navbar-scroll="false"
      >
        <div className="flex items-center justify-between w-full px-4 py-1 mx-auto flex-wrap-inherit">
          <nav>
            {/* breadcrumb */}

            <h6 className="mb-0 font-bold text-drk capitalize">{t(contentTitle)}</h6>
          </nav>
          <div className="flex items-center mt-2 grow sm:mt-0 sm:mr-6 md:mr-0 lg:flex lg:basis-auto">
            <div className="flex items-center md:ml-auto md:pr-4 opacity-0">
              <div className="relative flex flex-wrap items-stretch w-full transition-all rounded-lg ease">
                <span className="text-sm ease leading-5.6 absolute z-50 -ml-px flex h-full items-center whitespace-nowrap rounded-lg rounded-tr-none rounded-br-none border border-r-0 border-transparent bg-transparent py-2 px-2.5 text-center font-normal text-slate-500 transition-all">
                  <i className="fas fa-search" />
                </span>
                <input
                  type="text"
                  className="hidden pl-9 text-sm focus:shadow-primary-outline ease w-1/100 leading-5.6 relative -ml-px block min-w-0 flex-auto rounded-lg border border-solid border-gray-300   bg-white bg-clip-padding py-2 pr-3 text-gray-700 transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none focus:transition-shadow"
                  placeholder="Type here..."
                />
              </div>
            </div>
            <ul  className={`flex items-center justify-end gap-2 pl-0 mb-0 list-none md-max:w-full ${lanArabic ? 'flex-row-reverse' : 'flex-row '}`}  style={{zIndex:'9999999999999999999999 !import'}}>
              {/* online builder   */}
               <li class="flex items-center">
           
               <div className="dropdown dropdown-end hidden" >
  <label tabIndex={0} className=" -circle bg-none border-0 text-drk hvr:text-black">
  

                  <HiBell size={25}  />

  </label>
 
  
  <ul tabIndex={0} className="dropdown-content hidden z-[1] menu p-2 shadow bg-base-100 rounded-box w-52">
    <li><a>Item 1</a></li>
    <li><a>Item 2</a></li>
  </ul>
</div>

          </li> 
          
<div className="relative inline-block hidden text-left ">
      <div>
        <button
          type="button"
          className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold rounded-xl text-gray-900 hover:bg-gray-50"
          id="menu-button"
          aria-expanded={isDropdownOpen} // Use state to manage aria-expanded
          aria-haspopup="true"
          onClick={toggleDropdown} // Add click event handler to toggle dropdown
        >
          🌏︎ {t('Language')}
          <svg className="-mr-1 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" />
          </svg>
        </button>
      </div>

      {isDropdownOpen && (
        <div className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1">
          <div className="py-1" role="none">
            <a onClick={() => { changeLanguage('en'); window.location.reload(); }} className="text-gray-700 block px-4 py-2 text-sm cursor-pointer" role="menuitem" tabIndex="-1" id="menu-item-0">
            🇬🇧 English
            </a>
            <a onClick={() => { changeLanguage('ar'); window.location.reload(); }} className="text-gray-700 block px-4 py-2 text-sm cursor-pointer" role="menuitem" tabIndex="-1" id="menu-item-1">
            🇦🇪 عربي
            </a>
          </div>
        </div>
      )}
    </div>
              <li className="flex items-center dropdown" style={{zIndex: '9999999999999999999999 !important'}}>
              <a
  tabIndex={0}
  className={` border-0 m-1 flex items-center gap-2 p-0 text-sm font-semibold text-drk transition-all ease-nav-brand ${
    lanArabic ? 'flex-row-reverse' : ''
  }`}
>
              
{/* <img className="w-10 h-10 rounded-full" src={`${process.env.REACT_APP_API_KEY}/api/media/profile_picture/${profile?.avatar}/`} /> */}
              

                  
                 {(() => {
        if (user['role'] === 'Student' ) {
          return (
            
            <div>
            {(() => {
        if (profile?.avatar==="https://img.icons8.com/stickers/344/administrator-male.png" && profilePhotoUrl !==undefined) {
          return (
            <img
                          src={profilePhotoUrl}
                          alt="profile_image"
                          class="w-10 h-10 rounded-full"
                        />
          )
        }
        
   

        else {
          return (
            <img className="w-10 h-10 rounded-full" src={profile?.avatar} />
          )
        }
      })()}
            </div>
          )
        } else if (user['role'] === 'Network Admin' ) {
          return (
            <div>
            {/* <img
                          src={profileData?.avatar}
                          alt="profile_image"
                          class="w-10 h-10 img-fit rounded-full"
                        /> */}

                        <div className="avatar online placeholder">
  <div className="bg-neutral text-neutral-content rounded-full w-12">
    <span className="text-xl">{profile?.network_name?.charAt(0)?.toUpperCase()}</span>
  </div>
</div> 
                        
                        </div>
          )
        }
      })()}

                  


                  <span className=" sm:inline">

                  {/* {(() => {
                    if (user["role"] === "society") {
                      return <div >{profile?.name} <small className="badge text-xs"> {user.role} </small></div>;
                    } else if (user["role"] === "Network Admin") {
                      return <div > {user.name} <small className="badge  text-xs"> {user.role} </small></div>;
                    } else {
                      return <div></div>;
                    }
                  })()} */}


                  {(() => {
        if (user['role']==='Network Admin') {
          return (
            <div className="flex flex-row gap-2 items-center dropdown dropdown-end">{profileData?.network_name} <small className="badge text-xs"> {t(user.role)} </small></div>
          )
        } else if (user['role']==='Student') {
          return (
            <div className="flex flex-row gap-2 items-center dropdown dropdown-end">{profileData?.firstname} {profileData?.lastname} <small className="badge text-xs"> {t(user.role)} </small></div>

          )
        }
      })()}

                
  <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52" style={{zIndex: '9999999999999999999999 !important'}}>


{(() => {
        if (user['role']==='Student') {
          return (
            <li className="text-black">
  <Link className='p-2' to={user['role'] === "Network Admin" || user['role'] === "School Admin" ? `/admin_profile` : `/student_profile`}>
     {t('Profile')}
  </Link>
</li>
          )
        }
      })()}

    <li onClick={logoutUser} to="/login" className="text-red-400 p-2" style={{zIndex: '9999999999999999999999 !important'}}><a>{t('Logout')}</a></li>
  </ul>
                  </span>
                </a>
              </li>



              <li className="flex items-center pl-4 xl:hidden text-drk border-0" onClick={() => { toggleSidebar(); handleClick(); }}>
                
                <button className="btn btn-circle btn-sm btn-primary">

                {isShown ? (
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
  <path fill-rule="evenodd" d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z" clip-rule="evenodd" />
</svg>

      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
  <path fill-rule="evenodd" d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z" clip-rule="evenodd" />
</svg>
      )}




                </button>


              </li>
            </ul>
          </div>
        </div>
      </nav>


      
      <MobileNav source={contentTitle} img={profile?.avatar} />


    </div>

   
  );
};
export default Navbar;
