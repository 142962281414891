const Certifications_Data = [
    {
        "Main Categories": "Certification",
        "Sub Categories": "First aid certification",
        "Technology": "",
        "Leadership": "",
        "Interpersonal Skills": "",
        "Social Responsibility": "3",
        "Critical Thinking": "2",
        "Physical Fitness": ""
    },
    {
        "Main Categories": "Certification",
        "Sub Categories": "Driving license",
        "Technology": "",
        "Leadership": "",
        "Interpersonal Skills": "",
        "Social Responsibility": "",
        "Critical Thinking": "",
        "Physical Fitness": ""
    },
    {
        "Main Categories": "Certification",
        "Sub Categories": "Google certification",
        "Technology": "3",
        "Leadership": "",
        "Interpersonal Skills": "",
        "Social Responsibility": "",
        "Critical Thinking": "3",
        "Physical Fitness": ""
    },
    {
        "Main Categories": "Certification",
        "Sub Categories": "Coursera course",
        "Technology": "",
        "Leadership": "",
        "Interpersonal Skills": "",
        "Social Responsibility": "",
        "Critical Thinking": "3",
        "Physical Fitness": ""
    },
    {
        "Main Categories": "Certification",
        "Sub Categories": "edX course",
        "Technology": "",
        "Leadership": "",
        "Interpersonal Skills": "",
        "Social Responsibility": "",
        "Critical Thinking": "3",
        "Physical Fitness": ""
    },
    {
        "Main Categories": "Certification",
        "Sub Categories": "Udemy course",
        "Technology": "",
        "Leadership": "",
        "Interpersonal Skills": "",
        "Social Responsibility": "",
        "Critical Thinking": "3",
        "Physical Fitness": ""
    },
    {
        "Main Categories": "Certification",
        "Sub Categories": "Masterclass course",
        "Technology": "",
        "Leadership": "",
        "Interpersonal Skills": "",
        "Social Responsibility": "",
        "Critical Thinking": "3",
        "Physical Fitness": ""
    },
    {
        "Main Categories": "Certification",
        "Sub Categories": "Duolingo course",
        "Technology": "",
        "Leadership": "",
        "Interpersonal Skills": "",
        "Social Responsibility": "",
        "Critical Thinking": "3",
        "Physical Fitness": ""
    },
    {
        "Main Categories": "Certification",
        "Sub Categories": "Khan Academy course",
        "Technology": "",
        "Leadership": "",
        "Interpersonal Skills": "",
        "Social Responsibility": "",
        "Critical Thinking": "3",
        "Physical Fitness": ""
    },
    {
        "Main Categories": "Certification",
        "Sub Categories": "Codecademy course",
        "Technology": "3",
        "Leadership": "",
        "Interpersonal Skills": "",
        "Social Responsibility": "",
        "Critical Thinking": "3",
        "Physical Fitness": ""
    },
    {
        "Main Categories": "Certification",
        "Sub Categories": "Domestika course",
        "Technology": "",
        "Leadership": "",
        "Interpersonal Skills": "",
        "Social Responsibility": "",
        "Critical Thinking": "3",
        "Physical Fitness": ""
    },
    {
        "Main Categories": "Certification",
        "Sub Categories": "Udacity course",
        "Technology": "",
        "Leadership": "",
        "Interpersonal Skills": "",
        "Social Responsibility": "",
        "Critical Thinking": "3",
        "Physical Fitness": ""
    }
];

export default Certifications_Data;