import React, { useState, useEffect, useContext } from "react";

import { Link, Navigate, Redirect, useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";

import axios from "axios";

const Home = () => {
  const navigate = useNavigate();

  let { user } = useContext(AuthContext);
  let { logoutUser } = useContext(AuthContext);
  let role = user["role"];
  let userID = user["user_id"];

  const [profile, setProfile] = useState([]);

  let endpoint = "";

  if (role === "Student") {
    endpoint = "profile";
  } else if (role === "School Admin") {
    endpoint = "school_profile";
  } else if (role === "School Admin") {
    endpoint = "school_profile";
  } else if (role === "Network Admin") {
    endpoint = "network_profile";
  } else if (role === "Super Admin") {
    endpoint = "ssuper-admin/profile";
  }

  const [profileData, setProfileData] = useState([]);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_KEY}/api/${endpoint}/${userID}`)
      .then((response) => {
        const data = response.data;
        setProfileData(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [userID]);

  if (role === "Student" && profileData?.is_moderator === 0 && profileData?.trial_student === 1) {
    navigate(`/${profileData?.trial_route}`);
  } else if (role === "Student" && profileData?.is_moderator === 0 && profileData?.trial_student === 0) {
    navigate(`/categories`);
  } else if (role === "Student" && profileData?.is_moderator === 1) {
    navigate(`/moderator`);
  } else if (role === "Network Admin") {
    navigate(`/network`);
  } else if (role === "School Admin") {
    navigate(`/school/${user.user_id}`);
  } else if (role === "Super Admin") {
    navigate(`/super/${user.user_id}`);
  }


  return (
    <div className="home_main">
      <div className="flex items-center justify-center space-x-2 h-screen">
        <div className="w-4 h-4 rounded-full animate-pulse dark:bg-violet-400"></div>
        <div className="w-4 h-4 rounded-full animate-pulse dark:bg-violet-400"></div>
        <div className="w-4 h-4 rounded-full animate-pulse dark:bg-violet-400"></div>
      </div>
    </div>
  );
};
export default Home;
