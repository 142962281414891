import React, { useState } from "react";
import Calendar from "react-calendar";
import "./Calendar.css";

function EventCalendar({ events }) {
  const [selectedDate, setSelectedDate] = useState(new Date());

  const eventDates = new Set(
    events?.map((event) => new Date(event.start_date).toDateString()) || []
  );

  const eventsForSelectedDate = events?.filter(
    (event) =>
      new Date(event.start_date).toDateString() ===
      selectedDate.toDateString()
  );

  const tileClassName = ({ date, view }) => {
    if (view === "month" && eventDates.has(date.toDateString())) {
      return "highlight";
    }
  };

  return (
    <div className="space-y-4">
      <Calendar
        onChange={setSelectedDate}
        value={selectedDate}
        tileClassName={tileClassName}
      />

      <div className="space-y-4">
        {events && eventsForSelectedDate.length > 0 ? (
          eventsForSelectedDate.map((event, i) => {
            let formattedDate = new Date(event.start_date).toLocaleDateString();
            return (
              <div key={i} className="p-4 border rounded-lg shadow">
                <div className="flex justify-between">
                  <div className="flex items-center">
                    <h3 className="text-xl font-medium text-violet-900">
                      {event.event_name}
                    </h3>

                  </div>

                  <div className="text-right">
                    <p className="text-sm text-violet-700">
                      {formattedDate}
                    </p>
                    <p className="text-sm text-violet-700">
                      {event.venue}
                    </p>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="p-4 border rounded-lg shadow">
            <h3 className="text-center text-xl font-medium text-violet-900">
              No events for this date
            </h3>
          </div>
        )}
      </div>

      <div className="flex gap-2 justify-center">
        <span className="badge text-xs text-white" style={{ background: '#006edc' }}>Selected</span>
        <span className="badge text-xs" style={{ background: '#ffff76' }}>Today</span>
        <span className="badge text-xs" style={{ background: '#c4b5fd' }}>Event</span>
      </div>
    </div>
  );
}

export default EventCalendar;
