import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import Chart from 'react-apexcharts';
import axios from 'axios';

const BarchartSchoolTrend = () => {
  let { user } = useContext(AuthContext);
  let params = useParams();
  let profileID = user['user_id'];
  let isMyself = user !== null && user['user_id'] === parseInt(profileID);

  const [profile, setProfile] = useState();
  const [series, setSeries] = useState([]);

  const [options, setOptions] = useState({
    xaxis: {
      categories: ['Activities'],
    },
    colors: [
      '#477BFF',
      '#47D1CB',
      '#6F47FF',
      '#FFCB47',
      '#FF477B',
      '#59DBFF',
      '#CB47FF',
      '#FF884D',
      '#00897B',
      '#9288F8',
    ],
    bar: {
      columnWidth: '45%',
      distributed: true,
    },
    stroke: {
      colors: ['transparent'],
      width: 5,
    },
    legend: {
      position: 'bottom',
    },
  });

  const activityLabels = {
    'lead': 'Leadership',
    'society': 'Societies',
    'social': 'Social Work',
    'awards': 'Awards',
    'social': 'Social Work',
    'sports': 'Sports',
    'intern': 'Internships',
    'cert': 'Certificates',
    'talent': 'Talents',
    'eventStd': 'Events',
    // ... add other mappings as needed
  };

  useEffect(() => {
    const getProfile = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_KEY}/api/network_profile/${profileID}`);
        let data = await response.json();
        setProfile(data);
      } catch (err) {
        console.log("The requested profile does not exists.");
      }
    };
    getProfile();
  }, [profileID]);

  useEffect(() => {
    const fetchData = async () => {
      try {

        const response = await fetch(`${process.env.REACT_APP_API_KEY}/api/network_profile/${profileID}`);
        let data = await response.json();
        setProfile(data);
      
        // Now that you have the profile, fetch the rest of the data
        const networkResponse = await fetch(`${process.env.REACT_APP_API_KEY}/api/student_count/`);
        const studentsInNetwork = await networkResponse.json();
        const studentsWithProfileID = studentsInNetwork.filter(student => student.network_in === data.network_id);
        const studentUserIdsWithProfileID = studentsWithProfileID.map(student => student.user);

        // const networkResponse = await fetch(`${process.env.REACT_APP_API_KEY}/api/student_count/`);
        // const studentsInNetwork = await networkResponse.json();
        // const studentsWithProfileID = studentsInNetwork.filter(student => student.network_in === profile?.network_id);
        
        // const studentUserIdsWithProfileID = studentsWithProfileID.map(student => student.user);
        
        const activityEndpoints = [
            '/api/get_lead/',
            '/api/get_society/',
            '/api/get_social/',
            '/api/get_awards/',
            '/api/get_sports/',
            '/api/get_intern/',
            '/api/get_cert/',
            '/api/get_talent/',
            '/api/get_lang/',
            '/api/get_eventStd/',
        ];

        const activityCounts = {};
        for (const endpoint of activityEndpoints) {
          const activities = await axios.get(`${process.env.REACT_APP_API_KEY}${endpoint}`);
          const relevantActivities = activities.data.filter(activity => 
            studentUserIdsWithProfileID.includes(parseInt(activity.posted_by))
          );
          const activityType = endpoint.split('/').slice(-2, -1)[0].replace('get_', '');
          activityCounts[activityType] = (activityCounts[activityType] || 0) + relevantActivities.length;
        }

        const categories = Object.keys(activityCounts).map(key => activityLabels[key] || key);
        setOptions(prevOptions => ({
          ...prevOptions,
          xaxis: {
            categories: categories,
          },
        }));

        const counts = Object.values(activityCounts); 
        setSeries([{ data: counts }]);

        console.log("Date ;", counts);

      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, [profileID]);

  const [screenSizeType, setScreenSizeType] = useState('');
  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth >= 1600) {
        setScreenSizeType('600');
      } else if (screenWidth >= 1200) {
        setScreenSizeType('500');
      } else if (screenWidth >= 992) {
        setScreenSizeType('400');
      } else if (screenWidth >= 768) {
        setScreenSizeType('350');
      } else if (screenWidth >= 576) {
        setScreenSizeType('300');
      } else {
        setScreenSizeType('330');
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Chart
      options={options}
      series={series}
      type="bar"
      width={screenSizeType}
      height={430}
      style={{ margin: 'auto' }}
      className="chartMain"
    />
  );
};

export default BarchartSchoolTrend;
