import React, { useState, useEffect } from "react";
import BottomCTA from "./BottomCTA";
import Footer from "./Footer";
import Navbar from "./Navbar";

import {
  EmailShareButton,
  FacebookShareButton,
  GabShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
} from "react-share";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import Vimeo from "@u-wave/react-vimeo";
import CaseStudyQuote from "./CaseStudyQuote";

function CaseStudyArticle() {
  const copyURLToClipboard = () => {
    const currentURL = window.location.href;
    navigator.clipboard
      .writeText(currentURL)
      .then(() => {
        //alert('URL copied to clipboard!');
        toast.success(`Link copied to clipboard!`);
      })
      .catch((error) => {
        console.error("Failed to copy URL: ", error);
      });
  };

  const [liked, setLiked] = useState(false);
  const [likesCount, setLikesCount] = useState(875);

  const handleLikeClick = () => {
    setLiked(!liked);
    setLikesCount(liked ? likesCount - 1 : likesCount + 1);
  };

  return (
    <>
      <Navbar />

      <div className="block absolute w-full bg-[#faf5ff] h-[42rem] md:h-[28rem] lg:h-[28rem]"></div>

      <div className="relative">
        <>
          {/* Blog Article */}
          <div className="max-w-[64rem] px-4 pt-6 lg:pt-10 pb-12 sm:px-6 lg:px-8 mx-auto">
            <div className="max-w-4xl">
              {/* Avatar Media */}

              {/* End Avatar Media */}
              {/* Content */}
              <div className="">
                <div className="">
                  <div className="group flex items-center gap-x-12 ">
                    <div className="group grow block" href="">
                      <div>
                        <Link
                          to={"/case_studies"}
                          className="p-0 mb-6 btn btn-sm hover:bg-transparent btn-ghost"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            class="w-6 h-6"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M15.75 19.5 8.25 12l7.5-7.5"
                            />
                          </svg>
                          all case studies
                        </Link>
                      </div>

                      <div className="flex items-center gap-x-4 my-4">
                        <img
                          className="w-[8rem]"
                          src="https://cambridge.tlh.edu.pk/wp-content/uploads/2021/12/tlh-logo-1.png"
                          alt="Image Description"
                        />

<div className="divider dividerCT divider-horizontal m-2 opacity-50 text-primary "></div>

                        <h5 className="text-3xl font-bold text-primary">
                          The Learning Hub Cambridge
                        </h5>
                      </div>

                      <p className="text-base text-gray-800 darki:text-gray-200 flex flex-col mt-[2rem]">
                        In today's age of holistic education, where academic
                        excellence is just one element of student development,
                        'The Learning Hub' stands as a beacon of innovation and
                        foresight. This case study unfolds the transformative
                        journey of 'The Learning Hub' with Start Up Early's
                        cutting-edge EdTech solutions—Extracurricular Management
                        System (EMS) and Extracurricular Transcripts. Dive into
                        the details of this collaboration through the experience
                        of Mr Allauddin, the Principal, and Miss Hadiqa, the
                        Events Coordinator, as they navigate the challenges and
                        achievements of elevating extracurricular engagement to
                        unprecedented heights.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="mt-[8rem] mb-12 flex justify-center w-full h-full">
                  <div style={{ width: "100%", borderRadius: "10px" }}>
                    <div className="video-container">
                      <Vimeo video={929924046} />
                    </div>
                  </div>
                </div>
                <p className="text-base text-gray-800 darki:text-gray-200 flex flex-col">
                  <strong className="text-lg pb-4">
                  Addressing the Need for Extracurricular Reporting

                    <br />
                  </strong>
                  Mr Allauddin, the Principal of The Learning Hub, identified the lack of attention paid to the extracurricular development of students. He recognises the need for extracurricular reporting and management as the core requirement in the journey towards effective extracurricular development. He emphasized that for most schools, extracurricular development is often not a priority. Therefore, the schools don’t have adequate systems to record and manage the extracurricular activities of their students. 
{" "}
                </p>

                <CaseStudyQuote
                  text={
                    "Usually schools don’t have a system in place that records the extracurriculars of their students; and how these recorded extracurriculars are linked to the skills they develop for the students"
                  }
                  name={`Mr Allauddin, Principal`}
                />

                <p className="text-base text-gray-800 darki:text-gray-200 flex flex-col">
                Miss Hadiqa, the Events Coordinator at The Learning Hub, reflected on the extracurricular management at their institution before the involvement of Start Up Early. She identified that previously, they never attempted to record the extracurricular performance and achievements of their students; they only focused on the top achievers of any activity, and the maximum reward provided to them was a certificate. 
{" "}
                </p>

                <CaseStudyQuote
                  text={
                    "We didn’t have any platform to record the extracurricular progress of the students. To acknowledge the students, we just used to give them certificates for participation, without identifying their strengths or improvement areas since we lacked the resources to do so"
                  } name={`Miss Hadiqa, Events Coordinator`}
                />



                <p className="text-base text-gray-800 darki:text-gray-200 flex flex-col">
                  <strong className="text-lg pb-4">
                  Managing Extracurriculars at The Learning Hub
                    <br />
                  </strong>
                  Start Up Early’s Product development and IT team collaborated to customize and manage the Extracurricular Management System (EMS) and Extracurricular Transcripts according to the needs of the school. In addition to record-keeping and visual reports, the school wanted to improve the interest and engagement of its students in extracurriculars. Considering this, a combination of in-app and email messages to the students and parents was introduced, which constantly informs and guides the students about extracurricular development opportunities. The combination of Extracurricular Transcripts with personalized roadmaps and suggested activities not only quantifies the extracurricular achievements with a score but also provides personalized instructions on how they can develop better skills and gain enhanced experiences through specific extracurricular involvements.
                </p>

                <CaseStudyQuote
                  text={
                    "EMS portal encourages the students to explore more extracurricular activities in order to increase their transcript scores; the students are motivated to get better scores than their peers, and in order to do it, they are encouraged to participate in better extracurriculars. This increased participation in extracurriculars has improved their confidence, skills, and overall potential"
                  } name={`Miss Hadiqa, Events Coordinator`}
                />

                <p className="text-base text-gray-800 darki:text-gray-200 flex flex-col">
                These EdTech solutions have increased the efficiency of extracurricular management at The Learning Hub. The school has experienced significant ease in improving the extracurricular progress of its students by being able to collectively judge and plan the extracurricular paths of its students in similar extracurricular categories. The Extracurricular Activity Report within the Extracurricular Management System (EMS) provides helpful visual aids to the school. According to Miss Hadiqa, this feature of EMS improves the efficiency of the school by providing a collective activity trend of the extracurricular involvements of its students in ten different categories.
{" "}
                </p>

                <CaseStudyQuote
                  text={
                    "Before EMS, we were looking at the extracurricular progress of each student individually. But now we can manage the students in similar categories collectively, thus making it really easy and convenient for us to manage each student’s progress, leading to increased efficiency in terms of the quality of work and time saved while doing work"
                  } name={`Miss Hadiqa, Events Coordinator`}
                />
                <img
                  className="w-10/12 mx-auto"
                  src="https://res.cloudinary.com/ahbranding/image/upload/v1712264675/AHbranding/Group_34784984_ml0xtg.png"
                />

                {/* <small className="block text-center my-4 opacity-70">
                  {" "}
                  Based on this “Activity Trend” graph, the school identified the lack of participation of its students in internships and events, which necessitated the need for improved efforts towards the development of these weak areas. The “Activity Performance” graph provides a record and comparison of the monthly activity count of the school’s students, which helps the school keep a check on its overall extracurricular progress. 
                </small> */}


                <p className="text-base mt-6 text-gray-800 darki:text-gray-200 flex flex-col">
                Based on this “Activity Trend” graph, the school identified the lack of participation of its students in internships and events, which necessitated the need for improved efforts towards the development of these weak areas. The “Activity Performance” graph provides a record and comparison of the monthly activity count of the school’s students, which helps the school keep a check on its overall extracurricular progress. 
                </p>

                <p className="text-base text-gray-800 darki:text-gray-200 flex flex-col">
                  <strong className="text-lg pb-4">
                  Parent and Student Experience

                    <br />
                  </strong>
                  Parents have started to recognise the importance of extracurricular development in today’s competitive world. The school’s initiative to provide extracurricular transcripts to parents at the bi-annual 'Parent-Teacher Meeting' has improved the involvement of parents in the extracurricular planning and management of their students. The transcripts are divided into different segments, which makes it easy for parents to understand the holistic personalities of their students. The significance of our reporting system and extracurricular transcripts was recognised and appreciated by a parent at a recent ‘Parent-Teacher Meeting.’
                </p>

                <CaseStudyQuote
                  text={
                    "The extracurricular reporting and transcripts is a great initiative which could eventually help my child get university admissions and score great internships"
                  } name={`Parent`}
                />

                <p className="text-base text-gray-800 darki:text-gray-200 flex flex-col">
                  Through the holistic reporting system and in-app engagement,
                  the school has noticed an increase in students’ interest in
                  extracurriculars. Following the increased focus of the school
                  towards extracurricular development and the aid provided by
                  Start Up Early’s products, the students have identified the
                  opportunity to improve or aid their academic portfolios
                  through their extracurricular engagements. Miss Hadiqa noticed
                  this change in many students and shared the story of a student
                  - Zainab, in the following works.{" "}
                </p>

                <CaseStudyQuote
                  text={
                    "Zainab is really brilliant in academics. Since the adoption of EMS, she has been showing strong interest in extracurriculars; she says that she wants to aid her academic achievements with her extracurriculars to strengthen her portfolio for university admissions and internships"
                  } name={`Miss Hadiqa, Events Coordinator`}
                />



<p className="text-base text-gray-800 darki:text-gray-200 flex flex-col">
Like Zainab, many other students have success stories; For example, the recent extracurricular top performers at The Learning Hub - Bismillah Awais, Ahmad Aamir, Taha Usman, and Mesum Imran, all with over thirty five extracurricular involvements. These top performers become the school’s extracurricular benchmark and provide a realistic comparison for all the other students, leading to better motivation and commitment towards improvement. 

                </p>


                <img
                  className="w-10/12 mx-auto"
                  src="https://res.cloudinary.com/ahbranding/image/upload/v1712264675/AHbranding/Group_34784987_o717z0.png"
                />

                <p className="text-base text-gray-800 darki:text-gray-200 flex flex-col mt-6">
                  <strong className="text-lg pb-4">
                  Recommendations to Other Schools

                    <br />
                  </strong>
                  Among many other targets, schools often aim to attract new students and also retain their current students. Considering the increased awareness among students and parents regarding the importance of extracurricular activities in child development, the demand and value of schools that prioritize extracurricular development is constantly growing. In light of such circumstances, The Learning Hub is a prime example of a school that experienced an increase in student retention and admissions after its collaboration with Start Up Early.

                </p>

                <CaseStudyQuote
                  text={
                    "The students are really invested to record their extracurriculars, participate in quizzes, competitions, and other extracurriculars. Since these opportunities are provided to them at our institution, they are motivated to stay at our institution and not leave. Similarly, these factors help to attract new students as well,"
                  } name={`Miss Hadiqa, Events Coordinator`}
                />

<p className="text-base text-gray-800 darki:text-gray-200 flex flex-col">

The Learning Hub's story serves as an inspiring blueprint for educational institutions worldwide, illustrating the need to unlock students' full potential through the synergistic blend of technology and education. In the ever-evolving learning landscape, The Learning Hub emerges not just as a school but as a visionary working towards the holistic development of its students beyond the traditional academic metrics. 

                </p>
              </div>
              {/* End Content */}
            </div>
          </div>
        </>
      </div>







      <BottomCTA />
      <Footer />

      
    </>
  );
}

export default CaseStudyArticle;
