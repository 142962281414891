import React, { useEffect, useState, useContext, useRef } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import ProfileEditForm from "./ProfileEditForm";
import { axiosInstance } from "../Axios";
import axios from "axios";
import { FaSignal, FaTrophy, FaRegCheckCircle } from "react-icons/fa";
import { SlGraph } from "react-icons/sl";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { Tooltip } from "react-tooltip";
import Popup from "reactjs-popup";

const EngagementHeader = () => {
  const { t } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const lanArabic = i18n.language === "ar";

  let { user } = useContext(AuthContext);
  let { logoutUser } = useContext(AuthContext);
  let [totalCompletions, setTotalCompletions] = useState(0);
  let [totalExperiencePoints, setTotalExperiencePoints] = useState(0);
  let [currentLevel, setCurrentLevel] = useState("Level 1");
  let [profile, setProfile] = useState();
  let UserID = user["user_id"];

  useEffect(() => {
    const getProfile = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/profile/${UserID}`
        );
        // parse the data in json
        let data = await response.json();
        setProfile(data);
      } catch (err) {
        // console.log('The requested profile does not exist.');
      }
    };

    const calculateCompletions = async () => {
      // API endpoints to check how many entries match in each endpoint
      const endpoints = [
        `${process.env.REACT_APP_API_KEY}/api/get_lead/`,
        `${process.env.REACT_APP_API_KEY}/api/get_society/`,
        `${process.env.REACT_APP_API_KEY}/api/get_social/`,
        `${process.env.REACT_APP_API_KEY}/api/get_awards/`,
        `${process.env.REACT_APP_API_KEY}/api/get_sports/`,
        `${process.env.REACT_APP_API_KEY}/api/get_intern/`,
        `${process.env.REACT_APP_API_KEY}/api/get_cert/`,
        `${process.env.REACT_APP_API_KEY}/api/get_talent/`,
        `${process.env.REACT_APP_API_KEY}/api/get_lang/`,
        `${process.env.REACT_APP_API_KEY}/api/get_eventStd/`,
      ];

      // Sum all completions from each endpoint
      let completions = 0;
      for (const endpoint of endpoints) {
        try {
          const response = await axios.get(endpoint);
          completions += response.data.filter(
            (entry) => parseInt(entry.posted_by) === UserID
          ).length;
        } catch (error) {
          // console.log(`Error fetching data from endpoint: ${endpoint}`);
        }
      }

      setTotalCompletions(completions);
    };

    const calculateExperiencePoints = async () => {
      try {
        // Fetch data from relevant endpoints to calculate experience points
        const leadResponse = await axios.get(
          `${process.env.REACT_APP_API_KEY}/api/get_lead/`
        );
        const societyResponse = await axios.get(
          `${process.env.REACT_APP_API_KEY}/api/get_society/`
        );
        const socialResponse = await axios.get(
          `${process.env.REACT_APP_API_KEY}/api/get_social/`
        );
        const awardsResponse = await axios.get(
          `${process.env.REACT_APP_API_KEY}/api/get_awards/`
        );
        const sportsResponse = await axios.get(
          `${process.env.REACT_APP_API_KEY}/api/get_sports/`
        );
        const internResponse = await axios.get(
          `${process.env.REACT_APP_API_KEY}/api/get_intern/`
        );
        const certResponse = await axios.get(
          `${process.env.REACT_APP_API_KEY}/api/get_cert/`
        );
        const talentResponse = await axios.get(
          `${process.env.REACT_APP_API_KEY}/api/get_talent/`
        );
        const langResponse = await axios.get(
          `${process.env.REACT_APP_API_KEY}/api/get_lang/`
        );
        const eventResponse = await axios.get(
          `${process.env.REACT_APP_API_KEY}/api/get_eventStd/`
        );

        // Filter data to include only entries where posted_by is equal to UserID
        const filterByUserID = (data) =>
          data.filter(
            (entry) => parseInt(entry.posted_by) === parseInt(UserID)
          );

        const leadData = filterByUserID(leadResponse.data);
        const societyData = filterByUserID(societyResponse.data);
        const socialData = filterByUserID(socialResponse.data);
        const awardsData = filterByUserID(awardsResponse.data);
        const sportsData = filterByUserID(sportsResponse.data);
        const internData = filterByUserID(internResponse.data);
        const certData = filterByUserID(certResponse.data);
        const talentData = filterByUserID(talentResponse.data);
        const langData = filterByUserID(langResponse.data);
        const eventData = filterByUserID(eventResponse.data);

        function getLeadershipActivityLabel(activity_name) {
          const labelMap = {
            "Student Council General Secretary": 9,
            "Deputy Head Student": 8.01,
            "House Captain": 8.02,
            "Class Prefect": 8.03,
            "Captain Sports Team": 8.04,
            "Class Monitor": 7,
            "Head Student": 10,
          };
          // console.log("Pushed Activity: "+labelMap[activity_name]);
          return labelMap[activity_name] || 1;
        }

        function getAwardsLabel(activity_name) {
          const awardsMapping = {
            "Full attendance": 10,
            "Academic excellence": 10,
            "Sports scholarship": 10,
            "Merit scholarship": 10,
            "Research grant": 10,
            "Poem Recitation": 6,
            "Spoken Word Contest": 6,
            "Play/Musical": 7,
            "Choir Performance": 7,
            "Aerobics Competition": 7,
            "Bake Sale": 6,
            Artbeat: 7,
            "Debates Competition": 8,
            "School/college MUN": 8,
            "Math Competition": 8,
            "Trivia Competition": 7,
            "Science Competition": 8,
            "Science Fair": 8,
            "Dance Competition": 7,
            "Short Film Competition": 8,
            "Robotics Competition": 8,
            "Poster Making Competition": 7,
            "Gaming Competition": 7,
            "Fashion Design Competition": 7,
            "Cooking Competition": 7,
            "Modeling Competition": 7,
            "Talent Show": 7,
            "Handwriting Competition": 5,
            "Essay Competition": 8,
            "Acting Competition": 7,
            "Drawing Competition": 8,
            "Photography Competition": 8,
            "Painting Competition": 8,
            "Composition Competition": 8,
            "Lucky Draw": 6,
            "Spelling Competition": 7,
            "Certificate of Achievement": 10,
          };

          return awardsMapping[activity_name] || 1;
        }

        function getCertificationLabel(cert_name) {
          const certificationMapping = {
            "First aid certification": 10,
            "Driving license": 7,
            "Google certification": 8,
            "Coursera course": 8,
            "edX course": 8,
            "Udemy course": 8,
            "Masterclass course": 8,
            "Duolingo course": 8,
            "Khan Academy course": 8,
            "Codecademy course": 8,
            "Domestika course": 8,
            "Udacity course": 8,
          };

          return certificationMapping[cert_name] || 1;
        }

        function getEventManagementLabels2(activity_name) {
          const labelMap = {
            "Executive Council": 9,
            "Logistics Team": 8,
            "Registrations Team": 8,
            "Socials Team": 8,
            "Publications Team": 8,
            "Finance Team": 8,
            "Promotions Team": 8,
            "Communications Team": 8,
            "IT Team": 8,
            "Security Team": 8,
            "Operations Team": 8,
          };
          return labelMap[activity_name] || 1;
        }

        function getInternIndustry(activity_name) {
          const labelMap = {
            Research: 8,
            Engineering: 8,
            "Marketing/Advertising": 8,
            "Engineering/Technical": 8,
            "Finance/Accounting": 8,
            "Media/Entertainment": 8,
            "Nonprofit/NGO": 8,
            Medical: 8,
            Legal: 8,
            "Graphic Design/Visual Arts": 8,
            "Software Development/IT": 8,
            "Startup/Entrepreneurship": 8,
          };

          return labelMap[activity_name] || 1;
        }

        function getSocialActivityLabel(activity_name) {
          const labelMap = {
            "Food drive": 8,
            "Clothes drive": 8,
            "Charity collection": 8,
            "Blood donation drive": 9,
            "Hospital volunteer": 9,
            "Trash clean-up": 8,
            "Charity bake sale": 8,
          };
          return labelMap[activity_name] || 1;
        }

        function getSocietyNameLabel(activity_name) {
          const labelMap = {
            "STEM/STEAM Society": 8,
            "Music Society": 8,
            "Art Society": 8,
            "Debates Society": 8,
            "MUN Society": 8,
            "Drama/Theater Society": 8,
            "Math Society": 8,
            "Astronomy Society": 7,
            "Literature Society": 8,
            "Film Society": 7,
            "Environment Society": 8,
            "Community Service Society": 8,
            "Media & Publications Society": 8,
            "Sports Society": 8,
            "Choir Society": 7,
            "Cooking Society": 7,
            "Cardio Society": 7,
            "Yoga Society": 7,
            "Book Club": 7,
            "Cooking Club": 7,
            "School Magazine": 8,
            "School Social Media": 7,
          };
          return labelMap[activity_name] || 1;
        }

        function getSportsLabel(activity_name) {
          const sportsMapping = {
            Football: 7,
            Basketball: 7,
            Cricket: 7,
            Hockey: 7,
            Badminton: 7,
            Tennis: 7,
            Gymnastics: 7,
            Throwball: 7,
            Futsal: 7,
            Archery: 7,
            Cycling: 7,
            Sprint: 7,
            "Relay race": 7,
            Baseball: 7,
            Fencing: 7,
            Golf: 7,
            Handball: 7,
            Netball: 7,
            Rowing: 7,
            Skateboarding: 7,
            "Road bicycle racing": 7,
            Ballet: 7,
            Kabaddi: 7,
            Kickboxing: 7,
            Boxing: 7,
            Strongman: 7,
            Lacrosse: 7,
            Polo: 7,
            "Water Polo": 7,
            "Long Jump": 7,
            "High Jump": 7,
            "Shot put": 7,
            "Pole Vault": 7,
            Pickleball: 7,
            Chess: 8,
            "Track & field": 7,
            Curling: 7,
            "Endurance running": 7,
            "Hurdle races": 7,
            "Ice skating": 7,
            Surfing: 7,
            Skiing: 7,
            Snowboarding: 7,
            Dodgeball: 7,
            Climbing: 7,
            Snooker: 7,
            Squash: 7,
            Swimming: 7,
            "Table Tennis": 7,
            Volleyball: 7,
            Weightlifting: 7,
            Rugby: 7,
          };

          return sportsMapping[activity_name] || 1;
        }

        // Calculate experience points for each endpoint
        let lead_result = leadData.reduce(
          (total, entry) =>
            total +
            getLeadershipActivityLabel(entry.activity_name) *
              parseInt(entry.scope) *
              (parseInt(entry.duration) * 0.1) *
              parseInt(entry.service_hours),
          0
        );
        let society_result = societyData.reduce(
          (total, entry) =>
            total +
            getSocietyNameLabel(entry.activity_name) *
              parseInt(entry.role) *
              (parseInt(entry.duration) * 0.1) *
              parseInt(entry.service_hours),
          0
        );
        let social_result = socialData.reduce(
          (total, entry) =>
            total +
            getSocialActivityLabel(entry.activity_name) *
              parseInt(entry.scope) *
              parseInt(entry.role) *
              (parseInt(entry.duration) * 0.1) *
              parseInt(entry.service_hours),
          0
        );
        let awards_result = awardsData.reduce(
          (total, entry) =>
            total +
            getAwardsLabel(entry.activity_name) *
              parseInt(entry.scope) *
              entry.rank *
              parseInt(entry.service_hours),
          0
        );
        let sports_result = sportsData.reduce(
          (total, entry) =>
            total +
            getSportsLabel(entry.activity_name) *
              parseInt(entry.scope) *
              parseInt(entry.role) *
              (parseInt(entry.duration) * 0.1) *
              parseInt(entry.service_hours),
          0
        );
        let intern_result = internData.reduce(
          (total, entry) =>
            total +
            getInternIndustry(entry.activity_name) *
              entry.type *
              parseInt(entry.role) *
              (parseInt(entry.duration) * 0.1),
          0
        );
        let cert_result = certData.reduce(
          (total, entry) => total + getCertificationLabel(entry.cert_name),
          0
        );
        let talent_result = talentData.reduce(
          (total, entry) => total + 8 * parseInt(entry.service_hours),
          0
        );
        let lang_result = langData.reduce(
          (total, entry) => total + parseInt(entry.prof) * 7,
          0
        );
        let event_result = eventData.reduce(
          (total, entry) =>
            total +
            getEventManagementLabels2(entry.activity_name) *
              parseInt(entry.scope) *
              parseInt(entry.role) *
              (parseInt(entry.duration) * 0.1) *
              parseInt(entry.service_hours),
          0
        );

        // Sum all results from each endpoint and multiply by 15 to get total experience points
        let experiencePoints =
          (lead_result +
            society_result +
            social_result +
            awards_result +
            sports_result +
            intern_result +
            cert_result +
            talent_result +
            lang_result +
            event_result) *
          15;

        let roundedExperiencePoints = Math.round(experiencePoints);
        setTotalExperiencePoints(roundedExperiencePoints);

        // console.log("EXP: " + totalExperiencePoints);
      } catch (error) {
        // console.log('Error fetching data for experience points calculation');
      }
    };

    getProfile();
    calculateCompletions();
    calculateExperiencePoints();
  }, [UserID]);

  // console.log("EXP: " + totalExperiencePoints);

  // let currentLevel = 'Level 1';

  const level = Math.floor(totalExperiencePoints / 500) + 1;

  //  currentLevel = level;

  //  if(totalExperiencePoints<=500){currentLevel = {t('Novice')};}

  let nextLevelThreshold;
  let previousLevelThreshold;
  let progress;

  //   if (totalExperiencePoints <= 500) currentLevel = `${t("Novice")}`;
  //   if (totalExperiencePoints > 500 && totalExperiencePoints <= 1000)
  //     currentLevel = `${t("Rising")}`;
  //   if (totalExperiencePoints > 1000 && totalExperiencePoints <= 1500)
  //     currentLevel = `${t("Explorer")}`;
  //   if (totalExperiencePoints > 1500 && totalExperiencePoints <= 2000)
  //     currentLevel = `${t("Enthusiast ")}`;
  //   if (totalExperiencePoints > 2000 && totalExperiencePoints <= 2500)
  //     currentLevel = `${t("Achiever")}`;
  //   if (totalExperiencePoints > 2500 && totalExperiencePoints <= 3000)
  //     currentLevel = `${t("Collaborator ")}`;
  //   if (totalExperiencePoints > 3000 && totalExperiencePoints <= 3500)
  //     currentLevel = `${t("Veteran")}`;
  //   if (totalExperiencePoints > 3500 && totalExperiencePoints <= 4000)
  //     currentLevel = `${t("Influencer ")}`;
  //   if (totalExperiencePoints > 4000 && totalExperiencePoints <= 4500)
  //     currentLevel = `${t("Master")}`;
  //   if (totalExperiencePoints > 4500) currentLevel = `${t("Legend")}`;\

  if (totalExperiencePoints <= 500) {
    currentLevel = t("Novice");
    previousLevelThreshold = 0;
    nextLevelThreshold = 500;
  } else if (totalExperiencePoints <= 1000) {
    currentLevel = t("Rising");
    previousLevelThreshold = 500;
    nextLevelThreshold = 1000;
  } else if (totalExperiencePoints <= 1500) {
    currentLevel = t("Explorer");
    previousLevelThreshold = 1000;
    nextLevelThreshold = 1500;
  } else if (totalExperiencePoints <= 2000) {
    currentLevel = t("Enthusiast");
    previousLevelThreshold = 1500;
    nextLevelThreshold = 2000;
  } else if (totalExperiencePoints <= 2500) {
    currentLevel = t("Achiever");
    previousLevelThreshold = 2000;
    nextLevelThreshold = 2500;
  } else if (totalExperiencePoints <= 3000) {
    currentLevel = t("Collaborator");
    previousLevelThreshold = 2500;
    nextLevelThreshold = 3000;
  } else if (totalExperiencePoints <= 3500) {
    currentLevel = t("Veteran");
    previousLevelThreshold = 3000;
    nextLevelThreshold = 3500;
  } else if (totalExperiencePoints <= 4000) {
    currentLevel = t("Influencer");
    previousLevelThreshold = 3500;
    nextLevelThreshold = 4000;
  } else if (totalExperiencePoints <= 4500) {
    currentLevel = t("Master");
    previousLevelThreshold = 4000;
    nextLevelThreshold = 4500;
  } else {
    currentLevel = t("Legend");
    previousLevelThreshold = 4500;
    nextLevelThreshold = 5000; // Assuming 5000 as the max for the sake of calculation
  }

  const pointsToNextLevel = nextLevelThreshold - totalExperiencePoints;
  progress =
    ((totalExperiencePoints - previousLevelThreshold) /
      (nextLevelThreshold - previousLevelThreshold)) *
    100;

  //  if(lanArabic){
  //   if (totalExperiencePoints < 500) currentLevel = `${t('')} 1`;
  //   if (totalExperiencePoints > 500 C) currentLevel = `${t('')} 2`;
  //   if (totalExperiencePoints > 1000 ) currentLevel = `${t('')} 3`;
  //   if (totalExperiencePoints >= 700) currentLevel = `${t('')} 4`;
  //   if (totalExperiencePoints >= 1000) currentLevel = `${t('')} 5`;
  //  }

  //  else{
  //   if (totalExperiencePoints < 200) currentLevel = `${t('Level')} 1`;
  //   if (totalExperiencePoints >= 200) currentLevel = `${t('Level')} 2`;
  //   if (totalExperiencePoints >= 400) currentLevel = `${t('Level')} 3`;
  //   if (totalExperiencePoints >= 700) currentLevel = `${t('Level')} 4`;
  //   if (totalExperiencePoints >= 1000) currentLevel = `${t('Level')} 5`;
  //  }

  //// console.log(role);

  const scrollContainerRef = useRef(null);
  // Function to scroll the content left
  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      const scrollAmount = 250; // Adjust the scroll amount as needed
      scrollContainerRef.current.scrollLeft -= scrollAmount;
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      const scrollAmount = 250; // Adjust the scroll amount as needed
      scrollContainerRef.current.scrollLeft += scrollAmount;
    }
  };
  return (
    <div className="w-full px-6  mx-auto pt-28 md:py-6">
      <div className="p-6 py-6 bg-white text-gray-50 rounded-2xl text-gray-600 custom-container">
        <div className="container mx-auto">
          {/* <div className="flex flex-col lg:flex-row items-center justify-between"> */}
          <div
            className={`flex flex-col lg:flex-row items-center justify-between ${
              lanArabic ? "lg:flex-row-reverse" : ""
            }`}>
            <div className={`${
              lanArabic ? "rtl" : ""
            }`}> 
              <h2 className="text-center lg:text-start text-xl tracki ">
                {t("Welcome")} {profile?.firstname}!{" "}
                {t("Your Co-Curricular Journey Progresses")} 🌟
              </h2>
              <div className=" mx-auto lg:mx-0 space-x-2 text-center lg:text-start text-gray-600 w-8/12 py-2 lg:py-0">
                <span>
                  {t('Dive into your latest activities and see how close your are to your next milestones. Every step counts!')}
                </span>

                <div className="mt-8 mx-0">
                  <div className="w-full bg-gray-200 rounded-full h-4 mb-2">
                    <div
                      className="bg-primary rounded-full h-4"
                      style={{ width: `${progress}%` }}
                    ></div>
                  </div>
                  <div className="flex justify-between">
                    <span className="text-md font-medium">
                      {currentLevel}{" "}
                      <span className="badge badge-ghost">{t('Current Level')}</span>
                    </span>
                    <span className="text-md font-medium">
                      {pointsToNextLevel > 0
                        ? `${pointsToNextLevel} ${t('points to next level')}`
                        : "Max Level"}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <Link
              to="/categories"
              className="btn py-2.7 text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap rounded-lg px-4 font-semibold text-slate-700 transition-colors gradient-animation text-white"
            >
              {" "}
              + {t('Add Activities')}{" "}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EngagementHeader;
