import React, { useState, useEffect } from 'react';
import { Calendar, globalizeLocalizer } from 'react-big-calendar'
// import 'react-big-calendar/lib/sass/styles';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import globalize from 'globalize'
import EventList from './EventList';
import { Link } from 'react-router-dom';
function ClubEvents() {

    const localizer = globalizeLocalizer(globalize);
    const myEventsList = [
        {
          id: 1,
          title: 'School Assembly',
          start: new Date(2024, 0, 10, 9, 0),
          end: new Date(2024, 0, 10, 10, 0),
        },
        {
          id: 2,
          title: 'Parent-Teacher Conference',
          start: new Date(2024, 0, 15, 14, 0),
          end: new Date(2024, 0, 15, 16, 0),
        },
        {
          id: 3,
          title: 'Science Fair',
          start: new Date(2024, 1, 5, 10, 0),
          end: new Date(2024, 1, 5, 15, 0),
        },
        {
          id: 4,
          title: 'Field Trip - Zoo',
          start: new Date(2024, 1, 20, 8, 30),
          end: new Date(2024, 1, 20, 15, 0),
        },
        {
          id: 5,
          title: 'Sports Day',
          start: new Date(2024, 2, 1, 13, 0),
          end: new Date(2024, 2, 1, 17, 0),
        },
      ];
      

    return (
      <div>
{/* <div className='flex'>

  <div className='flex-1 p-2 w-8/12'>
 
    <div className='bg-white rounded-xl p-2 shadow-xl overflow-y-auto w-8/12'>

      <EventList events={myEventsList} />

      <button className='bg-blue-500 text-white p-2 rounded-md mt-2'>
        Click me
      </button>
    </div>
  </div>


  <div className='flex-2 p-2 w-full contents'>

    <div className='bg-white rounded-xl p-2 shadow-xl' style={{ height: 500}}>
      <Calendar
        localizer={localizer}
        events={myEventsList}
        startAccessor="start"
        endAccessor="end"
        popup={true}
        onShowMore={(events, date) => console.log(date)}
      />
    </div>
  </div>
</div>  */} 





<>
  {/* Article */}
  <div className="max-w-[85rem] px-4 sm:px-6 lg:px-8 mx-auto">

  <div className="flex items-center mb-4 justify-end gap-4">
  <Link to={'/addevent'} className="btn btn-primary"> + Add Activity </Link>


  </div>
  <div className='divider mb-2'></div>
    <div className="grid lg:grid-cols-3 gap-y-8 lg:gap-y-0 lg:gap-x-6 lg:gap-x-12">
      {/* Content */}
      <div className="lg:col-span-2">
        <div className="">

        <div className='bg-white rounded-xl p-2 shadow-xl' style={{ height: 500}}>
      <Calendar
        localizer={localizer}
        events={myEventsList}
        startAccessor="start"
        endAccessor="end"
        popup={true}
        onShowMore={(events, date) => console.log(date)}
      />
    </div>
          
        </div>
      </div>
      {/* End Content */}
      {/* Sidebar */}
      <div className="lg:col-span-1 lg:w-full lg:h-full bg-white rounded-xl">
        <div className="sticky top-0 start-0 py-8 px-6">
          {/* Avatar Media */}
          <div className="group flex items-center gap-x-3 border-b border-gray-200 pb-4 mb-4 darki:border-gray-700">
            <span className="group grow block" >
              <h5 className="group-hover:text-gray-600 text-sm font-semibold text-gray-800 darki:group-hover:text-gray-400 darki:text-gray-200">
                All Activities
              </h5>
              <p className="text-sm text-gray-500">List of all activities and events</p>
            </span>
          </div>
          {/* End Avatar Media */}
          <div className="space-y-6">
            {/* Media */}
            <a className="group flex items-center gap-x-6" href="#">
            <EventList events={myEventsList} />
            </a>
            {/* End Media */}
          </div>
        </div>
      </div>
      {/* End Sidebar */}
    </div>
  </div>
  {/* End Article */}
</>



</div>

    )
}

export default ClubEvents;