import React from 'react';
import {
    Link,
    Routes,
    Route,
    useNavigate,
  } from 'react-router-dom';

function BackNav() {
    const navigate = useNavigate();

  return (


<>

  
{/*     

<div className={`navbar sticky pb-4 pt-8 top-0 z-30 w-full bg-base-100 px-4 `}>
  <div className="flex-1">
    <button className='btn btn-ghost' onClick={() => navigate(-1)}> <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
</svg>
 </button>
Back  </div>
  <div className="flex-none gap-2">
    <div className="dropdown dropdown-end hidden">
      <label tabIndex={0} className="btn btn-ghost btn-circle">
        <div className="indicator">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="h-5 w-5">
            <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
          </svg>
          <span className="badge badge-sm indicator-item">0</span>
        </div>
      </label>
      <div tabIndex={0} className="mt-3 z-[1] card card-compact dropdown-content w-52 bg-base-100 shadow">
        <div className="card-body">
          <span className="text-base">No new notification</span>
        </div>
      </div>
    </div>

  </div>
</div> */}
</>


  );
}

export default BackNav;
