import React, { useState } from 'react';
import Lead from './Lead';
import Society from './Society';
import Social from './Social';
import Sports from './Sports';
import Internship from './Internship';
import Talent from './Talent';
import EventMgmt from './EventMgmt';
import Lang from './Lang';
import Certification from './Certification';
import Award from './Award';
import { useLanguage } from '../components/language';



// const Tab0Content = () =>

// <div className='flex justify-center items-center max-h-screen'>


// <div className="mockup-window border border-base-300 w-8/12">
//   <div className="flex flex-col justify-center px-4 py-16 border-t border-base-300">

//     <img className='w-8/12 mx-auto' src='https://res.cloudinary.com/ahbranding/image/upload/v1699694071/AHbranding/chrome_vggMXyPuUl_gehq7j.gif' />

//     <p>  </p>

//     <p className="font-medium text-sm  leading-normal text-center m-0">
//                               {" "}
//                               👆 Please select an activity category from the dropdown list
//                             </p>

//   </div>
// </div>


// <div>



// </div>

// </div>;


const Tab0Content = () => (
  <div>

{/* 
<div className='p-4 mt-4' >
                            <h6 className="mb-2 ">How to add an activity</h6>
                            <p className="text-sm  leading-normal ">
                              {" "}
                              👇 Brief tutorial of how to add an activity
                            </p>
                          </div>

  <div className='flex flex-col justify-center px-2 pb-4 md:px-0 items-center max-h-screen'>



    <div className="mockup-window border border-base-300 px w-full  md:w-8/12">
      <div className="flex flex-col justify-center px-0 md:px-4 py-8 border-t border-base-300">

        <img className='w-full md:w-11/12 mx-auto' src='https://res.cloudinary.com/ahbranding/image/upload/v1699694071/AHbranding/chrome_vggMXyPuUl_gehq7j.gif' />
      </div>
    </div>
    <div>

    </div>
  </div> */}


  </div>
);



const Tab1Content = () => <div><Lead /></div>;
const Tab2Content = () => <div><Society /></div>;
const Tab3Content = () => <div> <Social /> </div>;
const Tab5Content = () => <div> <Sports /> </div>;
const Tab6Content = () => <div> <Internship /> </div>;
const Tab7Content = () => <div> <Certification /> </div>;
const Tab8Content = () => <div> <Lang /> </div>;
const Tab9Content = () => <div> <Talent /> </div>;
const Tab10Content = () => <div> <EventMgmt /> </div>;
const Tab11Content = () => <div> <Award /> </div>;
// Add more components for additional tabs if needed...

function PillCat() {
  const [activeTab, setActiveTab] = useState(0);
  const { t, changeLanguage, lanArabic } = useLanguage();

  const handleTabChange = (tabIndex) => {
    setActiveTab(tabIndex);
    setIsDropdownOpen(!isDropdownOpen);
    console.log("Tab: "+activeTab)
  };

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

const [isDropdownOpen, setIsDropdownOpen] = useState(false);

const toggleDropdown = () => {
  setIsDropdownOpen(!isDropdownOpen);
  console.log("Tab: "+activeTab)
};

  return (
    <div>
  <div class="relative block mx-auto w-8/12 text-left">
    <div className='mb-8'>
    
      <button type="button" class="flex items-center w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 border-0 ring-1 bg-purple-100 ring-inset ring-purple-400 hover:bg-purple-100" id="menu-button" onClick={toggleDropdown} aria-expanded={isDrawerOpen} aria-haspopup="true">
      <span class="relative flex h-3 w-3">
  <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary opacity-75"></span>
  <span class="relative inline-flex rounded-full h-3 w-3 bg-primary"></span>
</span> {t('')}
        <svg class="-mr-1 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fill-rule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clip-rule="evenodd" />
        </svg>
Select a category
      </button>
      
    </div>
    {isDropdownOpen && (
    <div class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
      <div class="py-1" role="none">
        <a  class={`text-gray-700 block px-4 py-2 text-sm ${activeTab === 0 ? 'bg-gray-100 text-gray-900' : 'text-gray-700'}`} role="menuitem" tabindex="-1" onClick={() => handleTabChange(0)}>{t('Select an extracurricular category')}</a>
        <div className='divider m-0'></div>
        <a  class={`flex justify-between items-center text-gray-700 block px-4 py-2 text-sm ${activeTab === 1 ? 'bg-gray-100 text-gray-900' : 'text-gray-700'}`} role="menuitem" tabindex="-1" onClick={() => handleTabChange(1)}> <span>🏆 Leadership</span> <span className="tooltip" data-tip="Activities where you held a leadership role, such as team leader, club president, or project manager."><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5"><path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" /></svg></span></a>
        <a  class={`flex justify-between items-center text-gray-700 block px-4 py-2 text-sm ${activeTab === 2 ? 'bg-gray-100 text-gray-900' : 'text-gray-700'}`} role="menuitem" tabindex="-1" onClick={() => handleTabChange(2)}>♣️ {t('Society & Clubs')}  <span className="tooltip" data-tip="Participation in school or community-based clubs and societies, like debate club, science society, or book club."><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5"><path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" /></svg></span></a>
        <a  class={`flex justify-between items-center text-gray-700 block px-4 py-2 text-sm ${activeTab === 3 ? 'bg-gray-100 text-gray-900' : 'text-gray-700'}`} role="menuitem" tabindex="-1" onClick={() => handleTabChange(3)}>🎗️ {t('Social Work')} <span className="tooltip" data-tip="Involvement in community service, volunteering, and initiatives aimed at social betterment."><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5"><path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" /></svg></span></a>
        <a  class={`flex justify-between items-center text-gray-700 block px-4 py-2 text-sm ${activeTab === 5 ? 'bg-gray-100 text-gray-900' : 'text-gray-700'}`} role="menuitem" tabindex="-1" onClick={() => handleTabChange(5)}>⚽ {t('Sports')}  <span className="tooltip" data-tip="Participation in any sports, athletics, or related physical activities, either competitively or recreationally."><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5"><path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" /></svg></span></a>
        <a  class={`flex justify-between items-center text-gray-700 block px-4 py-2 text-sm ${activeTab === 6 ? 'bg-gray-100 text-gray-900' : 'text-gray-700'}`} role="menuitem" tabindex="-1" onClick={() => handleTabChange(6)}>💼 {t('Internship')}  <span className="tooltip" data-tip="Professional work experiences, including internships, apprenticeships, or other work placements."><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5"><path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" /></svg></span></a>
        <a  class={`flex justify-between items-center text-gray-700 block px-4 py-2 text-sm ${activeTab === 7 ? 'bg-gray-100 text-gray-900' : 'text-gray-700'}`} role="menuitem" tabindex="-1" onClick={() => handleTabChange(7)}>📜 {t('Certifications')}  <span className="tooltip" data-tip="Courses or exams you've completed that resulted in a certification or qualification."><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5"><path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" /></svg></span></a>
        <a  class={`flex justify-between items-center text-gray-700 block px-4 py-2 text-sm ${activeTab === 8 ? 'bg-gray-100 text-gray-900' : 'text-gray-700'}`} role="menuitem" tabindex="-1" onClick={() => handleTabChange(8)}>🔠 {t('Language')}  <span className="tooltip" data-tip="Proficiency or learning experiences in languages other than your native tongue."><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5"><path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" /></svg></span></a>
        <a  class={`flex justify-between items-center text-gray-700 block px-4 py-2 text-sm ${activeTab === 9 ? 'bg-gray-100 text-gray-900' : 'text-gray-700'}`} role="menuitem" tabindex="-1" onClick={() => handleTabChange(9)}>🎨 {t('Talents')}  <span className="tooltip" data-tip="Unique skills or talents, such as musical ability, art skills, or other personal talents."><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5"><path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" /></svg></span></a>
        <a  class={`flex justify-between items-center text-gray-700 block px-4 py-2 text-sm ${activeTab === 10 ? 'bg-gray-100 text-gray-900' : 'text-gray-700'}`} role="menuitem" tabindex="-1" onClick={() => handleTabChange(10)}>📆 {t('Event Management')}  <span className="tooltip" data-tip="Experience in organizing, planning, and managing events, whether small gatherings or large functions."><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5"><path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" /></svg></span></a>
        <a  class={`flex justify-between items-center text-gray-700 block px-4 py-2 text-sm ${activeTab === 11 ? 'bg-gray-100 text-gray-900' : 'text-gray-700'}`} role="menuitem" tabindex="-1" onClick={() => handleTabChange(11)}>🎖️ {t('Awards')}  <span className="tooltip" data-tip="Recognition or awards received for achievements in any field, including academic, extracurricular, or community-based accolades."><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5"><path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" /></svg></span></a>
        {/* Add more options for additional tabs if needed... */}
      </div>
    </div>)}
  </div>

  <div className="tab-content block">
    {activeTab === 0 && <Tab0Content />}
    {activeTab === 1 && <Tab1Content />}
    {activeTab === 2 && <Tab2Content />}
    {activeTab === 3 && <Tab3Content />}
    {activeTab === 5 && <Tab5Content />}
    {activeTab === 6 && <Tab6Content />}
    {activeTab === 7 && <Tab7Content />}
    {activeTab === 8 && <Tab8Content />}
    {activeTab === 9 && <Tab9Content />}
    {activeTab === 10 && <Tab10Content />}
    {activeTab === 11 && <Tab11Content />}
    {/* Render additional components for additional tabs if needed... */}
  </div>
</div>

  );
}

export default PillCat;
