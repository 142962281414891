import React, {useEffect, useState, useContext} from 'react'
import { NavLink,Link } from 'react-router-dom'
import AuthContext from '../context/AuthContext'
import { FaHouseChimney,FaPlus,FaTrophy,FaStopwatch,FaBell } from "react-icons/fa6";
import {FaRegCheckCircle, FaSchool, FaUsers, FaStarOfLife, FaAsterisk, FaCalendarWeek } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import { MdSpaceDashboard } from "react-icons/md";
import { SiGoogleforms } from "react-icons/si";
const SideInnerSMPilot = () => {

  let {user} = useContext(AuthContext)
  let userID = user['user_id']
  ////console.log("role: "+user['role'])
  const {t} = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  
  const lanArabic = i18n.language === 'ar'

let profileID = user['user_id'];
const [profile, setProfile] = useState();
const [loading, setLoading] = useState(true);

let url = '';

if(user['role']==='Student'){
url = 'profile'
} else if(user['role']==='Network Admin'){
url = 'network_profile'
}
const [clubAccess, setclubAccess] = useState(false);
useEffect(() => {
const fetchData = async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_KEY}/api/${url}/${profileID}`);
    let data = await response.json();
    setProfile(data);
    setLoading(false);
    if(data?.club_access===1){
      setclubAccess(true);
    }
  } catch (err) {
    //console.log("The requested profile does not exist.");
    setLoading(false); // Set loading to false even on error to stop showing the loader
  }
};

// Simulate the loader by setting a timer for 3 seconds
const timer = setTimeout(() => {
  fetchData();
}, 900);

// Clean up the timer when the component unmounts
return () => clearTimeout(timer);
}, [profileID]);

    return (


      <div>
     <aside className={`fixed ml-4 inset-y-0 flex-wrap items-center justify-between block w-full p-0 my-4 overflow-y-auto antialiased transition-transform duration-200 bg-white border-0 shadow-xl max-w-64 ease-nav-brand z-990 rounded-2xl xl:${lanArabic ? 'mr-6 right-0' : 'ml-6 left-0'} xl:-translate-x-full`} aria-expanded="false">

     <div class="h-19">
        <a class="block px-8 py-6 m-0 text-sm whitespace-nowrap  text-slate-700"  target="_blank">
        {(() => {
        if (user['role']==="Network Admin") {
          return (
            <img src={profile?.avatar} class="block mx-auto h-full max-w-full transition-all duration-200  ease-nav-brand max-h-16" alt="main_logo" />
          )
        } else if (user['role']==="Student") {
          return (
            <img src={profile?.trial_logo} class="block mx-auto h-full max-w-full transition-all duration-200  ease-nav-brand max-h-28" alt="main_logo" />
          )
        }
      })()}
          
        </a>
      </div>

 

      {/* <hr class="h-px mt-8 bg-transparent bg-gradient-to-r from-transparent via-black/40 to-transparent " /> */}

      <div className='divider mt-20 mx-auto w-11/12'></div>

      <div class="items-center block w-auto max-h-screen overflow-auto h-sidenav grow basis-full">
        <ul class="flex flex-col pl-0 mb-0">
        









      {(() => {
        if (user['role']==='Student') {
          return (
      
            <NavLink to={`/bisr`} className={({ isActive, isPending }) => isPending ? "pending-link" : isActive ? "active-link" : "" }>
                <li className="mt-0.5 w-full">
                  <a className={`gap-2 py-2.7 text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap rounded-lg px-4 font-semibold text-slate-700 transition-colors ${lanArabic ? 'flex-row-reverse' : ''}`}>

                    <div className="mr-2 flex 8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                    <SiGoogleforms   size={20}/>
                    </div>
                    <span className="ml-1 duration-300 opacity-100 pointer-events-none ease">{t('Activity Form')}</span>
                  </a>
                </li>
              </NavLink>
   
          )
        }
      })()}


      {(() => {
        if (user['role']==='Student') {
          return (
      
            <NavLink to={`/preview`} className={({ isActive, isPending }) => isPending ? "pending-link" : isActive ? "active-link" : "" }>
                <li className="mt-0.5 w-full">
                  <a className={`gap-2 py-2.7 text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap rounded-lg px-4 font-semibold text-slate-700 transition-colors ${lanArabic ? 'flex-row-reverse' : ''}`}>

                    <div className="mr-2 flex 8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                    <MdSpaceDashboard  size={20}/>
                    </div>
                    <span className="ml-1 duration-300 opacity-100 pointer-events-none ease">{t('Dashboard')}</span>
                  </a>
                </li>
              </NavLink>
   
          )
        }
      })()}

      
      {(() => {
        if (user['role']==='Network Admin') {
          return (
      
            <NavLink to={`/network`} className={({ isActive, isPending }) => isPending ? "pending-link" : isActive ? "active-link" : "" }>
                <li className="mt-0.5 w-full">
                  <a className={`gap-2 py-2.7 text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap rounded-lg px-4 font-semibold text-slate-700 transition-colors ${lanArabic ? 'flex-row-reverse' : ''}`}>

                    <div className="mr-2 flex 8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                    <FaHouseChimney  size={20}/>
                    </div>
                    <span className="ml-1 duration-300 opacity-100 pointer-events-none ease">{t('Dashboard')}</span>
                  </a>
                </li>
              </NavLink>
   
          )
        }
      })()}


      {(() => {
        if (user['role']==='Network Admin') {
          return (
      
            <NavLink to={`/network_students`} className={({ isActive, isPending }) => isPending ? "pending-link" : isActive ? "active-link" : "" }>
                <li className="mt-0.5 w-full">
                  <a className={`gap-2 py-2.7 text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap rounded-lg px-4 font-semibold text-slate-700 transition-colors ${lanArabic ? 'flex-row-reverse' : ''}`}>

                    <div className="mr-2 flex 8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                    <FaUsers size={20}/>
                    </div>
                    <span className="ml-1 duration-300 opacity-100 pointer-events-none ease">{t('Students')}</span>
                  </a>
                </li>
              </NavLink>
   
          )
        }
      })()}


      {(() => {
        if (user['role']==='Network Admin') {
          return (
      
            <NavLink to={`/network_schools`} className={({ isActive, isPending }) => isPending ? "pending-link" : isActive ? "active-link" : "" }>
                <li className="mt-0.5 w-full">
                  <a className={`gap-2 py-2.7 text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap rounded-lg px-4 font-semibold text-slate-700 transition-colors ${lanArabic ? 'flex-row-reverse' : ''}`}>

                    <div className="mr-2 flex 8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                    <FaSchool size={20}/>
                    </div>
                    <span className="ml-1 duration-300 opacity-100 pointer-events-none ease">{t('Schools')}</span>
                  </a>
                </li>
              </NavLink>
   
          )
        }
      })()}


      {(() => {
        if (user['role']==='Network Admin' && clubAccess) {
          return (
      
            <NavLink to={`/clubs` } className={({ isActive, isPending }) => isPending ? "pending-link" : isActive ? "active-link" : "" }>
                <li className="mt-0.5 w-full">
                  <a className={`gap-2 py-2.7 text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap rounded-lg px-4 font-semibold text-slate-700 transition-colors ${lanArabic ? 'flex-row-reverse' : ''}`}>

                    <div className="mr-2 flex 8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                    <FaStarOfLife size={20}/>
                    </div>
                    <span className="ml-1 duration-300 opacity-100 pointer-events-none ease">{t('Student Clubs')}</span>
                  </a>
                </li>
              </NavLink>
   
          )
        }
      })()}







        </ul>
      </div>




    </aside>
      </div>


)
}

export default SideInnerSMPilot