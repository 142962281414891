import React, { useEffect, useRef, useState } from "react";
import { createGlobalStyle } from "styled-components";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import Papa from "papaparse";
import toast from "react-hot-toast";
import * as XLSX from "xlsx";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Modal from "react-modal";
import Select from "react-select";
import SampleFile from "./SampleFile";
import axios from "axios";

const SchoolOnboardForm = () => {
  const [step, setStep] = useState(1);

  //   const nextStep = () => {
  //     setStep(step + 1);
  //   };

  const nextStep = () => {
    if (step === 1) {
      // Check condition for skipping step 2
      if (hasCampuses) {
        setStep(step + 1);
      } else {
        setStep(step + 2); // Skip step 2 and move to step 3
      }
    } else {
      setStep(step + 1);
    }
  };

  const prevStep = () => {
    if (step > 1) {
      if (step === 3 && !hasCampuses) {
        setStep(step - 2); // Skip step 2 and move back to step 1
      } else {
        setStep(step - 1);
      }
    }
  };

  let totalsteps = 4;

  const GlobalStyle = createGlobalStyle`
    html {
      background: #F9F3FF !important;
      /* Add other global styles here if needed */
    }
  `;

  const [hasCampuses, sethasCampuses] = useState(false);

  const [campuses, setCampuses] = useState([{ name: "", email: "" }]);

  const handleInputChange = (index, field, value) => {
    const newCampuses = [...campuses];
    newCampuses[index][field] = value;
    setCampuses(newCampuses);
  };

  const addMoreCampus = () => {
    setCampuses([...campuses, { name: "", email: "" }]);
  };

  const [users, setUsers] = useState([]);
  const fileInputRef = useRef(null);
  const [schoolName, setSchool] = useState("");
  const [schoolEmail, setSchoolEmail] = useState("");
  const [schoolLogo, setSchoolLogo] = useState("");

  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      try {
        const reader = new FileReader();

        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: "binary" });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const parsedUsers = XLSX.utils.sheet_to_json(sheet);

          // Log parsed users to the console
          console.log(parsedUsers);

          // Set the users state
          setUsers(parsedUsers);

          // Save data to localStorage
          localStorage.setItem("users", JSON.stringify(parsedUsers));

          // Show success toast
          toast.success("File uploaded successfully!");
        };

        reader.readAsBinaryString(file);
      } catch (error) {
        // Show error toast
        toast.error(
          "Error parsing the file. Please make sure it is a valid CSV/XLS file."
        );
      }
    }
  };

  // Load data from localStorage on component mount
  useEffect(() => {
    const storedUsers = localStorage.getItem("users");
    if (storedUsers) {
      const parsedUsers = JSON.parse(storedUsers);
      setUsers(parsedUsers);
    }
  }, []);

  const [selectedUser, setSelectedUser] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  useEffect(() => {
    // Load data from localStorage on component mount
    const storedUsers = localStorage.getItem("users");
    if (storedUsers) {
      const parsedUsers = JSON.parse(storedUsers);
      setUsers(parsedUsers);
    }
  }, []);

  const handleEditUser = (index) => {
    setSelectedUser(users[index]);
    setIsEditModalOpen(true);
  };

  const handleDeleteUser = (index) => {
    confirmAlert({
      title: "Confirm Deletion",
      message: "Are you sure you want to delete this user?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const updatedUsers = [...users];
            updatedUsers.splice(index, 1);
            setUsers(updatedUsers);
            saveUsersToLocalStorage(updatedUsers);
            toast.success("User deleted successfully!");
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const handleSaveEdit = () => {
    // Implement logic to save edited user data
    // For demonstration purposes, just log the changes to the console
    console.log("Saving edited user:", selectedUser);

    const updatedUsers = users.map((user) =>
      user === selectedUser ? { ...user, ...selectedUser } : user
    );
    setUsers(updatedUsers);
    saveUsersToLocalStorage(updatedUsers);

    setIsEditModalOpen(false);
    setSelectedUser(null);
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedUser((prevUser) => ({ ...prevUser, [name]: value }));
  };

  const saveUsersToLocalStorage = (usersToSave) => {
    localStorage.setItem("users", JSON.stringify(usersToSave));
  };

  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);

  // Sample role and permission options
  const roleOptions = [
    { value: "admin", label: "Admin" },
    { value: "teacher", label: "Teacher" },
    // Add more role options as needed
  ];

  const permissionOptions = [
    { value: "read", label: "Read" },
    { value: "write", label: "Write" },
    // Add more permission options as needed
  ];

  const handleRoleChange = (selectedOptions, index) => {
    setUsers((prevUsers) =>
      prevUsers.map((user, i) =>
        i === index ? { ...user, selectedRoles: selectedOptions } : user
      )
    );
  };

  const handlePermissionChange = (selectedOptions, index) => {
    setUsers((prevUsers) =>
      prevUsers.map((user, i) =>
        i === index ? { ...user, selectedPermissions: selectedOptions } : user
      )
    );
  };

  const handleForm = async () => {
    axios
      .post(`${process.env.REACT_APP_API_KEY}/api/create_network/`, {
        schoolName,
        schoolEmail,
        schoolLogo,
        campuses: campuses ?? [],
        users: users ?? [],
      })
      .then((response) => {
        toast.success("Form Submitted Succesfully", {
          icon: "👏",
          style: {
            borderRadius: "55px",
            marginBottom: "15px",
          },
        });
      })
      .catch((error) => {
        toast.error("Error Submitting Form");
        console.error("Error submitting Form:", error);
      });
  };

  return (
    <div className="">
      <GlobalStyle />

      {step === 1 && (
        <div>
          <>
            {/* Card Section */}
            <div className="max-w-4xl px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto h-screen">
              {/* Card */}
              <div className="bg-white rounded-xl shadow p-4 sm:p-7 darki:bg-slate-900">
                <div className="mb-8">
                  <h2 className="text-xl font-bold text-gray-800 darki:text-gray-200">
                    Basic Information{" "}
                    <span className="text-gray-300 font-normal text-base ml-4">
                      {step} of {totalsteps}
                    </span>
                  </h2>
                  <p className="text-sm text-gray-600 darki:text-gray-400">
                    Add basic details like School Name, Email address and
                    environment media.
                  </p>
                </div>

                {/* Grid */}
                <div className="grid sm:grid-cols-12 gap-2 sm:gap-6">
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="af-account-email"
                      className="inline-block text-sm text-gray-800 mt-2.5 darki:text-gray-200"
                    >
                      School Name
                    </label>
                  </div>
                  {/* End Col */}
                  <div className="sm:col-span-9">
                    <input
                      id="network_name"
                      type="text"
                      value={schoolName}
                      onChange={(e) => setSchool(e.target.value)}
                      className="input input-bordered w-full"
                      placeholder="eg: Northville School"
                    />
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="af-account-email"
                      className="inline-block text-sm text-gray-800 mt-2.5 darki:text-gray-200"
                    >
                      School Email
                    </label>
                  </div>
                  {/* End Col */}
                  <div className="sm:col-span-9">
                    <input
                      id="network_email"
                      type="email"
                      value={schoolEmail}
                      className="input input-bordered w-full"
                      placeholder="eg: hello@northville.com"
                      onChange={(e) => setSchoolEmail(e.target.value)}
                    />
                  </div>

                  <div className="sm:col-span-3">
                    <label className="inline-block text-sm text-gray-800 mt-2.5 darki:text-gray-200">
                      Logo
                    </label>
                  </div>

                  {/* End Col */}

                  <div className="sm:col-span-9">
                    <div className="flex items-center gap-5">
                      <div className="flex gap-x-2">
                        <div>
                          <form>
                            <label htmlFor="file-input" className="sr-only">
                              Choose file
                            </label>
                            <input
                              type="file"
                              name="file-input"
                              onChange={(e) => setSchoolLogo(e.target.files[0])}
                              id="file-input"
                              className="block w-full border border-gray-200 shadow-sm rounded-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none darki:bg-slate-900 darki:border-gray-700 darki:text-gray-400 darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600
    file:bg-gray-50 file:border-0
    file:bg-gray-100 file:me-4
    file:py-3 file:px-4
    darki:file:bg-gray-700 darki:file:text-gray-400"
                            />
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="bg-gray-50 border border-gray-200 text-sm text-gray-600 rounded-lg p-4 darki:bg-white/[.05] darki:border-white/10 darki:text-gray-400 mt-8"
                  role="alert"
                >
                  <div className="flex items-base">
                    <div className="flex-shrink-0">
                      <svg
                        className="flex-shrink-0 h-4 w-4 mt-0.5"
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <circle cx={12} cy={12} r={10} />
                        <path d="M12 16v-4" />
                        <path d="M12 8h.01" />
                      </svg>
                    </div>
                    <div className="flex-1 md:flex md:justify-between ms-2">
                      <p className="text-sm m-0">
                        Do you want to add multiple campuses in your school
                        network
                      </p>
                      <p className="text-sm m-0">
                        <Toggle
                          color="red"
                          defaultChecked={hasCampuses}
                          // onChange={(e) => setToggle(e.target.value)}
                          onChange={() => sethasCampuses(!hasCampuses)}
                        />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Card */}
            </div>
            {/* End Card Section */}
          </>
        </div>
      )}

      {step === 2 && (
        <div>
          <>
            {/* Card Section */}
            <div className="max-w-4xl px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto h-screen">
              {/* Card */}
              <div className="bg-white rounded-xl shadow p-4 sm:p-7 darki:bg-slate-900">
                <div className="mb-8">
                  <h2 className="text-xl font-bold text-gray-800 darki:text-gray-200">
                    Add Campus{" "}
                    <span className="text-gray-300 font-normal text-base ml-4">
                      {step} of {totalsteps}
                    </span>
                  </h2>
                  <p className="text-sm text-gray-600 darki:text-gray-400">
                    Add a campus to your school network.
                  </p>
                </div>
                {campuses.map((campus, index) => (
                  <div
                    key={index}
                    className="grid my-4 sm:grid-cols-12 gap-2 sm:gap-6 bg-[#f4effb52] p-4 rounded-xl"
                  >
                    <div className="sm:col-span-3">
                      <label
                        htmlFor={`campus-name-${index}`}
                        className="inline-block text-sm text-gray-800 mt-2.5 darki:text-gray-200"
                      >
                        Campus Details
                      </label>
                    </div>
                    <div className="sm:col-span-9">
                      <div className="flex gap-2 items-center flex-row">
                        <input
                          id={`campus-name-${index}`}
                          type="text"
                          className="input input-bordered w-full"
                          placeholder="eg: Northville Central Campus"
                          value={campus.name}
                          onChange={(e) =>
                            handleInputChange(index, "name", e.target.value)
                          }
                        />
                        <input
                          id={`campus-email-${index}`}
                          type="email"
                          className="input input-bordered w-full"
                          placeholder="eg: central@northville.com"
                          value={campus.email}
                          onChange={(e) =>
                            handleInputChange(index, "email", e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                ))}

                <button
                  className="btn btn-primary btn-sm mt-4"
                  onClick={addMoreCampus}
                >
                  {" "}
                  + Add more{" "}
                </button>
              </div>
              {/* End Card */}
            </div>
            {/* End Card Section */}
          </>
        </div>
      )}

      {step === 3 && (
        <div>
          <>
            {/* Card Section */}
            <div className="max-w-4xl px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto h-screen">
              {/* Card */}
              <div className="bg-white rounded-xl shadow p-4 sm:p-7 darki:bg-slate-900">
                <div className="mb-8">
                  <h2 className="text-xl font-bold text-gray-800 darki:text-gray-200">
                    Upload Users{" "}
                    <span className="text-gray-300 font-normal text-base ml-4">
                      {step} of {totalsteps}
                    </span>
                  </h2>
                  <p className="text-sm text-gray-600 darki:text-gray-400">
                    Upload users to your school space
                  </p>
                </div>

                {/* Grid */}
                <div className="grid sm:grid-cols-12 gap-2 sm:gap-6">
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="af-account-email"
                      className="inline-block text-sm text-gray-800 mt-2.5 darki:text-gray-200"
                    >
                      Sample File
                    </label>
                  </div>
                  {/* End Col */}
                  <div className="sm:col-span-9">
                    <SampleFile />
                  </div>

                  <div className="sm:col-span-3">
                    <label className="inline-block text-sm text-gray-800 mt-2.5 darki:text-gray-200">
                      Upload File
                    </label>
                  </div>

                  {/* End Col */}

                  <div className="sm:col-span-9">
                    <div className="flex items-center gap-5">
                      <div
                        className="flex gap-x-2"
                        style={{ width: "-moz-available" }}
                      >
                        <div class="flex items-center justify-center w-full">
                          <label
                            for="dropzone-file"
                            class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 darki:hover:bg-bray-800 darki:bg-gray-700 hover:bg-gray-100 darki:border-gray-600 darki:hover:border-gray-500 darki:hover:bg-gray-600"
                          >
                            <div class="flex flex-col items-center justify-center pt-5 pb-6">
                              <svg
                                class="w-10 h-10 mb-3 text-gray-400"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                ></path>
                              </svg>
                              <p class="mb-2 text-sm text-gray-500 darki:text-gray-400">
                                <span class="font-semibold">
                                  Click to upload
                                </span>{" "}
                                or drag and drop
                              </p>
                              <p class="text-xs text-gray-500 darki:text-gray-400">
                                CSV/XLS (MAX. 2MB)
                              </p>
                            </div>
                            <input
                              id="dropzone-file"
                              type="file"
                              class="hidden"
                              onChange={handleFileChange}
                              ref={fileInputRef}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Card */}
            </div>
            {/* End Card Section */}
          </>
        </div>
      )}

      {step === 4 && (
        <div>
          <div className="max-w-4xl px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto h-screen">
            <div className="bg-white rounded-xl shadow p-4 sm:p-7 darki:bg-slate-900">
              <div className="mb-8">
                <h2 className="text-xl font-bold text-gray-800 darki:text-gray-200">
                  Set Roles & Permissions{" "}
                  <span className="text-gray-300 font-normal text-base ml-4">
                    {step} of {totalsteps}
                  </span>
                </h2>
                <p className="text-sm text-gray-600 darki:text-gray-400">
                  Define roles and permissions for each user
                </p>
              </div>

              {users?.map((user, index) => (
                <div key={index} className="bg-[#f4effb52] p-4 rounded-xl my-4">
                  <div className="sm:col-span-9 w-full">
                    <div className="join my-4 gap-6 flex justify-between flex-row">
                      <div className="w-full">
                        <div className="mr-4">
                          <span className="text-sm ">
                            <strong>{`${user["FIRST NAME"]} ${user["LAST NAME"]}`}</strong>{" "}
                            <br />
                            {user["Email"]}
                          </span>
                        </div>
                        <div className="flex gap-2 items-center mt-2">
                          <button
                            className=""
                            onClick={() => handleEditUser(index)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="w-5 h-5"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                              />
                            </svg>
                          </button>
                          {/* Delete button */}
                          <button
                            className=""
                            onClick={() => handleDeleteUser(index)}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="text-red-500 w-5 h-5"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                      <div className="w-11/12">
                        <label>Roles:</label>
                        <Select
                          isMulti
                          options={roleOptions}
                          value={user.selectedRoles}
                          onChange={(selectedOptions) =>
                            handleRoleChange(selectedOptions, index)
                          }
                        />
                      </div>
                      <div className="w-11/12">
                        <label>Permissions:</label>
                        <Select
                          isMulti
                          options={permissionOptions}
                          value={user.selectedPermissions}
                          onChange={(selectedOptions) =>
                            handlePermissionChange(selectedOptions, index)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {/* <div style={{ marginTop: '20px' }}>
        {step > 1 && (
          <button onClick={prevStep}>Previous</button>
        )}
        {step < 3 && (
          <button onClick={nextStep}>Next</button>
        )}
      </div> */}

      <div className="fixed inset-x-0 bottom-0">
        <div className="relative flex items-center justify-center gap-4 bg-primary px-4 py-3 text-white">
          <div
            className="flex w-52 h-4 bg-gray-200 rounded-full overflow-hidden darki:bg-gray-700"
            role="progressbar"
            aria-valuenow={(step / totalsteps) * 100}
            aria-valuemin={0}
            aria-valuemax={totalsteps}
          >
            <div
              className="flex flex-col justify-center rounded-full overflow-hidden bg-blue-600 text-xs text-white text-center whitespace-nowrap darki:bg-blue-500 transition duration-500"
              style={{ width: `${(step / totalsteps) * 100}%` }}
            >
              {(step / totalsteps) * 100}%
            </div>
          </div>

          {step > 1 && (
            <button
              onClick={prevStep}
              type="button"
              class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-white hover:bg-lilac disabled:opacity-50 disabled:pointer-events-none darki:text-white darki:hover:bg-gray-700 darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
            >
              BACK
            </button>
          )}

          {step < 4 && (
            <button
              onClick={nextStep}
              type="button"
              class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-white hover:bg-lilac disabled:opacity-50 disabled:pointer-events-none darki:text-white darki:hover:bg-gray-700 darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
            >
              NEXT
            </button>
          )}

          {step > 3 && (
            <button
              type="button"
              onClick={handleForm}
              class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-white hover:bg-lilac disabled:opacity-50 disabled:pointer-events-none darki:text-white darki:hover:bg-gray-700 darki:focus:outline-none darki:focus:ring-1 darki:focus:ring-gray-600"
            >
              Submit
            </button>
          )}
        </div>
      </div>

      <Modal
        isOpen={isEditModalOpen}
        onRequestClose={() => setIsEditModalOpen(false)}
        contentLabel="Edit User"
      >
        <h2>Edit User</h2>
        <form>
          <label>
            First Name:
            <input
              type="text"
              name="FIRST NAME"
              value={selectedUser ? selectedUser["FIRST NAME"] : ""}
              onChange={handleEditInputChange}
            />
          </label>
          <label>
            Last Name:
            <input
              type="text"
              name="LAST NAME"
              value={selectedUser ? selectedUser["LAST NAME"] : ""}
              onChange={handleEditInputChange}
            />
          </label>
          <label>
            Email:
            <input
              type="text"
              name="Email"
              value={selectedUser ? selectedUser["Email"] : ""}
              onChange={handleEditInputChange}
            />
          </label>
          <button type="button" onClick={handleSaveEdit}>
            Save
          </button>
        </form>
      </Modal>
    </div>
  );
};

export default SchoolOnboardForm;
