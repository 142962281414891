import React, {useEffect, useState, useContext} from 'react'
import { NavLink,Link } from 'react-router-dom'
import AuthContext from '../context/AuthContext'
import { FaHouseChimney,FaPlus,FaTrophy, FaStopwatch,FaBell } from "react-icons/fa6";
import {FaRegCheckCircle, FaCompass , FaSchool, FaUsers, FaStarOfLife, FaAsterisk, FaCalendarWeek } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { tr } from 'date-fns/locale';

import { MdSpaceDashboard } from "react-icons/md";
import { SiGoogleforms } from "react-icons/si";

const SideInnerPilot = () => {

    let {user} = useContext(AuthContext)
    let userID = user['user_id']
    ////console.log("role: "+user['role'])

    const [shouldRenderQuizzesLink, setShouldRenderQuizzesLink] = useState(false);
const [shouldRenderNotificationsLink, setShouldRenderNotificationsLink] = useState(false);

let profileID = userID

const [profile, setProfile] = useState();
const [networkID, setnetworkID] = useState();
const [networkData, setnetworkData] = useState();
const [loading, setLoading] = useState(true);

let url = '';

if(user['role']==='Student'){
  url = 'profile'
} else if(user['role']==='Network Admin'){
  url = 'network_profile'
}

const {t} = useTranslation();
const changeLanguage = (lng) => {
  i18n.changeLanguage(lng);
};

const lanArabic = i18n.language === 'ar'

console.warn("You're: "+user['role'])

const [clubAccess, setclubAccess] = useState(false);
useEffect(() => {
  const fetchProfileData = async () => {
    try {
      const profileResponse = await fetch(`${process.env.REACT_APP_API_KEY}/api/${url}/${profileID}`);
      const profileData = await profileResponse.json();
      setProfile(profileData);
      setnetworkID(profileData?.network_in);
      setLoading(false);

      if(profileData?.club_access===1){
        setclubAccess(true);
      }

      // Check if the school name is not matching exactly
      if (profileData.school.trim() === "Northville Public School") {
        setShouldRenderQuizzesLink(false);
        setShouldRenderNotificationsLink(false);
      } else {
        setShouldRenderQuizzesLink(true);
        setShouldRenderNotificationsLink(true);
      }
    } catch (profileError) {
      console.error("Error fetching profile data:", profileError);
      setLoading(false);
    }
  };

  const fetchNetworkData = async () => {
    try {
      const networkResponse = await fetch(`${process.env.REACT_APP_API_KEY}/api/network_profile_by_network_id/${networkID}/`);
      const networkData = await networkResponse.json();
      setnetworkData(networkData);
      setLoading(false);
      if(networkData?.club_access===1){
        setclubAccess(true);
      }
    } catch (networkError) {
      console.error("Error fetching network data:", networkError);
      setLoading(false);
    }
  };

  // Simulate the loader by setting a timer for 900 milliseconds
  const timer = setTimeout(() => {
    fetchProfileData();
    fetchNetworkData();
  }, 900);

  // Clean up the timer when the component unmounts
  return () => clearTimeout(timer);

}, [profileID, networkID]);




    return (


      <div>
<aside className={`fixed inset-y-0 flex-wrap items-center justify-between block w-full p-0 my-4 overflow-y-auto antialiased transition-transform duration-200 ${lanArabic ? 'xl:right-0 xl:mr-6' : 'xl:left-0 xl:ml-6'} -translate-x-full bg-white border-0 shadow-xl max-w-64 ease-nav-brand z-990 rounded-2xl xl:translate-x-0`}>

      <div class="h-19">
        <i class="absolute top-0 right-0 p-4 opacity-50 cursor-pointer fas fa-times text-slate-400 xl:hidden" ></i>
        <a class="block px-8 py-6 m-0 text-sm whitespace-nowrap  text-slate-700"  target="_blank">
        {(() => {
        if (user['role']==="Network Admin") {
          return (
            <img src={profile?.avatar} class="block mx-auto h-full max-w-full transition-all duration-200  ease-nav-brand max-h-28" alt="main_logo" />
          )
        } else if (user['role']==="Student") {
          return (
            <img src={profile?.trial_logo} class="block mx-auto h-full max-w-full transition-all duration-200  ease-nav-brand max-h-28" alt="main_logo" />
          )
        }
      })()}
          
        </a>
      </div>

 

      {/* <hr class="h-px mt-8 bg-transparent bg-gradient-to-r from-transparent via-black/40 to-transparent " /> */}

      <div className='divider mt-20 mx-auto w-11/12'></div>

      <div class="items-center block w-auto max-h-screen overflow-auto h-sidenav grow basis-full">
        <ul class="flex flex-col pl-0 mb-0">
        









      {(() => {
        if (user['role']==='Student') {
          return (
      
            <NavLink to={`/${profile?.trial_route}`} className={({ isActive, isPending }) => isPending ? "pending-link" : isActive ? "active-link" : "" }>
                <li className="mt-0.5 w-full">
                  <a className={`gap-2 py-2.7 text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap rounded-lg px-4 font-semibold text-slate-700 transition-colors ${lanArabic ? 'flex-row-reverse' : ''}`}>

                    <div className="mr-2 flex 8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                    <SiGoogleforms   size={20}/>
                    </div>
                    <span className="ml-1 duration-300 opacity-100 pointer-events-none ease">{t('Activity Form')}</span>
                  </a>
                </li>
              </NavLink>
   
          )
        }
      })()}


      {(() => {
        if (user['role']==='Student') {
          return (
      
            <NavLink to={`/preview`} className={({ isActive, isPending }) => isPending ? "pending-link" : isActive ? "active-link" : "" }>
                <li className="mt-0.5 w-full">
                  <a className={`gap-2 py-2.7 text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap rounded-lg px-4 font-semibold text-slate-700 transition-colors ${lanArabic ? 'flex-row-reverse' : ''}`}>

                    <div className="mr-2 flex 8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                    <MdSpaceDashboard  size={20}/>
                    </div>
                    <span className="ml-1 duration-300 opacity-100 pointer-events-none ease">{t('Dashboard')}</span>
                  </a>
                </li>
              </NavLink>
   
          )
        }
      })()}

      
      {(() => {
        if (user['role']==='Network Admin') {
          return (
      
            <NavLink to={`/network`} className={({ isActive, isPending }) => isPending ? "pending-link" : isActive ? "active-link" : "" }>
                <li className="mt-0.5 w-full">
                  <a className={`gap-2 py-2.7 text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap rounded-lg px-4 font-semibold text-slate-700 transition-colors ${lanArabic ? 'flex-row-reverse' : ''}`}>

                    <div className="mr-2 flex 8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                    <FaHouseChimney  size={20}/>
                    </div>
                    <span className="ml-1 duration-300 opacity-100 pointer-events-none ease">{t('Dashboard')}</span>
                  </a>
                </li>
              </NavLink>
   
          )
        }
      })()}


      {(() => {
        if (user['role']==='Network Admin') {
          return (
      
            <NavLink to={`/network_students`} className={({ isActive, isPending }) => isPending ? "pending-link" : isActive ? "active-link" : "" }>
                <li className="mt-0.5 w-full">
                  <a className={`gap-2 py-2.7 text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap rounded-lg px-4 font-semibold text-slate-700 transition-colors ${lanArabic ? 'flex-row-reverse' : ''}`}>

                    <div className="mr-2 flex 8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                    <FaUsers size={20}/>
                    </div>
                    <span className="ml-1 duration-300 opacity-100 pointer-events-none ease">{t('Students')}</span>
                  </a>
                </li>
              </NavLink>
   
          )
        }
      })()}


      {(() => {
        if (user['role']==='Network Admin') {
          return (
      
            <NavLink to={`/network_schools`} className={({ isActive, isPending }) => isPending ? "pending-link" : isActive ? "active-link" : "" }>
                <li className="mt-0.5 w-full">
                  <a className={`gap-2 py-2.7 text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap rounded-lg px-4 font-semibold text-slate-700 transition-colors ${lanArabic ? 'flex-row-reverse' : ''}`}>

                    <div className="mr-2 flex 8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                    <FaSchool size={20}/>
                    </div>
                    <span className="ml-1 duration-300 opacity-100 pointer-events-none ease">{t('Schools')}</span>
                  </a>
                </li>
              </NavLink>
   
          )
        }
      })()}


      {(() => {
        if (user['role']==='Network Admin' && clubAccess) {
          return (
      
            <NavLink to={`/clubs`} className={({ isActive, isPending }) => isPending ? "pending-link" : isActive ? "active-link" : "" }>
                <li className="mt-0.5 w-full">
                  <a className={`gap-2 py-2.7 text-sm ease-nav-brand my-0 mx-2 flex items-center whitespace-nowrap rounded-lg px-4 font-semibold text-slate-700 transition-colors ${lanArabic ? 'flex-row-reverse' : ''}`}>

                    <div className="mr-2 flex 8 items-center justify-center rounded-lg bg-center stroke-0 text-center xl:p-2.5">
                    <FaStarOfLife size={20}/>
                    </div>
                    <span className="ml-1 duration-300 opacity-100 pointer-events-none ease">{t('Student Clubs')}</span>
                  </a>
                </li>
              </NavLink>
   
          )
        }
      })()}







        </ul>
      </div>




    </aside>
      </div>


)
}

export default SideInnerPilot