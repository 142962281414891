// src/components/BlogSidebar.js
import React from 'react';

const ClubSidebar = ({ categories, onSelectCategory, onShowAll }) => {
  return (
    <div className="mb-4 bg-white p-6 rounded-xl">
    <div className='flex justify-between'>
      <h2 className="text-lg font-medium mb-2">Categories</h2>

      <span class="inline-flex items-center gap-x-1.5 py-1.5 px-3 rounded-full text-xs font-medium bg-blue-100 text-blue-800 darki:bg-blue-800/30 darki:text-blue-500"> {categories?.length} </span>
      </div>
      <ul className='text-start'>
        <li className="p-4 cursor-pointer text-blue-500 hover:underline" onClick={() => onShowAll()}>
         All Clubs
        </li>
        {categories.map((category) => (
          <li key={category} className="p-4 cursor-pointer text-blue-500 hover:underline" onClick={() => onSelectCategory(category)}>
            {category}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ClubSidebar;
