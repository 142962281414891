import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";
import AuthContext from "../context/AuthContext";
import BackNav from "./BackNav";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useLanguage } from "../components/language";

const Social = () => {
  let { user } = useContext(AuthContext);
  const history = useNavigate();

  // extract profile id
  let params = useParams();
  let profileID = user["user_id"];

  // validate the user if he is the owner of the profile
  let isMyself = true;
  if (user !== null && user["user_id"] === parseInt(profileID)) {
    isMyself = true;
  }
  const { t, changeLanguage, lanArabic } = useLanguage();
  // initialize thread and posts component state
  const [profile, setProfile] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const getProfile = async () => {
    try {
      setIsLoading(true);

      const response = await fetch(
        `${process.env.REACT_APP_API_KEY}/api/profile/${profileID}`
      );
      const data = await response.json();

      setProfile(data);
      setIsLoading(false);
    } catch (err) {
      console.log("The requested profile does not exist.");
      setIsLoading(false);
    }
  };

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_KEY}/api/profile/${profileID}`
        );
        let data = await response.json();
        setProfile(data);
        setLoading(false);
      } catch (err) {
        console.log("The requested profile does not exist.");
        setLoading(false); // Set loading to false even on error to stop showing the loader
      }
    };

    // Simulate the loader by setting a timer for 3 seconds
    const timer = setTimeout(() => {
      fetchData();
    }, 900);

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, [profileID]);

  console.log("profile LEad" + profile);

  // Function to get the current date in the required format (YYYY-MM-DD)
  const getCurrentDate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const [formData, setFormData] = useState({
    activity_name: "",
    grade: 1,
    duration: "",
    scope: "",
    service_hours: "",
    school_in: profile ? profile.school_in : "", // Use profile data if available
    status: 0,
    score: 0,
    role: "",
    feedback: 0,
    description: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Update post_date and posted_by fields
    const dataToSend = {
      ...formData,
      post_date: getCurrentDate(),
      posted_by: user ? user.user_id : "", // Use the user's ID if available
      school_in: profile?.school_in,
      status: 0,
      score: 0,
      feedback: 0,
      role: 1,
    };

    const start = new Date(formData.start_date);
    const end = new Date(formData.end_date);
    const durationMonths = getDurationMonths(start, end);

    dataToSend.duration = durationMonths;

    try {
      setIsSubmitting(true); // Set the submitting state to true

      const response = await axios.post(
        `${process.env.REACT_APP_API_KEY}/api/add_social/`,
        dataToSend
      );

      // Reset the form data after successful submission
      setFormData({
        activity_name: "",
        grade: 1,
        duration: "",
        scope: 0,
        service_hours: "",
        school_in: profile ? profile.school_in : "",
        role: "",
      });

      // Reset the date range picker
      setDateRange([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
        },
      ]);

      toast.success("Good Job! Activity Saved", {
        icon: "👏",
        style: {
          borderRadius: "55px",
          marginBottom: "15px",
        },
      });
      setCurrentStep(1);
    } catch (error) {
      console.error("Error creating activity:", error.response.data);
      toast.error("Oh No! Something went wrong", {
        icon: "😓",
        style: {
          borderRadius: "55px",
          marginBottom: "15px",
        },
      });
    } finally {
      setIsSubmitting(false); // Set the submitting state back to false after the request is done
    }
  };

  const getDurationMonths = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const diffTime = Math.abs(end - start);
    const diffMonths = Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 30));
    return diffMonths;
  };

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const handleChangeDate = (ranges) => {
    setDateRange([ranges.selection]);
    const duration = getDurationMonths(
      ranges.selection.startDate,
      ranges.selection.endDate
    );
    setFormData((prevData) => ({
      ...prevData,
      duration: String(duration),
      start_date: ranges.selection.startDate.toISOString().split("T")[0],
      end_date: ranges.selection.endDate.toISOString().split("T")[0],
      scope: 0,
    }));
  };

  const handleIncrementGrade = () => {
    if (formData.grade < 13) {
      setFormData((prevData) => ({
        ...prevData,
        grade: String(parseInt(prevData.grade) + 1),
      }));
    }
  };

  const handleDecrementGrade = () => {
    if (formData.grade > 1) {
      setFormData((prevData) => ({
        ...prevData,
        grade: String(parseInt(prevData.grade) - 1),
      }));
    }
  };

  const currentYear = new Date().getFullYear();

  const [dateError, setDateError] = useState("");

  const handleDateValidation = () => {
    const start = new Date(formData.start_date);
    const end = new Date(formData.end_date);

    // Check if end_date is older than start_date
    if (end < start) {
      setDateError("End Date cannot be earlier than Start Date");
    } else {
      // Clear any previous error message
      setDateError("");
    }
  };

  useEffect(() => {
    const start = new Date(formData.start_date);
    const end = new Date(formData.end_date);
    const durationMonths = getDurationMonths(start, end);
    setFormData((prevData) => ({
      ...prevData,
      duration: durationMonths.toString(), // Convert to string
    }));
  }, [formData.start_date, formData.end_date]);

  const totalSteps = 6; // Set the total number of steps here

  let [currentStep, setCurrentStep] = useState(1);

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarVisible((prevState) => !prevState);
  };

  const getDisplayText = (value, options) => {
    const selectedOption = options.find((option) => option.value === value);
    return selectedOption ? selectedOption.text : "";
  };

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Detect if the user is using a mobile device
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    setIsMobile(
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        userAgent
      )
    );
  }, []);

  return (
    <div className="bg-white	">
      {isMobile && <BackNav />}

      <div className="mx-auto max-w-screen-xl px-4 py- sm:px-6 lg:px-8">
        <div className="mx-auto max-w-lg lg:max-w-2xl">
          <form
            className="mb-0 mt-6 space-y-4 rounded-lg px-6 py-8 bg-white"
            onSubmit={handleSubmit}
          >
            <h1 className="text-center text-2xl font-bold sm:text-3xl mt-6">
              🎗️ Social Work
            </h1>
            <p className="mx-auto mt-4 max-w-md text-center text-gray-500">
              Have you done any volunteering or social work
            </p>

            {currentStep === 1 && (
              <>
                <div className="form-control w-full">
                  <label className="label mb-2 flex flex-row items-center justify-between">
                    <span className="label-text">Social Work Activity</span>
                    <div
                      className="tooltip"
                      data-tip="Choose the activity you participated in. This helps in categorizing your experience more accurately."
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-5 h-5"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                        />
                      </svg>
                    </div>
                  </label>
                  <select
                    className="select select-bordered"
                    name="activity_name"
                    value={formData.activity_name}
                    onChange={handleChange}
                  >
                    <option selected>Pick one</option>
                    <option value="Food drive">Food drive</option>
                    <option value="Clothes drive">Clothes drive</option>
                    <option value="Charity collection">
                      Charity collection
                    </option>
                    <option value="Blood donation drive">
                      Blood donation drive
                    </option>
                    <option value="Hospital volunteer">
                      Hospital volunteer
                    </option>
                    <option value="Trash clean-up">Trash clean-up</option>
                    <option value="Charity bake sale">Charity bake sale</option>
                  </select>
                </div>
                <textarea
                  name="description"
                  id="description"
                  class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none darki:bg-slate-900 dark:border-gray-700 darki:text-gray-400 darki:focus:ring-gray-600"
                  rows="3"
                  placeholder="Tell us more about your experience.."
                  onChange={handleChange}
                  value={formData.description}
                ></textarea>
              </>
            )}

            {currentStep === 2 && (
              <>
                <div>
                  {dateError && (
                    <div className="alert alert-error mb-6">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="stroke-current shrink-0 h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                      <span>{dateError}</span>
                    </div>
                  )}
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-y-4">
                    <label className="label mb-2 flex flex-row items-center justify-start gap-2">
                      <span className="label-text">Start Date</span>
                      <div
                        className="tooltip"
                        data-tip="Select start date when you performed this role."
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                          />
                        </svg>
                      </div>
                    </label>
                    <input
                      type="date"
                      name="start_date"
                      value={formData.start_date}
                      onChange={handleChange}
                      onBlur={handleDateValidation} // Add onBlur event handler
                      max="2024-12-31" // Set max attribute to the current date
                      className="input w-full"
                    />

                    <label className="label mb-2 flex flex-row items-center justify-start gap-2">
                      <span className="label-text">End Date</span>
                      <div
                        className="tooltip"
                        data-tip="Select end date when you performed this role."
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="w-5 h-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                          />
                        </svg>
                      </div>
                    </label>
                    <input
                      type="date"
                      name="end_date"
                      value={formData.end_date}
                      onChange={handleChange}
                      onBlur={handleDateValidation} // Add onBlur event handler
                      max="2024-12-31" // Set max attribute to the current date
                      className="input w-full"
                    />

                    {/* Display the error message */}
                  </div>
                </div>
              </>
            )}

            {currentStep === 3 && (
              <>
                <div>
                  <label className="label mb-2 flex flex-row items-center justify-between">
                    <span className="label-text">Semester</span>
                    <div
                      className="tooltip"
                      data-tip="Enter the grade you were in when you held this role"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-5 h-5"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                        />
                      </svg>
                    </div>
                  </label>
                  <div className="flex items-center justify-between mt-2">
                    <button
                      type="button"
                      className="btn btn-circle"
                      onClick={handleDecrementGrade}
                    >
                      -
                    </button>
                    <input
                      type="text"
                      name="grade"
                      value={formData.grade}
                      onChange={handleChange}
                      required
                      style={{ width: "50%" }}
                      className="input input-bordered"
                    />
                    <button
                      type="button"
                      className="btn btn-circle"
                      onClick={handleIncrementGrade}
                    >
                      +
                    </button>
                  </div>
                </div>
              </>
            )}

            {currentStep === 4 && (
              <>
                <div className="form-control w-full">
                  <label className="label mb-2 flex flex-row items-center justify-between">
                    <span className="label-text">Role</span>
                    <div
                      className="tooltip"
                      data-tip="Select the role of your Social experience."
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-5 h-5"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                        />
                      </svg>
                    </div>
                  </label>
                  <select
                    className="select select-bordered"
                    name="scope"
                    value={formData.scope}
                    onChange={handleChange}
                  >
                    <option selected>Pick one</option>
                    <option value="1">Volunteered</option>
                    <option value="1.2">Organized</option>
                    <option value="1.5">Donated</option>
                  </select>
                </div>
              </>
            )}

            {currentStep === 5 && (
              <>
                <div className="form-control w-full">
                  <label className="label mb-2 flex flex-row items-center justify-between">
                    <span className="label-text">Engagement</span>
                    <div
                      className="tooltip"
                      data-tip="Select how frequently you were engaged in this role."
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-5 h-5"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                        />
                      </svg>
                    </div>
                  </label>
                  <select
                    className="select select-bordered"
                    name="service_hours"
                    value={formData.service_hours}
                    onChange={handleChange}
                    required
                  >
                    <option selected>Pick one</option>
                    <option value="1">
                      Light Engagement (1-2 hours/week){" "}
                    </option>
                    <option value="1.2">
                      Moderate Engagement (3-5 hours/week)
                    </option>
                    <option value="1.4">
                      Heavy Engagement (6-7 hours/week)
                    </option>
                    <option value="1.5">
                      Extra Engagement (7+ hours/week)
                    </option>
                  </select>
                </div>
              </>
            )}

            {currentStep === 6 && (
              <>
                <label className="label mb-2 flex flex-row items-center justify-between">
                  <span className="label-text">Overview</span>
                  <div
                    className="tooltip"
                    data-tip="Take a final look at your choices before submission"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-5 h-5"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                      />
                    </svg>
                  </div>
                </label>

                <div class="flow-root">
                  <dl class="-my-3 divide-y divide-gray-100 text-sm">
                    <div class="grid grid-cols-1 gap-1 py-3 sm:grid-cols-3 sm:gap-4">
                      <dt class="font-medium text-gray-900">Social Activity</dt>
                      <dd class="text-gray-700 sm:col-span-2">
                        {formData?.activity_name}
                      </dd>
                    </div>

                    <div class="grid grid-cols-1 gap-1 py-3 sm:grid-cols-3 sm:gap-4">
                      <dt class="font-medium text-gray-900">Grade</dt>
                      <dd class="text-gray-700 sm:col-span-2">
                        {formData?.grade}
                      </dd>
                    </div>

                    <div class="grid grid-cols-1 gap-1 py-3 sm:grid-cols-3 sm:gap-4">
                      <dt class="font-medium text-gray-900">Role</dt>
                      <dd class="text-gray-700 sm:col-span-2">
                        {getDisplayText(formData?.scope, [
                          { value: "1", text: "Volunteered" },
                          { value: "1.2", text: "Organized" },
                          { value: "1.5", text: "Donated" },
                        ])}
                      </dd>
                    </div>

                    <div class="grid grid-cols-1 gap-1 py-3 sm:grid-cols-3 sm:gap-4">
                      <dt class="font-medium text-gray-900">Engagement</dt>
                      <dd class="text-gray-700 sm:col-span-2">
                        {getDisplayText(formData?.service_hours, [
                          {
                            value: "1",
                            text: "Light Engagement (1-2 hours/week)",
                          },
                          {
                            value: "1.2",
                            text: "Moderate Engagement (3-5 hours/week)",
                          },
                          {
                            value: "1.4",
                            text: "Heavy Engagement (6-7 hours/week)",
                          },
                          {
                            value: "1.5",
                            text: "Extra Engagement (7+ hours/week)",
                          },
                        ])}
                      </dd>
                    </div>

                    <div class="grid grid-cols-1 gap-1 py-3 sm:grid-cols-3 sm:gap-4">
                      <dt class="font-medium text-gray-900">Duration</dt>
                      <dd class="text-gray-700 sm:col-span-2">
                        {formData.duration} month(s)
                      </dd>
                    </div>

                    <div class="grid grid-cols-1 gap-1 py-3 sm:grid-cols-3 sm:gap-4">
                      <dt class="font-medium text-gray-900">Description</dt>
                      <dd class="text-gray-700 sm:col-span-2">
                        {formData.description}
                      </dd>
                    </div>
                  </dl>
                </div>
              </>
            )}

            <div className="flex justify-between mt-8">
              {currentStep > 1 && (
                <button
                  type="button"
                  className="btn btn-sm "
                  onClick={prevStep}
                >
                  ‹ Back
                </button>
              )}
              {currentStep < totalSteps && (
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  onClick={nextStep}
                  disabled={
                    (currentStep === 1 && !formData.activity_name) ||
                    (currentStep === 3 && !formData.grade) ||
                    (currentStep === 4 && !formData.scope) ||
                    (currentStep === 5 && !formData.service_hours) ||
                    (currentStep === 2 && dateError) ||
                    (currentStep === 2 && !formData.duration) ||
                    (currentStep === 2 && formData.duration === "NaN")
                  }
                >
                  Next ›
                </button>
              )}
              {currentStep === totalSteps && (
                <button
                  type="submit"
                  className="btn btn-primary btn-sm"
                  disabled={
                    isSubmitting ||
                    (currentStep === 1 && !formData.activity_name) ||
                    (currentStep === 3 && !formData.grade) ||
                    (currentStep === 4 && !formData.scope) ||
                    (currentStep === 5 && !formData.service_hours) ||
                    (currentStep === 2 && dateError) ||
                    (currentStep === 2 && !formData.duration) ||
                    (currentStep === 2 && formData.duration === "NaN")
                  }
                >
                  Submit
                </button>
              )}
            </div>

            {/* <button
              type="submit"
              className="block btn-primary mt-4 btn w-full rounded-lg bg-indigo-600 px-5 py-3 text-sm font-medium text-white"
              disabled={isSubmitting || !formData.activity_name || !formData.grade || !formData.scope || !formData.service_hours || !formData.duration}
            >
              Add Activity
            </button> */}
          </form>
        </div>
      </div>

      <div style={{ zIndex: 999999999999999, marginBottom: "2vh" }}>
        <Toaster position="bottom-center" />
      </div>
    </div>
  );
};

export default Social;
