import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Chart from "react-apexcharts";
import axios from 'axios';
import AuthContext from '../context/AuthContext';
import { useLanguage } from './language';

const RadarChart = ({source}) => {
  let { user } = useContext(AuthContext);
  
  // extract profile id
  //let params = useParams();
  //let profileID = user['user_id'];
  const { t, lanArabic } = useLanguage(); // Use the useLanguage hook to access translations and lanArabic

  const params = useParams();
    
  let profileID;
  if (source === 'Param') {
    profileID = params.id;
  } else if (source === 'Self') {
    profileID = user['user_id'];
  } 

  if (!profileID) {
    console.error('Invalid source provided to TimelineStack');
    profileID = 0;
  }




  //let userID = user['user_id'];
  let currentUser = profileID

  
  const [profile, setProfile] = useState();
  const userID = parseInt(profileID);



  

  
  useEffect(() => {
    const getProfile = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_KEY}/api/profile/${currentUser}`);
        // parse the data in json
        let data = await response.json();
        setProfile(data);
      } catch (err) {
        console.log('The requested profile does not exist.');
      }
    };

    getProfile();
  }, [profileID]);

  // validate if the user is the owner of the profile
  let isMyself = user !== null && user['user_id'] === parseInt(userID);

  const [competencyData11, setcompetencyData11] = useState([]);
  const [competencyData12, setcompetencyData12] = useState([]);
  const [competencyData13, setcompetencyData13] = useState([]);
  const [competencyData14, setcompetencyData14] = useState([]);
  const [competencyData15, setcompetencyData15] = useState([]);
  const [competencyData16, setcompetencyData16] = useState([]);
  const [competencyData17, setcompetencyData17] = useState([]);


  const [competencyData21, setcompetencyData21] = useState([]);
  const [competencyData22, setcompetencyData22] = useState([]);
  const [competencyData23, setcompetencyData23] = useState([]);
  const [competencyData24, setcompetencyData24] = useState([]);
  const [competencyData25, setcompetencyData25] = useState([]);
  const [competencyData26, setcompetencyData26] = useState([]);

  const [competencyData31, setcompetencyData31] = useState([]);
  const [competencyData32, setcompetencyData32] = useState([]);
  const [competencyData33, setcompetencyData33] = useState([]);
  const [competencyData34, setcompetencyData34] = useState([]);
  const [competencyData35, setcompetencyData35] = useState([]);
  const [competencyData36, setcompetencyData36] = useState([]);
  const [competencyData37, setcompetencyData37] = useState([]);
  const [competencyData38, setcompetencyData38] = useState([]);
  const [competencyData39, setcompetencyData39] = useState([]);

  const [competencyData41, setcompetencyData41] = useState([]);
  const [competencyData42, setcompetencyData42] = useState([]);
  const [competencyData43, setcompetencyData43] = useState([]);
  const [competencyData44, setcompetencyData44] = useState([]);
  const [competencyData45, setcompetencyData45] = useState([]);
  const [competencyData46, setcompetencyData46] = useState([]);

  const [competencyData50, setcompetencyData50] = useState([]);
  const [competencyData51, setcompetencyData51] = useState([]);
  const [competencyData52, setcompetencyData52] = useState([]);
  const [competencyData53, setcompetencyData53] = useState([]);
  const [competencyData54, setcompetencyData54] = useState([]);
  const [competencyData55, setcompetencyData55] = useState([]);
  const [competencyData56, setcompetencyData56] = useState([]);
  const [competencyData57, setcompetencyData57] = useState([]);
  const [competencyData58, setcompetencyData58] = useState([]);
  const [competencyData59, setcompetencyData59] = useState([]);
  

  const [competencyData61, setcompetencyData61] = useState([]);
  const [competencyData62, setcompetencyData62] = useState([]);
  const [competencyData63, setcompetencyData63] = useState([]);
  const [competencyData64, setcompetencyData64] = useState([]);
  const [competencyData65, setcompetencyData65] = useState([]);
  const [competencyData66, setcompetencyData66] = useState([]);
  const [competencyData67, setcompetencyData67] = useState([]);


  useEffect(() => {
    // Fetch data only where posted_by matches userID
      axios.get(`${process.env.REACT_APP_API_KEY}/api/get_lead/`)
      .then(response => {
        const filteredData11 = response.data.filter(item => parseInt(item.posted_by) === parseInt(userID));
        
        const filteredData31 = response.data.filter(item => parseInt(item.posted_by) === parseInt(userID));
        const filteredData41 = response.data.filter(item => parseInt(item.posted_by) === parseInt(userID));
        const filteredData50 = response.data.filter(item => parseInt(item.posted_by) === parseInt(userID));
        const filteredData61 = response.data.filter(item => parseInt(item.posted_by) === parseInt(userID));

        setcompetencyData11(filteredData11);
        
        setcompetencyData31(filteredData31);
        setcompetencyData41(filteredData41);
        setcompetencyData50(filteredData50);
        setcompetencyData61(filteredData61);
      })

      axios.get(`${process.env.REACT_APP_API_KEY}/api/get_social/`)
      .then(response => {
        const filteredData12 = response.data.filter(item => parseInt(item.posted_by) === parseInt(userID));
        setcompetencyData12(filteredData12);
        
        const filteredData22 = response.data.filter(item => parseInt(item.posted_by) === parseInt(userID));
        setcompetencyData22(filteredData22);

        const filteredData32 = response.data.filter(item => parseInt(item.posted_by) === parseInt(userID));
        setcompetencyData32(filteredData32);

        const filteredData42 = response.data.filter(item => parseInt(item.posted_by) === parseInt(userID));
        setcompetencyData42(filteredData42);

        const filteredData51 = response.data.filter(item => parseInt(item.posted_by) === parseInt(userID));
        setcompetencyData51(filteredData51);

        const filteredData62 = response.data.filter(item => parseInt(item.posted_by) === parseInt(userID));
        setcompetencyData62(filteredData62);
        
        
      })

      axios.get(`${process.env.REACT_APP_API_KEY}/api/get_sports/`)
      .then(response => {
        const filteredData13 = response.data.filter(item => parseInt(item.posted_by) === parseInt(userID));
        setcompetencyData13(filteredData13);

        const filteredData23 = response.data.filter(item => parseInt(item.posted_by) === parseInt(userID));
        setcompetencyData23(filteredData23);

        const filteredData33 = response.data.filter(item => parseInt(item.posted_by) === parseInt(userID));
        setcompetencyData33(filteredData33);

        const filteredData43 = response.data.filter(item => parseInt(item.posted_by) === parseInt(userID));
        setcompetencyData43(filteredData43);

        const filteredData52 = response.data.filter(item => parseInt(item.posted_by) === parseInt(userID));
        setcompetencyData52(filteredData52);

        const filteredData63 = response.data.filter(item => parseInt(item.posted_by) === parseInt(userID));
        setcompetencyData63(filteredData63);

      })

      axios.get(`${process.env.REACT_APP_API_KEY}/api/get_awards/`)
      .then(response => {
        const filteredData14 = response.data.filter(item => parseInt(item.posted_by) === parseInt(userID));
        setcompetencyData14(filteredData14);

        const filteredData24 = response.data.filter(item => parseInt(item.posted_by) === parseInt(userID));
        setcompetencyData24(filteredData24);

        

        const filteredData35 = response.data.filter(item => parseInt(item.posted_by) === parseInt(userID));
        setcompetencyData35(filteredData35);

        const filteredData44 = response.data.filter(item => parseInt(item.posted_by) === parseInt(userID));
        setcompetencyData44(filteredData44);

        const filteredData54 = response.data.filter(item => parseInt(item.posted_by) === parseInt(userID));
        setcompetencyData44(filteredData54);

        const filteredData64 = response.data.filter(item => parseInt(item.posted_by) === parseInt(userID));
        setcompetencyData44(filteredData64);

        const filteredData53 = response.data.filter(item => parseInt(item.posted_by) === parseInt(userID));
        setcompetencyData53(filteredData53);
      })

      axios.get(`${process.env.REACT_APP_API_KEY}/api/get_society/`)
      .then(response => {
        const filteredData15 = response.data.filter(item => parseInt(item.posted_by) === parseInt(userID));
        setcompetencyData15(filteredData15);

        const filteredData25 = response.data.filter(item => parseInt(item.posted_by) === parseInt(userID));
        setcompetencyData25(filteredData25);

        const filteredData36 = response.data.filter(item => parseInt(item.posted_by) === parseInt(userID));
        setcompetencyData36(filteredData36);

        const filteredData45 = response.data.filter(item => parseInt(item.posted_by) === parseInt(userID));
        setcompetencyData45(filteredData45);

        const filteredData54 = response.data.filter(item => parseInt(item.posted_by) === parseInt(userID));
        setcompetencyData54(filteredData54);

        const filteredData65 = response.data.filter(item => parseInt(item.posted_by) === parseInt(userID));
        setcompetencyData65(filteredData65);
      })

      axios.get(`${process.env.REACT_APP_API_KEY}/api/get_eventStd/`)
      .then(response => {
        const filteredData16 = response.data.filter(item => parseInt(item.posted_by) === parseInt(userID));
        setcompetencyData16(filteredData16);

        const filteredData26 = response.data.filter(item => parseInt(item.posted_by) === parseInt(userID));
        setcompetencyData26(filteredData26);

        const filteredData38 = response.data.filter(item => parseInt(item.posted_by) === parseInt(userID));
        setcompetencyData38(filteredData38);

        const filteredData55 = response.data.filter(item => parseInt(item.posted_by) === parseInt(userID));
        setcompetencyData55(filteredData55);

        const filteredData66 = response.data.filter(item => parseInt(item.posted_by) === parseInt(userID));
        setcompetencyData66(filteredData66);
      })

      axios.get(`${process.env.REACT_APP_API_KEY}/api/get_intern/`)
      .then(response => {
        const filteredData17 = response.data.filter(item => parseInt(item.posted_by) === parseInt(userID));
        setcompetencyData17(filteredData17);

        const filteredData37 = response.data.filter(item => parseInt(item.posted_by) === parseInt(userID));
        setcompetencyData37(filteredData37);

        const filteredData56 = response.data.filter(item => parseInt(item.posted_by) === parseInt(userID));
        setcompetencyData56(filteredData56);

        const filteredData67 = response.data.filter(item => parseInt(item.posted_by) === parseInt(userID));
        setcompetencyData67(filteredData67);
      })



      axios.get(`${process.env.REACT_APP_API_KEY}/api/get_cert/`)
      .then(response => {
        const filteredData21 = response.data.filter(item => parseInt(item.posted_by) === parseInt(userID)); setcompetencyData21(filteredData21);

        const filteredData57 = response.data.filter(item => parseInt(item.posted_by) === parseInt(userID)); setcompetencyData57(filteredData57);
      })


      axios.get(`${process.env.REACT_APP_API_KEY}/api/get_lang/`)
      .then(response => {
        const filteredData34 = response.data.filter(item => parseInt(item.posted_by) === parseInt(userID));
        setcompetencyData34(filteredData34);

        const filteredData58 = response.data.filter(item => parseInt(item.posted_by) === parseInt(userID)); setcompetencyData58(filteredData58);
      })

      

      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, [userID]);

  function LeadLead(activity_name) {
    const labelMap = {
      "Student Council General Secretary": 3,
      "Deputy Head Student": 3,
      "House Captain": 3,
      "Class Prefect": 3,
      "Captain Sports Team": 2,
      "Class Monitor": 2,
      "Head Student": 3,
    };
    return labelMap[activity_name] || 0;
  }


  function LeadSocial(activity_name) {
    const labelMap = {
      "Charity bake sale": 2,
    };
    return labelMap[activity_name] || 0;
  }


  function C32(activity_name) {
    const labelMap = {
      8.01: 3,
      9: 3,
      8.03: 2,
    };
    return labelMap[activity_name] || 0;
  }

  function LeadSports(activity_name) {
    const labelMap = {
      "Football": 1,
      "Basketball": 1,
      "Cricket": 1,
      "Hockey": 1,
      "Lacrosse": 2,
      "Volleyball": 2,
      "Rugby": 3
    };

    return labelMap[activity_name] || 0;
  }


  function C33(activity_name) {
    const labelMap = {
      7: 3,
      7.01: 3,
      7.02: 3,
      7.2: 3,
    };

    return labelMap[activity_name] || 0;
  }


  function C14(activity_name) {
    const labelMap = {
      10: 1,
      10.01: 1,
      10.03: 3,
      10.04: 3,
      7.02: 1,
      8.01: 2,
      8.02: 2,
    };

    return labelMap[activity_name] || 0;
  }

  function LeadAwards(activity_name) {
    const labelMap = {
      "Full attendance": 1,
      "Academic excellence": 1,
      "Merit scholarship": 3,
      "Research grant": 3,
      "Choir Performance": 1,
      "Debates Competition": 2,
      "School/college MUN": 2
    };

    return labelMap[activity_name] || 0;
  }


  function LeadSociety(activity_name) {
    const labelMap = {
      "STEM/STEAM Society": 3,
      "Music Society": 2,
      "Art Society": 2,
      "Debates Society": 3,
      "MUN Society": 3,
      "Drama/Theater Society": 3,
      "Math Society": 2,
      "Astronomy Society": 2,
      "Literature Society": 2,
      "Film Society": 2,
      "Environment Society": 2,
      "Community Service Society": 2,
      "Media & Publications Society": 2,
      "Sports Society": 2,
      "Choir Society": 1,
      "Cooking Society": 1,
      "Cardio Society": 1,
      "Yoga Society": 1,
      "Book Club": 1,
      "Cooking Club": 1,
      "School Magazine": 1,
      "School Social Media": 1
    };
    return labelMap[activity_name] || 0;
  }


  function LeadEvents(activity_name) {
    const labelMap = {
      "Executive Council": 3,
      "Logistics Team": 2,
      "Registrations Team": 2,
      "Socials Team": 2,
      "Publications Team": 2,
      "Finance Team": 2,
      "Promotions Team": 2,
      "Communications Team": 2,
      "IT Team": 2,
      "Security Team": 2,
      "Operations Team": 2
    };
    return labelMap[activity_name] || 0;
  }


  function LeadIntern(activity_name) {
    const labelMap = {
      "Research": 3,
      "Engineering": 0,
      "Marketing/Advertising": 0,
      "Engineering/Technical": 2,
      "Finance/Accounting": 2,
      "Media/Entertainment": 2,
      "Nonprofit/NGO": 2,
      "Medical": 3,
      "Legal": 3,
      "Graphic Design/Visual Arts": 0,
      "Software Development/IT": 0,
      "Startup/Entrepreneurship": 3
    };
    return labelMap[activity_name] || 0;
  }


  function TechCert(cert_name) {
    const labelMap = {
      "Google certification": 3,
      "Codecademy course": 3
    };
    return labelMap[cert_name] || 0;
  }

  function TechAward(activity_name) {
    const labelMap = {
      "Full attendance": 1,
      "Academic excellence": 1,
      "Science Competition": 2,
      "Science Fair": 2,
      "Short Film Competition": 3,
      "Robotics Competition": 3,
      "Gaming Competition": 3,
      "Photography Competition": 3
    };
    return labelMap[activity_name] || 0;
  }


  function TechTalent(activity_name) {
    const labelMap = {
      "Photography": 3,
      "Videography": 3,
      "Gaming": 3,
      "SEO": 3,
      "GFX": 3,
      "Graphic Design": 1,
      "Guitar": 1,
      "Editing": 3,
      "Jewellery Making": 0,
      "Social Media Influencer": 3
    };
    return labelMap[activity_name] || 0;
  }


  function TechSociety(activity_name) {
    const labelMap = {
      "STEM/STEAM Society": 3,
      "Math Society": 3,
      "Astronomy Society": 2,
      "Film Society": 3,
      "Media & Publications Society": 3
    };
    return labelMap[activity_name] || 0;
  }


  function TechEvent(activity_name) {
    const labelMap = {

      "Publications Team": 2,
      "Finance Team": 2,
      "Communications Team": 2,
      "IT Team": 3
    };
    return labelMap[activity_name] || 0;
  }


  function TechIntern(activity_name) {
    const labelMap = {

      "Engineering": 3,
      "Marketing/Advertising": 3,
      "Engineering/Technical": 2,
      "Media/Entertainment": 3,
      "Graphic Design/Visual Arts": 3,
      "Software Development/IT": 3,
      "Startup/Entrepreneurship": 3
    };
    return labelMap[activity_name] || 0;
  }


  function IPSLead (activity_name) {
    const labelMap = {

      "Head Student": 3,
      "Student Council General Secretary": 3,
      "Deputy Head Student": 3,
      "House Captain": 3,
      "Class Prefect": 2,
      "Class Monitor": 2,
      "Captain Sports Team": 2
    };
    return labelMap[activity_name] || 0;
  }


  function IPSSocial(activity_name) {
    const labelMap = {

      "Charity collection": 3,
      "Hospital volunteer": 3,
      "Charity bake sale": 2
    };
    return labelMap[activity_name] || 0;
  }


  function IPSSports(activity_name) {
    const labelMap = {

      "Football": 3,
      "Basketball": 3,
      "Cricket": 3,
      "Hockey": 3,
      "Throwball": 2,
      "Futsal": 2,
      "Rowing": 3,
      "Baseball": 2,
      "Handball": 2,
      "Netball": 2,
      "Lacrosse": 2,
      "Ballet": 2,
      "Volleyball": 3,
      "Dodgeball": 2,
      "Rugby": 3
    };
    return labelMap[activity_name] || 0;
  }


  function IPSLang(activity_name) {
    const labelMap = {
      af: 2,
    sq: 2,
    am: 2,
    ar: 2,
    an: 2,
    hy: 2,
    ast: 2,
    az: 2,
    eu: 2,
    be: 2,
    bn: 2,
    bs: 2,
    br: 2,
    bg: 2,
    ca: 2,
    ckb: 2,
    zh: 2,
    "zh-HK": 2,
    "zh-CN": 2,
    "zh-TW": 2,
    co: 2,
    hr: 2,
    cs: 2,
    da: 2,
    nl: 2,
    eo: 2,
    et: 2,
    fo: 2,
    fil: 2,
    fi: 2,
    fr: 2,
    "fr-CA": 2,
    "fr-FR": 2,
    "fr-CH": 2,
    gl: 2,
    ka: 2,
    de: 2,
    "de-AT": 2,
    "de-DE": 2,
    "de-LI": 2,
    "de-CH": 2,
    el: 2,
    gn: 2,
    gu: 2,
    ha: 2,
    haw: 2,
    he: 2,
    hi: 2,
    hu: 2,
    is: 2,
    id: 2,
    ia: 2,
    ga: 2,
    it: 2,
    "it-IT": 2,
    "it-CH": 2,
    ja: 2,
    kn: 2,
    kk: 2,
    km: 2,
    ko: 2,
    ku: 2,
    ky: 2,
    lo: 2,
    la: 2,
    lv: 2,
    ln: 2,
    lt: 2,
    mk: 2,
    ms: 2,
    ml: 2,
    mt: 2,
    mr: 2,
    mn: 2,
    ne: 2,
    no: 2,
    nb: 2,
    nn: 2,
    oc: 2,
    or: 2,
    om: 2,
    ps: 2,
    fa: 2,
    pl: 2,
    pt: 2,
    "pt-BR": 2,
    "pt-PT": 2,
    pa: 2,
    qu: 2,
    ro: 2,
    mo: 2,
    rm: 2,
    ru: 2,
    gd: 2,
    sr: 2,
    sh: 2,
    sn: 2,
    sd: 2,
    si: 2,
    sk: 2,
    sl: 2,
    so: 2,
    st: 2,
    es: 2,
    "es-AR": 2,
    "es-419": 2,
    "es-MX": 2,
    "es-ES": 2,
    "es-US": 2,
    su: 2,
    sw: 2,
    sv: 2,
    tg: 2,
    ta: 2,
    tt: 2,
    te: 2,
    th: 2,
    ti: 2,
    to: 2,
    tr: 2,
    tk: 2,
    tw: 2,
    uk: 2,
    ug: 2,
    uz: 2,
    vi: 2,
    wa: 2,
    cy: 2,
    fy: 2,
    xh: 2,
    yi: 2,
    yo: 2,
    zu: 2,
    };
    return labelMap[activity_name] || 0;
  }



  function IPSAwards(activity_name) {
    const labelMap = {

      "Full attendance": 1,
      "Academic excellence": 1,
      "Merit scholarship": 1,
      "Research grant": 3,
      "Play/Musical": 2,
      "Choir Performance": 2,
      "Aerobics Competition": 3,
      "Bake Sale": 2,
      "Trivia Competition": 2,
      "Cooking Competition": 2,
      "Modeling Competition": 1,
      "Talent Show": 3,
      "Acting Competition": 3,
      "Composition Competition": 2
    };
    return labelMap[activity_name] || 0;
  }


  function IPSTalents(activity_name) {
    const labelMap = {

      "Videography": 3,
      "Graphic Design": 2,
      "Cooking": 3,
      "Piano": 1,
      "Ukulele": 1,
      "Violin": 1,
      "Singing": 2,
      "Flute": 1,
      "Cycling": 2,
      "Cardio": 3,
      "Swimming": 0,
      "Social Media Influencer": 2
    };
    return labelMap[activity_name] || 0;
  }

  function IPSSociety(activity_name) {
    const labelMap = {

      "Music Society": 3,
      "Debates Society": 2,
      "MUN Society": 2,
      "Drama/Theater Society": 2,
      "Literature Society": 2,
      "Book Club": 2,
      "Cooking Club": 2,
      "School Magazine": 2,
      "School Social Media": 2
    };
    return labelMap[activity_name] || 0;
  }


  function IPSEvents(activity_name) {
    const labelMap = {

      "Executive Council": 3,
      "Logistics Team": 2,
      "Registrations Team": 2,
      "Socials Team": 3,
      "Promotions Team": 2,
      "Security Team": 2,
    };
    return labelMap[activity_name] || 0;
  }
    


  function SocialSocial(activity_name) {
    const labelMap = {
      "Food drive": 3,
      "Clothes drive": 3,
      "Charity collection": 3,
      "Blood donation drive": 3,
      "Hospital volunteer": 3,
      "Trash clean-up": 2,
      "Charity bake sale": 2
    };
    return labelMap[activity_name] || 0;
  }


  function SocialCert(activity_name) {
    const labelMap = {
      "First aid certification": 3,
    };
    return labelMap[activity_name] || 0;
  }

  function SocialAwards(activity_name) {
    const labelMap = {
      "Full attendance": 1,
    };
    return labelMap[activity_name] || 0;
  }

  function SocialSociety(activity_name) {
    const labelMap = {
      "Environment Society": 3,
      "Community Service Society": 3
    };
    return labelMap[activity_name] || 0;
  }

  function SocialIntern(activity_name) {
    const labelMap = {
      "Nonprofit/NGO"	: 3,
      "Medical" :	3
    };
    return labelMap[activity_name] || 0;
  }


  function CtLead(activity_name) {
    const labelMap = {
      "Head Student": 3,
      "Student Council General Secretary": 3,
      "Deputy Head Student": 2,
      "House Captain": 2,
      "Class Prefect": 2,
      "Captain Sports Team": 2
    };
    return labelMap[activity_name] || 0;
  }


  function CtCert(cert_name) {
    const labelMap = {
      "First aid certification": 2,
      "Google certification": 3,
      "Coursera course": 3,
      "edX course": 3,
      "Udemy course": 3,
      "Masterclass course": 3,
      "Duolingo course": 3,
      "Khan Academy course": 3,
      "Codecademy course": 3,
      "Domestika course": 3,
      "Udacity course": 3
    };
    return labelMap[cert_name] || 0;
  }


  function CtAwards(activity_name) {
    const labelMap = {
      "Full attendance": 1,
      "Academic excellence": 1,
      "Merit scholarship": 3,
      "Research grant": 3,
      "Poem Recitation": 2,
      "Spoken Word Contest": 2,
      "Play/Musical": 2,
      "Aerobics Competition": 1,
      "Artbeat": 3,
      "Debates Competition": 3,
      "School/college MUN": 2,
      "Math Competition": 3,
      "Trivia Competition": 2,
      "Science Competition": 3,
      "Science Fair": 3,
      "Short Film Competition": 2,
      "Robotics Competition": 2,
      "Poster Making Competition": 3,
      "Gaming Competition": 2,
      "Fashion Design Competition": 3,
      "Cooking Competition": null,
      "Modeling Competition": null,
      "Talent Show": 2,
      "Handwriting Competition": 3,
      "Essay Competition": 3,
      "Acting Competition": 2,
      "Drawing Competition": 3,
      "Painting Competition": 3,
      "Composition Competition": 3,
      "Lucky Draw": 3,
      "Spelling Competition": 3,
      "Certificate of Achievement": 1
    };
    return labelMap[activity_name] || 0;
  }



  function CtTalent(activity_name) {
    const labelMap = {
      "Photography": 2,
      "Videography": 3,
      "Gaming": 3,
      "SEO": 3,
      "GFX": 3,
      "Knitting/Crochet": 3,
      "Resin art": 3,
      "Embroidery": 3,
      "Painting": 3,
      "Drawing": 3,
      "Sculpture": 3,
      "Graphic Design": 3,
      "Writing": 3,
      "Poetry": 3,
      "Reading": 3,
      "Makeup": 1,
      "Cooking": 2,
      "Piano": 3,
      "Ukulele": 3,
      "Violin": 3,
      "Singing": 2,
      "Flute": 3,
      "Cycling": 2,
      "Editing": 3,
      "Jewellery Making": 2,
      "Swimming": 0,
      "Chess": 3,
      "Social Media Influencer": 2
    };
    return labelMap[activity_name] || 0;
  }


  function CtSociety(activity_name) {
    const labelMap = {
      "STEM/STEAM Society": 2,
      "Art Society": 3,
      "Debates Society": 3,
      "MUN Society": 3,
      "Drama/Theater Society": 3,
      "Math Society": 3,
      "Astronomy Society": 3,
      "Literature Society": 3,
      "Choir Society": 2,
      "Cooking Society": 1,
      "Book Club": 2,
      "Cooking Club": 2,
      "School Magazine": 2,
      "School Social Media": 2
    };
    return labelMap[activity_name] || 0;
  }

  function CtEvents(activity_name) {
    const labelMap = {
      "Executive Council": 3,
      "Registrations Team": 1,
      "Socials Team": 2,
      "Publications Team": 2,
      "Finance Team": 3,
      "Promotions Team": 2,
      "Communications Team": 1,
      "IT Team": 3,
      "Operations Team": 3
    };
    return labelMap[activity_name] || 0;
  }

  function CtIntern(activity_name) {
    const labelMap = {
      "Research": 3,
      "Engineering": 3,
      "Marketing/Advertising": 3,
      "Engineering/Technical": 3,
      "Finance/Accounting": 3,
      "Media/Entertainment": 3,
      "Nonprofit/NGO": 3,
      "Medical": 3,
      "Legal": 3,
      "Graphic Design/Visual Arts": 3,
      "Software Development/IT": 3,
      "Startup/Entrepreneurship": 3
    };
    return labelMap[activity_name] || 0;
  }

  
  function CtSports(activity_name) {
    const labelMap = {
      "Football": 2,
      "Basketball": 2,
      "Cricket": 2,
      "Hockey": 2,
      "Badminton": 2,
      "Tennis": 2,
      "Gymnastics": 2,
      "Throwball": 2,
      "Futsal": 2,
      "Archery": 2,
      "Cycling": 2,
      "Sprint": 2,
      "Relay race": 2,
      "Baseball": 2,
      "Fencing": 3,
      "Golf": 2,
      "Handball": 2,
      "Netball": 2,
      "Rowing": 1,
      "Skateboarding": 2,
      "Road bicycle racing": 2,
      "Ballet": 3,
      "Kabaddi": 2,
      "Kickboxing": 2,
      "Boxing": 2,
      "Lacrosse": 2,
      "Polo": 2,
      "Water Polo": 2,
      "Long Jump": 2,
      "High Jump": 2,
      "Shot put": 2,
      "Pole Vault": 2,
      "Pickleball": 2,
      "Chess": 3,
      "Track & field": 2,
      "Curling": 2,
      "Hurdle races": 2,
      "Ice skating": 3,
      "Surfing": 2,
      "Skiing": 3,
      "Snowboarding": 2,
      "Dodgeball": 3,
      "Climbing": 3,
      "Snooker": 3,
      "Squash": 2,
      "Table Tennis": 2,
      "Volleyball": 2,
      "Weightlifting": 2,
      "Rugby": 2
    };
    return labelMap[activity_name] || 0;
  }


  function PhyLead(activity_name) {
    const labelMap = {
      "Captain Sports Team": 3,
      
    };
    return labelMap[activity_name] || 0;
  }

  function PhySocial(activity_name) {
    const labelMap = {
      "Hospital volunteer": 2,
      "Trash clean-up": 2
    };
    return labelMap[activity_name] || 0;
  }

  function PhySocial(activity_name) {
    const labelMap = {
      "Football": 3,
      "Basketball": 3,
      "Cricket": 3,
      "Hockey": 3,
      "Badminton": 3,
      "Tennis": 3,
      "Gymnastics": 3,
      "Throwball": 3,
      "Futsal": 3,
      "Archery": 3,
      "Cycling": 3,
      "Sprint": 3,
      "Relay race": 3,
      "Baseball": 3,
      "Fencing": 2,
      "Golf": 3,
      "Handball": 3,
      "Netball": 3,
      "Rowing": 3,
      "Skateboarding": 3,
      "Road bicycle racing": 3,
      "Ballet": 3,
      "Kabaddi": 3,
      "Kickboxing": 3,
      "Boxing": 3,
      "Strongman": 3,
      "Lacrosse": 3,
      "Polo": 3,
      "Water Polo": 3,
      "Long Jump": 3,
      "High Jump": 3,
      "Shot put": 3,
      "Pole Vault": 3,
      "Pickleball": 3,
      "Track & field": 3,
      "Curling": 3,
      "Endurance running": 3,
      "Hurdle races": 3,
      "Ice skating": 3,
      "Surfing": 3,
      "Skiing": 3,
      "Snowboarding": 3,
      "Dodgeball": 3,
      "Climbing": 3,
      "Snooker": 1,
      "Squash": 3,
      "Swimming": 3,
      "Table Tennis": 3,
      "Volleyball": 3,
      "Weightlifting": 3,
      "Rugby": 3
    };
    return labelMap[activity_name] || 0;
  }


  function PhyAwards(activity_name) {
    const labelMap = {
      "Full attendance":1,
      "Sports scholarship":3,
      "Dance Competition" : 3,
      "Modeling Competition": 3
    };
    return labelMap[activity_name] || 0;
  }

  function PhyTalent(activity_name) {
    const labelMap = {
      "Cycling": 3,
      "Dancing": 2,
      "Cardio": 3,
      "Football": 3,
      "Cricket": 3,
      "Swimming": 3,
      "Basketball": 3,
      "Badminton": 3,
      "Hockey": 3,
      "Tennis": 3,
      "Ballet": 3,
      "Table Tennis": 3,
      "Horseriding": 3,
      "Skating": 3
    };
    return labelMap[activity_name] || 0;
  }

  const calculatedCompetencies11 = competencyData11.map(item => LeadLead(item.activity_name));
  const calculatedCompetencies12 = competencyData12.map(item => LeadSocial(item.activity_name));
  const calculatedCompetencies13 = competencyData13.map(item => LeadSports(item.activity_name));
  const calculatedCompetencies14 = competencyData14.map(item => LeadAwards(item.activity_name));
  const calculatedCompetencies15 = competencyData15.map(item => LeadSociety(item.activity_name));
  const calculatedCompetencies16 = competencyData16.map(item => LeadEvents(item.activity_name));
  const calculatedCompetencies17 = competencyData17.map(item => LeadIntern(item.activity_name));

  const totalCompetencies11 = calculatedCompetencies11.reduce((sum, competency) => sum + competency, 0);
  const totalCompetencies12 = calculatedCompetencies12.reduce((sum, competency) => sum + competency, 0);
  const totalCompetencies13 = calculatedCompetencies13.reduce((sum, competency) => sum + competency, 0);
  const totalCompetencies14 = calculatedCompetencies14.reduce((sum, competency) => sum + competency, 0);
  const totalCompetencies15 = calculatedCompetencies15.reduce((sum, competency) => sum + competency, 0);
  const totalCompetencies16 = calculatedCompetencies16.reduce((sum, competency) => sum + competency, 0);
  const totalCompetencies17 = calculatedCompetencies17.reduce((sum, competency) => sum + competency, 0);




  const calculatedCompetencies21 = competencyData21.map(item => TechCert(item.cert_name));
  const calculatedCompetencies22 = competencyData22.map(item => TechAward(item.activity_name));
  const calculatedCompetencies23 = competencyData23.map(item => TechTalent(item.activity_name));
  const calculatedCompetencies24 = competencyData24.map(item => TechIntern(item.activity_name));
  const calculatedCompetencies25 = competencyData25.map(item => TechSociety(item.activity_name));
  const calculatedCompetencies26 = competencyData25.map(item => TechEvent(item.activity_name));


  const totalCompetencies21 = calculatedCompetencies21.reduce((sum, competency) => sum + competency, 0);
  const totalCompetencies22 = calculatedCompetencies22.reduce((sum, competency) => sum + competency, 0);
  const totalCompetencies23 = calculatedCompetencies23.reduce((sum, competency) => sum + competency, 0);
  const totalCompetencies24 = calculatedCompetencies24.reduce((sum, competency) => sum + competency, 0);
  const totalCompetencies25 = calculatedCompetencies25.reduce((sum, competency) => sum + competency, 0);
  const totalCompetencies26 = calculatedCompetencies26.reduce((sum, competency) => sum + competency, 0);

  const calculatedCompetencies31 = competencyData31.map(item => IPSLead(item.activity_name));
  const totalCompetencies31 = calculatedCompetencies31.reduce((sum, competency) => sum + competency, 0);

  const calculatedCompetencies32 = competencyData32.map(item => IPSAwards(item.activity_name));
  const totalCompetencies32 = calculatedCompetencies32.reduce((sum, competency) => sum + competency, 0);

  const calculatedCompetencies33 = competencyData33.map(item => IPSEvents(item.activity_name));
  const totalCompetencies33 = calculatedCompetencies33.reduce((sum, competency) => sum + competency, 0);

  const calculatedCompetencies34 = competencyData34.map(item => IPSSocial(item.activity_name));
  const totalCompetencies34 = calculatedCompetencies34.reduce((sum, competency) => sum + competency, 0);

  const calculatedCompetencies35 = competencyData35.map(item => IPSSociety(item.activity_name));
  const totalCompetencies35 = calculatedCompetencies35.reduce((sum, competency) => sum + competency, 0);

  const calculatedCompetencies36 = competencyData36.map(item => IPSSports(item.activity_name));
  const totalCompetencies36 = calculatedCompetencies36.reduce((sum, competency) => sum + competency, 0);

  const calculatedCompetencies37 = competencyData37.map(item => IPSTalents(item.activity_name));
  const totalCompetencies37 = calculatedCompetencies37.reduce((sum, competency) => sum + competency, 0);

  const calculatedCompetencies38 = competencyData38.map(item => IPSSports(item.activity_name));
  const totalCompetencies38 = calculatedCompetencies38.reduce((sum, competency) => sum + competency, 0);


  const calculatedCompetencies42 = competencyData42.map(item => SocialSociety(item.activity_name));
  const totalCompetencies42 = calculatedCompetencies42.reduce((sum, competency) => sum + competency, 0);

  const calculatedCompetencies43 = competencyData43.map(item => SocialAwards(item.activity_name));
  const totalCompetencies43 = calculatedCompetencies43.reduce((sum, competency) => sum + competency, 0);


  const calculatedCompetencies44 = competencyData44.map(item => SocialCert(item.cert_name));
  const totalCompetencies44 = calculatedCompetencies44.reduce((sum, competency) => sum + competency, 0);

  const calculatedCompetencies45 = competencyData45.map(item => SocialIntern(item.activity_name));
  const totalCompetencies45 = calculatedCompetencies45.reduce((sum, competency) => sum + competency, 0);

  const calculatedCompetencies46 = competencyData46.map(item => SocialSocial(item.activity_name));
  const totalCompetencies46 = calculatedCompetencies46.reduce((sum, competency) => sum + competency, 0);



  const calculatedCompetencies50 = competencyData50.map(item => CtLead(item.activity_name));
  const totalCompetencies50 = calculatedCompetencies50.reduce((sum, competency) => sum + competency, 0);

  const calculatedCompetencies51 = competencyData51.map(item => CtSports(item.activity_name));
  const totalCompetencies51 = calculatedCompetencies51.reduce((sum, competency) => sum + competency, 0);


  const calculatedCompetencies52 = competencyData52.map(item => IPSLang(item.activity_name));
  const totalCompetencies52 = calculatedCompetencies52.reduce((sum, competency) => sum + competency, 0);

  const calculatedCompetencies53 = competencyData53.map(item => CtAwards(item.activity_name));
  const totalCompetencies53 = calculatedCompetencies53.reduce((sum, competency) => sum + competency, 0);

  const calculatedCompetencies54 = competencyData54.map(item => CtCert(item.activity_name));
  const totalCompetencies54 = calculatedCompetencies54.reduce((sum, competency) => sum + competency, 0);
  
  const calculatedCompetencies55 = competencyData55.map(item => CtIntern(item.activity_name));
  const totalCompetencies55 = calculatedCompetencies55.reduce((sum, competency) => sum + competency, 0);

  const calculatedCompetencies56 = competencyData56.map(item => CtSociety(item.activity_name));
  const totalCompetencies56 = calculatedCompetencies56.reduce((sum, competency) => sum + competency, 0);

  const calculatedCompetencies57 = competencyData57.map(item => CtAwards(item.activity_name));
  const totalCompetencies57 = calculatedCompetencies57.reduce((sum, competency) => sum + competency, 0);
  

  const calculatedCompetencies61 = competencyData61.map(item => PhyAwards(item.activity_name));
  const totalCompetencies61 = calculatedCompetencies61.reduce((sum, competency) => sum + competency, 0);
  
  const calculatedCompetencies62 = competencyData62.map(item => PhyLead(item.activity_name));
  const totalCompetencies62 = calculatedCompetencies62.reduce((sum, competency) => sum + competency, 0);
  
  const calculatedCompetencies63 = competencyData63.map(item => PhySocial(item.activity_name));
  const totalCompetencies63 = calculatedCompetencies63.reduce((sum, competency) => sum + competency, 0);
  
  const calculatedCompetencies64 = competencyData64.map(item => PhyTalent(item.activity_name));
  const totalCompetencies64 = calculatedCompetencies64.reduce((sum, competency) => sum + competency, 0);
  
  const LeadComp = (totalCompetencies11 + totalCompetencies12+ totalCompetencies13+ totalCompetencies14 + totalCompetencies15+ totalCompetencies16 + totalCompetencies17);
  const sumTimes1 = (totalCompetencies11 + totalCompetencies12+ totalCompetencies13+ totalCompetencies14 + totalCompetencies15+ totalCompetencies16 + totalCompetencies17) * 20;

  const TechComp = (totalCompetencies21 + totalCompetencies22+ totalCompetencies23+ totalCompetencies24 + totalCompetencies25 + totalCompetencies26 );
  const sumTimes2 = (totalCompetencies21 + totalCompetencies22+ totalCompetencies23+ totalCompetencies24 + totalCompetencies25+ totalCompetencies26) * 20;
  
  const ipsComp = (totalCompetencies31+totalCompetencies32+totalCompetencies33+totalCompetencies34+totalCompetencies35+ totalCompetencies36 + totalCompetencies37 + totalCompetencies38);
  const sumTimes3 = (totalCompetencies31+totalCompetencies32+totalCompetencies33+totalCompetencies34+totalCompetencies35+ totalCompetencies36 + totalCompetencies37 + totalCompetencies38) * 20;


  const socialComp = (totalCompetencies42+totalCompetencies43+totalCompetencies44+totalCompetencies45+totalCompetencies46);
  const sumTimes4 = (totalCompetencies42+totalCompetencies43+totalCompetencies44+totalCompetencies45+totalCompetencies46) * 20;

  const CtComp = (totalCompetencies50+totalCompetencies51+totalCompetencies52+totalCompetencies53+totalCompetencies54+totalCompetencies55+totalCompetencies56+totalCompetencies57);
  const sumTimes5 = (totalCompetencies50+totalCompetencies51+totalCompetencies52+totalCompetencies53+totalCompetencies54+totalCompetencies55+totalCompetencies56+totalCompetencies57) * 20;


  const PhyComp = (totalCompetencies63);
  const sumTimes6 = (totalCompetencies63) * 20;


  let level1;
  let level2;
  let level3;
  let level4;
  let level5;
  let level6;


  if (sumTimes1 >= 10 && sumTimes1 <= 199) {
    level1 = 1;
  } else if (sumTimes1 >= 200 && sumTimes1 <= 399) {
    level1 = 2;
  } else if (sumTimes1 >= 400 && sumTimes1 <= 699) {
    level1 = 3;
  } else if (sumTimes1 >= 700 && sumTimes1 <= 999) {
    level1 = 4;
  } 
  
  else if (sumTimes1 < 1) {
    level1 = 0;
  }

  else {
    level1 = 5;
  }


  if (sumTimes2 >= 10 && sumTimes2 <= 199) {
    level2 = 1;
  } else if (sumTimes2 >= 200 && sumTimes2 <= 399) {
    level2 = 2;
  } else if (sumTimes2 >= 400 && sumTimes2 <= 699) {
    level2 = 3;
  } else if (sumTimes2 >= 700 && sumTimes2 <= 999) {
    level2 = 4;
  }
  
  else if (sumTimes2 < 1) {
    level2 = 0;
  }

  else {
    level2 = 5;
  }

  if (sumTimes3 >= 10 && sumTimes3 <= 199) {
    level3 = 1;
  } else if (sumTimes3 >= 200 && sumTimes3 <= 399) {
    level3 = 2;
  } else if (sumTimes3 >= 400 && sumTimes3 <= 699) {
    level3 = 3;
  } else if (sumTimes3 >= 700 && sumTimes3 <= 999) {
    level3 = 4;
  } 
  
  else if (sumTimes3 < 1) {
    level3 = 0;
  }

  else {
    level3 = 5;
  }

  if (sumTimes4 >= 10 && sumTimes4 <= 199) {
    level4 = 1;
  } else if (sumTimes4 >= 200 && sumTimes4 <= 399) {
    level4 = 2;
  } else if (sumTimes4 >= 400 && sumTimes4 <= 699) {
    level4 = 3;
  } else if (sumTimes4 >= 700 && sumTimes4 <= 999) {
    level4 = 4;
  } 
  
  else if (sumTimes4 < 1) {
    level4 = 0;
  }

  else {
    level4 = 5;
  }


  if (sumTimes5 >= 10 && sumTimes5 <= 199) {
    level5 = 1;
  } else if (sumTimes5 >= 200 && sumTimes5 <= 399) {
    level5 = 2;
  } else if (sumTimes5 >= 400 && sumTimes5 <= 699) {
    level5 = 3;
  } else if (sumTimes5 >= 700 && sumTimes5 <= 999) {
    level5 = 4;
  }
  else if (sumTimes5 < 1) {
    level5 = 0;
  } 
  else {
    level5 = 5;
  }


  if (sumTimes6 >= 10 && sumTimes6 <= 199) {
    level6 = 1;
  } else if (sumTimes6 >= 200 && sumTimes6 <= 399) {
    level6 = 2;
  } else if (sumTimes6 >= 400 && sumTimes6 <= 699) {
    level6 = 3;
  } else if (sumTimes6 >= 700 && sumTimes6 <= 999) {
    level6 = 4;
  } 
  
  else if (sumTimes6 < 10) {
    level6 = 0;
  }
  else {
    level6 = 5;
  }



  const options = {
    xaxis: {
      categories: [t("Technology"), t("Leadership"), t("Interpersonal"), t("Critical Thinking"), t("Physical Fitness"), t("Social Work")]
    },

    yaxis: {
      show: false,
      showAlways: false,
    },
    markers: {
      size: 5,
      hover: {
        size: 10
      },
      colors: ['#477BFF', '#47D1CB', '#6F47FF', '#FFCB47', '#FF477B', '#59DBFF', '#CB47FF', '#FF884D', '#00897B'],
    },
    bar: {
      columnWidth: '45%',
      distributed: true,
    },
    stroke: {
      width: 2,
      colors: ['#477BFF', '#47D1CB', '#6F47FF', '#FFCB47', '#FF477B', '#59DBFF', '#CB47FF', '#FF884D', '#00897B'],
    },
      plotOptions: {
        radar: {
          polygons: {
            strokeColor: '#e8e8e8',
            fill: {
                colors: ['#f8f8f8', '#fff']
            }
          }
        }
      }



    
  };

  const series = [
    {
      name: "Level",
      data: [level2, level1, level3, level5, level6, level4]
    }
  ];

  //console.log("Lead" + LeadComp+"("+sumTimes1+")", "Tech" + TechComp+"("+sumTimes2+")","IPS" + ipsComp+"("+sumTimes3+")", "Social" + socialComp+"("+sumTimes4+")","CT" + CtComp+"("+sumTimes5+")", "Phys" + PhyComp+"("+sumTimes6+")");

  const sendPoints = () => {
    axios
      .post(`${process.env.REACT_APP_API_KEY}/api/calculate_badges/`, {
        user_id: currentUser,
        techSum: sumTimes1,
        leadSum: sumTimes2,
        inpSum: sumTimes3,
        spSum: sumTimes4,
        ctSum: sumTimes5,
        pfSum: sumTimes6,
      })
      .then((response) => {
        console.log("Points set successfully");
      })
      .catch((error) => {
        console.error("Error Setting Points:", error);
      });
  };

  useEffect(() => {
    sendPoints();
  }, [currentUser, sumTimes1, sumTimes2, sumTimes3, sumTimes4, sumTimes5, sumTimes6]);

  return (
    <div>
      <Chart
        options={options}
        series={series}
        type="radar"
        width="100%"
        height={430}
        style={{ margin: 'auto' }}
        className="chartMain"
      />

<div className='block md:hidden'>
<div className='divider'></div>

      <div className='p-2'>
        <ul className='flex flex-col gap-3'>
          <li className='flex flex-row justify-between'> <span>💻 Technology</span> <span className='badge badge-primary'> {sumTimes1} pt.</span> </li>
          <li className='flex flex-row justify-between'> <span>🏆 Leadership</span> <span className='badge badge-primary'> {sumTimes2} pt.</span> </li>
          <li className='flex flex-row justify-between'> <span>🤝 Interpersonal</span> <span className='badge badge-primary'> {sumTimes3} pt.</span> </li>
          <li className='flex flex-row justify-between'> <span>🎗️ Social Work</span> <span className='badge badge-primary'> {sumTimes6} pt.</span> </li>
          <li className='flex flex-row justify-between'> <span>🧠 Critical</span> <span className='badge badge-primary'> {sumTimes4} pt.</span> </li>
          <li className='flex flex-row justify-between'> <span>⛹️‍♂️ Physical Fitness</span> <span className='badge badge-primary'> {sumTimes5} pt.</span> </li>
        </ul>
      </div>
      </div>
    </div>
  );
};

export default RadarChart;
