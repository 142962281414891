import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast, Toaster } from 'react-hot-toast';
const FeedbackEvents = () => {
  let params = useParams();
  let activityId = params.id;
  const [activity, setActivity] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchActivity = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_KEY}/api/get_eventStd/`);
        const filteredActivity = response.data.find(
          (activity) => parseInt(activity.id) === parseInt(activityId)
        );
        setActivity(filteredActivity);
      } catch (error) {
        console.log('Error fetching activity data:', error);
      }
    };

    fetchActivity();
  }, [activityId]);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    // Use the provided endpoint to update the activity details
    try {
      const response = await axios.put(`${process.env.REACT_APP_API_KEY}/api/update_eventStd/${activityId}/`, {
        status: activity.status,
        feedback: activity.feedback,
        score: activity.score,
        activity_name: activity.activity_name,
        duration: activity.duration,
        grade: activity.grade,
        role: activity.role,
        scope: activity.scope,
        service_hours: activity.service_hours,
        posted_by: activity.posted_by,
        post_date: activity.post_date,
        school_in: activity.school_in,
        start_date: activity.start_date,
        category: activity.category,
        // Include other fields here, but don't modify them
      });
      console.log('Activity updated successfully:', response.data);
      toast.success('Good Job! Activity Saved', {
        icon: '👏',
        style: {
          borderRadius: '55px',
          marginBottom: '15px',
        },
      });
      

    } catch (error) {
      console.log('Error updating activity:', error);
    }
  };

  return (
    <div>

{activity && (

<div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">


<button className="btn btn-outline flex justify-center mx-auto mb-8" onClick={() => navigate(-1)}>Go Back</button>



  <div className="mx-auto max-w-lg text-center">
    <h1 className="text-2xl font-bold sm:text-3xl">Review and grade activity</h1>

    <p className="mt-4 text-gray-500">
      Update Status by grading and leaving feedback
    </p>
  </div>

  <form className="mx-auto mb-0 mt-8 max-w-md space-y-4" onSubmit={handleFormSubmit}>


  <div className="form-control w-full ">
  <label className="label">
    <span className="label-text">Status</span>
  </label>
  <select className="select select-bordered" value={activity.status} onChange={(e) => setActivity({ ...activity, status: e.target.value })}>
    <option  selected>Pick one</option>
    <option value="1">Approve</option>
    <option value="0">Reject</option>
  </select>

    </div>
    
    <div className="form-control w-full ">
    <label className="label">
      <span className="label-text">Score</span>

    </label>
    <input type="number" placeholder="Type here" className="input input-bordered w-full " value={activity.score} onChange={(e) => setActivity({ ...activity, score: e.target.value })}/>
    </div>


    <div className="form-control">
    <label className="label">
      <span className="label-text">Your Feedback</span>
    </label>
    <textarea className="textarea textarea-bordered h-24" placeholder="Feedback" value={activity.feedback} onChange={(e) => setActivity({ ...activity, feedback: e.target.value })}></textarea>
  </div>

  <button type="submit" className='btn btn-primary'>Submit</button>
  </form>



  <input type="hidden" name="activity_name" value={activity.activity_name} />
          <input type="hidden" name="grade" value={activity.grade} />
          <input type="hidden" name="duration" value={activity.duration} />
          <input type="hidden" name="role" value={activity.role} />
          <input type="hidden" name="scope" value={activity.scope} />
          <input type="hidden" name="service_hours" value={activity.service_hours} />
          <input type="hidden" name="start_date" value={activity.start_date} />
          <input type="hidden" name="posted_by" value={activity.posted_by} />
          <input type="hidden" name="school_in" value={activity.school_in} />
          <input type="hidden" name="category" value={activity.category} />
          <input type="hidden" name="post_date" value={activity.post_date} />



          
</div>






)}

<div style={{ zIndex: 999999999999999, marginBottom: '2vh' }}>
        <Toaster position="bottom-center" />
      </div>
    </div>
  );
};

export default FeedbackEvents;
