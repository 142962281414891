import React from "react";

function SelectDropdown({ selectedOption, onSelect }) {
  return (
<div>

<select className="select select-bordered join-item w-full"
      value={selectedOption}
      onChange={(e) => onSelect(e.target.value)}>
  <option value="Activity Present">Participating in Extracurriculars</option>
      <option value="Activity Not Present">Not Participating in Extracurriculars</option>
      <option value="All">All</option>
  </select>

</div>
  );
}

export default SelectDropdown;