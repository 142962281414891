// Example SearchInput.js
import React, { useState, useEffect } from 'react';

const SearchInput = ({ onSearch }) => {
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      onSearch(searchQuery);
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchQuery, onSearch]);

  return (
    <div className='p-6'>
<label className="form-control w-full max-w-xs">
  <div className="label">
    <span className="label-text">Search by name or activity</span>
  </div>
  <input type="text" placeholder="eg: Chess Club" value={searchQuery} className="input input-bordered w-full max-w-xs" onChange={(e) => setSearchQuery(e.target.value)} />
</label>
      
    </div>
  );
};

export default SearchInput;
